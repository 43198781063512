import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { ADMIN_UPDATE_FEES, GET_ALL_FEES } from "../../../services/api-service";
import ConfirmModal from "../../../components/deleteConfirmModal";
import "./index.scss";

const TaskbarFee = (props) => {
  const [fees, setFees] = useState({
    promoteTask: 0,
    promoteTasker: 0,
    fees: 0,
  });
  const [feesArray, setFeesArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({
    name: "",
    open: false,
    text: "",
  });

  useEffect(() => {
    getAllFees();
  }, []);

  const getAllFees = async () => {
    setLoading(true);
    try {
      const response = await GET_ALL_FEES();
      setFeesArray(
        response.data.result
          .filter((fee) => fee.value.feesType === "normal")
          .map((fee) => {
            return {
              id: fee._id,
              name: fee.name,
              value: fee.value,
            };
          })
      );

      setFees({
        promoteTask: response.data.result.filter((fee) => fee.name === "promoteTask")[0].value.fees,
        promoteTasker: response.data.result.filter((fee) => fee.name === "promoteTasker")[0].value.fees,
        fees: response.data.result.filter((fee) => fee.name === "fees" && fee.value.feesType === "normal")[0].value.fees,
      });

      setLoading(false);
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  //Handle input Change
  const handleFeechange = (event) => {
    const { name, value } = event.target;
    const feeUpdated = { ...fees };
    feeUpdated[name] = value;
    setFees(feeUpdated);
  };

  const handleAlertPopup = (name) => {
    setAlertData({
      name: name,
      open: true,
      text: name === "promoteTask" ? "This update of Promote task will affect on all task " : name === "promoteTasker" ? "This update will affect on all tasker." : "This changes in Fee will Affect on All contracts",
    });
  };

  //Handle Click On Update
  const handleUpdate = async () => {
    const reqBody = {
      id: feesArray.filter((fee) => fee.name === alertData.name)[0].id,
      value: {
        operation: "+",
        fees: parseFloat(fees[alertData.name]),
        feesType: "normal",
      },
    };
    //console.log("reqBody" , reqBody);
    try {
      const response = await ADMIN_UPDATE_FEES(reqBody);
      //console.log('response',response);
      if (response.data.status === "success") {
        notifier.success(MESSAGE.SUCCESS.TASKBAR_FEE_UPDATED_SUCESSFULLY);
        getAllFees();
        setAlertData({
          name: "",
          open: false,
          text: "",
        });
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  const handleAlertCancel = () => {
    setAlertData({
      name: "",
      open: false,
      text: "",
    });
  };

  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>Taskbar Fee Setings</h4>
      </div>
      <div className="taskbarFee">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="taskbarFee__item">
              <span>Promote Task Fee:</span>
              <TextField
                type="number"
                label="Promote Task Fee"
                variant="outlined"
                size="small"
                name="promoteTask"
                helperText="Value should be in Decimal formate. Like 5.00"
                InputProps={{
                  endAdornment: <InputAdornment position="end">ℏ(s)</InputAdornment>,
                }}
                value={fees.promoteTask}
                onChange={handleFeechange}
              />
              <button onClick={handleAlertPopup.bind(this, "promoteTask")}>Update</button>
            </div>
            <div className="taskbarFee__item">
              <span>Promote Tasker Fee:</span>
              <TextField
                type="number"
                label="Promote Tasker Fee"
                variant="outlined"
                size="small"
                helperText="Value should be in Decimal formate. Like 5.00"
                name="promoteTasker"
                InputProps={{
                  endAdornment: <InputAdornment position="end">ℏ(s)</InputAdornment>,
                }}
                value={fees.promoteTasker}
                onChange={handleFeechange}
              />
              <button onClick={handleAlertPopup.bind(this, "promoteTasker")}>Update</button>
            </div>
            <div className="taskbarFee__item">
              <span>Taskbar Fee Normal:</span>
              <TextField
                type="number"
                label="Taskbar Commision"
                variant="outlined"
                size="small"
                helperText="Value should be in Decimal formate. Like 5.00"
                name="fees"
                InputProps={{
                  endAdornment: <InputAdornment position="end">ℏ(s)</InputAdornment>,
                }}
                value={fees.fees}
                onChange={handleFeechange}
              />
              <button onClick={handleAlertPopup.bind(this, "fees")}>Update</button>
            </div>
          </>
        )}
      </div>
      {/* <div className="taskbarFee__bottom">
          <button>Save</button>
        </div> */}
      <ConfirmModal text={alertData.text} yesCallback={() => handleUpdate()} cancelCallback={handleAlertCancel} modal={alertData.open} delBtnLabel={"Cancel"} yesBtnLabel={"Yes Update"} />
    </>
  );
};

export default TaskbarFee;
