import { ThemeProvider } from "@material-ui/styles";
import "moment-timezone";
import React, { useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import notifier from "./components/notifier";
import { getErrorString } from "./components/validator";
import { DarkThemeContext } from "./context/DarkThemeContext";
import { getToken } from "./firebase";
import RouterOutlet from "./router-outlet";
import { taskbarTheme } from "./theme";

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const value = useMemo(
    () => ({isDarkTheme, setIsDarkTheme}),
    [isDarkTheme, setIsDarkTheme]
  );

  try {
    getToken(setTokenFound);
  } catch (e) {
    console.log(e);
    notifier.warning("Warning From Firebase", getErrorString(e));
  }

  return (
    <DarkThemeContext.Provider value={value}>
      <ThemeProvider theme={taskbarTheme}>
        <BrowserRouter>
          <RouterOutlet />
        </BrowserRouter>
      </ThemeProvider>
    </DarkThemeContext.Provider>
  );
}

export default App;
