import { Fade, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, withStyles } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ListSharpIcon from '@material-ui/icons/ListSharp';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import notifier from '../../../components/notifier';
import Pagination from '../../../components/Pagination';
import { getErrorString } from '../../../components/validator';
import { StyledBackdrop } from '../../../lib/validators';
import { ADMIN_DELETE_PREDEFINED_TASK, ADMIN_PREDEFINED_TASK } from '../../../services/api-service';
import './index.scss';
import PredefinedTaskForm from './teskAddEditForm';

const PredefinedTask  = () =>{
    const [tasks , setTasks] = useState([]);
    const [totalRecords, setTotalRecord] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerms , setSearchTerms] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editTaskData , setEditTaskData] = useState({})
    const [isEditForm , setIsEditForm] = useState(false);
    const [isAlertDelModal , setAlertDelModal] = useState(false);
    const [delTaskId,setDelTaskID] = useState('')


    useEffect(()=>{
      getTasks({})
    },[])

    const getTasks = async(reqBody) => {
      setIsLoading(true);
      try{
         const response = await ADMIN_PREDEFINED_TASK(reqBody);
         setTasks(response.data.result.data);
         setTotalRecord(response.data.result.totalCount);
         setIsLoading(false);
      }
      catch(e){
          notifier.error("Error", getErrorString(e)); 
          setIsLoading(false)
      }
    }


    //events
    const handlePageChange = (page) => {
      setPageNumber(page);
      getTasks({ pageNo: page });
    };

    const handleSearchTermsChange = (e) =>{
      setSearchTerms(e.target.value);
      if( e.target.value === '')
        getTasks({ pageNo: pageNumber });
    }

    const showList = () =>{
        setIsFormOpen(false);
        setIsEditForm(false);
        setEditTaskData({...{}})
        getTasks({ pageNo: pageNumber });
    }

    const editTask = (index) =>{
      setEditTaskData({
        ...{
          id: tasks[index]._id,
          heading: tasks[index].heading,
          description: tasks[index].description,
          skills: tasks[index].skills
        },
      });
      setIsEditForm(true);
      setIsFormOpen(true);
    }

    const delTask = (id) => {
      setAlertDelModal(true)
      setDelTaskID(id)
    }

    const delTaskById = async()=>{
      const reqBody = {
        taskId: delTaskId,
      };

      try{
         const response = await ADMIN_DELETE_PREDEFINED_TASK(reqBody);
         //console.log(response.data);
        if(response.data.status === "success"){
            notifier.success(response.data.result);
             getTasks({ pageNo: pageNumber });
              setAlertDelModal(false);
              setDelTaskID('');
        }
      }
      catch(e){
         notifier.error("Error", getErrorString(e));   
      }
    }

    const handleDelAlertClose = () =>{
      setAlertDelModal(false)
    }

    return (
      <>
        <div className="admin__body__continer__content__heading">
          <h4>
            {!isFormOpen
              ? "Predefined Task List"
              : isEditForm
              ? "Edit Predefined Task"
              : "Add New Predefined Task"}
          </h4>
          {!isFormOpen ? (
            <button className="addButton" onClick={() => setIsFormOpen(true)}>
              Add New
            </button>
          ) : (
            <Tooltip title="Show Task list" placement="top" arrow>
              <IconButton
                onClick={showList}
                style={{ marginLeft: "auto", color: "#66aaa2"}}
              >
                <ListSharpIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {!isFormOpen ? (
          <div style={{ height: "calc(100% - 50px)" }}>
            <div className="search-from">
              <div className="input-feild">
                <TextField
                  label="Search in Predefined Task"
                  className="input"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchTerms}
                  onChange={handleSearchTermsChange}
                />
              </div>
              <button
                className="searchbtn"
                onClick={() => getTasks({ searchPhrase: searchTerms })}
              >
                Search
              </button>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <TableContainer className="data-table-tasks">
                  {tasks.length > 0 ? (
                    <Table size="small" stickyHeader>
                      <colgroup>
                        <col width="50" />
                        <col />
                        <col />
                        <col width="150" />
                        <col />
                        <col width="100" />
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Sr.</StyledTableCell>
                          <StyledTableCell>Heading</StyledTableCell>
                          <StyledTableCell>Descrption</StyledTableCell>
                          <StyledTableCell>Subskills</StyledTableCell>
                          <StyledTableCell>Payment</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tasks.map((task, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell>{task.heading}</StyledTableCell>
                              <StyledTableCell>
                                {task.description}
                              </StyledTableCell>
                              <StyledTableCell>
                                {task.skills.map((skill) => `${skill.name}, `)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {task.paymentTerms.paymentType}
                              </StyledTableCell>
                              <StyledTableCell size="small">
                                <IconButton
                                  size="small"
                                  style={{position: "unset"}}
                                  onClick={editTask.bind(this, index)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <Tooltip
                                  placement="top"
                                  title="Delete this Task"
                                  arrow
                                >
                                  <IconButton
                                    size="small"
                                    onClick={delTask.bind(this, task._id)}
                                    style={{ color: "red", position: "unset" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <div>
                      <p style={{ textAlign: "center", padding: "10px 0" }}>
                        No data to show
                      </p>
                    </div>
                  )}
                </TableContainer>
                <div className="pagination">
                  <Pagination
                    totalRecord={totalRecords}
                    pageNumber={pageNumber}
                    sendCurrentPage={handlePageChange}
                    showControls={true}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="form">
            <PredefinedTaskForm
              handleCancel={showList}
              isEditForm={isEditForm}
              editFormData={editTaskData}
            />
          </div>
        )}
        <Modal
          aria-labelledby="Issue Reporting"
          aria-describedby="If you found any issu Report Here"
          className="alertModal"
          open={isAlertDelModal}
          onClose={handleDelAlertClose}
          closeAfterTransition
          BackdropComponent={StyledBackdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isAlertDelModal}>
            <div className="alertModal__paper">
              <p>Are You Sure ! You want to Delete This Task.</p>
              <div className="actions">
                <button className="yes" onClick={delTaskById}>Yes Delete</button>
                <button className="cancel" onClick={handleDelAlertClose}>
                  Cancel
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
}


const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
      
    },
    
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(6)":{
    
    }
  },
  head: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);


export default PredefinedTask;