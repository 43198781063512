import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/privateRoute";
import AdminUser from "./adminUser";
import CRForm from "./CRForm";
import DisputeManagement from "./disputeManagment";
import ExportExcel from "./exportExcel";
import HederaWalletTask from "./hederaWalletTask";
import PhorhibitedWords from "./phorhibitedWords";
import PointSystem from "./pointSystem";
import PredefinedTask from "./predefinedTasks";
import PhorhibitedTask from "./prohibitedTask";
import Reportings from "./reportings";
import TaskbarFee from "./taskbarFee";
import Transactions from "./transactions";
import UnhandledTransection from "./unhandledTransection";




class RouterOutlet extends Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
          <PrivateRoute path={`${match.url}/users`} component={AdminUser} />
          <PrivateRoute path={`${match.url}/issues`} component={Reportings} />
          <PrivateRoute path={`${match.url}/my-taskbar-fee`} component={TaskbarFee} />
          <PrivateRoute path={`${match.url}/predefined-tasks`} component={PredefinedTask} />
          <PrivateRoute path={`${match.url}/prohibited-words`} component={PhorhibitedWords} />
          <PrivateRoute path={`${match.url}/prohibited-tasks`} component={PhorhibitedTask} />
          <PrivateRoute path={`${match.url}/transactions`} component={Transactions} />
          <PrivateRoute path={`${match.url}/unhandledTransaction`}  component={UnhandledTransection} />
          <PrivateRoute path={`${match.url}/disputeManagement`} component={DisputeManagement} />
          <PrivateRoute path={`${match.url}/raise-an-issue`} component={CRForm} />
          <PrivateRoute path={`${match.url}/walletTask`} component={HederaWalletTask} />
          <PrivateRoute path={`${match.url}/exportExcel`} component={ExportExcel} />
          <PrivateRoute path={`${match.url}/pointSystem`} component={PointSystem} />
          {/*defaultRouts*/}
          <PrivateRoute exact path={`${match.url}/`} component={AdminUser} />
       </Switch>
    );
  }
}

export default RouterOutlet;
