import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Hidden } from "@material-ui/core";
import { isEmpty } from "lodash";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MyTaskDropDownMenu, MyTaskDropDownMenuItem } from "../../../components/MyTaskDropDownMenu";
import NoDataToShow from "../../../components/NoDataToShow";
import TaskOptions from "../../../constants/myTaskOptions";
import { ActionObjectForStatus, CurrentStatuses, MyTasks, PaymentTerms, Task, TaskActionString, TaskPaymentType, TaskSections, TaskStatuses, BidsForMyTask } from "../../../types";
import { checkBidAllowed, useTaskOperation } from "./useTasksOperation";
import { Images } from "../../../assets/common";
import { convertSecondsToHoursMin } from "../../../components/validator";
import { useAppDispatch } from "../../../redux/app/hook";
import { openTimer } from "../../../redux/features/my-tasks/myTasksSlice";

interface MyTasksListProps {
  taskUIView: MyTasks;
  tasks: Task[];
  currentUser: string | null;
  bids: BidsForMyTask[];
}

type getOptionsArrayParams = {
  paymentType: TaskPaymentType;
  taskStatus: TaskStatuses;
  section: TaskSections;
  currentStatus: string;
  paymentTerms: PaymentTerms;
  onChange: (actionString: TaskActionString) => any;
};

const getStatusColor = (status: CurrentStatuses) => {
  switch (status) {
    case "Running":
      return "green";
    case "Expired":
      return "red";
    case "Completed":
      return "green";
    default:
      return "#2c2c2c";
  }
};

const MyTasksList = (props: MyTasksListProps) => {
  const { tasks, currentUser, taskUIView, bids } = props;
  const { paymentType, taskStatus, section } = taskUIView;
  const appDispatch = useAppDispatch();

  const handleTaskOperation = useTaskOperation();

  const handleOpenTimer = (event: React.MouseEvent, task: Task) => {
    appDispatch(openTimer(task));
  };

  if (section === "created" && tasks.length < 1) return <NoDataToShow />;
  if (section === "applied" && bids.length < 1) return <NoDataToShow />;

  return (
    <React.Fragment>
      <Hidden smDown>
        <TableContainer className="Table-container">
          <Table stickyHeader aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Task Title</StyledTableCell>
                <StyledTableCell>Contract Type</StyledTableCell>
                <StyledTableCell>Budget</StyledTableCell>

                {/** Component that are conditional*/}
                {section === "created" && taskStatus === "Pending" && <StyledTableCell>Total offers</StyledTableCell>}

                {paymentType === "hourly" && <StyledTableCell>Time Spent</StyledTableCell>}

                {/** Component that are conditional end*/}

                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>More</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {section === "created"
                ? tasks.map((task) => {
                    return <TableItem currentStatus={task.currentStatus} taskUIView={taskUIView} task={task} handleOpenTimer={handleOpenTimer} handleTaskOperation={handleTaskOperation} />;
                  })
                : null}
              {section === "applied"
                ? bids.map((bid) => {
                    return <TableItem currentStatus={bid.currentStatus} taskUIView={taskUIView} task={bid.task} handleOpenTimer={handleOpenTimer} handleTaskOperation={handleTaskOperation} />;
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        <div className="taskListMobile">
          {section === "created"
            ? tasks.map((task) => {
                return <TableItemMobile currentStatus={task.currentStatus} taskUIView={taskUIView} task={task} handleOpenTimer={handleOpenTimer} handleTaskOperation={handleTaskOperation} />;
              })
            : null}
          {section === "applied"
            ? bids.map((bid) => {
                return <TableItemMobile currentStatus={bid.currentStatus} taskUIView={taskUIView} task={bid.task} handleOpenTimer={handleOpenTimer} handleTaskOperation={handleTaskOperation} />;
              })
            : null}
        </div>
      </Hidden>
    </React.Fragment>
  );
};

interface TableItemProps {
  taskUIView: MyTasks;
  task: Task;
  handleOpenTimer: (event: React.MouseEvent, task: Task) => void;
  handleTaskOperation: ({ task, actionString }: { task: Task; actionString: TaskActionString }) => void;
  currentStatus: CurrentStatuses;
}

const TableItem = ({ taskUIView, task, handleOpenTimer, handleTaskOperation, currentStatus }: TableItemProps) => {
  const { section, taskStatus, paymentType } = taskUIView;
  return (
    <StyledTableRow key={`${section}_${taskStatus}_${task._id}`}>
      <StyledTableCell>
        <Link to={`/tasks/${task._id}`}>{task.heading}</Link>
        <br />
        {task.paymentTerms.biddingEnabled && taskStatus === "Pending" && <div className={checkBidAllowed(task.paymentTerms) ? "bid-allow" : "bid-closed"}>Bid {checkBidAllowed(task.paymentTerms) ? "Allowed" : "Closed"}</div>}
        {task.prohibited && <span className="ph-warning">PROHIBITED TASK</span>}
        {task.disputes ? <span className="ph-warning">DISPUTE RESOLVED</span> : null}
      </StyledTableCell>

      <StyledTableCell>{paymentType.toUpperCase()}</StyledTableCell>

      <StyledTableCell>
        {" "}
        <b>{task.rate}</b> {paymentType === "fixed" ? "ℏ(s)" : "ℏ(s)/hr"}
      </StyledTableCell>

      {/** Component that are conditional*/}
      {section === "created" && taskStatus === "Pending" && <StyledTableCell>{task.bidders?.length}</StyledTableCell>}

      {paymentType === "hourly" && (
        <StyledTableCell className="timer">
          {currentStatus === "Running" ? (
            <button className="timerButton" onClick={(event: React.MouseEvent) => handleOpenTimer(event, task)}>
              <img alt="timer" src={`${Images?.timer}`} />
              <span>{`${convertSecondsToHoursMin(task?.totalSeconds ?? 0)}`}</span>
            </button>
          ) : (
            <span>{`${convertSecondsToHoursMin(task?.totalSeconds ?? 0)}`}</span>
          )}
        </StyledTableCell>
      )}

      {/** Component that are conditional end*/}

      <StyledTableCell className="status" style={{ color: getStatusColor(currentStatus) }}>
        {currentStatus}
      </StyledTableCell>

      <StyledTableCell>
        <MyTaskOptions paymentType={paymentType} section={section} taskStatus={taskStatus} paymentTerms={task.paymentTerms} currentStatus={currentStatus} onChange={(actionString) => handleTaskOperation({ task, actionString })} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

const TableItemMobile = ({ taskUIView, task, handleOpenTimer, handleTaskOperation, currentStatus }: TableItemProps) => {
  const { section, taskStatus, paymentType } = taskUIView;
  console.log(taskUIView);
  console.log(task);
  return (
    <div className="taskListMobile__item" key={`mobile_${task._id}`}>
      {/* Task Title **/}
      <div className="taskListMobile__item__content">
        <div className="taskListMobile__item__content--left">Task Title</div>
        <div className="taskListMobile__item__content--right">
          <Link to={`/tasks/${task._id}`}>{task.heading}</Link>
          <br />
          {task.paymentTerms.biddingEnabled && taskStatus === "Pending" && <div className={checkBidAllowed(task.paymentTerms) ? "bid-allow" : "bid-closed"}>Bid {checkBidAllowed(task.paymentTerms) ? "Allowed" : "Closed"}</div>}
          {task.prohibited && <span className="ph-warning">PROHIBITED TASK</span>}
          {task.disputes ? <span className="ph-warning">DISPUTE RESOLVED</span> : null}
        </div>
      </div>

      {/* Contract Type **/}
      <div className="taskListMobile__item__content">
        <div className="taskListMobile__item__content--left">Contract Type</div>
        <div className="taskListMobile__item__content--right">{paymentType.toUpperCase()}</div>
      </div>

      {/* Budget **/}
      <div className="taskListMobile__item__content">
        <div className="taskListMobile__item__content--left">Budget</div>
        <div className="taskListMobile__item__content--right">
          <b>{task.rate}</b> {paymentType === "fixed" ? "ℏ(s)" : "ℏ(s)/hr"}
        </div>
      </div>

      {/** ========= Component that are conditionals ============*/}

      {/* Total offers for created section only **/}
      {section === "created" && taskStatus === "Pending" && (
        <div className="taskListMobile__item__content">
          <div className="taskListMobile__item__content--left">Total offers</div>
          <div className="taskListMobile__item__content--right">{task.bidders?.length}</div>
        </div>
      )}

      {/** ========= Component that are conditionals ============*/}

      {/* Task Status **/}
      <div className="taskListMobile__item__content">
        <div className="taskListMobile__item__content--left">Status</div>
        <div className="taskListMobile__item__content--right">
          <span style={{ color: getStatusColor(currentStatus) }}>{currentStatus}</span>
        </div>
      </div>

      {/* More Options **/}
      <div className="taskListMobile__item__content">
        <div className="taskListMobile__item__content--left">More Options</div>
        <div className="taskListMobile__item__content--right">
          <MyTaskOptions paymentType={paymentType} section={section} taskStatus={taskStatus} paymentTerms={task.paymentTerms} currentStatus={currentStatus} onChange={(actionString) => handleTaskOperation({ task, actionString })} />
        </div>
      </div>
    </div>
  );
};

const MyTaskOptions = ({ paymentType, taskStatus, section, currentStatus, paymentTerms, onChange }: getOptionsArrayParams) => {
  const [optionsArray, setOptionsArray] = useState<ActionObjectForStatus[] | null>(null);

  useEffect(() => {
    let str;

    switch (section) {
      case "created":
        switch (taskStatus) {
          case "Pending":
            if (currentStatus === "Open") str = `${section}_${taskStatus}_${currentStatus}_${paymentTerms.biddingEnabled ? "bidding" : "apply"}`.toLocaleLowerCase();
            else str = `${section}_${taskStatus}_${currentStatus.replace(/ /g, "_")}`.toLocaleLowerCase();
            break;
          default:
            str = `${section}_${taskStatus}_${currentStatus.trim().replace(/ /g, "_")}`.toLowerCase();
            break;
        }
        break;
      case "applied":
        switch (taskStatus) {
          case "Pending":
            str = `${section}_${taskStatus}_${currentStatus}_${paymentTerms.biddingEnabled ? "bidding" : "apply"}`.toLocaleLowerCase();
            break;
          case "Archived":
            str = "archive";
            break;
          default:
            str = `${section}_${taskStatus}_${currentStatus.trim().replace(/ /g, "_")}`.toLowerCase();
            break;
        }

        break;
    }
    console.log(str);
    setOptionsArray(TaskOptions[str]);
  }, []);

  if (!optionsArray) return null;

  return (
    <MyTaskDropDownMenu label={"Task Options"} description="Avilable Options for this task." onChange={onChange}>
      {optionsArray?.map((menu) => (
        <MyTaskDropDownMenuItem action={menu.action} value={menu.key} description={menu.description ?? ""} key={menu.key}>
          {menu.label}
        </MyTaskDropDownMenuItem>
      ))}
    </MyTaskDropDownMenu>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    textTransform: "unset",
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#F2FFFE",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    "&:nth-of-type(1)": {
      textAlign: "left",
      marginRight: "0rem",
    },
    "&:nth-of-type(2)": {
      textAlign: "center",
    },
    "&:nth-of-type(5)": {
      textAlign: "center",
    },
    "&:nth-of-type(6)": {
      textAlign: "center",
    },
    "& a": {
      color: "#2c2c2c",
    },
  },
  head: {
    backgroundColor: "#ffffff",
    color: "#656565",
  },
  body: {
    border: "none",
    fontSize: 13,
    b: {
      color: "#2c2c2c",
    },
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);

export default MyTasksList;
