import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, withStyles, Link } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import Hide from "../../../../components/Hide";
import NoDataToShow from "../../../../components/NoDataToShow";
import { BreakPoints } from "../../../../lib/validators";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PDFInvoice } from "./InvoicePdf";
import "./index.scss";
import { dragonURl } from "../../../../constants";

const currentUser = JSON.parse(localStorage.getItem("loggedInUser"));

// const MyDocument = ({ data}) => (
//   <Document>
//     <Page style={styles.body}>
//       <View style={styles.header}>
//         <View style={styles.logo}>
//           <Image src={Images.LogoPng} style={{width:'100%',height:'auto'}} />
//         </View>
//         <Text style={styles.headingText}>Transaction Invoice</Text>
//       </View>
//       <View style={styles.userDetails}>
//         <Text style={styles.usdText}>User Details</Text>
//         <Text style={styles.uid}>User id:      @{currentUser.nickName}</Text>
//         <Text style={styles.email}>Email:         {currentUser.email}</Text>
//         <Text style={styles.email}>Name:         {`${currentUser.firstName} ${currentUser.lastName}`}</Text>
//         <Text style={styles.email}>Account Id:         {currentUser.hederaAccountDetails.id}</Text>
//       </View>
//       <Text style={styles.meta}>Date:    {moment(data.createdAt).format("DD/MM/yyyy")}</Text>
//       <Text style={styles.meta}>Transaction:  {data.transactionTitle}</Text>
//       <Text style={styles.meta}>Amount: {`${data.totalAmountC} hbars`}</Text>
//       <Text style={styles.meta}>Transaction Time:{moment(data.createdAt).format("DD/MM/yyyy HH:mm:ss")}</Text>
//       <Text style={styles.meta}>Transaction Id: {data.vendorTransactionId}</Text>
//       {/* <Text style={styles.meta}>Transaction Hash: {data.transactionResponseObject.transaction_hash.toString()}</Text> */}

//       {/* <Text style={styles.meta}>Memo Code:{data.transactionMessage}</Text> */}

//       <View style={styles.status}>
//         <Text style={styles.label}>Status: </Text>
//         {data.transactionOutcome === 'Successful' ? (<Text style={styles.success}>SUCCESS</Text>):(<Text style={styles.fails}>FAILD</Text>)}
//         </View>
//     </Page>
//   </Document>
// );

// Date : 26/02/2021
// Transection: Need a logo Designer
// Amount : 100 hbars
// Transection Time:
// Transection ID : 0.0.320715-1614259100-575000000

const TransactionHistory = (props) => {
  const { transactionHistory } = props;
  // const [instance, updateInstance] = usePDF({ document: MyDoc });

  transactionHistory.forEach((d) => {
    if (d.transactionOperation === "credit") {
      d["transactionTitle"] = `Payment received for task "${d.task.heading}"`;
      d["totalAmountC"] = `+${d.totalAmount ? d.totalAmount.toFixed(2) : "0"}`;
    } else if (d.transactionOperation === "debit") {
      d["totalAmountC"] = `-${d.totalAmount ? d.totalAmount.toFixed(2) : "0"}`;
      if (d.transactionType === "PROMOTE_TASK") {
        d["transactionTitle"] = ` Payment received for promotion of task :"${d.task.heading}"`;
      } else if (d.transactionType === "PROMOTE_TASKER") {
        d["transactionTitle"] = ` Payment received for promotion of your superskills.`;
      } else if (d.transactionType === "ESCROW") {
        d["transactionTitle"] = `Escrow payment for task "${d.task.heading}"`;
      } else if (d.transactionType === "RECEIVE_PAYMENT") {
        d["transactionTitle"] = `Payment received for task, "${d.task.heading}"`;
      } else if (d.transactionType === "REMAINING_AMOUNT_RETURN") {
        d["transactionTitle"] = `Remaining amount returned for task, "${d.task.heading}" from smart contract.`;
      } else {
        d["transactionTitle"] = `Payment for task "${d.task.heading}"`;
      }
    }
  });
  //console.log(Images.LogoPng);
  return (
    <div className="transectionHistory">
      <Hide type="down" size={BreakPoints.sm}>
        <div className="dashboard__content__heading">
          <h2 className="dashboard__content__heading--label">Transaction History</h2>
        </div>
        <TableContainer className="transectionHistory__content">
          {transactionHistory && transactionHistory.length > 0 ? (
            <Table size="small" stickyHeader>
              <colgroup>
                <col width="116px" />
                <col width="260px" />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Transaction</StyledTableCell>
                  <StyledTableCell>Invoice</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Transaction ID</StyledTableCell>
                  {/* <StyledTableCell>Transaction Type</StyledTableCell> */}
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>View</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((data, index) => {
                  // //console.log(data);
                  return (
                    <StyledTableRow key={`transectionItem-${data._id}`}>
                      <StyledTableCell>
                        <Moment format={"DD/MM/YYYY"}>{data.createdAt}</Moment>
                      </StyledTableCell>
                      <StyledTableCell className="transaction-title">{data.transactionTitle}</StyledTableCell>
                      <StyledTableCell>
                        <PDFInvoice data={data} currentUser={currentUser} />
                      </StyledTableCell>
                      <StyledTableCell>{`${data.totalAmountC} hbars`}</StyledTableCell>
                      <StyledTableCell className="transactionId" title={data.vendorTransactionId}>
                        {data.vendorTransactionId}
                      </StyledTableCell>
                      {/* <StyledTableCell>{data.transactionType} </StyledTableCell> */}
                      <StyledTableCell>
                        <span className="pending">{data.transactionStatus}</span>
                      </StyledTableCell>
                      <StyledTableCell className="transactionId" title={data.vendorTransactionId.replace(/[.-]/g, "")}>
                        <Link style={{ color: "#6dc7be" }} href={`${dragonURl}/hedera/transactions/${data.vendorTransactionId.replace(/[@.-]/g, "")}`} target="_blank">
                          <VisibilityIcon />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <NoDataToShow />
          )}
        </TableContainer>
      </Hide>
      <Hide type="up" size={BreakPoints.sm}>
        <div className="th">
          {transactionHistory && transactionHistory.length > 0 ? (
            transactionHistory.map((data, index) => {
              return (
                <div className="th__item" key={index}>
                  <p>
                    <span className="left">DATE</span>
                    <span className="right">
                      <Moment format={"DD/MM/YYYY"}>{data.createdAt}</Moment>
                    </span>
                  </p>
                  <p>
                    <span className="left">TRANSACTION</span>
                    <span className="right"> {data.transactionTitle}</span>
                  </p>
                  <p>
                    <span className="left">INVOICE</span>
                    <span className="right">
                      <PDFInvoice data={data} currentUser={currentUser} />
                    </span>
                  </p>
                  <p>
                    <span className="left">AMOUNT</span>
                    <span className="right">{`${data.totalAmountC} hbars`}</span>
                  </p>
                  <p>
                    <span className="left">TRANSACTION ID</span>
                    <span className="right">{data.vendorTransactionId}</span>
                  </p>
                  <p>
                    <span className="left">STATUS</span>
                    <span className="right">
                      <span className="pending">{data.transactionStatus}</span>
                    </span>
                  </p>
                  <p>
                    <span className="left">TRANSACTION ID</span>
                    <span className="right">
                      {" "}
                      <Link style={{ color: "#6dc7be" }} href={`${dragonURl}/hedera/transactions/${data.vendorTransactionId.replace(/@[.-]/g, "")}`} target="_blank">
                        <VisibilityIcon />
                      </Link>
                    </span>
                  </p>
                </div>
              );
            })
          ) : (
            <NoDataToShow />
          )}
        </div>
      </Hide>
    </div>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    border: "none",
    fontSize: 13,
    color: "#2c2c2c",
    "&:last-child": {
      textAlign: "right",
      textTransform: "uppercase",
    },
    "&:nth-child(3),&:nth-child(4),&:nth-child(5)": {
      textAlign: "center",
    },
  },
  head: {
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #dddddd",
    textTransform: "uppercase",
  },
  stickyHeader: {
    zIndex: "auto",
  },
  body: {
    "& span.cnf": { color: "#07D62F" },
    "& span.rej": { color: "#ED0B0B" },
    "& span.pending": { color: "#2C2C2C" },
  },
}))(TableCell);

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2c2c2c",
    color: "#ffffff",
    fontSize: 12,
  },
}))(Tooltip);

// const styles = StyleSheet.create({
//   body: {
//     paddingTop: 35,
//     paddingBottom: 65,
//     paddingHorizontal: 45,
//   },
//   header: {
//     display: "flex",
//     alignContent: "center",
//     flexDirection: "row",
//   },
//   logo: {
//     width: "30%",
//     //backgroundColor: "red",
//   },
//   headingText: {
//     marginLeft: "auto",
//     fontSize: "27pt",
//     fontWeight: 400,
//     family: "Oswald",
//     color: "#eb5b24",
//   },
//   userDetails: {
//     marginTop: "60pt",
//     paddingBottom: "40pt",
//     borderBottom: "3pt solid #eb5b24",
//     marginBottom: "25pt",
//   },
//   usdText: {
//     color: "#79c6c0",
//     fontSize: "27pt",
//     fontWeight: "bold",
//   },
//   uid: {
//     fontSize: "20pt",
//     marginTop: "40pt",
//     marginBottom: "6pt",
//   },
//   email: {
//     fontSize: "20pt",
//     marginBottom: "7pt",
//   },
//   meta: {
//     marginVertical: "8pt",
//   },
//   status: {
//     position: "absolute",
//     bottom: "45pt",
//     left: "45pt",
//     display: "flex",
//     flexDirection: "row",
//     fontSize: "27pt",
//   },
//   label: { fontSize: "27pt" },
//   success: { fontSize: "27pt", color: "green" },
//   fails: { fontSize: "27pt", color: "red" },
// });

export default TransactionHistory;
