import React from "react";

type ModalStatus = {
  open: boolean;
  successBtnLabel?: string;
  message: string;
  cancelBtnLabel?: string;
};

interface ConfirmationModalContext {
  modal: ModalStatus;
  openModal: ({}: ModalStatus) => any;
  closeModal: () => any;
  onSuccess: any;
  onCancel?: any;
  setSuccessCallBack: any;
  setCancelCallBack?: any;
  loading: boolean;
  setLoading: any;
}

export const ConfirmationModalContext =
  React.createContext<ConfirmationModalContext>({
    modal: {
      open: false,
      message: "",
      successBtnLabel: "",
      cancelBtnLabel: "",
    },
    openModal: () => {},
    closeModal: () => {},
    onCancel: () => {},
    onSuccess: () => {},
    setSuccessCallBack: () => {},
    setCancelCallBack: () => {},
    loading: false,
    setLoading: () => {},
  });

const initialModalData: ModalStatus = {
  open: false,
  successBtnLabel: "",
  cancelBtnLabel: "",
  message: "",
};

const ConfirmationModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [modal, setModal] = React.useState<ModalStatus>(initialModalData);
  const [successCallBack, setSuccessCallBack] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOpenModal = React.useCallback(
    ({ open, successBtnLabel, cancelBtnLabel, message }: ModalStatus) => {
      setModal((prevModal) => ({
        ...prevModal,
        open,
        successBtnLabel,
        cancelBtnLabel,
        message,
      }));
    },
    []
  );
  const handleCloseModal = React.useCallback(() => {
    setModal((prevModal) => ({
      ...prevModal,
      ...initialModalData,
    }));
  }, []);

  const modalVal = React.useMemo(() => ({ ...modal }), [modal]);

  return (
    <ConfirmationModalContext.Provider
      value={{
        modal: modalVal,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
        onSuccess: successCallBack,
        setSuccessCallBack,
        loading,
        setLoading,
      }}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalProvider;
