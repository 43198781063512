import FavoriteIcon from "@material-ui/icons/Favorite";
import React, {useEffect, useState} from "react";
import {createRef} from "react";
import {useRef} from "react";
import Moment from "react-moment";
import {Images} from "../../../../assets/common";
import Loader from "../../../../components/Loader";
import {
  getFavriteTask,
  rateConversionUSDTask,
} from "../../../../components/validator";
import {REQ_USER_DETAILS , REQ_FAVOURITE_TASK} from "../../../../services/api-service";

const FavTasks = (props) => {
  const [loading, setLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  // const [conversionDate, setConversionDate] = useState(parseFloat(localStorage.getItem('conversionDate')));
  const taskListRef = useRef([]);

  useEffect(() => {
    getUserDetails();
    // getTasks();
  }, []);

  const getUserDetails = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      try {
        setLoader(true);
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        setLoader(false);
        let userDetails = response.data.result;
        setTasks(rateConversionUSDTask(getFavriteTask(userDetails)));
      } catch (e) {
        setLoader(false);
        console.log(e);
      }
    }
  };

  //!do unfavorite taskOperation
  const doUnfavoriteOperation = async (id , ref) => {
    ref.current.classList.add("nowRemoving");

    try {
      const requestBody = {
        favouriteTaskId: id,
        operation: 'unfavourite',
      };
      const response = await REQ_FAVOURITE_TASK(requestBody);
      getUserDetails();
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  }

  //! this one create ref of each Task.
  taskListRef.current = tasks.map(
    (element, i) => taskListRef.current[i] ?? createRef()
  );

  return (
    <div>
      <div className="favourite-opt-content__heading">
        <h2>Tasks</h2>
      </div>
      <div className="fav-task">
        {loading ? (
          <Loader height={"300px"} />
        ) : tasks && tasks.length > 0 ? (
          tasks.map((task, i) => (
            <div
              className="tasks-container"
              key={task._id}
              ref={taskListRef.current[i]}>
              <div className="task-details">
                {task.pinned ? (
                  <img src={Images.pinTask} alt="" className="pintask" />
                ) : null}
                <div className="task-details-top">
                  <div className="tasker-profile">
                    <div className="profile-content">
                      <div className="info">
                        Posted &nbsp;<Moment fromNow>{task.createdAt}</Moment>
                        <div className="sepration"></div>
                        <div className="info-name">
                          <img
                            src={Images.user}
                            alt={""}
                            className="info-img"
                          />
                          {task.author.firstName} {task.author.lastName}
                        </div>
                        <div className="sepration"></div>
                        <div className="tasker-location">
                          <img src={Images.location} alt={""} />
                          {task.author.address.country}
                        </div>
                      </div>
                      <div className="brandCompany-name">{task.heading}</div>
                    </div>
                  </div>
                  <FavoriteIcon
                    style={{color: "#6DC7BE"}}
                    className="favicon"
                    onClick={() =>  doUnfavoriteOperation(task._id , taskListRef.current[i] )}
                  />
                </div>
                <div className="List-Task-info">
                  <p>{task.description}</p>
                  <div className="design-details">
                    {task.skills.map((skill) => (
                      <div className="design">{skill.name}</div>
                    ))}
                  </div>
                </div>
                {task.paymentTerms.hourly ? (
                  <div className="task-bottom">
                    <span className="hoursneed">
                      <span className="hours">Hours Needed </span>
                      <span className="weektime">
                        {task.paymentTerms.hourly.hoursPerWeek} {"hrs/week"}
                      </span>
                    </span>
                    <span className="duration">
                      <span className="hours">Duration </span>
                      <span className="weektime">
                        {task.paymentTerms.hourly.taskDuration}
                      </span>
                    </span>
                    <span className="hourlyrate">
                      <span className="hours">Hourly Rate </span>
                      <span className="weektime">
                        {task.paymentTerms.hourly.ratePerHour} hbars/hr
                        <span className="usd-form">{`(${task.usdRate} USD)`}</span>
                      </span>
                    </span>
                    <div className="task-bottom-right">
                      {task.paymentTerms.biddingEnabled ? (
                        <>
                          <div className="bidclose_div">
                            <div>Bid Closes in</div>
                            <div>
                              <img src={Images.time} alt={""} />
                              <Moment fromNow>
                                {task.paymentTerms.bidExpiryDate}
                              </Moment>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              props.history.push(
                                `/Task/actionOnTasks?action=bid&taskId=${task._id}`
                              )
                            }>
                            Bid Now
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="bidclose_div">
                            <div>Apply Before</div>
                            <div>
                              <img src={Images.time} alt={""} />
                              {task.paymentTerms.hourly.applyBefore}
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              props.history.push(
                                `/Task/actionOnTasks?action=apply&taskId=${task._id}`
                              )
                            }>
                            Apply
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="task-bottom">
                    <span className="hoursneed">
                      <span className="hours">Task Deadline </span>
                      <span className="weektime">
                        <Moment format="DD/MM/YYYY">
                          {task.paymentTerms.fixed.taskDeadline}
                        </Moment>
                      </span>
                    </span>
                    <span className="duration">
                      <span className="hours">Fixed Price </span>
                      <span className="weektime">
                        {task.paymentTerms.fixed.rate} hbars
                        <span className="usd-form">{`(${task.usdRate} USD)`}</span>
                      </span>
                    </span>
                    <div className="task-bottom-right">
                      {task.paymentTerms.biddingEnabled ? (
                        <>
                          <div className="bidclose_div">
                            <div>Bid Closes in</div>
                            <div>
                              <img src={Images.time} alt={""} />
                              <Moment fromNow>
                                {task.paymentTerms.bidExpiryDate}
                              </Moment>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              props.history.push(
                                `/Task/actionOnTasks?action=bid&taskId=${task._id}`
                              )
                            }>
                            Bid Now
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="bidclose_div">
                            <div>Apply Before</div>
                            <div>
                              <img src={Images.time} alt={""} />
                              {task.paymentTerms.fixed.applyBefore}
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              props.history.push(
                                `/Task/actionOnTasks?action=apply&taskId=${task._id}`
                              )
                            }>
                            Apply
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="no-task">No Task to show</div>
        )}
      </div>
    </div>
  );
};

export default FavTasks;
