import { Backdrop, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

export const validEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

export const validNumber = (number) => {
  const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
  return numberRegex.test(String(number).toLowerCase());
};

export const validWalletID = (accountId) => {
  let pattern = /^0.0.[0-9]{4,10}$/gm;
  if (!accountId.match(pattern)) {
    return false;
  } else {
    return true;
  }
};

export const checkWalletAvailablity = () => {
  let userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
  let isWalletLinked = userDetails?.hederaAccountDetails?.id;
  return isWalletLinked;
};

//This is Screen Breackpoints
export const BreakPoints = {
  sm: 576,
  md: 768,
};

export const dynamicSort = (property, order) => {
  let sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    // a should come before b in the sorted order
    if (a[property] < b[property]) {
      return -1 * sort_order;
      // a should come after b in the sorted order
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  };
};

//hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

// Hook
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const isStringAlphaNumericOnly = (nickName) => /^[A-Za-z0-9]+$/i.test(nickName);

export const getParamsByName = (name) => new URLSearchParams(window.location.search).get(name);

export const StyledBackdrop = withStyles((theme) => ({
  root: {
    background: " rgba(44, 44, 44, 0.3) 0% 0% no-repeat padding-box",
  },
}))(Backdrop);
