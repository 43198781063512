import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../components/privateRoute";
import { TaskSections, TaskStatuses } from "../../../types";
import ViewBidsOrProposals from "./BidsOrProposalsOnTask";
import TaskLists from "./ListOfTasks";

const withTaskUIViews = (
  TaskLists: any,
  { section, status }: { section: TaskSections; status: TaskStatuses }
) => {
  return () => <TaskLists section={section} taskStatus={status} />;
};

const withBidsOrProposals = (type: "Bids" | "Proposals", Component: any) => {
  return () => <Component viewType={type} />;
};

const RouterOutlet = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        path={`${match.url}/created-pending`}
        component={withTaskUIViews(TaskLists, {
          section: "created",
          status: "Pending",
        })}
      />

      <PrivateRoute
        path={`${match.url}/created-active`}
        component={withTaskUIViews(TaskLists, {
          section: "created",
          status: "Active",
        })}
      />
      <PrivateRoute
        path={`${match.url}/created-archived`}
        component={withTaskUIViews(TaskLists, {
          section: "created",
          status: "Archived",
        })}
      />
      <PrivateRoute
        path={`${match.url}/applied-pending`}
        component={withTaskUIViews(TaskLists, {
          section: "applied",
          status: "Pending",
        })}
      />
      <PrivateRoute
        path={`${match.url}/applied-active`}
        component={withTaskUIViews(TaskLists, {
          section: "applied",
          status: "Active",
        })}
      />
      <PrivateRoute
        path={`${match.url}/applied-archived`}
        component={withTaskUIViews(TaskLists, {
          section: "applied",
          status: "Archived",
        })}
      />
      <PrivateRoute
        path={`${match.url}/bids/:taskId`}
        component={withBidsOrProposals("Bids", ViewBidsOrProposals)}
      />
      <PrivateRoute
        path={`${match.url}/proposals/:taskId`}
        component={withBidsOrProposals("Proposals", ViewBidsOrProposals)}
      />
      <PrivateRoute
        path={`${match.url}/`}
        component={() => <Redirect to={`${match.url}/created-pending`} />}
      />
    </Switch>
  );
};

export default RouterOutlet;
