import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { MyTaskActive, MyTaskArchive, MYTaskPendingIcon } from "../../../assets/common";
import GoBack from "../../../components/back-btn/GoBack";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hook";
import { updateTaskUIView } from "../../../redux/features/my-tasks/myTasksSlice";
import { MyTaskState, TaskPaymentType, TaskSections, TaskStatuses } from "../../../types";
import RouterOutlet from "./RouterOutlet";
import "./styles.scss";

const getGetTotalTask = (taskState: MyTaskState, nowSection: TaskSections, nowTaskStatus: TaskStatuses) => {
  const {
    tasks,
    bids,
    taskUIView: { section, taskStatus },
  } = taskState;
  if (section === "created") return section === nowSection && taskStatus === nowTaskStatus && `(${tasks?.fixed && tasks?.hourly ? tasks.fixed.length + tasks.hourly.length : 0})`;
  else return section === nowSection && taskStatus === nowTaskStatus && `(${bids?.fixed && bids?.hourly ? bids.fixed.length + bids.hourly.length : 0})`;
};

const MyTaskSideBar = () => {
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const taskState = useAppSelector((state) => state.myTask);
  const {
    taskUIView: { section },
  } = taskState;

  const handleLabelClick = () => {
    if (matches) {
      history.push(`/home/my-tasks/${section === "created" ? "applied" : "created"}-pending/`);
    }
  };

  return (
    <div className="tasks-type-content">
      <h1>My Tasks</h1>
      <h3 className={`create-task-content ${section === "created" ? "mobileActive" : ""}`} onClick={handleLabelClick}>
        {" "}
        Created tasks
      </h3>
      <div className={`create-container m-bottom-menu ${section === "created" ? "created open" : ""}`}>
        <NavLink className="create-task-content" to="/home/my-tasks/created-pending" activeClassName="activeLink">
          <MYTaskPendingIcon className="icon" />
          <span>Pending {getGetTotalTask(taskState, "created", "Pending")}</span>
        </NavLink>
        <NavLink className="create-task-content" to="/home/my-tasks/created-active" activeClassName="activeLink">
          <MyTaskActive className="icon" />
          <span>Active {getGetTotalTask(taskState, "created", "Active")}</span>
        </NavLink>
        <NavLink className="create-task-content" to="/home/my-tasks/created-archived" activeClassName="activeLink">
          <MyTaskArchive className="icon" />
          <span>Archived {getGetTotalTask(taskState, "created", "Archived")}</span>
        </NavLink>
      </div>
      <h3 className={`create-task-content ${section === "applied" ? "mobileActive" : ""}`} onClick={handleLabelClick}>
        {"Bids / Applied on tasks "}
      </h3>
      <div className={`create-container m-bottom-menu ${section === "applied" ? "applied open" : ""}`}>
        <NavLink className="create-task-content" to="/home/my-tasks/applied-pending" activeClassName="activeLink">
          <MYTaskPendingIcon className="icon" />
          <span>Pending {getGetTotalTask(taskState, "applied", "Pending")}</span>
        </NavLink>
        <NavLink className="create-task-content" to="/home/my-tasks/applied-active" activeClassName="activeLink">
          <MyTaskActive className="icon" />
          <span>Active {getGetTotalTask(taskState, "applied", "Active")}</span>
        </NavLink>
        <NavLink className="create-task-content" to="/home/my-tasks/applied-archived" activeClassName="activeLink">
          <MyTaskArchive className="icon" />
          <span>Archived {getGetTotalTask(taskState, "applied", "Archived")}</span>
        </NavLink>
      </div>
    </div>
  );
};

const tabClassName = (paymentTerms: TaskPaymentType, stateVal: TaskPaymentType) => (paymentTerms === stateVal ? "active-type" : "disable-type");

const MyTaskHeading = () => {
  const myTasks = useAppSelector((state) => state.myTask);
  const {
    tasks,
    bids,
    taskUIView: { section, isTaskDetailsPage, paymentType },
  } = myTasks;
  const dispatch = useAppDispatch();

  const handleTabClick = (event: React.MouseEvent, paymentType: TaskPaymentType) => {
    event.preventDefault();
    dispatch(
      updateTaskUIView({
        paymentType,
      })
    );
  };

  if (isTaskDetailsPage) {
    return (
      <div
        className="task-top"
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 10,
        }}
      >
        <GoBack />
        <p style={{ color: "var(--primary-btn)", margin: "0 auto" }}>{tasks?.singleTask?.heading}</p>
      </div>
    );
  }

  return (
    <div className="task-top">
      <div className="payment-container">
        <div className="btn-container">
          <div className="type-change-btn">
            <div className={tabClassName("hourly", paymentType)} onClick={(event) => handleTabClick(event, "hourly")}>
              Hourly ({section === "applied" ? bids.hourly.length : tasks.hourly?.length})
            </div>
          </div>
          <div className="type-change-btn">
            <div className={tabClassName("fixed", paymentType)} onClick={(event) => handleTabClick(event, "fixed")}>
              Fixed ({section === "applied" ? bids.fixed.length : tasks.fixed?.length})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyTaskPage = () => {
  return (
    <div className="my-task-page">
      <div className="tasks-type_div">
        <MyTaskSideBar />
      </div>
      <div className="tasks">
        <MyTaskHeading />
        <RouterOutlet />
      </div>
    </div>
  );
};

export default MyTaskPage;
