import {
  Fade,
  makeStyles,
  Modal,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Images } from "../../assets/common";
import SingleReview from "../single-review";
import { GET_REVIEWS } from "../../services/api-service";
import Loader from "../../components/Loader";
import './index.scss';
import { StyledBackdrop } from "../../lib/validators";


const ReviewPopUp = (props) => {
  const classes = Styles();
  const [superskillReview, setSuperskillReview] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getSuperskillReview();
  }, [props.superskillId])

  const handleClose = () => {
    props.onClose();
  };

  const getSuperskillReview = async () => {
    if (props.superskillId) {
      try {
        setLoading(true)
        const requestBody = {
          "superskillId": props.superskillId
        }
        const response = await GET_REVIEWS(requestBody);
        const result = response.data.result;
        setLoading(false)
        setSuperskillReview(result)
      } catch (e) {
        setLoading(false)
        console.log(e);
      }
    }
  };


  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={`${classes.modal} reviewPopup`}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`${classes.paper} reviewPopup__paper`}>
          {loading ? <Loader /> : null}
          <div className={classes.reviews}>
            <div className="heading">
              <h4 className="heading__label">{`Reviews (${superskillReview.length})`}</h4>
              <span className="closeBtn" onClick={handleClose}>
                <img src={Images.IconForCloseModal} alt=""/>
              </span>
            </div>
            <div className="reviews">
              {superskillReview.map((data, i) => <SingleReview key={data._id} review={data} openHCSPopup={props.openHCSPopup} hcsInfo={true} />)}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const Styles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    position: 'relative',
    height: "calc(100vh - 75px - 30px)",
    width: 952,
    backgroundColor: "#ffffff",
    marginTop: 75,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
    outline: 'none'
  },
  reviews: {
    height: "100%",
    border: "none!important",
    outline: "none !important",
    "&:focus , &:focus-visible": {
      border: "none!important",
      outline: "none !important",
    },
    "& .heading": {
      padding: "16px 20px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&__label": {
        fontSize: 16,
        color: "rgba(44, 44, 44, 1)",
        textTransform: "uppercase",
        fontWeight: 600,
        marginBottom: 0,
        "& img": {
          width: 26,
          marginRight: 12,
        },
      },
      "& .closeBtn": {
        marginLeft: "auto",
        cursor: "pointer",
        "& img": {
          width: 14,
        },
      },
    },
    '& .reviews': {
      padding: '0 30px',
      maxHeight: 'calc(100% - 57px)',
      overflowY: 'auto',
      paddingBottom: '30px',
      "&::-webkit-scrollbar": {
        width: 5,
        "&-track": {
          background: "transparent",
        },
        "&-thumb": {
          backgroundColor: "#DDDDDD",
          borderRadius: 10,
        },
      },
    }
  },
});



export default ReviewPopUp;
