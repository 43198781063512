export const MESSAGE = {
  // ***************** SUCCESS MESSAGES ****************
  SUCCESS: {
    PASSWORD_CHANGED_SUCESSFULLY: "Password changed sucessfully",
    UPDATED_SUCESSFULLY: "Updated sucessfully",
    ACCOUNT_DELETED_SUCESSFULLY: "Account closed sucessfully",
    TASK_CREATED_SUCESSFULLY: "Task created sucessfully",
    PROFILE_PIC_UPDATED: "Profile picture has been updated!",
    INVITATION_SEND: "Invitation send sucessfully",
    BID_CREATED_SUCESSFULLY: "Bid created sucessfully",
    APPLIED_SUCESSFULLY: "Sucessfully applied",
    NOTIFICATIONS_UPDATED_SUCESSFULLY: "Notifications updated sucessfully",
    TASK_UPDATED_SUCESSFULLY: "Task Updated sucessfully",
    TASK_CANCELLED_SUCESSFULLY: "Task Cancelled Sucessfully",
    BIDDING_CLOSED_SUCESSFULLY: "Bidding Closed Sucessfully",
    DISPUTE_RAISED_SUCESSFULLY: "Dispute Raised Sucessfully",
    END_CONTRACT_SUCESSFULLY: "Contract End Request Send Sucessfully",
    TASK_CANCELED_SUCESSFULLY: "Task Canceled Sucessfully",
    HIRED_SUCESSFULLY: "Tasker hired Sucessfully",
    REVIEW_CREATE_SUCESSFULLY: "Review Created Sucessfully",
    REPORT_GENRATED_SUCESSFULLY: "Your Report is Created Sucessfully ",
    MARKED_COMPLETED_SUCESSFULLY: "Task Marked as Completed Sucessfully",
    APPROVED_SUCESSFULLY: "Task Approved Sucessfully",
    TASKBAR_FEE_UPDATED_SUCESSFULLY:'Taskbar Fees Updated Successfully'
  },
  // **************************************************

  // ***************** WARNING MESSAGES ***************
  WARN: {
    NOT_LOGGED_IN: 'You need to Login first',
    NOT_VALID_WALLET_ID: 'Enter valid wallet ID',
    RE_WALLET_ID_INVALID: "Re Entered wallet ID  does not match",
    NEEDER_ACTION: 'Only Needer can perform this action',
  },
  // **************************************************

  // ***************** INFO MESSAGES ******************
  INFO: {},
  // **************************************************

  // ***************** ERROR MESSAGES *****************
  ERROR: {
    ORG_NAME_EMPTY: "What is the name of your Organization",
    ORG_NAME_LENGTH: "Should be greater than 2 characters",
    PHONE_EMPTY: "Mobile number is mandatory",
    PHONE_INVALID: "Mobile number is invalid",
    EMAIL_EMPTY: "Email is mandatory",
    EMAIL_INVALID: "Email is invalid",
    CURRENT_PASSWORD_EMPTY: "Current Password is mandatory",
    PASSWORD_EMPTY: "You will need a Password to get access",
    PASSWORD_INVALID: "Password should be greater than 8 characters",
    PASSWORD_CRITERIA: "Password doesnt meet required criteria",
    CPASSWORD_INVALID: "Confirmed Password does not match",
    SESSION_EXPIRED: "Session Expired!, Please login to continue",
    TITLE_EMPTY: "Title is mandatory",
    TITLE_LENGTH: "Title Should be greater than 2 characters",
    DESCRIPTION_EMPTY: "Description is mandatory",
    SKILLS_EMPTY: "Subskills are mandatory",
    RATE_EMPTY: "Pay rate is mandatory",
    FIRST_NAME_EMPTY: "First name is mandatory",
    LAST_NAME_EMPTY: "Last name is mandatory",
    USER_NAME_EMPTY: "Username is mandatory",
    USER_NAME_NOT_VALID: "Username is not valid",
    USER_NAME_EXIST: "Username is already exist",
    COUNTRY_EMPTY: "Country is mandatory",
    NATIONALITY_EMPTY: "Nationality is mandatory",
    NATIONALITY_INVALID: "Nationality should be less than 50 characters",
    CITY_EMPTY: "City is mandatory",
    CITY_INVALID: "City should be less than 50 characters",
    SOMETHING_WENT_WRONG: "Something went wrong!",
    ACCET_TERMS_CONDITIONS: "Accept terms of use and services",
    PRICE_EMPTY: "Price is mandatory",
    PRICE_INVALID: "Invalid price",
    PRICE_MUST:'Price should be greater than 0',
    TIME_EMPTY: "Time is mandatory",
    TIME_INVALID: "Invalid Time",
    TASK_DURATION_EMPTY: "Task duration is mandatory",
    APPLY_BEFORE_EMPTY: "Apply before date is mandatory",
    TASK_DEADLINE_EMPTY: "Task deadline is mandatory",
    EXPIRY_DATE_EMPTY: "Expiry date is mandatory",
    // TITLE_EMPTY:"Title is mandatory",
  },
  // **************************************************

  // ***************** GENERAL MESSAGES ***************
  GENERAL: {},
  // **************************************************
};

export const String = {

  MEMO_CODE_PREFIX: 'ES'

}
