import TextField from "@material-ui/core/TextField";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Button, Popover } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Rating from "@material-ui/lab/Rating";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as WebLink } from "@material-ui/core";
import { Images } from "../../../assets/common";
import Hide from "../../../components/Hide";
import history from "../../../components/history";
import InviteTaskerModal from "../../../components/InviteTaskerModal";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { getFavriteTaskers, paginationLimit, rateConversionUSDTasker } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { BreakPoints, getParamsByName } from "../../../lib/validators";
import { GET_TASKS, REQ_FAVOURITE_TASKER, REQ_SKILLS, REQ_TASKERS, REQ_USER_DETAILS } from "../../../services/api-service";
import cookieStorage from "../../../services/cookie-storage";
import "./index.scss";

class TaskFinder extends Component {
  constructor() {
    super();
    this.state = {
      taskers: [],
      tasker: "",
      tasks: [],
      invitedTaskers: [],
      loading: false,
      findtask: true,
      findtaskers: false,
      AllSkills: [],
      brand: "",
      minRate: "",
      maxRate: "",
      manual: false,
      favTaskers: [],
      sidecounts: [],
      rating: "",
      sortBy: "",
      taskOrTasker: "tasker",
      search: "",
      count: "",
      totalRecord: 0,
      pageNumber: 1,
      skills: [],
      paymentType: "",
      conversionRate: null,
      conversionDate: "",
      hireNowModelStatus: false,
      isMobileFilterOpen: false,
      anchorEl: null,
      openPortfolioPopoverId: null,
    };
  }

  componentDidMount = () => {
    // var str = window.location.search;
    var searchValue = getParamsByName("search");
    let conversionDate = localStorage.getItem("conversionDate");
    this.getUserDetails();
    this.setState(
      {
        search: searchValue ? searchValue : " ",
        conversionDate,
      },
      () => {
        this.getTaskers();
      }
    );
  };

  getUserDetails = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      try {
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        let userDetails = response.data.result;
        this.setState({
          favTaskers: getFavriteTaskers(userDetails).map((d) => d._id),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  getCreatedTasks = async (id) => {
    try {
      this.setState({ tasks: [] });
      let currentUser = localStorage.getItem("currentUser");
      const requestBody = {
        userId: currentUser,
        myTasks: {
          section: "created",
        },
      };
      const response = await GET_TASKS(requestBody);
      const task = response.data.result;
      let bidders = [];
      task.forEach((task, i) => {
        bidders.push(...task.bidders);
      });
      const openedTasks = task.filter((d) => d.currentStatus === "Open");
      this.setState({ tasks: openedTasks, invitedTaskers: bidders });
    } catch (e) {
      console.log(e);
    }
  };

  getTaskers = async () => {
    let { search, sortBy, skills, paymentType, minRate, maxRate, rating, pageNumber } = this.state;
    try {
      this.setState({ loading: true });
      const requestBody = {
        skills: [skills._id].filter((e) => e).length ? [skills._id].filter((e) => e) : undefined,
        minRate: minRate ? minRate : undefined,
        maxRate: maxRate ? maxRate : undefined,
        searchPhrase: search ? search : undefined,
        paymentType: paymentType ? paymentType : undefined,
        sortBy: sortBy ? sortBy : undefined, // relevance, newest, priceSort
        minRating: rating ? rating : undefined,
        pageNumber: pageNumber,
        noOfDocuments: paginationLimit,
      };
      const response = await REQ_TASKERS(requestBody);
      this.setState({ loading: false });
      let Tasker = response.data.result;
      let taskerData = Tasker.data;
      if (Tasker.pinnedTasker) {
        const pinnedTaskId = Tasker.pinnedTasker._id;
        taskerData.forEach((d, i) => {
          if (d._id === pinnedTaskId) {
            d["priority"] = 1;
          } else {
            d["priority"] = i + 2000;
          }
        });
        taskerData.sort((a, b) => a.priority - b.priority);
      }
      this.setState(
        {
          taskers: rateConversionUSDTasker(taskerData),
          count: Tasker.totalCount,
          totalRecord: Tasker.totalCount,
          sidecounts: Tasker.sidebarCounts,
        },
        () => this.getCreatedTasks()
      );
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };

  getCurrentPage = (currentPage) => {
    this.setState({ pageNumber: currentPage }, () => this.getTaskers());
  };

  handleChange = (type, min, max) => {
    // this.setState({ manual: false })
    //console.log("yash", type, min, max);
    switch (type) {
      case "RANGE":
        //console.log("1");
        this.setState({ minRate: min, maxRate: max }, () => this.getTaskers());
        break;
      case "MIN_VALUE":
        //console.log("2");
        this.setState({ minRate: min }, () => this.getTaskers());
        break;
      case "MAX_VALUE":
        //console.log("3");
        this.setState({ maxRate: max }, () => this.getTaskers());
        break;
      default:
        //console.log("not right");
        break;
    }
    // this.getTasks();
  };

  handlePaymentType = (type) => {
    //console.log("handlePaymentType", type);
    switch (type) {
      case "hourly":
        this.setState({ paymentType: type }, () => {
          this.getTaskers();
        });
        break;
      case "fixed":
        this.setState({ paymentType: type }, () => {
          this.getTaskers();
        });
        break;
      default:
        //console.log("not right");
        break;
    }
  };

  taskOrTaskerChange = (type) => {
    //console.log("taskOrTaskerChange", type);
    switch (type) {
      case "task":
        this.setState({ taskOrTasker: type }, () => {
          this.props.history.push(`/Task/list-task?search=${this.state.search}`);
        });
        break;
      case "tasker":
        this.setState({ taskOrTasker: type }, () => {
          this.props.history.push(`/Task/find-tasker?search=${this.state.search}`);
        });
        break;
      default:
        //console.log("not right");
        break;
    }
  };

  handleInputChange = async (event) => {
    const id = event.target.name;
    const value = event.target.value;
    // const checked = event.target.checked;
    //console.log("yash", id, value);
    switch (id) {
      case "skill":
        this.setState({ skills: value }, () => this.getAllSkills(value));
        this.getTaskers(value);
        break;
      case "sortBy":
        this.setState({ sortBy: value }, () => this.getTaskers(value));
        break;
      default:
        console.warn("Something is not right !");
    }
    // this.getTaskers();
  };

  searchTasks = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    if (event.key === "Enter") {
      switch (id) {
        case "search":
          this.setState({ search: value }, () => this.getTaskers(value));
          let searchValue = value;
          this.props.history.push(`/Task/find-tasker?search=${searchValue}`);
          //console.log("handlePageRedirection");
          break;
        default:
          console.warn("Something is not right !");
      }
    }
  };

  clearAll = () => {
    this.setState(
      {
        sortBy: "",
        findtask: true,
        findtaskers: false,
        taskOrTasker: "task",
        AllSkills: [],
        search: "",
        count: "",
        skills: [],
        paymentType: "",
        brand: "",
        minRate: "",
        rating: "",
        maxRate: "",
        manual: false,
      },
      () => this.getTaskers()
    );
  };

  getAllSkills = async (skill) => {
    this.setState({ loading: true });
    try {
      let allSkills;
      if (skill.length > 2) {
        const param = {
          searchPhrase: skill,
        };
        const response = await REQ_SKILLS(param);
        allSkills = response.data.result;
        this.setState({ AllSkills: allSkills });
      }
      // else{
      //     await timeout(2000);
      //     const param = {
      //         "searchPhrase": skill
      //     }
      //     const response = await REQ_SKILLS(param);
      //      allSkills=response.data.result
      //     //console.log("skill",allSkills)
      // }
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };

  FavouriteOpration = async (TaskerId, op) => {
    try {
      const requestBody = {
        favouriteTaskerId: TaskerId,
        operation: op,
      };
      const response = await REQ_FAVOURITE_TASKER(requestBody);
      const fav = response.data.result.profileStats.favouriteTaskers;
      this.setState({ favouriteTaskERs: fav, favTaskers: fav });
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  };

  portfolioPopup = (e, id) => {
    this.setState({
      openPortfolioPopoverId: id,
      anchorEl: e.target,
    });
  };

  portfolioPopoverClose = () => {
    this.setState({
      openPortfolioPopoverId: null,
      anchorEl: null,
    });
  };

  rating = (id) => {
    this.setState({ rating: id }, () => this.getTaskers());
  };

  onchangeAddSkill = (event, newValue) => {
    this.setState({ skills: newValue }, () => {
      this.getTaskers();
    });
  };

  taskerProfile = (taskerId) => {
    //console.log("taskerId", taskerId);
    let id = taskerId;
    this.props.history.push(`profile-description?taskerId=${id}`);
  };

  openInviteTaskerModal = (tasker) => {
    const token = cookieStorage.getCookie("token");
    if (!_.isEmpty(token)) {
      this.setState(
        {
          tasker: tasker,
        },
        () => this.setState({ hireNowModelStatus: true })
      );
    } else {
      notifier.warning("Warning", MESSAGE.WARN.NOT_LOGGED_IN);
    }
  };

  handleHireNowModelClose = () => {
    this.setState({
      hireNowModelStatus: false,
    });
  };

  openMobileFilter = () => {
    this.setState({ isMobileFilterOpen: true });
  };

  closeFilter = () => {
    this.setState({ isMobileFilterOpen: false });
  };
  clearAllMobile = () => {
    this.clearAll();
    this.setState({
      isMobileFilterOpen: false,
    });
  };
  renderLeftSide = () => {
    //numRatin,
    let { AllSkills, taskOrTasker, minRate, maxRate, manual } = this.state;
    return (
      <div className="filter-page">
        <div className="filters-container">
          <div className="filterHeader">
            <div className={`filterHeader__findTask ${taskOrTasker === "task" ? "active" : ""}`} onClick={(e) => this.taskOrTaskerChange("task")}>
              <span>Find Task</span>
            </div>
            <div className={`filterHeader__findTasker  ${taskOrTasker === "tasker" ? "active" : ""}`} onClick={(e) => this.taskOrTaskerChange("tasker")}>
              <span>Find Tasker</span>
            </div>
          </div>
          <Hide type="down" size={BreakPoints.sm}>
            <div className="filterclear-container">
              <h3>FILTERS</h3>
              <span className="clearall-btn" onClick={() => this.clearAll()}>
                Clear all
              </span>
            </div>
          </Hide>
          <div className="show-container">
            {/* <h4>Show Results</h4> */}
            <Hide type="up" size={BreakPoints.sm}>
              <button className="mobilefilter" onClick={this.openMobileFilter}>
                More Filters
              </button>
            </Hide>
            {/* <div className="findtaskstaskers-container">
              <div className="findtaskstaskers">
                <div
                  className={` ${
                    taskOrTasker === "task" ? "active-type" : "disable-type"
                  } `}
                  onClick={(e) => this.taskOrTaskerChange("task")}
                ></div>{" "}
                <div>Find Tasks</div>
              </div>
              <div className="findtaskstaskers">
                <div
                  className={` ${
                    taskOrTasker === "tasker" ? "active-type" : "disable-type"
                  } `}
                  onClick={(e) => this.taskOrTaskerChange("tasker")}
                ></div>
                <div>Find Taskers</div>
              </div>
            </div> */}
          </div>
          <div className={this.state.isMobileFilterOpen ? "mobile__filter open" : "mobile__filter"}>
            <Hide type="up" size={BreakPoints.sm}>
              <div className="filterclose">
                <h5>FILTERS</h5>
                <span onClick={() => this.clearAllMobile()}>Clear All</span>
                <span className="closeFilter" onClick={this.closeFilter}>
                  <img src={Images.IconForCloseModal} alt="" />
                </span>
              </div>
            </Hide>
            <div className="brand-container">
              <h4>Subskills</h4>
              <Hide type="up" size={BreakPoints.sm}>
                <Button className="mobileApplyButton" size="small" color="primary" variant="contained" onClick={this.closeFilter}>
                  Apply
                </Button>
              </Hide>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                // options={AllSkills.map((option) => option.name)}
                options={AllSkills}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => this.onchangeAddSkill(event, newValue)}
                renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" placeholder="Search Subskills" name="skill" onChange={(e) => this.handleInputChange(e)} />}
              />
              <p>eg.Logo,php,mobile</p>
            </div>
            <br />
            <div className="payrate-container">
              <div className="payment-container">
                <h4>Hourly Pay Rate (ℏ(s)/hr)</h4>
                {/* <div className="btn-container">
                <div className="type-change-btn">
                  <div className={` ${paymentType === 'hourly' ? 'active-type' : 'disable-type'} `} onClick={(e) => this.handlePaymentTypeHourly(e)}></div>
                  <div className="radio-btn-label">Hourly (Rate/hr)</div>
                </div>
                <div className="type-change-btn">
                  <div className={` ${paymentType === 'fixed' ? 'active-type' : 'disable-type'} `} onClick={(e) => this.handlePaymentTypeFixed(e)}></div>
                  <div className="radio-btn-label">Fixed</div>
                </div>
              </div> */}
              </div>
              <div className="rate-container">
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "", "")} className={`${minRate === "" && maxRate === "" ? "active-type" : "disable-type"}`}></div>
                  <div>Any Rates</div>
                  {/* <p className="num_p">(20)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "", "100")} className={`${minRate === "" && maxRate === "100" ? "active-type" : "disable-type"}`}></div>
                  <div>100 & below</div>
                  {/* <p className="num_p">(60)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "100", "300")} className={`${minRate === "100" && maxRate === "300" ? "active-type" : "disable-type"}`}></div>
                  <div>100-300</div>
                  {/* <p className="num_p">(50)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "300", "600")} className={`${minRate === "300" && maxRate === "600" ? "active-type" : "disable-type"}`}></div>
                  <div>300-600 </div>
                  {/* <p className="num_p">(30)</p> */}
                </div>

                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "600", "1000")} className={`${minRate === "600" && maxRate === "1000" ? "active-type" : "disable-type"}`}></div>
                  <div>600-1000</div>
                  {/* <p className="num_p">(10)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.setState({ manual: true })} className={`${manual ? "active-type" : "disable-type"}`}></div>
                  <div>Enter the range</div>
                </div>
              </div>
              <div className="rect_div">
                <div className="hbar-container">
                  <input disabled={manual ? false : true} type="number" placeholder="min" name="minValue" onChange={(e) => this.handleChange("MIN_VALUE", e.target.value, null)} />
                  <div className="hbar_div">ℏ(s)</div>
                </div>
                <div className="hbar-container">
                  <input disabled={manual ? false : true} type="number" placeholder="max" name="maxValue" onChange={(e) => this.handleChange("MAX_VALUE", null, e.target.value)} />
                  <div className="hbar_div">ℏ(s)</div>
                </div>
              </div>
            </div>
            <br />
            <div className="rating">
              <h4>Tasker Rating</h4>
              <div className="all-rating">All Ratings</div>
              <Rating
                name="customized-empty"
                defaultValue={0}
                precision={5}
                style={{ color: "#6DC7BE" }}
                max={5}
                onChange={(event, newValue) => {
                  // setValue(newValue);

                  this.rating(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Rating
                name="customized-empty"
                defaultValue={0}
                precision={4}
                style={{ color: "#6DC7BE" }}
                max={4}
                onChange={(event, newValue) => {
                  // setValue(newValue);

                  this.rating(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Rating
                name="customized-empty"
                defaultValue={0}
                style={{ color: "#6DC7BE" }}
                precision={3}
                max={3}
                onChange={(event, newValue) => {
                  // setValue(newValue);

                  this.rating(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Rating
                name="customized-empty"
                defaultValue={0}
                precision={2}
                style={{ color: "#6DC7BE" }}
                max={2}
                onChange={(event, newValue) => {
                  // setValue(newValue);

                  this.rating(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Rating
                name="customized-empty"
                defaultValue={0}
                precision={1}
                style={{ color: "#6DC7BE" }}
                max={1}
                onChange={(event, newValue) => {
                  // setValue(newValue);

                  this.rating(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTaskers = () => {
    let { taskers, totalRecord, pageNumber, anchorEl, openPortfolioPopoverId } = this.state;

    return (
      <div>
        {taskers.map((tasker, i) => (
          <div className={`task-details`} key={tasker._id}>
            <div className={`task-details-top`}>
              {tasker.priority === 1 ? <div className="Promotional">Pinned Tasker</div> : null}
              <div className="tasker-profile">
                <div style={{ display: "flex" }}>
                  <div className="profile-image">
                    <img className="pr-img" src={tasker.userId.profileImgUrl} alt={""} />
                    <div className="active"></div>
                  </div>
                  <div className="profile-content">
                    {/* <div className="info">
                      <div className="info-name"> {tasker.userId.firstName}{" "}{tasker.userId.lastName}</div>
                      <div className="sepration"></div>
                      <div className="tasker-location"><img src={Images.location} alt={""} />{tasker.userId.address.country}</div>
                    </div> */}
                    <div className="profile-content">
                      <div className="info">
                        <div className="info-name">
                          <Link to={`/${tasker.userId.nickName}`}>
                            {/* {tasker.userId.firstName} {tasker.userId.lastName} */}
                            {tasker.userId.nickName}
                          </Link>
                        </div>
                        <div className="sepration"></div>
                        <div className="tasker-location">
                          <img src={Images.location} alt={""} />
                          {tasker.userId.address ? tasker.userId.address.country : ""}
                        </div>
                      </div>
                      <div className="task-name">{tasker.title}</div>
                    </div>
                  </div>
                </div>
                {this.state.favTaskers.includes(tasker._id) ? (
                  <FavoriteIcon style={{ color: "#6DC7BE" }} className="favicon" onClick={() => this.FavouriteOpration(tasker._id, "unfavourite")} />
                ) : (
                  <img src={Images.favIcon} alt={""} className="favicon" onClick={() => this.FavouriteOpration(tasker._id, "favourite")} />
                )}
              </div>
              <div className="task-info">
                <p>{tasker.description}</p>
                <div className="skills-details">
                  {tasker.skills.map((skill) => (
                    <div className="top-skill" key={skill._id}>
                      {skill.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="task-bottom">
                <div className="task-bottom-left">
                  <div className="task-bottom-left-block" style={{ borderRight: "1px solid #EAEAEA" }}>
                    <p>Rating</p>
                    <div>{tasker.avgRating}</div>
                  </div>
                  <div className="task-bottom-left-block">
                    <p>Portfolio</p>
                    {tasker.portfolio ? (
                      <div className="portfoilo portfolio-container">
                        <WebLink href={tasker.portfolio[0].portfolioLink} target="_blank">
                          {tasker.portfolio[0].portfolioName}
                        </WebLink>
                        <span onClick={(e) => this.portfolioPopup(e, tasker._id)}>{tasker.portfolio.length - 1 > 0 ? ` +${tasker.portfolio.length - 1} more` : null}</span>
                        <Popover
                          open={openPortfolioPopoverId === tasker._id}
                          anchorEl={anchorEl}
                          onClose={() => this.portfolioPopoverClose()}
                          PaperProps={{
                            className: "portfolioPopover",
                          }}
                          anchorOrigin={{
                            vertical: 15,
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <div className="portfolio_links">
                            {tasker.portfolio.map((portfo, i) => (
                              <WebLink href={portfo.portfolioLink} target="_blank" key={portfo._id}>
                                {portfo.portfolioName}
                              </WebLink>
                            ))}
                          </div>
                        </Popover>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="task-bottom-right">
                  <div className="task-bottom-right-block">
                    <p>Hourly Rate</p>
                    <div className="hbars" title={tasker.fullHbars}>
                      {tasker.ratePerHour} {"ℏ(s)/hr"}
                      <span>{`(${tasker.usdRate} USD)`}</span>
                    </div>
                  </div>
                  {/* {invitedTaskers.includes(tasker.userId._id) ?
                    <button disabled>
                      Invitation Sent
                    </button> : */}
                  <button onClick={() => this.openInviteTaskerModal(tasker)}>Invite</button>
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        ))}
        <Hide type="down" size={BreakPoints.sm}>
          <Pagination neighbours={5} totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={this.getCurrentPage} showControls={true} showJump={true} />
        </Hide>
        <Hide type="up" size={BreakPoints.sm}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination neighbours={3} totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={this.getCurrentPage} showControls={true} />
          </div>
        </Hide>
        <InviteTaskerModal open={this.state.hireNowModelStatus} onClose={this.handleHireNowModelClose} tasker={this.state.tasker} createdTasks={this.state.tasks} getTaskers={this.getTaskers} />
      </div>
    );
  };

  render() {
    let { taskers, search, count, loading } = this.state;

    return (
      <div className="Find-Tasker-page">
        <div className="task-filter">
          <Hide type="down" size={BreakPoints.sm}>
            <div className="back" onClick={() => history.goBack()}>
              <img src={Images.back} alt={""} />
              {"Go Back"}
            </div>
          </Hide>

          {this.renderLeftSide()}
        </div>
        <div className="right">
          <h2>Find Taskers by Superskill</h2>
          <div className="Find-Tasker-page-container">
            <div className="Find-Tasker-page-search">
              <div className="search">
                <input
                  placeholder={"Search For Superskills..."}
                  defaultValue={search}
                  name="search"
                  onKeyPress={(e) => this.searchTasks(e)}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      this.setState({ search: "" }, () => this.getTaskers(""));
                      this.props.history.push(`/Task/find-tasker?search=`);
                    }
                  }}
                />
                <img className="search-icon" src={Images.search} alt={""} />
              </div>
              <div className="task-search-filter">
                <div>{count ? count : 0} Tasker's Superskills found</div>
                <div className="filter-options">
                  <div>
                    <img src={Images.sorting} alt={""} />
                    Sort by:
                  </div>
                  <select name="sortBy" onChange={(e) => this.handleInputChange(e)}>
                    <option default value="newest">
                      Newest
                    </option>
                    <option value="relevance">Relevance</option>
                    <option value="priceLowToHigh">Price: Low to High</option>
                    <option value="priceHighToLow">Price: High to Low</option>
                    <option value="highRating">High Rating</option>
                    <option value="lowRating">Lower Rating</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="tasks-container" style={{ position: loading ? "relative" : "inherit" }}>
              {loading ? <Loader height={"300px"} /> : taskers && taskers.length > 0 ? this.renderTaskers() : <div className="no-task">No taskers to show!</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskFinder;
