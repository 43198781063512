import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Images } from "../../assets/common";
import FavouriteIcon from "../../components/FavouriteIcon";
import NegotiationModal from "../../components/NegotiationModal";
import notifier from "../../components/notifier";
import SummaryReportModal from "../../components/Summary";
import { getErrorString } from "../validator";
import { MESSAGE } from "../../constants/messages";
import { GET_FEES_FOR_TASK, GET_NEGOTIATION_HISTORY, HIRE_NOW, REQ_USER_DETAILS } from "../../services/api-service";
import "./index.scss";
import { isEmpty } from "lodash";

const ViewBidOnTask = (props) => {
  const classes = createStyles();
  const { bidDetails, bidType, isHired, setLoadingFalse, setLoadingTrue } = props;
  const [userDetails, setUserDetails] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [summaryReportPopup, setSummaryReportPopup] = useState(false);
  const [finalBidData, setFinalBidData] = useState({});
  const [feesPrice, setFeesPrice] = useState({});
  const [negotiationData, setNegotiationData] = useState([]);
  const [sender, setSender] = useState("");

  useEffect(() => {
    getUserDetails();
    getNegotiationHistory();
  }, []);

  const getUserDetails = async (id) => {
    const currentUser = localStorage.getItem("currentUser");
    const sender = currentUser === bidDetails.task.author._id ? "needer" : "tasker";
    setSender(sender);
    if (currentUser) {
      try {
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        const userDetails = response.data.result;
        setUserDetails(userDetails);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getNegotiationHistory = async (id) => {
    try {
      if (setLoadingTrue) setLoadingTrue();
      const requestBody = {
        bidId: bidDetails._id,
      };
      const response = await GET_NEGOTIATION_HISTORY(requestBody);
      let responseData = response.data.result;
      if (setLoadingFalse) setLoadingFalse();
      setNegotiationData(responseData);

      //Get bid details without negotiating(Direct Hire now)
      const finalBidData = responseData.filter((d) => d.bid === bidDetails._id);
      setFinalBidData(finalBidData[0]);
    } catch (e) {
      if (setLoadingFalse) setLoadingFalse();
      console.log(e);
    }
  };

  const hireNow = async (taskFees) => {
    try {
      setLoader(true);
      const requestBody = {
        taskId: finalBidData.task,
        finalBidId: finalBidData.bid,
        finalBidderId: finalBidData.tasker,
        finalBidderSuperskillId: bidDetails.taskerSuperskillId,
        taskPrice: taskFees.taskPrice,
        taskbarFees: taskFees.taskFees,
        taskbarFeesType: "+", // +, %
        totalAmount: taskFees.totalAmount,
      };
      const response = await HIRE_NOW(requestBody);
      if (response.data.status === "success") {
        notifier.success("Success", MESSAGE.SUCCESS.HIRED_SUCESSFULLY);
        getSummary();
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const getSummaryWithoutPay = async (finalBid) => {
    try {
      const bidId = finalBid.bid;
      const pay = false;
      setLoader(true);
      const response = await GET_FEES_FOR_TASK(bidId, pay);
      if (response.data.status === "success") {
        const taskFees = response.data.result;
        setFinalBidData(finalBid);
        hireNow(taskFees);
      }
    } catch (error) {
      setLoader(false);
      notifier.error("Error", getErrorString(error));
    }
  };

  const getSummary = async () => {
    try {
      const bidId = finalBidData.bid;
      const pay = true;
      setLoader(true);
      const response = await GET_FEES_FOR_TASK(bidId, pay);
      if (response.data.status === "success") {
        setLoader(false);
        const taskFees = response.data.result;
        setFeesPrice(taskFees);
        setSummaryReportPopup(true);
      }
    } catch (error) {
      setLoader(false);
      notifier.error("Error", getErrorString(error));
    }
  };

  const handleModalClose = () => {
    setModelOpen(false);
  };

  return (
    <>
      {bidDetails && bidDetails.bidder ? (
        <div className={`${classes.paperBox} paperBox`}>
          <div className={`${classes.profileDetails} profileDetails`}>
            <div className={`${classes.profileDetails__profile} profileDetails__profile`}>
              <div className="profilePic">
                <img src={bidDetails.bidder.profileImgUrl} alt="Profile Name" />
                <span className="box active "></span>
              </div>
              <div className="details">
                <div className="name_and_address">
                  <span className="name">{bidDetails.bidder.fnameVisibilty ? `${bidDetails.bidder.firstName} ${bidDetails.bidder.lastName}` : bidDetails.bidder.nickName}</span>
                  <span className="seprator"></span>
                  <span className="locationIcon">
                    <img src={Images.location} alt="" />
                  </span>
                  <span className="locaionText">{bidDetails.bidder.address ? bidDetails.bidder.address.country : null}</span>
                </div>
                <div className="rating">
                  {[...Array(5)].map((item, i) => (
                    <span key={i} className={i + 1 <= parseInt(bidDetails.bidder.profileStats.avgRating) ? "star fill" : "star"}></span>
                  ))}
                  <span className="ratingVal">{`(${bidDetails.bidder.profileStats.avgRating}/5)`}</span>
                </div>
              </div>
            </div>

            <div className="favBtnArea">
              <div className="favBtn">
                <FavouriteIcon taskId={bidDetails.task._id} userDetails={userDetails} />
              </div>
            </div>
          </div>
          {bidType === "Proposals" ? (
            <div className={`${classes.bidText} bidText`}>
              <p>{bidDetails.proposalDescription}</p>
            </div>
          ) : null}
          <div className={`${classes.profileMetaData} profileMetaData`}>
            <div className="left">
              <div className="price item">
                <span className="label">Price</span>
                <span className="val">
                  <div className="hbars" title={bidDetails.fullHbars}>
                    {bidDetails.amount}
                  </div>
                  {bidDetails.task.paymentTerms.paymentType === "hourly" ? " ℏ/hr" : " ℏ"}
                  <em>{`(${bidDetails.usdRate} USD)`}</em>
                </span>
                {bidType === "Proposals" ? <span className="newOfferAlert">New Offer received</span> : null}
              </div>
              <div className="limit item">
                <span className="label">{bidDetails.task.paymentTerms.paymentType === "hourly" ? "Weekly Limit" : "Duration Time"}</span>
                <span className="val">
                  {bidDetails.weeklyLimit} {bidDetails.task.paymentTerms.paymentType === "hourly" ? "Hrs" : "Days"}
                </span>
              </div>
            </div>
            <div className="right">
              <div className="applyDate">
                <span className="label">Date Applied</span>
                <span className="val">
                  <Moment format="DD-MM-YYYY">{bidDetails.createdAt}</Moment>
                </span>
              </div>
              {!isHired ? (
                bidType === "Proposals" ? (
                  <button className="hireNow" onClick={() => setModelOpen(true)}>
                    {"Negotiate"}
                  </button>
                ) : (
                  <button className="hireNow" onClick={() => getSummaryWithoutPay(finalBidData)}>
                    {loading ? <img src={Images.ellipsis_loader} alt="" /> : "Hire Now"}
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <NegotiationModal open={modelOpen} bidDetails={bidDetails} negotiationData={negotiationData} onClose={handleModalClose} hireNow={getSummaryWithoutPay} getNegotiationHistory={getNegotiationHistory} sender={sender} loading={loading} {...props} />
      {!isEmpty(feesPrice) && (
        <SummaryReportModal
          open={summaryReportPopup}
          onClose={() => {
            setSummaryReportPopup(false);
            window.location.reload();
          }}
          feesPrice={feesPrice}
        />
      )}
    </>
  );
};

const createStyles = makeStyles({
  paperBox: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08) , 2px 0px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 14,
    marginBottom: 18,
    "& > div": {
      borderRadius: 5,
    },
  },
  profileDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 22,
    "& .favBtnArea": {
      width: 175,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  profileDetails__profile: {
    flex: "0 0 calc(100% - 175px)",
    maxWidth: "calc(100% - 175px)",
    display: "flex",

    "& .profilePic": {
      width: 48,
      height: 48,
      position: "relative",
      marginRight: 20,
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: 3,
        objectFit: "cover",
        objectPosition: "center",
      },
      "& .box": {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: 10,
        height: 10,
        borderRadius: 3,
        "&.active": {
          backgroundColor: "#1bc11b",
        },
        "&.inactive": {
          backgroundColor: "#ED0B0B",
        },
      },
    },
    "& .details": {
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: "100%",

      "& .name_and_address": {
        marginBottom: 2,
        "&>span": {
          display: "inline-block",
        },
        "& .name": {
          padding: "0  7px",
          color: "#4DACA2",
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: 500,
        },
        "& .seprator": {
          width: 6,
          height: 6,
          borderRadius: "50%",
          backgroundColor: "#DDDDDD",
          marginRight: 8,
        },
        "& .locationIcon": {
          marginRight: 8,
          "& img": {
            height: 18,
            width: "auto",
          },
        },
        "& .locaionText": {
          fontSize: 13,
          lineHeight: "15px",
        },
      },
      "& .rating": {
        display: "flex",
        alignItems: "center",
        "&>span": {
          display: "inline-block",
        },
        "& .star": {
          width: 20,
          height: 20,
          backgroundImage: `url('${Images.Rating}')`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          "&.fill": {
            backgroundImage: `url('${Images.GreenRating}')`,
          },
        },
        "& .ratingVal": {
          color: "#2C2C2C",
          fontWeight: 500,
          fontSize: 13,
          marginLeft: 6,
        },
      },
    },
  },

  bidText: {
    marginBottom: 22,
    "& p": {
      color: "#2c2c2c",
      fontSize: 13,
      fontWeight: 500,
      marginBottom: 0,
    },
  },
  profileMetaData: {
    borderTop: "1px solid #dddddd",
    marginLeft: -14,
    marginRight: -14,
    padding: "14px",
    paddingBottom: 0,
    display: "flex",
    alignItems: "center",
    "& .left": {
      display: "flex",
      alignItems: "center",
      "&> .item": {
        borderRight: "1px solid #dddddd",
        "&>span": {
          display: "block",
        },
        "&:last-child": {
          border: "none",
        },
        "& .label": {
          fontSize: 12,
          color: "#707070",
        },
        "& .val": {
          fontSize: 14,
          color: "#2c2c2c",
          fontWeight: 600,
          "&>span": {
            fontSize: 12,
            fontWeight: 400,
          },
          "&>em": {
            fontSize: 12,
            textTransform: "uppercase",
            fontWeight: 400,
          },
        },
      },
      "& .price": {
        paddingRight: 40,
        position: "relative",
        "& .newOfferAlert": {
          position: "absolute",
          width: "max-content",
          display: "inline-block",
          padding: "5px 8px",
          borderRadius: 25,
          backgroundColor: "#FEC21C",
          fontSize: 10,
          color: "#2c2c2c",
          fontWeight: 600,
          top: -14,
          right: 30,
        },
      },
      "& .limit": {
        marginLeft: 25,
      },
      maxWidth: "50%",
      flex: "0 0 50%",
    },
    "& .right": {
      maxWidth: "50%",
      flex: "0 0 50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& button": {
        width: 100,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 3,
        outline: "none!important",
        "&.negotiate": {
          marginLeft: 15,
          border: "1px solid #6DC7BE",
          color: "#6DC7BE",
          backgroundColor: "transparent",
        },
        "&.hireNow": {
          marginLeft: 15,
          color: "white",
          border: "none",
          backgroundColor: "#6DC7BE",
        },
      },
      "& .applyDate": {
        marginLeft: 15,
        textAlign: "center",
        "&>span": {
          display: "block",
          "&.label": {
            fontSize: 12,
            color: "#707070",
          },
          "&.val": {
            fontSize: 14,
            color: "#2c2c2c",
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export default ViewBidOnTask;
