import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/privateRoute';
// import ChangePassword from './change-password';
import ChangePassword from './change-password';
import CloseAccount from './close-account';
import Notification from './notification';

class RouterOutlet extends Component {
  render() {
    const { match } = this.props;

    return(
      <Switch>
        <PrivateRoute path={`${match.url}/change-password`} component={ChangePassword} />
        <PrivateRoute path={`${match.url}/close-account`} component={CloseAccount} />
        <PrivateRoute path={`${match.url}/notification-setting`} component={Notification} />
        {/*Deafult Page For Setting*/}
        <PrivateRoute exact path={`${match.url}/`} component={ChangePassword} />
      </Switch>
    )
  } 
} 

export default RouterOutlet;