import { Button, Fade, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import ConfirmModal from "../../../components/deleteConfirmModal";
import Loader from "../../../components/Loader";
import NoDataToShow from "../../../components/NoDataToShow";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import StyledRadioButton from "../../../components/StyledRadioButton";
import { getErrorString, paginationLimit, serialize } from "../../../components/validator";
import { StyledBackdrop } from "../../../lib/validators";
import { DISPUTED_TASK_OPERATION, GET_DISPUTED_TASK } from "../../../services/api-service";
import "./index.scss";

const DisputeManagement = (props) => {
  const [disputes, setDisputes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageNo, setPageNumber] = useState(1);
  const [allRecords, setAllRecords] = useState(0);
  const [endModal, setEndModal] = useState(false);
  const [contractEndProgress, setContractEndProgress] = useState(false);
  const [operation, setOperation] = useState("");
  const [continueModal, setContinueModal] = useState(false);
  const [splitData, setSplitData] = useState({
    type: "fixed",
    splitValue: 0,
    escrowAmount: 0,
    needer: 0,
    tasker: 0,
    error: false,
    helperText: "",
    id: "",
  });

  useEffect(() => {
    getDisputedTask();
  }, []);

  //Gwt All Disputed task
  const getDisputedTask = async (reqObj) => {
    setLoading(true);
    try {
      const params = {
        pageNo: reqObj ? reqObj.pageNo : pageNo,
        limit: paginationLimit,
      };

      const response = await GET_DISPUTED_TASK(serialize(params));

      setDisputes(response.data.result.disputedTasks);
      setAllRecords(response.data.result.totalCount);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notifier.error("Error", getErrorString(e));
    }
  };

  //handle Page change
  const handlePageChange = (pageNo) => {
    setPageNumber(pageNo);
    getDisputedTask({ pageNo: pageNo });
  };

  //Opening Modal
  const handleSplitModalOpen = (dispute) => {
    const { task, _id: disputeId } = dispute;
    setSplitData({
      type: "fixed",
      splitValue: 0,
      escrowAmount: task.taskEscrowAmount.toFixed(4),
      needer: 0,
      tasker: 0,
      error: false,
      helperText: "",
      id: task._id,
      disputeId,
    });
    setEndModal(true);
    setOperation("end");
  };

  //Closing End Modal
  const endModalClose = () => {
    setEndModal(false);
  };

  const handSplitTypeChange = (e) => {
    const _splitData = splitData;
    _splitData.type = e.target.value;
    _splitData.needer = 0;
    _splitData.tasker = 0;
    setSplitData({ ..._splitData });
  };

  const handleSplitValueChange = (event) => {
    const value = parseFloat(event.target.value);
    const _splitData = splitData;
    if (_splitData.type === "fixed" && value >= 0 && value <= _splitData.escrowAmount) {
      _splitData.tasker = value.toFixed(4);
      _splitData.needer = (_splitData.escrowAmount - value).toFixed(4);
      _splitData.error = false;
      _splitData.helperText = "";
    } else if (_splitData.type === "percentage" && value >= 0 && value <= 100) {
      _splitData.tasker = (_splitData.escrowAmount * (value / 100)).toFixed(4);
      _splitData.needer = (_splitData.escrowAmount - _splitData.tasker).toFixed(4);
      _splitData.error = false;
      _splitData.helperText = "";
    } else if (value === "") {
      _splitData.needer = 0;
      _splitData.tasker = 0;
      _splitData.error = false;
      _splitData.helperText = "";
    } else {
      _splitData.error = true;
      _splitData.helperText = "Something Wrong With Entered Value.Make sure value should be less tha Escrow amount or in Percentage max allowd value is 100.";
      _splitData.needer = 0;
      _splitData.tasker = 0;
    }
    _splitData.value = value;
    setSplitData({ ..._splitData });
  };

  const doOperation = async () => {
    const reqBody = {
      // operation: "end",
      taskId: splitData.id,
      disputeId: splitData.disputeId,
      // "transferType": "percentage",
      // "value": 10
    };
    if (operation === "end") {
      reqBody["operation"] = "end";
      reqBody["transferType"] = splitData.type;
      reqBody["value"] = splitData.value;
    } else {
      reqBody["operation"] = "continue";
    }

    try {
      const response = await DISPUTED_TASK_OPERATION(reqBody);
      notifier.success("Operation Performed Successfully");
      setContinueModal(false);
      setEndModal(false);
      setContractEndProgress(false);
      getDisputedTask({ pageNo: pageNo });
    } catch (e) {
      notifier.error("Error", getErrorString(e));
      setContinueModal(false);
      setEndModal(false);
      setContractEndProgress(false);
      // getDisputedTask({pageNo:pageNo})
    }
  };

  const endContract = () => {
    const _splitData = splitData;

    if (splitData.value && splitData.value > 0) {
      setContractEndProgress(true);
      doOperation();
    } else {
      _splitData.error = true;
      _splitData.helperText = "Enter a value in fixed amount or in percentage that will go to the tasker.";
      setSplitData({ ..._splitData });
      notifier.warning("Value field should not be empty.");
    }
  };

  const doContinueOperation = (dispute) => {
    const { task, _id: disputeId } = dispute;
    const _splitData = splitData;
    _splitData.id = task._id;
    _splitData.disputeId = disputeId;

    setContinueModal(true);
    setOperation("continue");
    setSplitData({ ..._splitData });
  };

  const doCancel = () => {
    setContinueModal(false);
  };

  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>Dispute Management</h4>
      </div>
      <div className="disputemanageMent">
        {isLoading ? (
          <Loader />
        ) : (
          <TableContainer className="disputemanageMent__tableBox">
            {disputes && disputes.length > 0 ? (
              <Table stickyHeader size="small">
                <colgroup>
                  <col width="50" />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col width="200" />
                </colgroup>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Escrowamount</StyledTableCell>
                    <StyledTableCell>Operation</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {disputes.map((dispute, index) => {
                    const { task, _id: disputeId } = dispute;
                    return (
                      <StyledTableRow key={disputeId}>
                        <StyledTableCell>{task.numericId}</StyledTableCell>
                        <StyledTableCell>{task.heading}</StyledTableCell>
                        <StyledTableCell>{task.amountFixed.taskPrice.toFixed(4)} ℏ(s)</StyledTableCell>
                        <StyledTableCell>{task.paymentTerms.paymentType}</StyledTableCell>
                        <StyledTableCell>{task.taskEscrowAmount.toFixed(4)} ℏ(s)</StyledTableCell>
                        <StyledTableCell>
                          <Button variant="contained" color="primary" size="small" onClick={doContinueOperation.bind(this, dispute)}>
                            Continue
                          </Button>
                          &nbsp;&nbsp;
                          <Button variant="contained" color="secondary" size="small" onClick={handleSplitModalOpen.bind(this, dispute)}>
                            End
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <NoDataToShow />
            )}
          </TableContainer>
        )}
      </div>
      <div className="Pagination">
        <Pagination totalRecord={allRecords} pageNumber={pageNo} sendCurrentPage={handlePageChange} showControls={true} />
      </div>
      <Modal
        aria-labelledby="End-Contarct"
        aria-describedby="End Contarct Modal"
        className="EndContarctModal"
        open={endModal}
        onClose={endModalClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={endModal}>
          <div className="EndContarctModal__paper">
            <span className="CloseModalBtn" onClick={endModalClose}>
              <img src={Images.IconForCloseModal} alt="" />
            </span>
            <div className="heading">
              <h3>
                Please specify the split of amount to <span style={{ color: "red" }}>go to the tasker</span> in below boxes
              </h3>
            </div>
            <div className="fixedORPercentage">
              <StyledRadioButton name="splitType" checked={splitData.type === "fixed"} value="fixed" onChange={handSplitTypeChange} label="Fixed" id="split-fixed" />
              <StyledRadioButton name="splitType" checked={splitData.type === "percentage"} value="percentage" onChange={handSplitTypeChange} label="Percentage" id="split-percentage" />
            </div>
            <TextField
              name="splitedAmount"
              label={splitData.type === "fixed" ? "Enter The Amount that should goes to Tasker" : "Enter the % that should goes to Tasker"}
              id="Split-amount"
              variant="outlined"
              onChange={handleSplitValueChange}
              fullWidth
              type="number"
              error={splitData.error}
              helperText={splitData.helperText}
              size="small"
            />
            <p>Current Task Escrow Amount : {splitData.escrowAmount} ℏ(s)</p>
            <div className="amount">
              <div className="item">
                <span>Needer</span>
                <span className="amount">{splitData.needer}</span>
              </div>
              <div className="item">
                <span style={{ color: "red" }}>
                  <b>Tasker</b>
                </span>
                <span className="amount">{splitData.tasker}</span>
              </div>
            </div>
            <button disabled={splitData.error} onClick={endContract}>
              {contractEndProgress ? <img src={Images.ellipsis_loader} alt="wait..." /> : "submit"}
            </button>
          </div>
        </Fade>
      </Modal>
      <ConfirmModal modal={continueModal} yesCallback={() => doOperation()} cancelCallback={() => doCancel()} text="This operation will change task to normal state as before." yesBtnLabel="Yes Continue" />
    </>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    border: "none",
    fontSize: 13,
    color: "#2c2c2c",
    "&:last-child": {
      textAlign: "right",
      textTransform: "uppercase",
    },
    "&:nth-child(3),&:nth-child(4),&:nth-child(5)": {
      textAlign: "center",
    },
  },
  head: {
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #dddddd",
    textTransform: "uppercase",
    "&:nth-child(6)": {
      textAlign: "center",
    },
  },
  stickyHeader: {
    zIndex: "auto",
  },
  body: {
    "& span.cnf": { color: "#07D62F" },
    "& span.rej": { color: "#ED0B0B" },
    "& span.pending": { color: "#2C2C2C" },
  },
}))(TableCell);

// const StyledTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#2c2c2c",
//     color: "#ffffff",
//     fontSize: 12,
//   },
// }))(Tooltip);

export default DisputeManagement;
