import React from 'react';
import Header from '../../components/header';
import HeaderBoard from './header-board';
import './index.scss';
import RouterOutlet from './router-outlet';

function Home(props) {   

    return (
      <div className="home-page">
        <Header  {...props} />
        <div className="home-page-container">
          <HeaderBoard  {...props}/>
          <div className="home-page-content">
            <RouterOutlet {...props} />
          </div>
        </div>
      </div>
    );
}
export default Home;