import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import notifier from '../../../components/notifier';
import { getErrorString } from '../../../components/validator';
import { GET_ALL_SKILLS , GET_PROHIBITED_WORDS } from '../../../services/api-service';

import './index.scss';

const ExportExcel = () => {

    const [skillsHeader , setSkillsHeader] = useState([]);
    const [wordHeader , setWordHeader] = useState([]);
    const [skills , setAllSkills] = useState([]);
    const [words , setAllWords] = useState([])

    useEffect(() => {
        getAllSkills();
        getAllProhibitedWords();
    },[])

    const getAllSkills = async() =>{
        try{
            const response = await GET_ALL_SKILLS();
            const data = response.data.result;
            
            const skillsHeader = Object.keys(data[0]).map((d) =>{
                return { label: d, key: d }
            })
            setSkillsHeader(skillsHeader);
            setAllSkills(data);
        }
        catch(e){
            console.log(e)
            notifier.error('Error' , getErrorString(e))
        }
    } 


    const getAllProhibitedWords = async () => {
        try {
          const response = await GET_PROHIBITED_WORDS()
          const data = response.data.result;

          const wordHeader = Object.keys(data[0]).map((d) =>{
                return { label: d, key: d }
            })
            setWordHeader(wordHeader);
            setAllWords(data);
        } catch (e) {
          notifier.error("Error", getErrorString(e))
        }
      }

    return(<>
      <div className="admin__body__continer__content__heading">
            <h4>Export To Excel</h4>
      </div>
      <div className="exportIntoExcel">
      <CSVLink
        data={skills}
        filename={'allSkillsList.csv'}
        className="downloadSkill"
        headers={skillsHeader} >Export All Subskills </CSVLink>

        <CSVLink
        data={words}
        filename={'allProhibitedWords.csv'}
        className="downloadWords"
        headers={wordHeader} >Export All Prohibited Words </CSVLink>

      </div>
    </>)
}

export default ExportExcel;