import React, { useState, useMemo, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { MobileMenuContext } from "./context/MobileMenuContext";
import { TaskbarUserContext } from "./context/TaskbarUserContext";
import Pages from "./pages";
import cookieStorage from "./services/cookie-storage";

function RouterOutlet(props) {
  const [isMobileMenuOpen, setMobileMenuOpenStatus] = useState(false);
  const [isUserLogIn, setUserLogIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const value = useMemo(
    () => ({ isMobileMenuOpen, setMobileMenuOpenStatus }),
    [isMobileMenuOpen, setMobileMenuOpenStatus]
  );
  const taskbarUser = useMemo(
    () => ({ isUserLogIn, setUserLogIn, currentUser, setCurrentUser }),
    [isUserLogIn, setUserLogIn, currentUser, setCurrentUser]
  );

  useEffect(() => {
    getLogInUser()
  }, [])

  const getLogInUser = () => {
    const token = cookieStorage.getCookie("token");
    if (token) {
      setUserLogIn(true);
      setCurrentUser(JSON.parse(localStorage.getItem("loggedInUser")))
    }
  }

  return (
    <TaskbarUserContext.Provider value={taskbarUser}>
      <MobileMenuContext.Provider value={value}>
        <Route path="/" render={(props) => <Pages {...props} />} />
      </MobileMenuContext.Provider>
    </TaskbarUserContext.Provider>
  );
}

export default RouterOutlet;
