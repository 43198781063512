import { TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import DoneIcon from "@material-ui/icons/Done";
import React, { useState } from "react";
import { Images } from "../../../assets/common";
import notifier from "../../../components/notifier";
import {
  getErrorString, validatePasswordData
} from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { CHANGE_PASSWORD } from "../../../services/api-service";

const ChangePassword = (props) => {
  const [loading, setLoader] = useState(false);
  const [currentPasswordShow, setCurrentPasswordShown] = useState(false);
  const [newPasswordShow, setNewPasswordShown] = useState(false);
  const [confirmNewPasswordShow, setConfirmNewPasswordShown] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordValidit, setPasswordValidit] = useState({
    minChar: null,
    number: null,
    lower: null,
    upper: null,
    specialChar: null,
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const toggleCurrentPasswordVisiblity = () =>
    setCurrentPasswordShown(!currentPasswordShow);
  const toggleNewPasswordVisiblity = () =>
    setNewPasswordShown(!newPasswordShow);
  const toggleConfirmNewPasswordVisiblity = () =>
    setConfirmNewPasswordShown(!confirmNewPasswordShow);

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    switch (id) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        setPasswordValidit({
          minChar: newPassword.length >= 8 ? true : false,
          number: isNumberRegx.test(newPassword) ? true : false,
          lower: LowerRegx.test(newPassword) ? true : false,
          upper: UpperRegx.test(newPassword) ? true : false,
          specialChar: specialCharacterRegx.test(newPassword) ? true : false,
        });
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(value);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const changePassword = async () => {
    let email = "";
    let data = { email, newPassword, confirmNewPassword };
    if (!currentPassword) {
      notifier.warning("Password", MESSAGE.ERROR.CURRENT_PASSWORD_EMPTY);
    } else if (validatePasswordData(data).newPassword) {
      notifier.warning("Password", validatePasswordData(data).newPassword);
    } else if (validatePasswordData(data).confirmNewPassword) {
      notifier.warning(
        "Password",
        validatePasswordData(data).confirmNewPassword
      );
    } else
      try {
        setLoader(true);
        const requestBody = {
          currentPassword: currentPassword,
          password: newPassword,
          confirmPassword: confirmNewPassword,
        };
        const response = await CHANGE_PASSWORD(requestBody);
        if (response.data.status === "success") {
          setLoader(false);
          notifier.success(
            "Success",
            MESSAGE.SUCCESS.PASSWORD_CHANGED_SUCESSFULLY
          );
          setTimeout(() => {
            props.history.push('/login')
          }, 1000);
        }
      } catch (e) {
        setLoader(false);
        const errorString = getErrorString(e);
        notifier.error("Error", errorString);
      }
  };

  const isNumberRegx = /\d/;
  const LowerRegx = /(?=.*?[a-z])/;
  const UpperRegx = /(?=.*?[A-Z])/;
  const specialCharacterRegx = /(?=.*[!@#$%^&*])/;

  return (
    <>
      <div className="setting-opt-content__heading">
        <h2>Change Password</h2>
      </div>
      <div className="setting-opt-content__main">
        <div className="change_password_form">
          <div className="login-form__control">
            <div className={"field-container"}>
              <TextField
                label="Current Password"
                variant="outlined"
                name="currentPassword"
                onChange={handleInputChange}
                type={currentPasswordShow ? "text" : "password"}
                value={currentPassword}
              />
              <div className="eye">
                {currentPasswordShow ? (
                  <Visibility onClick={toggleCurrentPasswordVisiblity} />
                ) : (
                    <VisibilityOff onClick={toggleCurrentPasswordVisiblity} />
                  )}
              </div>
            </div>
          </div>

          <div className="login-form__control">
            <div className={"field-container"}>
              <TextField
                label="New Password"
                variant="outlined"
                name="newPassword"
                onBlur={() => setPasswordFocused(false)}
                onFocus={() => setPasswordFocused(true)}
                onChange={handleInputChange}
                type={newPasswordShow ? "text" : "password"}
                value={newPassword}
              />
              <div className="eye">
                {newPasswordShow ? (
                  <Visibility onClick={toggleNewPasswordVisiblity} />
                ) : (
                    <VisibilityOff onClick={toggleNewPasswordVisiblity} />
                  )}
              </div>
              {passwordFocused && (
                <div className="password-meter">
                  <div class="arrow-right"></div>
                  <p className="text-dark">Password must contain:</p>
                  <div className="text-muted">
                    <div className="pass-valid">
                      
                      {newPassword.length >= 8 ? (
                        <DoneIcon
                          style={{ fontSize: "14px", color: "#05CF5B" }}
                        />
                      ) : (
                          "-"
                        )}
                      {"   "}Have at least 8 characters
                    </div>
                    <div className="pass-valid">
                      
                      {passwordValidit.number ? (
                        <DoneIcon
                          style={{ fontSize: "14px", color: "#05CF5B" }}
                        />
                      ) : (
                          "-"
                        )}
                      {"   "}Have at least 1 number
                    </div>
                    <div className="pass-valid">
                      {passwordValidit.upper ? (
                        <DoneIcon
                          style={{ fontSize: "14px", color: "#05CF5B" }}
                        />
                      ) : (
                          "-"
                        )}
                      {"   "}One upper case
                    </div>
                    <div className="pass-valid">
                      
                      {passwordValidit.lower ? (
                        <DoneIcon
                          style={{ fontSize: "14px", color: "#05CF5B" }}
                        />
                      ) : (
                          "-"
                        )}
                      {"   "}One lower case
                    </div>
                    <div className="pass-valid">
                      
                      {passwordValidit.specialChar ? (
                        <DoneIcon
                          style={{ fontSize: "14px", color: "#05CF5B" }}
                        />
                      ) : (
                          "-"
                        )}
                      {"   "}Have at least 1 special character from <strong>!@#$%^&*</strong>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="login-form__control">
            <div className={"field-container"}>
              <TextField
                label="Confirm New Password"
                variant="outlined"
                name="confirmNewPassword"
                onChange={handleInputChange}
                type={confirmNewPasswordShow ? "text" : "password"}
                value={confirmNewPassword}
              />
              <div className="eye">
                {confirmNewPasswordShow ? (
                  <Visibility onClick={toggleConfirmNewPasswordVisiblity} />
                ) : (
                    <VisibilityOff onClick={toggleConfirmNewPasswordVisiblity} />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="setting-opt-content__footer">
        {/* <button className="cancel">Cancel</button> */}
        <button className="save" onClick={changePassword}>
          {loading ? (
            <img alt="" src={Images.ellipsis_loader} />
          ) : (
              <span>Save</span>
            )}
        </button>
      </div>
    </>
  );
};

export default ChangePassword;
