import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/privateRoute";
import PageNotFound from './404';
import Admin from './admin';
import BasicInfo from './basic-info';
import Email from './email-verify';
import ForgotPassword from './forgot-pass';
import Home from './home';
import InviteOnTaskbar from './invite';
import Landing from './landing';
import Login from './login';
import Register from './register';
import ResetPassword from './resetPassword';
import SetNewPassword from './set-new-pass';
import Settings from './settings';
import Task from './Task';
import TermsOfUse from './terms-of-use';
import UserProfile from './userProfile';




function RouterOutlet(props) {
    const userString = localStorage.getItem("loggedInUser");
    const logedInUser = userString && userString!=='' ? JSON.parse(userString) : [];
    const userRole = localStorage.getItem("loggedInUser")? logedInUser.role : null;
    const isAdmin = (userRole === "Admin" || userRole === "Super-Admin")  ? true :false ;

    return (
            <Router>
                <Switch>
                    <Route exact path="/" render={props => (<Landing {...props} />)} />
                    <Route path="/Task" render={props => (<Task {...props} />)} />
                    <Route path="/tasks" render={props => (<Task {...props} />)} />
                    <Route path="/login" render={props => (<Login {...props} />)} />
                    <Route path="/register" render={props => (<Register {...props} />)} />
                    <Route path="/resetPassword" render={props => (<ResetPassword {...props} />)} />
                    <Route path="/basic-info" render={props => (<BasicInfo {...props} />)} />
                    <Route path="/forgot-password" render={props => (<ForgotPassword {...props} />)} />
                    <Route path="/new-password" render={props => (<SetNewPassword {...props} />)} />
                    <Route path="/email-verifying" render={props => (<Email {...props} />)} />
                    <PrivateRoute path={'/home'} component={Home} />
                    <PrivateRoute path={'/settings'} component={Settings} />
                    <PrivateRoute path={'/invite-on-taskbar'} component={InviteOnTaskbar} />
                    {/*Routes for admin*/}
                    <PrivateRoute path="/admin" component={ isAdmin ? Admin : PageNotFound} />
                    <Route path="/terms-of-use" render={props => (<TermsOfUse {...props} />)} />
                    <Route exact path="/:nickName/:supperskillsId" render={props => (<UserProfile {...props} />)} />
                    <Route path="/:nickName" render={props => (<UserProfile {...props} />)} />
                    <Route  component={PageNotFound} />
                </Switch>
            </Router>
    );
}

export default RouterOutlet;
