import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/privateRoute";
import WalletRoute from "../../components/Walletroute";
import NewSuperSkill from "./add-superskill";
import CreateTask from "./create-task";
import Dashboard from "./dashboard";
import EditProfile from "./Edit-profile";
import EditTask from "./edit-task";
import Favourite from "./favourite";
import Leaderboard from "./leaderboard";
import Myprofile from "./my-profile";
import Mytasks from "./my-tasks";
import Wallet from "./wallet";


class RouterOutlet extends Component {
  render() {
    const { match } = this.props;
    return (
        <Switch>
          <PrivateRoute  path={`${match.url}/dashboard`} component={Dashboard} />
          
          <PrivateRoute path={`${match.url}/leaderboard`} component={Leaderboard}  />
          <PrivateRoute path={`${match.url}/profile`} component={Myprofile} />
          <PrivateRoute path={`${match.url}/wallet`} component={Wallet} />
          <PrivateRoute path={`${match.url}/my-tasks`} component={Mytasks} />  

          <WalletRoute path={`${match.url}/create-task`} component={CreateTask} />
          <PrivateRoute path={`${match.url}/edit-task`} component={EditTask} />
          <PrivateRoute path={`${match.url}/create-superskill`} component={NewSuperSkill} />
          <PrivateRoute path={`${match.url}/edit-profile`} component={EditProfile} />
          <PrivateRoute path={`${match.url}/favourite`} component={Favourite} />
          <PrivateRoute component={Dashboard} />
        </Switch>
    );
  }
}

export default RouterOutlet;
