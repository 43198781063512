import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import StyledCheckBox from '../../../components/StyledCheckBox';
import {
  getErrorString
} from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from "../../../services/api-service";

const Notification = (props) => {
  const [loading, setLoader] = useState(false);
  const [loading2, setLoader2] = useState(false);
  const [notifications, setNotifications] = useState([])


  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = async () => {
    try {
      setLoader2(true);
      const response = await GET_NOTIFICATIONS();
      setLoader2(false);
      let notification = response.data.result;
      let keys = Object.keys(notification)
      let responseArr = []
      for (let i = 0; i < keys.length; i++) {
        responseArr.push({
          label: keys[i],
          web: notification[keys[i]].web,
          email: notification[keys[i]].email
        })
      }
      setNotifications(responseArr)


    } catch (e) {
      setLoader2(true);
      console.log(e)
    }
  }

  const updateNotifications = async () => {
    try {
      setLoader(true);
      let requestBody = {}
      for (let j = 0; j < notifications.length; j++) {
        requestBody[`${notifications[j].label}`] = {}
        requestBody[`${notifications[j].label}`].web = notifications[j].web
        requestBody[`${notifications[j].label}`].email = notifications[j].email
      }
      const response = await UPDATE_NOTIFICATIONS(requestBody);
      if (response.data.status === "success") {
        setLoader(false);
        notifier.success(
          "Success",
          MESSAGE.SUCCESS.NOTIFICATIONS_UPDATED_SUCESSFULLY
        )
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  }

  const onClickCheckbox = (label, section) => {
    let notificationArr = notifications;
    notificationArr = notificationArr.filter((data, index) => {
      if (section === 'web' && data.label === label) {
        if (data.web) {
          data.web = false;
        } else {
          data.web = true;
        }
      }
      if (section === 'email' && data.label === label) {
        if (data.email) {
          data.email = false;
        } else {
          data.email = true;
        }
      }
      return data;
    })
    setNotifications(notificationArr)
  }

  return (
    <>
      <div className="setting-opt-content__heading">
        <h2>Notification Setting</h2>
      </div>
      <div className="setting-opt-content__main">
        {loading2 ? <Loader /> :
          <div className="notificationBox">
            <div className="notificationBox__item notificationBox__item--header">
              <div className="notificationBox__item__left">
                <span className="notificationBox__item__left--label">Type</span>
              </div>
              <div className="notificationBox__item__right">
                <div className="notificationBox__item__right--full">
                  <span className="notificationBox__item__right--full--label">
                    I want to receive notification on
                  </span>
                </div>
                <div className="notificationBox__item__right--half">
                  <span className="notificationBox__item__right--half--label">
                    Web
                  </span>
                </div>
                <div className="notificationBox__item__right--half">
                  <span className="notificationBox__item__right--half--label">
                    Email
                  </span>
                </div>
              </div>
            </div>

            {/**Item For Loop**/}
            {notifications && notifications.length > 0 ?
              notifications.map((data, index) => {
                return (
                  <div className="notificationBox__item">
                    <div className="notificationBox__item__left">
                      <span className="notificationBox__item__left--label">
                        {`${data.label} Related`}
                      </span>
                    </div>
                    <div className="notificationBox__item__right">
                      <div className="notificationBox__item__right--half">
                        <StyledCheckBox onClick={() => onClickCheckbox(data.label, 'web')} checked={data.web} name="notificationsOptions"/>
                        {/* <FormControlLabel
                          onClick={() => onClickCheckbox(data.label, 'web')}
                          checked={data.web}
                          control={<Checkbox name="notificationsOptions" />}
                        /> */}
                      </div>
                      <div className="notificationBox__item__right--half">
                         <StyledCheckBox onClick={() => onClickCheckbox(data.label, 'email')} checked={data.email} name="notificationsOptions"/>
                        {/* <FormControlLabel
                          onClick={() => onClickCheckbox(data.label, 'email')}
                          checked={data.email}
                          control={<Checkbox name="notificationsOptions" />}
                        /> */}
                      </div>
                    </div>
                  </div>
                )
              }) : ''}
          </div>}
      </div>

      <div className="setting-opt-content__footer">
        {/* <button className="cancel">Cancel</button> */}
        <button className="save" onClick={updateNotifications}>
          {loading ? (
            <img alt="" src={Images.ellipsis_loader} />
          ) : (
              <span>Save</span>
            )}
        </button>
      </div>
    </>
  );
};

export default Notification;
