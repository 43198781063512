import React from 'react'

function TaskerDetails(props) {
    return (
        <div>
            TaskerDetails
        </div>
    )
}

export default TaskerDetails;

