import { TextField } from '@material-ui/core';
import { CircularProgress, Link, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Images } from '../../assets/common';
import notifier from '../../components/notifier';
import { getErrorString, get } from '../../components/validator';
import { SERVER_URL } from '../../constants/api';
import { getParamsByName } from '../../lib/validators';
import { VERIFY_EMAIL, REQ_REGISTER_EMAILVERIFY } from '../../services/api-service';
import './index.scss';

function Email(props) {
  const [loading, setLoader] = useState(false);
  const [linkSend, setLinkSend] = useState(false);
  const [resendVerification, setResendVerificationLink] = useState(false);
  const [email, setEmail] = useState('');
  const [prevEmail, setPrevEmail] = useState('');
  // const [token, setToken] = useState('');

  const resendEmail = getParamsByName('resendEmail')
  const requestedEmail = getParamsByName('email')

  useEffect(() => {
    if (requestedEmail) setEmail(requestedEmail)
  }, [requestedEmail])

  useEffect(() => {
    if (resendEmail === 'true') {
      setResendVerificationLink(true);
    } else {
      var gettoken = getParamsByName('token');
      verifyEmail(gettoken)
    }

    return () => {
      // Component Will unmount will go here
    }
  }, []);

  const verifyEmail = async (t) => {
    const url = `${SERVER_URL}/auth/verifyEmail?token=${t}`;
    // const url = `http://34.121.128.66/auth/verifyEmail?token=${t}`;
    //console.log("yashurl", url)
    try {
      setLoader(true);
      const response = await VERIFY_EMAIL(url);
      if (response.data.status === 'success') {
        props.history.push('/login');
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      if (errorString === 'Session expired please login again !')
        setResendVerificationLink(true);
      else
        notifier.error('Error', errorString);
    }

  }



  const handleResendForm = async (e) => {
    e.preventDefault();
    try {
      const requestBody = {
        email: email,
      };
      const response = await REQ_REGISTER_EMAILVERIFY(requestBody);
      //console.log("cjhs", response, response.result)
      if (response.data.status === "success") {
        notifier.success("Verification mail sent sucessfully");
        setPrevEmail(email); setResendVerificationLink(false); setLinkSend(true); setEmail('')
      }
      setLoader(false);
    } catch (e) {
      const errorString = getErrorString(e);
      console.log("ERROR in handleRegister:::", errorString, e);
      notifier.error('Error', errorString);
      setLoader(false);
    }
  }


  return (
    <div className="email-page" >
      <div className="email-logo">
        <Link href="/"><img src={Images.Logo} alt={""} /></Link>
      </div>
      <div className="email-layout">
        <div className="image-container">
        </div>
        <div className="email-content-wrapper">
          <div className="email-content">
            <h2>{resendVerification ? 'Resend Email verification link.' : 'Verifying email!....'}</h2>
            {loading ?
              <div className="loading">
                <CircularProgress style={{ color: "#AAAAAA" }} />
              </div> : null}
            <div className="error-and-form">
              {linkSend ? <Alert severity="success">
                <AlertTitle>Verification mail sent successfully</AlertTitle>
                An email with a confirmation link has been sent to your email address <span style={{ color: '#4daca2' }}>{prevEmail}.</span>. Please follow the link to activate your account. If you didn't receive the email within a few minutes, please also check your Spam folder. If it's there, please click Not Spam, which will allow future messages to get through.
              </Alert> : null}
              {resendVerification ? <Alert severity="error">
                <AlertTitle>Error:</AlertTitle>
                Your Email Verification link has expired, Please resend your email verification link and activate account.
              </Alert> : null}
              {resendVerification ? <form onSubmit={handleResendForm} className="resend-email-verification-link-form">
                <div className="form_control">
                  <TextField variant="outlined" label="Enter email" fullWidth type="email" placeholder="Enter your email here." required name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                </div>
                <div className="form_control">
                  <Button variant="contained" fullWidth color="primary" type="submit">Resend Email Verification link</Button>
                </div>
              </form> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Email;
