import React, { useEffect, useState } from "react";
import "../create-task/index.scss";
import { Chip, FormControlLabel, Switch, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { GET_TASKS, REQ_SKILLS, REQ_UPDATE_TASK } from "../../../services/api-service";
import _ from "lodash";
import { Images } from "../../../assets/common";
import history from ".././../../components/history";
import notifier from "../../../components/notifier";
import { MESSAGE } from "../../../constants/messages";
import { validNumber } from "../../../lib/validators";
import { getErrorString } from "../../../components/validator";
import { Redirect, useLocation } from "react-router-dom";

const EditTask = (props) => {
  const { state } = useLocation();
  let taskId = state?.task?._id;

  const [disabled, setdisabled] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState("hourly");
  // const [hourly, setHourly] = useState(true)
  // const [fixed, setFixed] = useState(false)
  const [fixedRate, setFixedRate] = useState();
  const [fixedTaskDeadline, setFixedTaskDeadline] = useState("");
  const [applyBefore, setApplyBefore] = useState("");
  const [hourlyRate, setHourlyRate] = useState();
  const [hourlyTaskDeadline, setHourlyTaskDeadline] = useState("");
  const [hourlyTaskDuration, setHourlyTaskDuration] = useState("");
  const [checked, setChecked] = useState(false);
  const [skills, setSkills] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [bidExpiryDate, setBidExpiryDate] = useState("");
  const [loading, setLoader] = useState(false);
  const [todaysDate, setTodaysDate] = useState(null);
  const [checkSkillField, setCheckSkillField] = useState({
    error: false,
    helperText: "",
  });

  useEffect(() => {
    getTaskByTaskID();
    getTodayDate();
    getAllSkills();
  }, []);

  const getTaskByTaskID = async () => {
    const requestBody = {
      taskId: taskId,
    };
    const response = await GET_TASKS(requestBody);
    const taskData = response.data.result[0];
    setTitle(taskData.heading);
    setDescription(taskData.description);
    setSkills(taskData.skills);
    setPaymentType(taskData.paymentTerms.paymentType);

    if (taskData.paymentTerms.paymentType === "hourly") {
      setHourlyRate(taskData.paymentTerms.hourly.ratePerHour);
      setHourlyTaskDeadline(taskData.paymentTerms.hourly.hoursPerWeek);
      setHourlyTaskDuration(taskData.paymentTerms.hourly.taskDuration);
    } else {
      setFixedRate(taskData.paymentTerms.fixed.rate);
      setFixedTaskDeadline(taskData.paymentTerms.fixed.taskDeadline.split("T")[0]);
    }

    setChecked(taskData.paymentTerms.biddingEnabled);

    //ymd
    if (typeof taskData.paymentTerms.bidExpiryDate === "string") {
      taskData.paymentTerms.biddingEnabled ? setBidExpiryDate(taskData.paymentTerms.bidExpiryDate.split("T")[0]) : setApplyBefore(taskData.paymentTerms.bidExpiryDate.split("T")[0]);
    }

    //console.log(bidExpiryDate);
    //console.log(applyBefore);
    //console.log(taskData);
  };

  const getAllSkills = _.debounce(async (skill) => {
    try {
      let allSkills;
      const param = {
        searchPhrase: skill ? skill : undefined,
      };
      const response = await REQ_SKILLS(param);
      allSkills = response.data.result;
      setAllSkills(allSkills);
    } catch (e) {
      setLoader(false);
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  });

  const getTodayDate = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1; // getMonth() is zero-based
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;

    setTodaysDate(maxDate);
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    switch (id) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "paymentType":
        setPaymentType(value);
        break;
      case "hourlyRate":
        setHourlyRate(value);
        break;
      case "time":
        setHourlyTaskDeadline(value);
        break;
      case "applyBefore":
        setApplyBefore(value);
        break;
      case "hourlyTaskDuration":
        setHourlyTaskDuration(value);
        break;
      case "fixedRate":
        setFixedRate(value);
        break;
      case "fixedTaskDeadline":
        setFixedTaskDeadline(value);
        break;
      case "bidExpiryDate":
        setBidExpiryDate(value);
        break;
      case "checked":
        setChecked(checked);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const onchangeAddSkill = (event, newValue) => {
    let newArray = newValue.map((d, i) => {
      if (typeof d === "string") {
        return { name: d };
      } else {
        return d;
      }
    });
    setSkills(newArray);
  };

  const handlePaymentTypeHourly = (e) => {
    e.stopPropagation();
    setPaymentType("hourly");
    // setHourly(true)
    // setFixed(false)
  };

  const handlePaymentTypeFixed = (e) => {
    e.stopPropagation();
    setPaymentType("fixed");
    // setFixed(true)
    // setHourly(false)
  };

  const resetFields = () => {
    setTitle("");
    setDescription("");
    setPaymentType("hourly");
    setFixedRate();
    setFixedTaskDeadline("");
    setHourlyRate();
    setHourlyTaskDeadline("");
    setHourlyTaskDuration("");
    setChecked(false);
    setSkills([]);
    setAllSkills([]);
    setBidExpiryDate("");
    setApplyBefore("");
    setLoader(false);
  };

  const validateField = () => {
    let error = false;
    if (!title) {
      notifier.warning(MESSAGE.ERROR.TITLE_EMPTY);
      error = true;
    } else if (title.length <= 2) {
      notifier.warning(MESSAGE.ERROR.TITLE_LENGTH);
      error = true;
    } else if (!description) {
      notifier.warning(MESSAGE.ERROR.DESCRIPTION_EMPTY);
      error = true;
    } else if (skills.length === 0) {
      notifier.warning(MESSAGE.ERROR.SKILLS_EMPTY);
      error = true;
    } else if (paymentType === "hourly") {
      if (!hourlyRate) {
        notifier.warning(MESSAGE.ERROR.PRICE_EMPTY);
        error = true;
      } else if (!validNumber(hourlyRate)) {
        notifier.warning(MESSAGE.ERROR.PRICE_INVALID);
        error = true;
      } else if (Number(hourlyRate) <= 0) {
        notifier.warning(MESSAGE.ERROR.PRICE_MUST);
        error = true;
      } else if (!hourlyTaskDeadline) {
        notifier.warning(MESSAGE.ERROR.TIME_EMPTY);
        error = true;
      } else if (!validNumber(hourlyTaskDeadline)) {
        notifier.warning(MESSAGE.ERROR.TIME_INVALID);
        error = true;
      } else if (!hourlyTaskDuration) {
        notifier.warning(MESSAGE.ERROR.TASK_DURATION_EMPTY);
        error = true;
      }
    } else if (paymentType === "fixed") {
      if (!fixedRate) {
        notifier.warning(MESSAGE.ERROR.PRICE_EMPTY);
        error = true;
      } else if (!validNumber(fixedRate)) {
        notifier.warning(MESSAGE.ERROR.PRICE_INVALID);
        error = true;
      } else if (Number(hourlyRate) <= 0) {
        notifier.warning(MESSAGE.ERROR.PRICE_MUST);
        error = true;
      } else if (!fixedTaskDeadline) {
        notifier.warning(MESSAGE.ERROR.TASK_DEADLINE_EMPTY);
        error = true;
      }
    }
    if (checked) {
      if (!bidExpiryDate) {
        notifier.warning(MESSAGE.ERROR.EXPIRY_DATE_EMPTY);
        error = true;
      }
    } else if (!applyBefore) {
      notifier.warning(MESSAGE.ERROR.EXPIRY_DATE_EMPTY);
      error = true;
    }
    return error;
  };

  const updateTask = async () => {
    //diable update button
    setdisabled(true);
    const checkValid = validateField();
    if (checkValid) {
      return;
    }
    try {
      const bidEnd = checked ? bidExpiryDate.concat(" ").concat("00:00:00") : applyBefore.concat(" ").concat("00:00:00");
      if (paymentType === "fixed") {
        setLoader(true);
        const requestBody = {
          taskId: taskId,
          heading: title,
          description: description,
          paymentTerms: {
            paymentType: paymentType,
            hourly: {},
            fixed: {
              fixed_rate: fixedRate,
              fixed_taskDeadline: fixedTaskDeadline,
              fixed_applyBefore: bidEnd,
            },
            biddingEnabled: checked,
            bidExpiryDate: bidEnd,
          },
          skills: skills,
        };
        const response = await REQ_UPDATE_TASK(requestBody);
        if (response.data.status === "success") {
          notifier.success(MESSAGE.SUCCESS.TASK_UPDATED_SUCESSFULLY);
          resetFields();
          setdisabled(false);
          props.history.push(`/home/my-tasks`);
        }
      } else {
        setLoader(true);
        const requestBody = {
          taskId: taskId,
          heading: title,
          description: description,
          paymentTerms: {
            paymentType: paymentType,
            hourly: {
              hourly_ratePerHour: hourlyRate,
              hourly_hoursPerWeek: hourlyTaskDeadline,
              hourly_taskDuration: hourlyTaskDuration,
              hourly_applyBefore: bidEnd,
            },
            // Fixed has to be sent in this manner i.e {} if hourly is chosen.
            fixed: {},
            biddingEnabled: checked,
            // Format : // YYYY-MM-DD HH:mm:ss
            bidExpiryDate: bidEnd,
          },
          skills: skills,
        };
        const response = await REQ_UPDATE_TASK(requestBody);
        // localStorage.setItem("token", JSON.stringify(token))
        if (response.data.status === "success") {
          notifier.success(MESSAGE.SUCCESS.TASK_UPDATED_SUCESSFULLY);
          resetFields();
          setdisabled(false);
          props.history.push(`/home/my-tasks`);
        }
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const checkSkillsOnBlur = (e) => {
    if (e.target.value.trim() !== "") {
      notifier.warning("Warning", "Please Press Enter to add New subskill, Which is not in the List.", 6000);
      setCheckSkillField({
        error: true,
        helperText: "Please Press Enter to add New subskill, Which is not in the List.",
      });
    } else {
      setCheckSkillField({
        error: false,
        helperText: "",
      });
    }
  };

  //! return to home page if don't have task id
  if (!state?.task) {
    return <Redirect to={"/home/my-tasks"} />;
  }

  return (
    <div className="main-container">
      <div className="create-task-top">
        <h1>Create New Tasks</h1>
        <div className="cancel" onClick={() => history.goBack()}>
          <img src={Images.cancel} alt="" />
          {"  "}Cancel
        </div>
      </div>

      <div className="give-task-title-container">
        <div className="give_span">Give your task a title</div>
        <div className="designer-container">
          <input placeholder="Eg. Required a graphic designer for branding" className="title" name="title" value={title} onChange={handleInputChange} />
        </div>
        <br />

        <div className="list-example-container">
          <div className="see-example">See Examples:</div>
          <ul className="list-container">
            <li>Need a JAVA developer for mobile application</li>
            <li>Required a photo editor for 200 modelling photos to edit</li>
            <li>Flutter developer for android and iOS app</li>
          </ul>
        </div>
      </div>
      <div className="description-container">
        <div className="description-task">
          <span className="description_span">Provide a brief description of the task</span>
          <br />
          <textarea rows="5" className="description" name="description" value={description} onChange={handleInputChange} placeholder={"Give a detailed description about the task for maximum reach…"}></textarea>
        </div>
      </div>
      <div className="search-task-container">
        <div className="search_span">Search for task Subskills</div>
        <br />
        <img src={Images.search} className="search-icon" alt="" />
        <Autocomplete
          multiple
          id="tags-filled"
          options={AllSkills}
          value={skills}
          freeSolo
          onChange={(event, newValue) => onchangeAddSkill(event, newValue)}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <>{typeof option === "string" ? <Chip variant="outlined" label={option} {...getTagProps({ index })} /> : <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />}</>)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="What subskills and expertise are you looking for?"
              name="skills"
              error={checkSkillField.error}
              helperText={checkSkillField.helperText}
              //onChange={handleInputChange}
              onBlur={(e) => {
                checkSkillsOnBlur(e);
              }}
            />
          )}
        />

        <div className="example">For Example : UI Designer, Web developer, Accountant, etc</div>
      </div>
      <div className="payment-container">
        <div className="payment-term">Payment Terms</div>
        <div className="contract-type-div">Select the contract type</div>
        <div className="btn-container">
          <div className="hourly-change-btn">
            <div className={` ${paymentType === "hourly" ? "active-type" : "disable-type"} `} onClick={handlePaymentTypeHourly}></div>
            <div className="hourly-btn-label">Hourly</div>
          </div>
          <div className="fixed-change-btn">
            <div className={` ${paymentType === "fixed" ? "active-type" : "disable-type"} `} onClick={handlePaymentTypeFixed}></div>
            <div className="fixed-btn-label">Fixed Price</div>
          </div>
        </div>
      </div>
      {paymentType === "hourly" ? (
        <div className="hourly-container">
          <div className="brand-container">
            <div className="rate-container">Rate/ hr</div>
            <div className="input-container">
              <input type="number" placeholder={"Enter Price"} value={hourlyRate} name="hourlyRate" onChange={handleInputChange} />
              <div className="input_right">ℏ(s)</div>
            </div>
          </div>

          <div className="brand-container time">
            <span className="rate-container">Time (Hours/ Week)</span>
            <div className="input-container">
              <input type="number" placeholder={"Enter Time"} value={hourlyTaskDeadline} name="time" onChange={handleInputChange} />
              {/* <img src={Images.downArrow} alt={""} className="down" /> */}
            </div>
          </div>
          <div className="brand-container time">
            <span className="rate-container">Task Duration</span>
            <div className="input-container">
              <select name="hourlyTaskDuration" value={hourlyTaskDuration} onChange={handleInputChange}>
                <option value="">Select Duration</option>
                <option value="1">One week</option>
                <option value="2">Two weeks</option>
                <option value="3">Three weeks</option>
                <option value="4">One Month</option>
                <option value="12">Three Months</option>
                <option value="24">Six Months</option>
              </select>
              {/* <img src={Images.downArrow} alt={""} className="down" /> */}
            </div>
          </div>
        </div>
      ) : null}
      {paymentType === "fixed" ? (
        <div className="hourly-container">
          <div className="brand-container">
            <div className="rate-container">Rate/ hr</div>
            <div className="input-container">
              <input type="number" placeholder={"Enter Price"} value={fixedRate} name="fixedRate" onChange={handleInputChange} />
              <div className="input_right">ℏ(s)</div>
            </div>
          </div>

          <div className="brand-container">
            <div className="rate-container">Task Deadline</div>
            <div className="input-container">
              <input type="date" value={fixedTaskDeadline} name="fixedTaskDeadline" onChange={handleInputChange} min={todaysDate} />
              {/* <div className="input_right">hBar</div> */}
            </div>
          </div>
        </div>
      ) : null}

      <div className="bid">
        <FormControlLabel label="Apply Before" labelPlacement="start" control={<Switch checked={checked} onChange={handleInputChange} name="checked" inputProps={{ "aria-label": "secondary checkbox" }} />} />
        <span className="allow-bid">Allow for Bidding</span>
      </div>
      {checked ? (
        <div className="hourly-container bid-close">
          <div className="brand-container">
            <div className="bid-closes-container">Bid Closes by</div>
            <div className="input-container">
              <input type="date" name="bidExpiryDate" value={bidExpiryDate} onChange={handleInputChange} min={todaysDate} />
              {/* <div className="input_right">hBar</div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="hourly-container bid-close">
          <div className="brand-container">
            <div className="bid-closes-container">Select Date</div>
            <div className="input-container">
              <input type="date" name="applyBefore" value={applyBefore} onChange={handleInputChange} min={todaysDate} />
              {/* <div className="input_right">hBar</div> */}
            </div>
          </div>
        </div>
      )}
      <div className="btn">
        <button onClick={updateTask} disabled={disabled}>
          {loading ? <img alt="" src={Images.ellipsis_loader} /> : <span> Update Task </span>}
        </button>
      </div>
    </div>
  );
};

export default EditTask;
