import NotificationsIcon from "@material-ui/icons/Notifications";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
//Icons
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../components/header";
import HeaderBoard from "../home/header-board";
import "./index.scss";
import RouterOutlet from "./router-outlet";


const Settings = (props) => {
 




  return (
    <div className="setting-page">
      <Header {...props} />
      <div className="setting-page-container">
        <HeaderBoard {...props}/>
        <div className="setting-page-content">
          <div className="settings-options">
            <div className="setting-opt-menu">
              <h1>SETTINGS</h1>
              <div className="setting-opt-menu__box">
                <NavLink
                  exact
                  activeClassName="setting-opt-menu__item--active"
                  className="setting-opt-menu__item"
                  to={`/settings/change-password`}
                >
                  <span className="icon">
                    <VpnKeyIcon />
                  </span>
                  <span>Change Password</span>
                </NavLink>

                <NavLink
                  exact
                  activeClassName="setting-opt-menu__item--active"
                  className="setting-opt-menu__item"
                  to={`/settings/notification-setting`}
                >
                  <span className="icon">
                    <NotificationsIcon />
                  </span>
                  <span>Notification</span>
                </NavLink>

                <NavLink
                  exact
                  activeClassName="setting-opt-menu__item--active"
                  className="setting-opt-menu__item"
                  to={`/settings/close-account`}
                >
                  <span className="icon">
                    <PowerSettingsNewIcon />
                  </span>
                  <span>Close Account</span>
                </NavLink>
                <span className="setting-opt-menu__item--active--bg"></span>
              </div>
            </div>
          </div>
          <div className="setting-opt-content">
            <RouterOutlet {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
