import { Link as WebLink, Modal, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Images } from "../../../assets/common";
import AddFundModal from "../../../components/AddFundModal";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import Tabs from "../../../components/Tabs";
import TabPane from "../../../components/Tabs/TabPane";
import { getErrorString } from "../../../components/validator";
import { dragonURl } from "../../../constants";
import { MESSAGE, String } from "../../../constants/messages";
import { useHashConnect } from "../../../Hooks";
import { getParamsByName, validWalletID } from "../../../lib/validators";
import { GET_TASKS, LINK_WALLET_ID, REQ_CREATE_WALLET_TASK, REQ_USER_DETAILS } from "../../../services/api-service";
import "./index.scss";
import { WalletStyles } from "./styles";

const Wallet = (props) => {
  const localUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
  const [walletID, setWalletId] = useState("");
  const [renterWalletID, setRenterWalletID] = useState("");
  const [loading, setLoader] = useState(false);
  const [walletConnectLoading, setWalletConnectLoading] = useState(false);
  const [wallentLinked, setWalletLinked] = useState(false);
  const [openWalletLinkerForm, setOpenWalletLinkerForm] = useState(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [createdTask, setCreatedTask] = useState([]);
  const [appliedTask, setAppliedTask] = useState([]);
  const [taskDetails, setTaskDetails] = useState({});

  //hashconnectHooks
  const {
    connect,
    installedExtensions,
    resetAcknowledge,
    walletData: { accountIds },
  } = useHashConnect();

  useEffect(() => {
    const isWalletLinked = getParamsByName("walletLinked");
    console.log(typeof isWalletLinked, isWalletLinked);
    if (localUserDetails.hederaAccountDetails) {
      if (localUserDetails.hederaAccountDetails.id) {
        setWalletLinked(true);
        setWalletId(localUserDetails.hederaAccountDetails.id);
        getCreatedTasks();
        getAppliedTasks();
      }
    }
    if (isWalletLinked === "false") {
      setOpenWalletLinkerForm(true);
      notifier.warning("Warning", "First we need your Hedera Wallet Id.", 10000);
    }
  }, []);

  useEffect(() => {
    if (!localUserDetails?.hederaAccountDetails?.id && accountIds && !isEmpty(accountIds)) {
      setWalletId(accountIds[0]);
      submitWalletID({ walletId: accountIds[0], updatedWith: "wallet" });
    }
  }, [accountIds]);

  const classes = WalletStyles();

  const getUserDetails = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      try {
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        let userDetails = response.data.result;
        localStorage.setItem("loggedInUser", JSON.stringify(userDetails));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const linkHederaWallet = () => {
    setOpenWalletLinkerForm(true);
  };

  const handleWalletID = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "walletID":
        setWalletId(value);
        break;
      case "renterWalletID":
        setRenterWalletID(value);
        break;
      default:
        //console.log('error')
        break;
    }
  };

  const submitWalletID = async ({ walletId, updatedWith }) => {
    if (!walletId && !validWalletID(walletID)) {
      notifier.warning("Warning", MESSAGE.WARN.NOT_VALID_WALLET_ID);
    } else if (!walletId && walletID !== renterWalletID) {
      notifier.warning("Warning", MESSAGE.WARN.RE_WALLET_ID_INVALID);
    } else
      try {
        setLoader(true);
        const requestBody = {
          accountId: walletId ?? walletID,
          updatedWith: updatedWith ?? "manual",
        };
        const response = await LINK_WALLET_ID(requestBody);
        setLoader(false);
        notifier.success("Success", response.data.result);
        getUserDetails();
        setTimeout(() => {
          setWalletLinked(true);
        }, 500);
      } catch (error) {
        setLoader(false);
        notifier.error("Error", getErrorString(error));
      }
  };

  const submitWalletIDSubmit = async (event) => {
    event.preventDefault();
    await submitWalletID({});
  };

  const getCreatedTasks = async () => {
    try {
      setLoader(true);
      // let currentUser = localStorage.getItem("currentUser");
      const requestBody = {
        myTasks: {
          section: "created",
          taskStatus: "Active",
        },
      };
      const response = await GET_TASKS(requestBody);
      let task = response.data.result;
      setLoader(false);
      setCreatedTask(task);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const getAppliedTasks = async () => {
    try {
      setLoader(true);
      const requestBody = {
        myTasks: {
          section: "applied",
          taskStatus: "Active",
        },
      };
      const response = await GET_TASKS(requestBody);
      let task = response.data.result;
      setLoader(false);
      setAppliedTask(task);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const addFund = (task) => {
    setTaskDetails(task);
    setQrCodeModalOpen(true);
  };

  const qrcodeModalClose = () => {
    setQrCodeModalOpen(false);
  };

  const sendMarkOFwalletInstall = async () => {
    try {
      const requestBody = {
        isClickedOnWalletLink: true,
      };
      await LINK_WALLET_ID(requestBody);
    } catch (e) {
      console.log(e);
    }
  };

  const connectHashpack = async () => {
    setWalletConnectLoading(true);
    try {
      if (installedExtensions) {
        connect();
      } else {
        await sendMarkOFwalletInstall();
        // Taskbar Alert - Hashpack browser extension not installed, please click on <Go> to visit HashPack website and install their wallet on your browser
        alert("Taskbar Alert - Hashpack browser extension not installed, please click on <<Ok>> to visit HashPack website and install their wallet on your browser");
        window.open("https://www.hashpack.app");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setWalletConnectLoading(false);
    }
  };

  return (
    <div className={`${classes.walletPage} wallet-page`}>
      {wallentLinked ? (
        <div className="wallet">
          <div className="wallet__heading">
            <div className="help_required">
              <Typography component={"p"} variant="body2">
                <img src={Images.informationIcon} alt="Help" />
                {"  "}
                <WebLink href="https://hedera.com/account-creation" target={"_blank"} title="If you want to know about, how to create a hedera wallet id?. Then check out this link.">
                  how to create hedera id?
                </WebLink>
              </Typography>
            </div>
            <div className="wallet__heading--right" style={{ marginLeft: "auto" }}>
              <span>Hedera Wallet Id Link</span>
              <span>
                <WebLink href={`${dragonURl}/hedera/accounts/${walletID}`} target="_blank" style={{ color: "#6dc7be" }}>
                  {walletID}
                </WebLink>
              </span>
            </div>
          </div>
          <div className="wallet__body">
            <Tabs>
              <TabPane name="Created" key="1">
                <div className="createdFunds">
                  {loading ? (
                    <Loader />
                  ) : createdTask && createdTask.length > 0 ? (
                    createdTask.map((task, index) => {
                      return (
                        <div className="createdFunds__item" key={index}>
                          <div className="createdFunds__item__inner">
                            <p className="createdFunds__item__task">{task.heading}</p>
                            <p className="createdFunds__item__task--status">
                              {`(${task.paymentTerms.paymentType})`} {`(${task.currentStatus})`}
                            </p>
                            <p className="createdFunds__item__label">ℏ(s) in Escrow Account</p>
                            <p className="createdFunds__item__amount">
                              <span>{task.taskEscrowAmount.toFixed(4)}</span>
                              <span>ℏ(s)</span>
                            </p>
                            <p className="createdFunds__item__memocode" style={{ marginBottom: 10 }}>
                              <span>Memo Code:</span>
                              <span>{`${String.MEMO_CODE_PREFIX}-${task.numericId}`}</span>
                            </p>
                            {task.currentStatus === "Waiting For Payment" && (
                              <Alert severity="info" style={{ marginBottom: 10 }}>
                                <b>{task.amountFixed.taskPrice - task.taskEscrowAmount.toFixed(4) + task.amountFixed.taskbarFees} ℏ(s) </b> Required{" "}
                              </Alert>
                            )}
                            <button onClick={() => addFund(task)} disabled={false} className="createdFunds__item__button">
                              Add Fund
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data">No data to show</div>
                  )}
                </div>
              </TabPane>
              <TabPane name="Accepted" key="2">
                <div className="createdFunds">
                  {appliedTask && appliedTask.length > 0 ? (
                    appliedTask.map((task, index) => {
                      return (
                        <div className="createdFunds__item" key={index}>
                          <div className="createdFunds__item__inner">
                            <p className="createdFunds__item__task">{task.heading}</p>
                            <p className="createdFunds__item__task--status">
                              {`(${task.paymentTerms.paymentType})`} {`(${task.currentStatus})`}
                            </p>
                            <p className="createdFunds__item__label">ℏ(s) in Escrow Account</p>
                            <p className="createdFunds__item__amount">
                              <span>{task.taskEscrowAmount.toFixed(4)}</span>
                              <span>ℏ(s)</span>
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data">No data to show</div>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
          <AddFundModal taskDetails={taskDetails} open={qrCodeModalOpen} onClose={qrcodeModalClose} />
        </div>
      ) : (
        <React.Fragment>
          <div className="walletNotLinked">
            <div className="walletPage__content">
              <div className="walletPage__content__icon">
                <img src={Images.headersWalletIcon} alt="" />
              </div>
              <p>No Hedera Wallet ID linked</p>
              <div className="walletPage__content__button">
                <button onClick={linkHederaWallet}>Add Hedera Account</button>
                <button onClick={connectHashpack}>
                  <img src={Images.hashPackIcon} alt="Hashpck" /> {walletConnectLoading ? <img src={Images.ellipsis_loader} /> : " Connect with HashPack"}
                </button>
              </div>
            </div>
          </div>
          {openWalletLinkerForm ? (
            <div className="fullscreenForm">
              <div className="fullscreenForm__inner">
                <div className="fullscreenForm__logo">
                  <Link to={`/`} className="login-btn">
                    <img src={Images.Logo} alt="" />
                  </Link>
                </div>
                <form onSubmit={submitWalletIDSubmit} className="fullscreenForm__form">
                  <h2 className="fullscreenForm__form--heading">Require Hedera Wallet Id</h2>
                  <p className="fullscreenForm__form--slogan">It’s easy and secure.</p>
                  <div className="fullscreenForm__form--logo">
                    <img src={Images.headersWalletIcon} alt="" />
                  </div>
                  <div className="form__control">
                    <TextField variant="outlined" fullWidth label="Enter Hedera Wallet Id" placeholder="0.0.8870" name="walletID" value={walletID} size="small" onChange={handleWalletID} />
                  </div>
                  <div className="form__control">
                    <TextField variant="outlined" fullWidth label="Re Enter Hedera Wallet Id" placeholder="0.0.8870" name="renterWalletID" size="small" value={renterWalletID} onChange={handleWalletID} />
                  </div>
                  <p className="inst">You are responsible for ensuring that that the account details are correct. Please contact our support team if the account details need amending.</p>
                  <div className="fullscreenForm__form--btn">
                    <button type={"submit"}>{loading ? <img alt="" src={Images.ellipsis_loader} /> : "Submit"}</button>
                  </div>
                  <Typography component={"p"} variant="body2" style={{ textAlign: "center" }}>
                    <img src={Images.informationIcon} alt="Help" />
                    {"  "}
                    <WebLink href="https://hedera.com/account-creation" target={"_blank"} title="If you want to know about, how to create a hedera wallet id?. Then check out this link.">
                      how to create hedera id?
                    </WebLink>
                  </Typography>
                </form>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default Wallet;
