import { Checkbox, FormControlLabel, Link, TextField } from "@material-ui/core";
import { Done as DoneIcon, Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import notifier from "../../components/notifier";
import { getErrorString, validateRegisterData } from "../../components/validator";
import { MESSAGE } from "../../constants/messages";
import useDataLayer from "../../Hooks/useDataLayer";
import { getParamsByName, validEmail } from "../../lib/validators";
import { REQ_REGISTER, REQ_REGISTER_EMAILVERIFY } from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import "./index.scss";
import PasswordStrengthMeter from "./pass-strength";

function Register(props) {
  const [errors, updateErrors] = useState({});
  const { addEventToDataLayer } = useDataLayer();
  const [register, setRegister] = useState(true);
  const [emailPage, setemailPage] = useState(false);
  const [step, setStep] = useState("1");
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [cpassword, updateConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [CpasswordShown, setCPasswordShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoader] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordValidit, setPasswordValidit] = useState({
    minChar: null,
    number: null,
    lower: null,
    upper: null,
    specialChar: null,
  });
  const [referralId, setReferralId] = useState("");
  const [uwi, setUwi] = useState("");
  const [urlReferrals, setUrlReferrals] = useState(false);
  const isNumberRegx = /\d/;
  const LowerRegx = /(?=.*?[a-z])/;
  const UpperRegx = /(?=.*?[A-Z])/;
  const specialCharacterRegx = /(?=.*[!@#$%^&*])/;

  useEffect(() => {
    cookieStorage.deleteCookie();
    getUrlParams();
  }, []);

  const getUrlParams = () => {
    let referralId = getParamsByName("refId");
    let uwi = getParamsByName("uwi");
    setReferralId(referralId);
    setUwi(uwi);
    setUrlReferrals(referralId ? true : false);
  };

  const LoginRedirect = () => {
    props.history.push("/login");
  };

  const setRegisterFlow = () => {
    const id = step;
    switch (id) {
      case "1":
        setRegister(false);
        setemailPage(true);
        setStep("2");
        break;
      case "2":
        setemailPage(false);
        setRegister(false);
        props.history.push("/basic-info");
        setStep("3");
        break;

      default:
        console.warn("Something is not right !");
    }
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    switch (id) {
      case "email":
        updateEmail(value);
        break;
      case "password":
        updatePassword(value);
        setPasswordValidit({
          minChar: password.length >= 8 ? true : false,
          number: isNumberRegx.test(value) ? true : false,
          lower: LowerRegx.test(value) ? true : false,
          upper: UpperRegx.test(value) ? true : false,
          specialChar: specialCharacterRegx.test(value) ? true : false,
        });
        break;
      case "cpassword":
        updateConfirmPassword(value);
        break;
      case "checkedB":
        setChecked(checked);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleCPasswordVisiblity = () => {
    setCPasswordShown(CpasswordShown ? false : true);
  };

  const Resend = async () => {
    try {
      const requestBody = {
        email: email,
      };
      const response = await REQ_REGISTER_EMAILVERIFY(requestBody);
      //console.log("cjhs", response, response.result)
      if (response.data.status === "success") {
        notifier.success("Verification mail sent successfully");
      }
      setLoader(false);
    } catch (e) {
      const errorString = getErrorString(e);
      console.log("ERROR in handleRegister:::", errorString, e);
      setLoader(false);
    }
  };

  const onEnterPress = (event) => {
    if (event.key === "Enter") {
      handleRegister();
    }
  };

  const handleRegister = async () => {
    let data = { email, password, cpassword };
    if (!email) {
      notifier.warning(MESSAGE.ERROR.EMAIL_EMPTY);
    } else if (!validEmail(email)) {
      notifier.warning(MESSAGE.ERROR.EMAIL_INVALID);
    } else if (validateRegisterData(data).password) {
      notifier.warning("Password", validateRegisterData(data).password);
    } else if (validateRegisterData(data).cpassword) {
      notifier.warning("Password", validateRegisterData(data).cpassword);
    } else if (!checked) {
      notifier.warning(MESSAGE.ERROR.ACCET_TERMS_CONDITIONS);
    } else
      try {
        setLoader(true);
        const requestBody = {
          email: email,
          password: password,
          referralId: referralId,
        };
        const response = await REQ_REGISTER(requestBody);
        if (response.data.status === "success") {
          setRegisterFlow();
          notifier.success("You are Registered!");
          addEventToDataLayer("completed_signup");
          setLoader(false);
        } else {
          updateErrors(errors);
          notifier.warning("Warning", "Something is not right");
          setLoader(false);
        }
      } catch (e) {
        const errorString = getErrorString(e);
        notifier.error("Error", errorString);
        setLoader(false);
      }
  };

  return (
    <div className="login-page">
      <div className="login-logo">
        <Link href="/">
          <img src={Images.Logo} alt={""} />
        </Link>
      </div>
      <div className="login-layout">
        {register ? (
          <div className="login-content-wrapper">
            <div className="login-content">
              <h1>Register</h1>
              <h5>&amp; Join the Taskbar Community</h5>
              <p>Enter your details to create a new account.</p>
              <div className="login-form">
                <div className="login-form__control">
                  <div className={"field-container"}>
                    <TextField
                      id="standard-basic"
                      label="Enter Email"
                      variant="outlined"
                      name="email"
                      required
                      error={errors.email}
                      helperText={errors.email}
                      onChange={handleInputChange}
                      onKeyPress={onEnterPress}
                      type={"email"}
                      value={email}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="login-form__control">
                  <div className={"field-container-pass"}>
                    <TextField
                      label="Password"
                      variant="outlined"
                      name="password"
                      onBlur={() => setPasswordFocused(false)}
                      onFocus={() => setPasswordFocused(true)}
                      error={errors.password}
                      helperText={errors.password}
                      onChange={handleInputChange}
                      onKeyPress={onEnterPress}
                      type={passwordShown ? "text" : "password"}
                      value={password}
                      autoComplete="off"
                      required
                    />
                    <div className="eye">{passwordShown ? <Visibility onClick={togglePasswordVisiblity} /> : <VisibilityOff onClick={togglePasswordVisiblity} />}</div>
                    {passwordFocused && (
                      <div className="password-meter">
                        <div className="arrow-right"></div>
                        <p className="text-dark">Password must contain:</p>
                        <div className="text-muted">
                          <div className="pass-valid">
                            {password.length >= 8 && <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} />}
                            Have at least 8 characters
                          </div>
                          <div className="pass-valid">
                            {passwordValidit.number && <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} />}
                            Have at least 1 number
                          </div>
                          <div className="pass-valid">
                            {passwordValidit.upper && <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} />}
                            One upper case
                          </div>
                          <div className="pass-valid">
                            {passwordValidit.lower && <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} />}
                            One lower case
                          </div>
                          <div className="pass-valid">
                            {passwordValidit.specialChar && <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} />}
                            Have at least 1 special character from <strong>!@#$%^&*</strong>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <PasswordStrengthMeter password={password} />
                <div className="login-form__control">
                  <div className={"field-container"} style={{ marginBottom: 0 }}>
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      name="cpassword"
                      error={errors.cpassword}
                      helperText={errors.cpassword}
                      onChange={handleInputChange}
                      onKeyPress={onEnterPress}
                      type={CpasswordShown ? "text" : "password"}
                      value={cpassword}
                      autoComplete="off"
                      required
                    />
                    <div className="eye">{CpasswordShown ? <Visibility onClick={toggleCPasswordVisiblity} /> : <VisibilityOff onClick={toggleCPasswordVisiblity} />}</div>
                  </div>
                </div>
                {urlReferrals && (
                  <div className="referralsCode">
                    <p>Referrals Code:</p>
                    <p className="code">{referralId}</p>
                  </div>
                )}
                {!urlReferrals && (
                  <div style={{ marginTop: 15 }}>
                    {" "}
                    <TextField label="Referral Code(Optional)" variant="outlined" value={referralId} onChange={(e) => setReferralId(e.target.value)} fullWidth />
                  </div>
                )}
                <div className="forgot-pass-block" style={{ justifyContent: "flex-start" }}>
                  <FormControlLabel control={<Checkbox onChange={handleInputChange} name="checkedB" id="termsAndC" />} />
                  <label htmlFor="termsAndC">
                    I agree to the <Link href={"/terms-of-use"}>terms of use and services.</Link>
                  </label>
                </div>
                <div className="login-form-btn">
                  <button onClick={handleRegister}>{loading ? <img alt="" src={Images.ellipsis_loader} /> : <span> Register </span>}</button>
                </div>
                <div className="register-link">
                  <div className="text-dark">Already have an account?</div>
                  <p onClick={LoginRedirect}>Login</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {emailPage ? (
          <div className="login-content-wrapper">
            <div className="login-content text-center">
              <h1>Welcome to the Family!</h1>
              <p>
                An email with a confirmation link has been sent to your email address <span style={{ color: "#4daca2" }}>{email}.</span>. Please follow the link to activate your account. If you didn't receive the email within a few minutes, please
                also check your Spam folder. If it's there, please click not spam, which will allow future messages to get through.
              </p>
              <span></span>
              <div className="email-image my-1">
                <img src={Images.emailVerify} alt={""} onClick={setRegisterFlow} />
              </div>
              <div className="resend-link">
                <p className="my-1">Didn’t Receive the link?</p>
                <p className="resend" onClick={Resend}>
                  Re-send
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Register;
