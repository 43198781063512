import { makeStyles } from '@material-ui/core';
import React from 'react';


const StyledRadioButton = (props) => {
    const classes = Styles();

    const handleOnchange = (e) => {
        props.onChange(e);
    }

    ////console.log(props.label)

    return (
        <div className={`${classes.styledRadioButton} ${props.className ? props.className : ''}`}
            style={props.label ? null : ({ display: 'inline-block', width: '16px', height: '16px' })}>
            <input
                type="radio"
                checked={props.checked ? props.checked : false}
                name={props.name}
                id={props.id} value={props.value}
                onChange={props.onChange ? handleOnchange : null}
            />
            <span className="radio"></span>
            {props.label ?
                <label htmlFor={`#${props.id}`}>{props.label} </label>
                : ''}
        </div>
    )
}



const Styles = makeStyles({
    styledRadioButton: {
        alignItems: 'center',
        display: 'flex',
        padding: '6px 0',
        position: 'relative',
        '& input[type="radio"]': {
            position: 'absolute',
            opacity: 0,
            width: 16,
            height: 16,
            cursor: 'pointer',
            '&:checked ~ .radio': {
                borderColor: '#6DC7BE',
                position: 'relative',
                '&::after': {
                    content: '""',
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#6DC7BE',
                    position: 'absolute',
                    borderRadius: '50%',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50% , -50%)'
                }
            }
        },
        '& .radio': {
            display: 'inline-block',
            width: 16,
            height: 16,
            border: '2px solid #AAAAAA',
            borderRadius: '50%',
            marginRight: 12,
            curser: 'pointer',
        },
        '& label': {
            marginBottom: 0,
            display: 'block',
            fontSize: 14
        }
    }
})

export default StyledRadioButton;

