import {
  Button, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, TextField
} from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../assets/common";
import { NATIONALITY_LIST } from "../../../assets/common/natioanality";
import FileUploadBlob from "../../../components/FileUploadBlob";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { isStringAlphaNumericOnly, useDebounce } from "../../../lib/validators";
import {
  CHECK_USER_NAME_AVAIL,
  CREATE_NEW_USER_FROM_ADMIN
} from "../../../services/api-service";

const AddNewUser = () => {
  const [loading, setLoading] = useState(false);
  const [userName, setUsername] = useState("");
  const [profileImg, setProfileImg] = useState(null);
  const [availability, setAvailability] = useState({
    status: true,
    helperText: "",
  });
  const [walletStatus, setWalletStatus] = useState({
    status: true,
    helperText: "",
  });

  const addUserForm = useRef(null);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    emailMessage: "",
    walletId: "",
    country: "",
    city: "",
    nationality: "",
    isBrand: "no",
  });

  const nowCheckingUserName = useDebounce(userName, 300);

  useEffect(
    () => {
      if (nowCheckingUserName) {
        doCheckAvailability(nowCheckingUserName).then((results) => {
          setAvailability({ ...availability, ...results });
        });
      } else {
        setAvailability({
          status: true,
          helperText: "",
        });
      }
    },
    [nowCheckingUserName] // Only call effect if debounced search term changes
  );

  //Hit api for checking.
  const doCheckAvailability = async (userName) => {
    try {
      if (userName.length <= 2)
        return {
          status: false,
          helperText: "Username length must be greater that two characters.",
        };

      const requestBody = { nickName: userName };
      const response = await CHECK_USER_NAME_AVAIL(requestBody);
      if (response.data.result)
        return {
          status: true,
          helperText: "Username is available for use.",
        };

      return {
        status: false,
        helperText: "username is already taken.",
      };
    } catch (e) {
      notifier.error("Error", getErrorString(e));
      return {
        status: true,
        helperText: "",
      };
    }
  };

  //Strict AlphanUmeric userName validation.
  const handleUserNameChange = (e) => {
    const { value } = e.target;
    let isMatched = isStringAlphaNumericOnly(value);

    if (!isMatched) {
      setUsername(value);
      setAvailability({
        ...availability,
        status: false,
        helperText: "Try Alphanumeric username without spaces.",
      });
    } else {
      setUsername(value);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        setUserData({ ...userData, firstName: value });
        break;

      case "lastName":
        setUserData({ ...userData, lastName: value });
        break;
      case "email":
        setUserData({ ...userData, email: value });
        break;

      case "walletId":
        setUserData({ ...userData, walletId: value });
        break;
      case "country":
        setUserData({ ...userData, country: value });
        break;
      case "city":
        setUserData({ ...userData, city: value });
        break;
      case "nationality":
        setUserData({ ...userData, nationality: value });
        break;

      case "emailMessage":
        setUserData({ ...userData, emailMessage: value });
        break;

      default:
        break;
    }
  };

  const checkWalletFormat = (value) => {
    return /[0]\.[0]\.([0-9]{1,10}[^0-9]?)+$/i.test(value);
  };

  const checkWalletPattern = (e) => {
    const { value } = e.target;
    const match = checkWalletFormat(value);

    if (match)
      setWalletStatus({
        ...walletStatus,
        status: true,
        helperText: "Format is validated Successfully",
      });
    else
      setWalletStatus({
        ...walletStatus,
        status: false,
        helperText: "Walled Id should have a format similar to 0.0.xxxxxxx",
      });
  };

  //Handle Form submit
  const handleFormSubmit = async (event, formRef) => {
    event.preventDefault();
    setLoading(true);

    try {
      const fromData = new FormData(formRef.current);
      const response = await CREATE_NEW_USER_FROM_ADMIN(fromData);
      if (response.data.status === "success") {
        setUserData({
          ...userData,
          firstName: "",
          lastName: "",
          email: "",
          emailMessage: "",
          walletId: "",
          country: "",
          city: "",
          nationality: "",
          isBrand: "no",
        });
        setUsername("");
        setLoading(false);
        notifier.success("Create New User", "User Created Successfully.");
      }
    } catch (e) {
      setUsername("");
      setLoading(false);
      notifier.error("Error While Creating New user::", getErrorString(e));
    }
  };

  const handleBrandChange = (event) => {
    const { value } = event.target;

    setUserData({ ...userData, isBrand: value });
  };

  return (
    <div className="addNewUser">
      <Grid component="form" container spacing={2} onSubmit={(e) => handleFormSubmit(e, addUserForm)} ref={addUserForm}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Enter first name"
                id="firstName"
                name="firstName"
                value={userData.firstName}
                onChange={onInputChange}
                size="small"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Enter last name"
                id="lastName"
                name="lastName"
                value={userData.lastName}
                onChange={onInputChange}
                variant="outlined"
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Enter email"
                id="email"
                type="email"
                name="email"
                value={userData.email}
                onChange={onInputChange}
                variant="outlined"
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Enter username"
                id="userName"
                name="userName"
                value={userName}
                onChange={handleUserNameChange}
                variant="outlined"
                size="small"
                fullWidth
                required
                error={!availability.status}
                helperText={availability.helperText}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <FormLabel>Profile Photo(Optional)</FormLabel>
          <div className="profilePicUpload">
            <span
              onClick={() =>
                document.querySelector(`#newUserProfileImg${1}`).click()
              }
              title="Upload Profile photo"
              className="photo-picker">
              <PhotoCameraIcon />
            </span>
            <FileUploadBlob
              id={`newUserProfileImg${1}`}
              getFile={(file) => setProfileImg(file)}
              name={'profileImg'}
              acceptType={`image/*`}
            />
            <img src={""} className={profileImg ? 'previewImg' : ''} id="preview" alt="Upload Profile Image" />
          </div>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Wallet id (Optional)"
            id="walletId"
            name="walletId"
            value={userData.walletId}
            onChange={onInputChange}
            placeholder={"0.0.123456"}
            onBlur={checkWalletPattern}
            variant="outlined"
            size="small"
            error={!walletStatus.status}
            helperText={walletStatus.helperText}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend">Brand Status</FormLabel>
          <RadioGroup
            row
            aria-label="brand"
            name="isBrand"
            value={userData.isBrand}
            onChange={handleBrandChange}>
            <FormControlLabel
              value={"no"}
              control={<Radio color="primary" />}
              label="No"
            />
            <FormControlLabel
              value={"yes"}
              control={<Radio color="primary" />}
              label="Yes"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-select-country"
            select
            label="Select Country"
            name="country"
            fullWidth
            size="small"
            required
            variant="outlined"
            value={userData.country}
            onChange={onInputChange}>
            {NATIONALITY_LIST.map((option) => (
              <MenuItem key={option.Country} value={option.Country}>
                {option.Country}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Enter city"
            id="city"
            name="city"
            value={userData.city}
            onChange={onInputChange}
            variant="outlined"
            required
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-select-nationality"
            select
            label="Select Nationality"
            name="nationality"
            fullWidth
            value={userData.nationality}
            size="small"
            variant="outlined"
            onChange={onInputChange}>
            {NATIONALITY_LIST.map((option) => (
              <MenuItem key={option.Country} value={option.Nationality}>
                {option.Nationality}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 16 }}>
            Any message along with user's basic details(
            <small>
              this will be send as email along with created username and a temporary password
            </small>
            ).
          </InputLabel>
          <TextField
            id="outlined-Email-message"
            multiline
            label="Message"
            name="emailMessage"
            fullWidth
            rows={5}
            value={userData.emailMessage}
            size="small"
            variant="outlined"
            onChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary">
            {loading ? (
              <img src={Images.ellipsis_loader} alt="Loading..." />
            ) : (
              "Submit"
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewUser;
