import { Checkbox, FormControlLabel, MenuItem, Select, TextField, withStyles } from "@material-ui/core";
import React, { Component, useState } from "react";
import { Images } from "../../../assets/common/index";
import { NATIONALITY_LIST } from "../../../assets/common/natioanality";
import FileUploadBlob from "../../../components/FileUploadBlob/index.js";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { API } from "../../../constants/api";
import axios from "axios";
import { isStringAlphaNumericOnly, useDebounce } from "../../../lib/validators";
import { CHECK_USER_NAME_AVAIL, REQ_USER_DETAILS, UPDATE_BASIC_INFO, UPDATE_PROFILE_IMAGE } from "../../../services/api-service";
import history from ".././../../components/history";
import "./index.scss";

class About extends Component {
  constructor() {
    super();
    this.state = {
      allCountries: [],
      userDetails: {},
      fName: "",
      lName: "",
      email: "",
      fnameVisibilty: false,
      nickName: "",
      country: "",
      profileImage: {},
      imageUploading: false,
      City: "",
      Nationality: "",
      EditProfile: false,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getUserDetails();
    this.getCountriesList();
  };

  getCountriesList = async () => {
    try {
      const response = await axios.get(API.REST_COUNTRIES_API);
      const countries = response.data.map((d) => d.name.common).sort();
      this.setState({ allCountries: countries });
    } catch (error) {
      console.log(error);
    }
  };

  getUserDetails = async (id) => {
    // let { fName, lName, fnameVisibilty, email, nickName, country, City, Nationality } = this.state;
    let currentUser = localStorage.getItem("currentUser");
    try {
      const requestBody = {
        userId: currentUser,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      const details = response.data.result;
      //console.log("checked", details.fnameVisibilty)
      this.setState({
        fName: details.firstName,
        lName: details.lastName,
        fnameVisibilty: details.fnameVisibilty,
        email: details.email,
        nickName: details.nickName,
        country: details.address.country,
        City: details.address.city,
        Nationality: details.address.nationality,
      });
      // const skills = response.data.result.profileStats.superskills.skills
      // setSuperskills(skills)
      this.setState({ userDetails: details });
    } catch (e) {
      console.log(e);
    }
  };

  updateProfileImage = async (file) => {
    try {
      this.setState({ imageUploading: true });
      const formData = new FormData();
      formData.append("profileImg", file);
      const response = await UPDATE_PROFILE_IMAGE(formData);
      const profile = response.data.result;
      localStorage.setItem("profileImagePath", profile);
      if (response.data.status === "success") {
        notifier.success(MESSAGE.SUCCESS.PROFILE_PIC_UPDATED);
        this.setState({ imageUploading: false });
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  validateField = () => {
    let error = false;
    if (!this.state.fName) {
      notifier.warning(MESSAGE.ERROR.FIRST_NAME_EMPTY);
      error = true;
    } else if (!this.state.lName) {
      notifier.warning(MESSAGE.ERROR.LAST_NAME_EMPTY);
      error = true;
    } else if (!this.state.City) {
      notifier.warning(MESSAGE.ERROR.CITY_EMPTY);
      error = true;
    } else if (this.state.City.length > 50) {
      notifier.warning(MESSAGE.ERROR.CITY_INVALID);
      error = true;
    } else if (!this.state.country) {
      notifier.warning(MESSAGE.ERROR.COUNTRY_EMPTY);
      error = true;
    }

    return error;
  };

  updateUserDetails = async () => {
    const checkValid = this.validateField();
    if (checkValid) {
      return;
    }
    let { fName, lName, fnameVisibilty, nickName, country, City, Nationality } = this.state;
    try {
      this.setState({ loading: true });
      let requestBody = {};
      requestBody = {
        firstName: fName,
        lastName: lName,
        fnameVisibilty: fnameVisibilty,
        address: {
          country: country,
          city: City,
          nationality: Nationality,
        },
      };
      if (this.state.userDetails.nickName !== nickName) {
        requestBody["nickName"] = nickName;
      }

      const response = await UPDATE_BASIC_INFO(requestBody);
      const details = response.data.result;
      // setSuperskills(skills)
      localStorage.setItem("loggedInUser", JSON.stringify(details));
      this.setState({ userDetails: details });
      if (response.data.status === "success") {
        this.setState({ loading: false });
        notifier.success(MESSAGE.SUCCESS.UPDATED_SUCESSFULLY);
        this.getUserDetails();
      }
    } catch (e) {
      this.setState({ loading: false });
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
      console.log(e);
    }
  };

  handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    const checkedVal = event.target.checked;

    switch (id) {
      case "first_Name":
        this.setState({ fName: value });
        break;
      case "last_Name":
        this.setState({ lName: value });
        break;
      case "nickname":
        this.setState({ nickName: value });
        break;
      case "country":
        this.setState({ country: value });
        break;
      case "city":
        this.setState({ City: value });
        break;
      case "nationality":
        this.setState({ Nationality: value });
        break;
      case "checkedA":
        this.setState({ fnameVisibilty: checkedVal });
        break;
      default:
        console.warn("Something is not right !");
        break;
    }
  };

  render() {
    let { userDetails, fName, lName, fnameVisibilty, email, nickName, country, City, Nationality, imageUploading, loading } = this.state;
    return (
      <div className="Profile-Description-page">
        <div className="main-edit-container">
          <div className="about-information-container">
            <h1>Edit Personal information</h1>
            <div className="profile-image">
              {imageUploading ? <img className="loader-img" src={Images.circular_loader} alt="" /> : ""}
              <img id="preview" src={userDetails.profileImgUrl} alt={""} />
              {/* <span onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="photo-picker">
                Change Photo
              </span> */}
              <div className="change-profile">
                <FileUploadBlob
                  id={`chooseFile${1}`}
                  getFile={(file) => {
                    this.setState({ profileImage: file }, () => this.updateProfileImage(this.state.profileImage));
                  }}
                  acceptType={`image/*`}
                />
              </div>
            </div>
            <div className="about-container">
              <div className="about-details-container">
                <div className={"field-container"}>
                  <span className="fieldName">Username</span>
                  <UsernameInput value={nickName} onChange={(nickName) => this.setState({ nickName })}>
                    <TextField variant="outlined" placeholder="Rob Designer" className="rob-designer-input" name="nickname" />
                  </UsernameInput>
                </div>
                <div className="name-container">
                  <div className={"field-container"}>
                    <span className="fieldName">First name</span>
                    <TextField variant="outlined" placeholder="Robert" value={fName} className="Robert-input" name="first_Name" onChange={(e) => this.handleInputChange(e)} />
                  </div>
                  <div className={"field-container"}>
                    <span className="fieldName">Last name</span>
                    <TextField variant="outlined" placeholder="Downey" value={lName} className="Downey-input" name="last_Name" onChange={(e) => this.handleInputChange(e)} />
                  </div>
                </div>

                <div className={"field-container-check"}>
                  <span className="fieldName"></span>
                  <FormControlLabel control={<Checkbox name="checkedA" checked={fnameVisibilty} onChange={(e) => this.handleInputChange(e)} />} label="Keep my full name publically visible" />
                </div>
                <div className={"field-container"} style={{ marginTop: 0 }}>
                  <span className="fieldName">Email</span>
                  <TextField
                    disabled
                    aria-readonly
                    type="email"
                    variant="outlined"
                    placeholder="mytaskbar@mail.com"
                    value={email}
                    className="email-input"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className={"field-container"}>
                  <span className="fieldName">Country</span>
                  <StyledSelect
                    id="outlined-select-Country"
                    label="Select"
                    name="country"
                    placeholder="Australia"
                    value={country}
                    variant="outlined"
                    size="small"
                    fullWidth
                    IconComponent={() => (
                      <span className="selectIcon">
                        <img src={Images.selectDropDownIcon} alt="" />
                      </span>
                    )}
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    {this.state.allCountries.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
                <div className={"field-container"}>
                  <span className="fieldName">City</span>
                  <TextField variant="outlined" placeholder="California" value={City} className="city-input" name="city" onChange={(e) => this.handleInputChange(e)} />
                </div>
                <div className={"field-container"}>
                  <span className="fieldName">Nationality</span>
                  <StyledSelect
                    id="outlined-select-Nationality"
                    label="Select"
                    name="nationality"
                    placeholder="Australia"
                    value={Nationality}
                    variant="outlined"
                    size="small"
                    fullWidth
                    IconComponent={() => (
                      <span className="selectIcon">
                        <img src={Images.selectDropDownIcon} alt="" />
                      </span>
                    )}
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    {NATIONALITY_LIST.map((option) => (
                      <MenuItem key={option.Country} value={option.Nationality}>
                        {option.Nationality}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
              </div>
              <span className="button-container">
                <button className="cancel-btn" onClick={() => history.goBack()}>
                  Cancel
                </button>
                <button className="save-btn" onClick={() => this.updateUserDetails()}>
                  {loading ? <img alt="" src={Images.ellipsis_loader} /> : "Update"}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const UsernameInput = ({ onChange, value, children }) => {
  const [nickNameText, setNickNameText] = React.useState("");

  const defaultHelperText = { error: false, success: false, text: "Username must be greater than 2 character. Only alphanumeric combination is allowed. For example username123 etc.." };
  const [helperText, setHelperText] = React.useState({ ...defaultHelperText });

  const debouncedNickName = useDebounce(nickNameText, 800);

  React.useEffect(() => {
    setNickNameText(value);
  }, [value]);

  React.useEffect(() => {
    if (debouncedNickName) {
      getUserNameAvailability(nickNameText);
    }
  }, [debouncedNickName]);

  const showError = async ({ error, text }) => {
    if (error && text) {
      setHelperText({ error, text });
      setTimeout(() => setHelperText({ ...defaultHelperText }), 10000);
    } else {
      setHelperText({ ...defaultHelperText });
    }
  };

  const getUserNameAvailability = async (userName) => {
    onChange(userName);

    if (userName == value) {
      showError({});
    } else if (userName.length < 3) {
      await showError({
        error: true,
        text: "Username must be greater than 2 character.",
      });
    } else if (!isStringAlphaNumericOnly(userName)) {
      showError({
        error: true,
        text: "Only alphanumeric combination is allowed.",
      });
    } else {
      try {
        const requestBody = {
          nickName: userName,
        };
        const response = await CHECK_USER_NAME_AVAIL(requestBody);
        if (!response.data.result && userName !== value) {
          showError({
            error: true,
            text: "Username is not available",
          });
        } else {
          setHelperText({ ...helperText, success: true, error: false, text: "Username is available to use." });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const element = React.cloneElement(children, { value: nickNameText, onChange: (e) => setNickNameText(e.target.value), error: helperText.error, helperText: helperText.text, success: helperText.success });
  return element;
};

const StyledSelect = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
  },
}))(Select);

export default About;
