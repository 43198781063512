import FavoriteIcon from "@material-ui/icons/Favorite";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Images } from "../../../../assets/common";
import InviteTaskerModal from "../../../../components/InviteTaskerModal";
import Loader from "../../../../components/Loader";
import { getFavriteTaskers, rateConversionUSDTasker } from "../../../../components/validator";
import { getParamsByName } from "../../../../lib/validators";
import { GET_TASKS, REQ_FAVOURITE_TASKER, REQ_USER_DETAILS } from "../../../../services/api-service";

const FavTaskers = () => {
  const [loading, setLoader] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [createdTask, setCreatedTask] = useState([]);
  const [taskers, setTaskers] = useState([]);
  const [tasker, setTasker] = useState({});
  const [taskId, setTaskId] = useState("");
  const taskerListRef = useRef([]);

  useEffect(() => {
    getUserDetails();
    const taskId = getParamsByName("taskId");
    setTaskId(taskId);
    getCreatedTasks();
  }, []);

  const getUserDetails = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      try {
        setLoader(true);
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        setLoader(false);
        let userDetails = response.data.result;
        setTaskers(rateConversionUSDTasker(getFavriteTaskers(userDetails)));
      } catch (e) {
        setLoader(false);
        console.log(e);
      }
    }
  };

  const getCreatedTasks = async (id) => {
    try {
      setCreatedTask([]);
      let currentUser = localStorage.getItem("currentUser");
      const requestBody = {
        userId: currentUser,
        myTasks: {
          section: "created",
        },
      };
      const response = await GET_TASKS(requestBody);
      const task = response.data.result.filter((t) => t.currentStatus === "Open");
      setCreatedTask(task);
    } catch (e) {
      console.log(e);
    }
  };

  const openInviteModal = (tasker) => {
    setTasker(tasker);
    setInviteModal(true);
  };

  //! Unfavorite Operation
  const doUnfavoriteOperation = async (id, listRef) => {
    //Adding ui effect
    listRef.current.classList.add("nowRemoving");

    //Api request to do Unfavorite
    try {
      const requestBody = {
        favouriteTaskerId: id,
        operation: "unfavourite",
      };
      await REQ_FAVOURITE_TASKER(requestBody);
      getUserDetails();
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  };

  //! this one create ref of each tasker.
  taskerListRef.current = taskers.map((element, i) => taskerListRef.current[i] ?? createRef());

  return (
    <>
      <div className="favourite-opt-content__heading">
        <h2>Taskers</h2>
      </div>
      <div className="fav-tasker">
        {loading ? (
          <Loader height={"300px"} />
        ) : taskers && taskers.length > 0 ? (
          taskers.map((tasker, i) => (
            <div className="tasks-container" key={tasker._id} ref={taskerListRef.current[i]}>
              <div className="task-details">
                <div className="task-details-top">
                  {tasker.pinned === true ? <div className="Promotional">Pinned Tasker</div> : null}
                  <div className="tasker-profile">
                    <div style={{ display: "flex" }}>
                      <div className="profile-image">
                        <img className="pr-img" src={tasker.userId.profileImgUrl} alt={""} />
                        <div className="active"></div>
                      </div>
                      <div className="profile-content">
                        {/* <div className="info">
                        <div className="info-name"> {tasker.userId.firstName}{" "}{tasker.userId.lastName}</div>
                        <div className="sepration"></div>
                        <div className="tasker-location"><img src={Images.location} alt={""} />{tasker.userId.address.country}</div>
                      </div> */}
                        <div className="profile-content">
                          <div className="info">
                            <div className="info-name">
                              {tasker.userId.firstName} {tasker.userId.lastName}
                            </div>
                            <div className="sepration"></div>
                            <div className="tasker-location">
                              <img src={Images.location} alt={""} />
                              {tasker.userId.address ? tasker.userId.address.country : ""}
                            </div>
                          </div>
                          <div className="task-name">{tasker.title}</div>
                        </div>
                      </div>
                    </div>
                    <FavoriteIcon style={{ color: "#6DC7BE" }} className="favicon" onClick={() => doUnfavoriteOperation(tasker._id, taskerListRef.current[i])} />
                  </div>
                  <div className="task-info">
                    <p>{tasker.description}</p>
                    <div className="skills-details">
                      {tasker.skills.map((skill) => (
                        <div className="top-skill" key={skill._id}>
                          {skill.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="task-bottom">
                    <div className="task-bottom-left">
                      <div className="task-bottom-left-block" style={{ borderRight: "1px solid #EAEAEA" }}>
                        <p>Rating</p>
                        <div>{tasker.avgRating}</div>
                      </div>
                      <div className="task-bottom-left-block">
                        <p>
                          <select>
                            <option className="select-option" value="Portfolio">
                              Portfolio
                            </option>
                            {tasker.portfolio && tasker.portfolio.length > 0 ? (
                              tasker.portfolio.map((p, i) => (
                                <option className="select-option" value="Logo" key={i}>
                                  {tasker.portfolio.name}
                                </option>
                              ))
                            ) : (
                              <option> </option>
                            )}
                          </select>
                        </p>
                        {/* <div>Logo Designer<span>{tasker.portfolio.length - 1}</span></div> */}
                        {tasker.portfolio ? (
                          <div className="portfoilo portfolio-container">
                            {tasker.portfolio[0].portfolioName}
                            {"  "}
                            <span>
                              {"+"}
                              {tasker.portfolio.length - 1}
                              {"  more"}
                            </span>
                          </div>
                        ) : null}
                        {/* {this.state.portfolioIndex === i ? <div className="popup">{tasker.portfolio.map((portfo, i) => <span className="popup-list">{portfo.portfolioName}</span>)}</div> : null} */}
                      </div>
                    </div>
                    <div className="task-bottom-right">
                      <div className="task-bottom-right-block">
                        <p>Hourly Rate</p>
                        <div className="hbars" title={tasker.fullHbars}>
                          {tasker.ratePerHour} {"ℏ(s)/hr"}
                          <span>{`(${tasker.usdRate} USD)`}</span>
                        </div>
                      </div>
                      <button onClick={() => openInviteModal(tasker)}>Invite</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-task"> No Taskers to show!</div>
        )}
        <InviteTaskerModal open={inviteModal} onClose={() => setInviteModal(false)} tasker={tasker} taskId={taskId} createdTasks={createdTask} getTaskers={getUserDetails} />
      </div>
    </>
  );
};

export default FavTaskers;
