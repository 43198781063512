import _ from "lodash";
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import InviteTaskerModal from "../../components/InviteTaskerModal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import SingleReview from "../../components/single-review";
import { REQ_USER_DETAILS, GET_USER_ALL_TIME_DETAILS, REQ_FAVOURITE_TASKER, GET_REVIEWS, GET_TASKS, REQ_SUPERSKILLSDETAILS } from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import PageNotFound from "../404";
import HeaderBoard from "../home/header-board";
import Moment from "react-moment";

import "../Task/Task-creator/index.scss";
import { Images } from "../../assets/common";
import { rateConversionUSDTasker, getFavriteTaskers, getErrorString } from "../../components/validator";
import notifier from "../../components/notifier";

const UserProfile = (props) => {
  const nickName = props.match.params.nickName;
  // const supperskillsId = props.match.params.supperskillsId;
  const supperskillsId = new URLSearchParams(props.location.search).get("supperskillsId");

  const isUserLoggedIn = !_.isEmpty(cookieStorage.getCookie("token")) ? true : false;

  const currentUser = localStorage.getItem("currentUser");
  const [userID, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoader] = useState(false);
  const [superSkills, setSuperskills] = useState([]);
  const [uniqSkill, setUniqSkills] = useState([]);
  const [favTaskers, setFavouriteTasker] = useState([]);
  const [inviteTskerModal, setInviteTskerModal] = useState(false);
  const [userAlltimeDetails, setUserAllTimeDetails] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [openTasks, setOpenTasks] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getUserDetails(nickName);
    if (supperskillsId) {
      getSupperSkills(supperskillsId);
      getReviewsOfSuperSkills(supperskillsId);
    }
  }, [nickName, currentUser, supperskillsId]);

  const getUserDetails = async (nickName) => {
    try {
      setLoader(true);
      const requestBody = {
        nickName: nickName,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      const altResponse = await GET_USER_ALL_TIME_DETAILS(requestBody);
      const details = response.data.result;
      setUserDetails(details);
      setUserId(details._id);
      setUserAllTimeDetails({ ...altResponse.data.result });
      setLoader(false);
      const Superskills = response.data.result.profileStats.superskills;
      if (!supperskillsId) setSuperskills(rateConversionUSDTasker(Superskills));
      getUniqSkills(Superskills);
    } catch (e) {
      setLoader(false);
      console.log(e);
      notifier.error("Error", getErrorString(e));
      setUserDetails(null);
    }
  };

  const getUniqSkills = (Superskills) => {
    const uniqeSkills = new Set();
    Superskills.map((Superskill) => {
      return Superskill.skills.map((skill) => uniqeSkills.add(skill.name));
    });
    let myArr = Array.from(uniqeSkills);
    setUniqSkills(myArr);
  };

  const favouriteOpration = async (operation) => {
    try {
      const requestBody = {
        favouriteTaskerId: superSkills._id,
        operation: operation,
      };
      const response = await REQ_FAVOURITE_TASKER(requestBody);
      const fav = response.data.result.profileStats.favouriteTaskers;
      setFavouriteTasker(fav);
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  };

  const getLoggedInUserDetails = () => {
    let userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    setFavouriteTasker(getFavriteTaskers(userDetails).map((d) => d._id));
  };

  const getSupperSkills = async (id) => {
    try {
      const requestBody = {
        superskillId: id,
        reviews: true,
      };
      const response = await REQ_SUPERSKILLSDETAILS(requestBody);
      // //console.log(response.result.data);
      setSuperskills(rateConversionUSDTasker(response.data.result)[0]);
    } catch (e) {
      console.log(e);
      console.log("ERROR in Fetching Superskills:::");
    }
  };

  const getReviewsOfSuperSkills = async (id) => {
    try {
      const requestBody = {
        superskillId: id,
      };
      const response = await GET_REVIEWS(requestBody);
      setReviews(response.data.result);
      // //console.log(response.data.result);
    } catch (e) {
      console.log(e);
      console.log("ERROR in Fetching Geting Review of Superskills:::");
    }
  };

  //Modal Oprations
  const closeInviteModal = () => {
    setInviteTskerModal(false);
  };

  // Open Modal
  const openInviteModal = async () => {
    try {
      setModalLoading(true);
      const requestBody = {
        userId: currentUser,
        myTasks: {
          section: "created",
        },
      };
      const response = await GET_TASKS(requestBody);
      const task = response.data.result;
      let bidders = [];
      task.forEach((task, i) => {
        bidders.push(...task.bidders);
      });
      const openedTasks = task.filter((d) => d.currentStatus === "Open");
      setOpenTasks(openedTasks);
      setModalLoading(false);
      setInviteTskerModal(true);
      // this.setState({ tasks: openedTasks, invitedTaskers: bidders });
    } catch (e) {
      console.log(e);
    }
  };

  const renderLeftSide = () => {
    return (
      <div className="profile-description">
        <div className="profile">
          <div className="profile-top">
            <div className="profile-image">
              <img src={userDetails.profileImgUrl} alt="" />
              <div className={`box ${userDetails.availability ? "active" : ""}`}></div>
            </div>
            <div className="profile-content">
              <div className="info">
                <div className="info-name"> {userDetails.nickName}</div>
                {userDetails.fnameVisibilty ? (
                  <div className="name">
                    {userDetails.firstName} {userDetails.lastName}
                  </div>
                ) : null}

                {/* <hr /> */}
                {userDetails.address ? (
                  <>
                    <div className="tasker-location">
                      <img src={Images.location} alt={""} />
                      <p>
                        {" "}
                        {userDetails.address.city} {userDetails.address.country}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {/* Reputations */}
          <div className="reputation">
            <h3>Reputations</h3>
            <div className="badges">
              <div className="badges-name">Overall Rating</div>
              <div className="badges-icon">
                {userDetails.profileStats ? (
                  <>
                    {userDetails.profileStats.avgRating.toFixed(2)} {"  "}
                    <img src={Images.GreenRating} alt="" />
                  </>
                ) : null}
              </div>
            </div>
            <div className="badges">
              <div className="badges-name">Badges Earned</div>
              {/* <div className="badges-icon"><img src={Images.Rating} /><img src={Images.Rating} /><img src={Images.Rating} /></div> */}
            </div>
          </div>

          {/* All time Details */}
          <div className="reputation">
            <h3>All time Details</h3>

            <div className="badges">
              <div className="badges-name">Total Tasks</div>
              <div className="badges-icon">{userAlltimeDetails.totalTasks}</div>
            </div>
            <div className="badges">
              <div className="badges-name">Total Hours</div>
              <div className="badges-icon">{userAlltimeDetails.totalHours ? userAlltimeDetails.totalHours.toFixed(4) : ""}</div>
            </div>
            <div className="badges">
              <div className="badges-name">Member Since</div>
              <div className="badges-icon">
                <Moment format="MMMM YYYY">{userDetails.createdAt}</Moment>
              </div>
            </div>
            {supperskillsId && currentUser ? (
              <div className="actionButtons">
                {favTaskers.includes(superSkills._id) ? (
                  <button disabled={currentUser === userID} onClick={() => favouriteOpration("unfavourite")}>
                    Remove from Favourites
                  </button>
                ) : (
                  <button disabled={currentUser === userID} onClick={() => favouriteOpration("favourite")}>
                    Add to Favourites
                  </button>
                )}
                <button disabled={currentUser === userID} onClick={openInviteModal}>
                  {modalLoading ? <img src={Images.ellipsis_loader} alt="loading..." /> : "Invite"}
                </button>
              </div>
            ) : null}
          </div>
        </div>

        {/* Skills */}
        <div className="profile-skill">
          <h3>Subskills</h3>
          {uniqSkill ? (
            <div className="skills-details">
              {uniqSkill.map((uniq, i) => (
                <div className="top-skill" key={i}>
                  {uniq}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSuperSkills = () => {
    return (
      <div className="profile-description-content-body">
        {superSkills ? (
          <>
            {superSkills.length ? (
              <>
                {superSkills.map((superskill, i) => (
                  <div className="task-wrapper" key={`superSkill${i}`}>
                    <Link to={`/${nickName}?supperskillsId=${superskill._id}`}>
                      <div className="profile-content">
                        <div className="task-name">{superskill.title}</div>
                        <div className="task-pay">
                          <p>Hourly Rate</p>
                          <div style={{ display: "flex", alignItems: "baseline" }}>
                            <div className="hbars" title={superskill.fullHbars}>
                              {superskill.ratePerHour}
                            </div>
                            &nbsp;{"ℏ(s)"}/hr
                            <span>{`(${superskill.usdRate} USD)`}</span>
                          </div>
                        </div>
                      </div>
                      <div className="task-info">
                        <p>{superskill.description}</p>
                        <div className="skills">Subskills Required</div>
                        <div className="skills-details">
                          {superskill.skills.map((skill, i) => (
                            <div className="top-skill" key={i}>
                              {skill.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };

  const renderSuperSkillsDetails = () => {
    return (
      <div className="sprSkillsDetails">
        <div className="sprSkillsDetails__header">
          <Link to={`/${nickName}`}>
            <KeyboardBackspaceIcon />
            View All SuperSkills
          </Link>
        </div>
        <div className="sprSkillsDetails__content">
          <div className="sprSkillsDetails__content__top">
            <div className="sprSkillsDetails__content__top--title">
              <h4>{superSkills.title ? superSkills.title : ""}</h4>
            </div>
            <div className="sprSkillsDetails__content__top--rate">
              <span>Hourly Rate</span>
              <span>
                <b>
                  {superSkills.ratePerHour ? superSkills.ratePerHour : ""} {superSkills.currency}
                </b>
                /hours
              </span>
            </div>
          </div>
          <div className="sprSkillsDetails__content__details">
            <p>{superSkills?.description}</p>
          </div>
          <div className="sprSkillsDetails__content__skills">
            <h4 className="sprSkillsDetails__content__skills--title">Subskills</h4>
            <div className="sprSkillsDetails__content__skills--list">
              {superSkills.skills &&
                superSkills.skills.map((skill, i) => (
                  <span key={skill._id} className="sprSkillsDetails__content__skills--item">
                    {skill.name}
                  </span>
                ))}
            </div>
          </div>
          <div className="sprSkillsDetails__content__portfolio">
            <h4 className="sprSkillsDetails__content__portfolio--title">Portfolio Link</h4>
            <div className="sprSkillsDetails__content__portfolio--list">
              {superSkills.portfolio &&
                superSkills.portfolio.map((prt, i) => (
                  <a href={prt.portfolioLink} target="_blank" rel="noopener noreferrer" key={prt._id} className="sprSkillsDetails__content__portfolio--item">
                    {prt.portfolioName}
                  </a>
                ))}
            </div>
          </div>
          <div className="sprSkillsDetails__content__ratingReviews">
            <div className="sprSkillsDetails__content__ratingReviews--heading">
              <h4>Ratings &amp; Reviews </h4>
              <span>
                {superSkills.avgRating} <img src={Images.GreenRating} alt="" />
              </span>
              <span>{superSkills.reviews} Reviews</span>
            </div>
            <div className="sprSkillsDetails__content__ratingReviews__list">
              <div className="sprSkillsDetails__content__ratingReviews__list--item">{reviews && reviews.map((rvw, i) => <SingleReview key={rvw._id} review={rvw} />)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!userDetails) {
    return <PageNotFound />;
  }

  return (
    <div className="userProfile-page">
      <Header {...props} />
      <div className="userProfile__container">
        {isUserLoggedIn ? <HeaderBoard {...props} /> : null}
        <div className="Profile-Description-page">
          {/* <GoBack /> */}
          {loading ? (
            <div style={{ height: "70vh" }}>
              <Loader />
            </div>
          ) : (
            <div className="Profile-Description-page-content">
              {renderLeftSide()}
              {!supperskillsId ? (
                <div className="profile-description-content">
                  <div className="profile-description-content-header">
                    <div className="left">
                      <div className="profile-description-content-headerSlider">Profile Summary</div>
                      <div className="profile-description-content-headerSlider">Portfolio</div>
                    </div>
                  </div>

                  {renderSuperSkills()}
                </div>
              ) : (
                renderSuperSkillsDetails()
              )}
            </div>
          )}
          {supperskillsId ? (
            <InviteTaskerModal
              open={inviteTskerModal}
              onClose={closeInviteModal}
              tasker={superSkills}
              createdTasks={openTasks}
              // getTaskers={this.getTaskers}
            />
          ) : null}
        </div>
      </div>
      {isUserLoggedIn ? null : <Footer />}
    </div>
  );
};

export default UserProfile;
