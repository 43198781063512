import React from "react";

const CRForm = () => {
  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>Raise a new Issue/CR</h4>
      </div>
      <div className="formBox">
        <iframe
          className="clickup-embed clickup-dynamic-height"
          src="https://forms.clickup.com/f/3fg1y-2699/DIYG5F5AI05ECU3A08"
          title="CRForm"
          width="100%"
          height="100%"
          style={{background:'transparent'}}></iframe>
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
      </div>
    </>
  );
};

export default CRForm;
