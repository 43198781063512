import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import cookieStorage from '../../services/cookie-storage';
import HeaderBoard from '../home/header-board';
import Header from './../../components/header';
// import Filter from './filter';
import RouterOutlet from './router-outlet';
import './index.scss';
import './filter/index.scss';

function Task(props) {

  useEffect(() => {
    // Component Will mount code will go here
    return () => {
      // Component Will unmount will go here
     
    }
  });

  return (
    <div className="task-page">
      <Header {...props}  />
      {!_.isEmpty(cookieStorage.getCookie("token")) ? <HeaderBoard {...props}  /> : null}
      <div className="task-container">
        <div className="content">
          <RouterOutlet  {...props} />
        </div>
      </div>
    </div>
  );
}

export default Task;
