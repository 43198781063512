import _ from 'lodash';
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { TaskbarUserContext } from '../../context/TaskbarUserContext';
import cookieStorage from '../../services/cookie-storage';

export default function PrivateRoute({ component: Component, ...rest }) {
  const isUserLogIn = !_.isEmpty(cookieStorage.getCookie("token"))
  const {currentUser} = useContext(TaskbarUserContext);
  

  if(isUserLogIn  && currentUser?.requiredPasswordChange){
    return  <Redirect to="/resetPassword" />
  }

  return (

    <Route
      {...rest}
      render={props => {
        return isUserLogIn ? <Component {...props} /> : <Redirect to="/" />
      }}
    ></Route>
  )
}