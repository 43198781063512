import {
  Button,
  Fade,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import Loader from "../../../components/Loader";
import NoDataToShow from "../../../components/NoDataToShow";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { dateToLocalString, getErrorString } from "../../../components/validator";
import { StyledBackdrop } from "../../../lib/validators";
import {
  GET_ISSUES,
  MARK_SOLVED
} from "../../../services/api-service";
import "./index.scss";
const Reportings = () => {
  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalRecords, setTotalRecord] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalIssueIndex, setModalIssueIndex] = useState(0);

  useEffect(() => {
    getIssues({});
  }, []);

  const getIssues = async (reqBody) => {
    setIsLoading(true);
    try {
      const response = await GET_ISSUES(reqBody);
      setIssues(response.data.result.data);
      setTotalRecord(response.data.result.totalCount);
      setIsLoading(false);
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  const handleIssueClick = (id, index) => {
    setModalIssueIndex(index);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    getIssues({pageNo: pageNumber});
  };

  const markAsSolved = async (issueId) => {
    // //console.log(issueId)
    const reqBody = {
      issueId: issueId,
    };

    try {
      const response = await MARK_SOLVED(reqBody);
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getIssues({pageNo: pageNumber});
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
    getIssues({pageNo: page});
  };

  const renderInnerModal = () => {
    let issue = issues[modalIssueIndex];
    return (
      <div className="issueModal__body">
        <div className="issueModal__heading">
          <span className="issueModal__heading--count">
            {modalIssueIndex + 1}
          </span>
          <span className="issueModal__heading--label">
            Issues And Suggestions.
          </span>
          <div className="issueNavigation">
            <span
              className="prev"
              onClick={() =>
                setModalIssueIndex(
                  modalIssueIndex > 0 ? modalIssueIndex - 1 : 0
                )
              }>
              <NavigateBeforeIcon />
            </span>
            <span
              className="next"
              onClick={() =>
                setModalIssueIndex(
                  modalIssueIndex < issues.length - 1
                    ? modalIssueIndex + 1
                    : issues.length - 1
                )
              }>
              <NavigateNextIcon />
            </span>
          </div>
          <span
            className="issueModal__heading--closeIcon"
            onClick={handleModalClose}>
            <img src={Images.IconForCloseModal} alt="" />
          </span>
        </div>
        <div className="issueModal__content">
          <div className="issueModal__content__userDetails">
            <table>
              <tr>
                <td>Reported By:</td>
                <td>@{issue.user.nickName}</td>
                <td>Created At:</td>
                <td>{dateToLocalString(issue.createdAt)}</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td colSpan="3">{`${issue.user.firstName} ${issue.user.lastName}`}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td colSpan="2">{issue.user.email}</td>
                <td>
                  {issue.solved ? (
                    <span className="issueSolved">SOLVED</span>
                  ) : (
                    <span className="issueOpen">OPEN</span>
                  )}
                </td>
              </tr>
            </table>
          </div>
          <div className="issueModal__content__description">
            <h4>Issue Description</h4>
            <p>{issue.description}</p>
          </div>
          <div className="actionButtons">
            {!issue.solved ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DoneAllIcon />}
                onClick={markAsSolved.bind(this, issue._id)}
                style={{position: "unset"}}>
                Mark as Solved
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>All Issues and Suggestions</h4>
      </div>
      {isLoading ? (
        <Loader />
      ) : issues && issues.length > 0 ? (
        <TableContainer className="data-table">
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="50" />
              <col />
              <col />
              <col />
              <col width="100" />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl</StyledTableCell>
                <StyledTableCell>UserName</StyledTableCell>
                <StyledTableCell>Isuue</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {issues.map((issue, index) => {
                // //console.log("ssue", issue);
                return (
                  <StyledTableRow key={`issue${index}`} className="issueRow">
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      <p>{`${issue.user.firstName} ${issue.user.lastName}`}</p>
                      <i>
                        <span>@{issue.user.nickName}</span>&nbsp;&nbsp;
                        {/* <span className="email">{issue.user.email}</span> */}
                      </i>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={handleIssueClick.bind(this, issue._id, index)}>
                      <p>
                        {issue.description.length > 100
                          ? `${issue.description.substring(0, 97)}...`
                          : issue.description}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell>
                      {issue.solved ? (
                        <span className="issueSolved">SOLVED</span>
                      ) : (
                        <span className="issueOpen">OPEN</span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {!issue.solved ? (
                        <Tooltip title="Mark as solved" placement="top" arrow>
                          <IconButton
                            className="markSolvedIcon"
                            size="small"
                            onClick={markAsSolved.bind(this, issue._id)}>
                            <DoneAllIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataToShow />
      )}
      <div className="pagination">
        <Pagination
          totalRecord={totalRecords}
          pageNumber={pageNumber}
          sendCurrentPage={handlePageChange}
          showControls={true}
        />
      </div>
      <Modal
        aria-labelledby="Singke Isuue"
        aria-describedby="Perform Action On isuues"
        className="issueModal"
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isModalOpen}>
          <div className="issueModal__paper">
            {issues.length > 0 ? renderInnerModal() : null}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "none",
  },
  head: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);

export default Reportings;
