export const Images = {
  LogoPng: require("../common/resources/Logo_Taskbar_full-01.png"),
  Logo: require("../common/resources/taskbar-logo-beta.svg"),
  SearchLandingpage: require("./resources/search.png"),
  search: require("../common/resources/searchIcon.svg"),
  profileIcon: require("../common/resources/prof.jpg"),
  downArrow: require("../common/resources/arrow-down-sign-to-navigate.png"),
  favIcon: require("../common/resources/Circle Button.png"),
  sorting: require("../common/resources/Component 49 – 3.png"),
  location: require("../common/resources/outline-place-24px.png"),
  // user: require("../common/resources/user (1).png"),
  time: require("../common/resources/baseline-timer-24px.png"),
  back: require("./resources/Component 40 – 2.png"),
  authPage: require("./resources/Mask Group 6@2x (1).png"),
  emailVerify: require("./resources/email.png"),
  pinTask: require("./resources/pintask.svg"),
  pinback: require("../common/resources/pinbackground.svg"),
  pinpush: require("../common/resources/pushpin.svg"),
  Rating: require("./resources/Rating Item.svg"),
  Fav_hover: require("../common/resources/fav.svg"),
  plus: require("./resources/Component 79 – 1.svg"),
  plusActive: require("./resources/activeAddIcon.svg"),
  fav: require("./resources/Path.svg"),
  lightBulb: require("./resources/lightbulb.png"),
  delete: require("./resources/Group 7122@2x.png"),
  noSuperSkill: require("./resources/Group 7120.png"),
  cancel: require("./resources/Component 84 – 1@2x.png"),
  notification: require("./resources/Group 7094@2x.png"),
  GreenRating: require("./resources/greenrating.svg"),
  RearrangeSuperskill: require("./resources/rearrange-superskill.svg"),
  user: require("./resources/User.svg"),
  active: require("./resources/Active.svg"),
  pending: require("./resources/Pending.svg"),
  archived: require("./resources/archive.svg"),
  circular_loader: require("./resources/circular-loader.svg"),
  ellipsis_loader: require("./resources/ellipsis-loader.svg"),
  RecommendTaskBtn: require("./resources/Recommand-task.svg"),
  logoutIcon: require("./resources/logout.svg"),
  SettingIcon: require("./resources/settings.svg"),
  EditProfileIcon: require("./resources/edit-profile.svg"),
  InviteAfriendIcon: require("./resources/invite-a-friend.svg"),
  negotiationIcon: require("./resources/speech-bubble.svg"),
  IconForCloseModal: require("./resources/icons8-delete.svg"),
  calenderIcon: require("./resources/calender.svg"),
  redTick: require("./resources/tick.svg"),
  openIcon: require("./resources/ic_open_in_new_24px.svg"),
  minimizeModal: require("./resources/Component 157 – 1.svg"),
  timer: require("./resources/baseline-timer-24px@2x.png"),
  timerPause: require("./resources/timerPaush.svg"),
  timerStart: require("./resources/timerStart.svg"),
  historyIcon: require("./resources/historyIcon.svg"),
  headersWalletIcon: require("./resources/hedera-wallet-icon.svg"),
  informationIcon: require("./resources/information-button.svg"),
  dummyQRcode: require("./resources/Image 4.svg"),
  chipCloseIcon: require("./resources/chip-cross-icon.svg"),
  permotedTaskView: require("./resources/promoted-task-badge.svg"),
  summryReportIcon: require("./resources/SummryReportIcon.svg"),
  yourPositionIcon: require("./resources/position-icon.svg"),
  landingPageImage: require("./resources/landing-page-bg.svg"),
  transectionHistoryIcon: require("./resources/transections.svg"),
  piChartIcon: require("./resources/pie-chart.svg"),
  downloadInvoice: require("./resources/download-invoice.svg"),
  selectDropDownIcon: require("./resources/select-drop-dow-iconn.svg"),
  whyJTIcons: {
    earnHbars: require("./resources/Group_7667.svg"),
    earnHbarsHoverd: require("./resources/Group 7675.svg"),
    spendHbarHoverd: require("./resources/Group 7549.svg"),
    spendHbar: require("./resources/Spend.svg"),
    ultrLow: require("./resources/Group_10205.svg"),
    ultrLowHoverd: require("./resources/Group 7680.svg"),
    noPlatform: require("./resources/No platform.svg"),
    noPlatformHovered: require("./resources/Group 7681.svg"),
    monitize: require("./resources/Monetize.svg"),
    monitizeHovered: require("./resources/Group 7676.svg"),
    buildReputation: require("./resources/Build.svg"),
    buildReputationHovered: require("./resources/Group 7677.svg"),
    achieveIndipendence: require("./resources/Achieve.svg"),
    achieveIndipendenceHoverd: require("./resources/Group 7679.svg"),
    noBank: require("./resources/No bank.svg"),
    noBankHovered: require("./resources/Group 7682.svg"),
    watch: require("./resources/Watch.svg"),
  },
  listTick: require("./resources/Group-7572.svg"),
  logoWhite: require("./resources/logo-white.png"),
  scoreGraph: require("./resources/Path-5276.svg"),
  inviteAfriendFormIcon: require("./resources/invite-a-friend-form.svg"),
  mobileMenu: require("./resources/Menu.svg"),
  menuDrawerIcons: {
    dashboard: require("./resources/Group-8019.svg"),
    leaderBoard: require("./resources/Group-8017.svg"),
    myProfile: require("./resources/Group-8016.svg"),
    myTasks: require("./resources/Group-8015.svg"),
    wallet: require("./resources/Group-8014.svg"),
    admin: require("./resources/Mask-Group-95.svg"),
    createTask: require("./resources/Group-8013.svg"),
    fav: require("./resources/Group-8012.svg"),
  },
  hederaLogo: require("./resources/decentralized-on-hh_white.png"),
  img404: require('./resources/404.svg'),
  hederaIcon: require('./resources/hedera_logo.png'),
  reportAndSuggestions: require('./resources/report_or_suggestion.svg'),
  notificationSound: require('./resources/492512-marimba-notification-03.mp3'),
  hashPackIcon:require("./resources/hashpack.svg")
};

export * from './resources/Pending'
export * from './resources/Archive'
export * from './resources/Active'
export * from './resources/ArrowDownSignToNavigate'