import React from 'react';

interface Props {
	className?: string;
}

export const MyTaskArchive = (props: Props) => {
	return (
		<svg height="14.898" width="15.331" viewBox="0 0 15.331 14.898" xmlns="http://www.w3.org/2000/svg">
			<defs />
			<g transform="translate(0 -7.232)">
				<g transform="translate(0 11.805)">
					<g transform="translate(0 0)">
						<path className="a" d="M15.166,160.206a.72.72,0,0,0-.551-.254H.717a.72.72,0,0,0-.551.254.7.7,0,0,0-.16.542l.82,8.562c0,.007,0,.013,0,.02a1.087,1.087,0,0,0,1.067.947h11.54a1.063,1.063,0,0,0,1.069-.967l.82-8.562A.7.7,0,0,0,15.166,160.206Zm-1.727,9h0l-11.53,0a.034.034,0,0,1-.014-.016l-.783-8.166H14.223Z" transform="translate(0 -159.952)" />
					</g>
				</g>
				<g transform="translate(1.196 9.501)">
					<g transform="translate(0 0)">
						<path className="a" d="M52.725,83.241a.717.717,0,0,0-.542-.246H40.661a.724.724,0,0,0-.708.832l.322,2.09,1.059-.163-.26-1.687h10.7l-.259,1.687,1.059.163.322-2.1A.727.727,0,0,0,52.725,83.241Z" transform="translate(-39.944 -82.995)" />
					</g>
				</g>
				<g transform="translate(2.787 7.232)">
					<g transform="translate(0 0)">
						<path className="a" d="M102.648,7.478a.717.717,0,0,0-.542-.246H93.764a.724.724,0,0,0-.708.833l.322,2.054,1.059-.166L94.178,8.3h7.51l-.272,1.645,1.057.175.341-2.065A.726.726,0,0,0,102.648,7.478Z" transform="translate(-93.047 -7.232)" />
					</g>
				</g>
				<g transform="translate(4.879 15.181)">
					<g transform="translate(0 0)">
						<path className="a" d="M167.975,272.7h-4.5a.536.536,0,0,0,0,1.072h4.5a.536.536,0,1,0,0-1.072Z" transform="translate(-162.938 -272.703)" />
					</g>
				</g>
			</g>
		</svg>
	)
}

