import FavoriteIcon from '@material-ui/icons/Favorite';
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import { getFavriteTask } from '../validator';
import { REQ_FAVOURITE_TASK } from '../../services/api-service';


const FavouriteIcon = (props) => {
    const [favTask, setFavTask] = useState([])

    useEffect(() => {
        if (props.userDetails.length > 0) {
            setFavTask(getFavriteTask(props.userDetails).map((d) => d._id))
        }
    }, [])

    const FavouriteOpration = async (superSkillID, op) => {
        try {
            const requestBody = {
                "favouriteTaskId": superSkillID,
                "operation": op
            }
            const response = await REQ_FAVOURITE_TASK(requestBody);
            const fav = response.data.result.profileStats.favouriteTasks;
            setFavTask(fav)
        }
        catch (e) {
            console.log(e)
            console.log('ERROR in Favourite:::');
        }
    }

    return (
        <div style={{ cursor: 'pointer' }}>
            {favTask.includes(props.taskId) ?
                <FavoriteIcon style={{ color: '#6DC7BE', width: '40px', height: '40px' }} className="favicon" onClick={() => FavouriteOpration(props.taskId, 'unfavourite')} />
                : <img src={Images.favIcon} alt={""} className="favicon" onClick={() => FavouriteOpration(props.taskId, 'favourite')} />}

        </div>
    );
};

export default FavouriteIcon;
