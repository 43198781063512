import React from "react";
import "./index.scss";

const StyledCheckBox = (props) => {
  const handleChange = () => {
    props.onClick();
  };
  return (
    <>
      <span className="styledCheckBox" onClick={handleChange}>
        <input
          type="checkbox"
          name={props.name}
          id={props.id}
          value={props.value}
          // onChange={props.handleChange ? handleChange : ""}
          className={props.className}
          checked={props.checked}
        />
        <label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.942"
            height="9.942"
            viewBox="0 0 9.942 9.942"
          >
            <defs></defs>
            <path
              style={{
                fill: "none",
                stroke: " #dadce0",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 1.5,
              }}
              className="a"
              d="M0,0V3.915H7.145"
              transform="translate(1.061 6.113) rotate(-45)"
            />
          </svg>
        </label>
      </span>
    </>
  );
};
export default StyledCheckBox;
