import { Fade, Modal, TextField} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from 'react';
import { Images } from '../../../assets/common';
import ConfirmModal from '../../../components/deleteConfirmModal';
import Loader from '../../../components/Loader';
import NoDataToShow from '../../../components/NoDataToShow';
import notifier from '../../../components/notifier';
import { getErrorString } from '../../../components/validator';
import { StyledBackdrop } from '../../../lib/validators';
import { ADD_PROHIBITED_WORDS, DELETE_PROHIBITED_WORDS, GET_PROHIBITED_WORDS } from '../../../services/api-service';
import './index.scss';

const PhorhibitedWords = (props) => {
  // const [formEdit, setFormEdit] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [allWords, setAllWords] = useState([]);
  const [words, setWords] = useState('');
  const [wordId, setWordId] = useState('');
  const [confimModal,setconfimModal]=useState(false);

  useEffect(() => {
    getAllProbhitedWords();
  }, []);

  const getAllProbhitedWords = async () => {
    setLoading(true)
    try {
      const response = await GET_PROHIBITED_WORDS()
      setAllWords(response.data.result)
      setLoading(false)
    } catch (e) {
      notifier.error("Error", getErrorString(e))
    }
  }

  const addProbhitedWords = async () => {
    setBtnLoading(true)
    const reqBody = {
      "words": words
    }
    try {
      const response = await ADD_PROHIBITED_WORDS(reqBody)
      setBtnLoading(false)
      if (response.data.status === "success") {
        notifier.success(response.data.result)
        getAllProbhitedWords()
        handleModalClose()
      }
    } catch (e) {
      setBtnLoading(false);
      notifier.error("Error", getErrorString(e))
    }
  }

  const deleteProbhitedWords = async () => {
    try {
      const wordID = wordId;
      const reqBody = {
        "wordId": wordID
      }
      const response = await DELETE_PROHIBITED_WORDS(reqBody)
      if (response.data.status === "success") {
        notifier.success(response.data.result)
        getAllProbhitedWords()
        setconfimModal(false)
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
    
  }


  const handleModalClose = () => {
    setModalOpen(false)
    setWords('')
  }

  const openConfirmModal=()=>{

   setconfimModal(true);
  }

  const closeConfirmModal=()=>setconfimModal(false);

  const openEditModal = () => {
    setModalOpen(true)
  }

  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>Prohibited Words</h4>
        <button className="addButton" onClick={openEditModal}>
          Add New
          </button>
      </div>
      <div className="allSkills">
        <div className="allSkills__list">
          {isLoading ?
            <Loader /> :
            allWords && allWords.length > 0 ?
              allWords.map((data, index) => {
                return (
                  <div className="allSkills__list__item" key={index}>
                    <span className="skillsName">{data.word}</span>
                    {/* <span className="edit" onClick={handleEditClick}>
                      <EditIcon />
                    </span> */}
                    <span className="del" onClick={() => {setWordId(data._id) ;openConfirmModal()}}>
                      <DeleteIcon />
                    </span>
                  </div>
                );
              }) : <NoDataToShow />}
        </div>
      </div>
      <ConfirmModal modal={confimModal} yesCallback={deleteProbhitedWords} cancelCallback={closeConfirmModal} text={'Are you sure? you want to delete it ?'} />
      <Modal
        aria-labelledby="Add New Phorhibited words"
        aria-describedby="Form for adding new Phorhibited word"
        className="addNewModal"
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        

        <Fade in={isModalOpen}>
          <div className="addNewModal__paper">
            <div className="addNewModal__body">
              <div className="addNewModal__heading">
                <span className="addNewModal__heading--label">
                  ADD NEW PROHIBITED WORDS
                  </span>
                <span
                  className="addNewModal__heading--closeIcon"
                  onClick={handleModalClose}
                >
                  <img src={Images.IconForCloseModal} alt="" />
                </span>
              </div>
              <div className="skillsAction_form">
                <TextField
                  label="Enter Word"
                  variant="outlined"
                  className="inputFeild"
                  size="small"
                  value={words}
                  onChange={(e) => setWords(e.target.value)}
                />
                <div className='button-main'>
                  <button onClick={() => addProbhitedWords()}>
                    {btnLoading ? <img src={Images.ellipsis_loader} alt='' /> : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}


export default PhorhibitedWords;