/**
 * Hide Component
 * @augments {Component<Props, State>}
 * @description This component is about hiding childs acordingly screen size. Three are three types of condition for that 1. up 2. down 3.range(min , max)
 */

import React, { useEffect, useState } from "react";

const Hide = (props) => {
  const { type, size, children, min, max } = props;
  const [hide, setHide] = useState(false);
  const setHideCondition = () => {
    if (type === "up") {
      window.innerWidth > size ? setHide(true) : setHide(false);
    } else if (type === "down") {
      window.innerWidth < size ? setHide(true) : setHide(false);
    } else if (type === "range") {
      window.innerWidth < max && window.innerWidth > min
        ? setHide(true)
        : setHide(false);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", setHideCondition);
    setHideCondition();

    return () => {
      window.removeEventListener("resize", setHideCondition);
    }
  }, [type, size]);
  return <>{hide ? null : children}</>;
};
export default Hide;
