import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import notifier from '../../../components/notifier';
import Pagination from '../../../components/Pagination';
import { getErrorString , serialize} from '../../../components/validator';
import { GET_ALL_WALLET_TASK } from '../../../services/api-service';

import './index.scss'

const HederaWalletTask = () =>{

    const [loader , setLoader] = useState(false);
    const [pageNo , setPageNo] = useState(1);
    const [totalRecords , setTotalRecords] = useState(1);
    const [task , setTasks] = useState([])
    

    const getAllWalletTask = async(page_no) =>{
        try{
            setLoader(true);
            const params = {
                pageNo:page_no ? page_no :pageNo
            }
            const response = await GET_ALL_WALLET_TASK(serialize(params))
            setTasks(response.data.result.data);
            setTotalRecords(response.data.result.totalCount);
            setLoader(false);
        }   
        catch(e){
            setLoader(false);
            console.log(e)
            notifier.error('Error' , getErrorString(e))
        }
    }

    useEffect(()=>{
        getAllWalletTask()
    },[])

    const handlePageChange = (pageNo) => {
        setPageNo(pageNo);
        getAllWalletTask(pageNo)
    }
    
    return (<>
        <div className="admin__body__continer__content__heading">
            <h4>Hedera Wallet task</h4>
      </div>
      <div className="walletTask">
          {loader ? <Loader /> : task.map((task , index)=> <div className="task" key={task._id}>
            <h5>{task.heading}</h5>
            <p>{task.description}</p>
          </div>)}
      </div>
      <div className="pagination">
      <Pagination
          totalRecord={totalRecords}
          pageNumber={pageNo}
          sendCurrentPage={handlePageChange}
          showControls={true}
        />
      </div>
   </> )
}

export default HederaWalletTask;