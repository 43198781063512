import {
    makeStyles
} from "@material-ui/core";
import CropSquareIcon from '@material-ui/icons/CropSquare';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Images } from '../../assets/common';
import notifier from '../../components/notifier';
import { convertSecondsToHoursMin, getErrorString } from '../validator';
import { GET_TIMER_HISTORY, TIMER_ACTIONS } from '../../services/api-service';

const MyStopwatch = (props) => {

    const [timerId, setTimerId] = useState('')
    const [closedTimers, setClosedTimers] = useState([])
    const [timerTotal, setTimerTotal] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const [seconds, setSeconds] = useState(0)
    const taskId = props.taskDetails._id
    let timerInterval = null

    useEffect(() => {
        getTimerHistory();
    }, [])

    useEffect(() => {
        if (isRunning) {
            timerInterval = setInterval(() => {
                let count = seconds
                setSeconds(count + 1)
            }, 1000);
        }
        return () => {
            clearInterval(timerInterval)
        }
    }, [seconds, isRunning])

    const getTimerHistory = async (action) => {
        try {
            const response = await GET_TIMER_HISTORY(taskId)
            const closedTimers = response.data.result.closedTimers
            setClosedTimers(closedTimers)
            const runningTimer = response.data.result.runningTimer
            if (runningTimer !== undefined) {
                let a = new Date()
                let b = new Date(runningTimer.startTime)
                let seconds = parseInt((a.getTime() - b.getTime()) / 1000)
                setTimerId(runningTimer._id)
                setSeconds(seconds)
                setIsRunning(true)
            } else {
                setIsRunning(false)
                let _ = action === 'stop' ? clearInterval(timerInterval) : setSeconds(0)
            }
            let total = 0;
            closedTimers.forEach((d) => {
                total = total + d.totalSeconds
            })
            setTimerTotal(total)
            props.sendTimerData(total, closedTimers)
        } catch (error) {
            notifier.error('Error', getErrorString(error))
        }
    }

    const timerActions = async (action) => {
        if (action === 'start') {
            startTimer(action);

        } else if (action === 'stop') {
            stopTimer(action);

        } else if (action === 'reset') {
            resetTimer(action);
        }
        props.sendTimerData(timerTotal, closedTimers)
    }

    const startTimer = async (action) => {
        try {
            const requestBody = {
                "taskId": taskId,
                "accumulatedSeconds": "0"
            }
            const responseStart = await TIMER_ACTIONS(requestBody, action)
            getTimerHistory(action);
        } catch (error) {
            notifier.error('Error', getErrorString(error))
        }
    }

    const stopTimer = async (action) => {
        try {
            const requestBody = {
                "taskId": taskId,
                "timerId": timerId,
            }
            const responseStop = await TIMER_ACTIONS(requestBody, action)
            getTimerHistory(action);
        } catch (error) {
            notifier.error('Error', getErrorString(error))
        }
    }

    const resetTimer = async (action) => {
        try {
            const requestBody = {
                "taskId": taskId,
                "timerId": timerId,
            }
            const responseReset = await TIMER_ACTIONS(requestBody, action)
            getTimerHistory(action);
        } catch (error) {
            notifier.error('Error', getErrorString(error))
        }
    }

    return (
        props.isAppliedTask ?
            <div style={{ display: 'flex' }}>
                <div className="timerClock_btn">
                    {isRunning ?
                        <img src={Images.timerPause} onClick={() => timerActions('stop')} alt=""/> :
                        <img src={Images.timerStart} onClick={() => timerActions('start')} alt=""/>}
                </div>
                <div className="timerClock_meta">
                    <div className="timerClock_meta--analog">
                        <span>{convertSecondsToHoursMin(seconds)}</span>
                    </div>
                    <div className="timerClock_meta--resets">
                        {isRunning ? <span className="reSet" onClick={() => timerActions('reset')}>Reset</span> : ''}
                    </div>
                </div>
            </div> : ''
    );
}

const TimerPopUp = (props) => {
    const classes = Styles();
    const [backDropOpen, setBackDropOpen] = useState(true);
    const [minimise, setMinimise] = useState(false);
    const [closedTimers, setClosedTimers] = useState([])
    const [timerTotal, setTimerTotal] = useState(0)

    const isAppliedTask = props.taskType === 'applied';
    const taskId = props.taskDetails._id

    useEffect(() => {
        if (!isAppliedTask && taskId) {
            getCreatedTimerHistory()
        }
    }, [taskId])

    const handleClose = () => {
        props.onClose();
        setMinimise(false);
        setBackDropOpen(true)
    }

    const handleMinMax = () => {
        setMinimise(!minimise);
        setBackDropOpen(!backDropOpen)
    }

    const getTimerData = (timerTotal, closedTimers) => {
        setTimerTotal(timerTotal)
        setClosedTimers(closedTimers)
    }

    const getCreatedTimerHistory = async () => {
        try {
            const response = await GET_TIMER_HISTORY(taskId)
            const closedTimers = response.data.result.closedTimers
            setClosedTimers(closedTimers)
            let total = 0;
            closedTimers.forEach((d) => {
                total = total + d.totalSeconds
            })
            setTimerTotal(total)
        } catch (error) {
            notifier.error('Error', getErrorString(error))
        }
    }

    return (
        <>
            {props.open ?
                <div className={minimise ? `${classes.modal} minimise` : `${classes.modal} open`}>
                    {backDropOpen ? <div className={classes.backDrop}></div> : null}
                    <div className={classes.paper} style={{ height: isAppliedTask ? 430 : 'auto' }}>
                        <div className={classes.timer}>
                            <div className="heading">
                                {!isAppliedTask ? (<div className="label">
                                    <h4>Time Completed</h4>
                                    <span>Timer History</span>
                                </div>) : null}
                                <div className="left_controls">
                                    <span className="close" onClick={handleClose}>
                                        <img src={Images.IconForCloseModal} alt=""/>
                                    </span>
                                    {isAppliedTask ?
                                        <span className="minimise" onClick={handleMinMax}>
                                            {minimise ? <CropSquareIcon /> : <img src={Images.minimizeModal} alt=""/>}
                                        </span>
                                        : null}
                                </div>
                            </div>
                            <div className="body">
                                {isAppliedTask ? (
                                    <div className="timerClock" style={{ borderBottom: '1px solid #dddddd' }}>

                                        <MyStopwatch taskDetails={props.taskDetails} sendTimerData={getTimerData} isAppliedTask={isAppliedTask} />

                                        <span className="date">
                                            Date : <Moment format="DD-MM-YYYY">
                                                {new Date()}
                                            </Moment>
                                        </span>
                                    </div>
                                ) : null}
                                {!minimise ? (
                                    <>
                                        {isAppliedTask ? (
                                            <div className="timerHistory__heading">
                                                <h4><img src={Images.historyIcon} alt=""/> History</h4>
                                            </div>
                                        ) : null}

                                        <div className="timerHistory">
                                            <div className="timerHistory__item timerHistory__item--heading">
                                                <span className="timerHistory__item--left">Date</span>
                                                <span className="timerHistory__item--right">Total Time</span>
                                            </div>
                                            {closedTimers.map((data, i) => {
                                                return (
                                                    <div className="timerHistory__item" key={i}>
                                                        <span className="timerHistory__item--left">{data._id.date}</span>
                                                        <span className="timerHistory__item--right">{convertSecondsToHoursMin(data.totalSeconds)}</span>
                                                    </div>
                                                )
                                            })}
                                            <div className="timerHistory__item timerHistory__item--heading timerHistory__item--total">
                                                <span className="timerHistory__item--left">Total Time</span>
                                                <span className="timerHistory__item--right">{convertSecondsToHoursMin(timerTotal)}</span>
                                            </div>
                                        </div>
                                    </>) : null}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}


const Styles = makeStyles({
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        zIndex: 1100,
        '&.minimise': {
            width: 240,
            height: 70,
            top: 'unset',
            right: 'unset',
            bottom: '-15px',
            left: 10,
            '&>div': {
                position: 'relative',
                height: 'auto !important',
                borderRadius: '5px 5px 0 0',
                width: '240px',
                '& .heading': {
                    padding: '5px',
                    position: 'absolute',
                    right: 0,
                    '& span': {
                        width: 18,
                        height: 18,
                        '& img, & svg': {
                            width: 14,
                            height: 14
                        }
                    }
                },
                '& .body': {
                    padding: '7px 10px',
                    '& .timerClock': {
                        border: 'none',
                        '&_btn': {
                            width: 35,
                            height: 35,
                            '& img': {
                                width: 35,
                                height: 35,
                            }
                        },
                        '&_meta': {
                            '&--resets': {
                                fontSize: 10
                            }
                        }
                    }
                }
            }
        }
    },
    backDrop: {
        position: 'absolute',
        top: 0, left: 0,
        right: 0,
        bottom: 0,
        background: " rgba(44, 44, 44, 0.3) 0% 0% no-repeat padding-box",
        zIndex: -1
    },
    paper: {
        height: 430,
        width: 485,
        backgroundColor: "#ffffff",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: 10,
    },
    timer: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        '& .heading': {
            display: 'flex',
            padding: 14,
            alignItems: 'center',
            '& .left_controls': {
                marginLeft: 'auto',
                '&>span': {
                    display: 'inline-block',
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#dddddd',
                        borderRadius: 3
                    },
                    '&.close': {
                        marginLeft: 16
                    }
                }
            },
            '& .label': {
                '& h4': {
                    color: '#2C2C2C',
                    fontSize: 15,
                    marginBottom: 0,
                },
                '& span': {
                    color: '#656565',
                    fontSize: 12
                }
            }
        },
        '& .body': {
            padding: '10px 45px 30px 45px',
            '& .timerClock': {
                borderBottom: '1px solid #dddddd',
                paddingBottom: 13,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                '& .date': {
                    fontSize: '13px'
                },
                '&_btn': {
                    width: 48,
                    height: 48,
                    marginRight: 15,
                    cursor: 'pointer',
                },
                '&_meta': {
                    maxWidth: '100%',
                    flexGrow: 1,
                    flexBasis: 0,
                    '&--analog': {
                        fontSize: 16,
                        color: '#2C2C2C',
                        fontWeight: 500
                    },
                    '&--resets': {
                        display: 'flex',
                        fontSize: 13,
                        marginTop: 3,
                        '&>span:first-child': {
                            color: '#6DC7BE',
                            cursor: 'pointer'
                        },
                        // '&>span:last-child': {
                        //     color: '#2c2c2c',
                        //     display: 'inline-block',
                        //     marginLeft: 'auto'
                        // }
                    }
                }
            },
            '& .timerHistory': {
                height: 225,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: 1.5
                },
                '&__heading': {

                    marginTop: 30,
                    marginBottom: 12,

                    '& h4': {
                        fontSize: 16,
                        color: '#656565',
                        textTransform: 'uppercase'
                    },

                    '& img': {
                        width: '15px',
                        marginBottom: '3px',
                        marginRight: '5px',
                    }
                },
                '&__item': {
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 13,
                    color: '#2c2c2c',
                    paddingTop: 9,
                    paddingBottom: 9,
                    '&--left': {
                        marginRight: 'auto',
                    },
                    '&--right': {
                        marginLeft: 'auto',
                    },
                    '&--heading': {
                        padding: '8px 12px',
                        backgroundColor: '#FAFAFA',
                        color: '#656565',
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                    },
                    '&--total': {
                        bottom: 0,
                        marginTop: 20,
                        color: 'black',
                        fontWeight: '500'
                    }
                }
            }
        }
    }
});

export default TimerPopUp