import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Images } from '../../assets/common';
import notifier from '../../components/notifier';
import { getErrorString, validateRegisterData } from '../../components/validator';
import { MESSAGE } from '../../constants/messages';
import { UPDATE_RESETPASS } from '../../services/api-service';
import PasswordStrengthMeter from '../register/pass-strength';
import './index.scss';
import { getParamsByName } from '../../lib/validators';


function SetNewPassword(props) {
    const [errors, updateErrors] = useState({});
    const [token, setToken] = useState('')
    const [password, updatePassword] = useState('');
    const [cpassword, updateConfirmPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [CpasswordShown, setCPasswordShown] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [loading, setLoader] = useState(false);
    const [passwordValidit, setPasswordValidit] = useState({
        minChar: null,
        number: null,
        lower: null,
        upper: null,
        specialChar: null
    });

    useEffect(() => {
        var str = window.location.search;
        var gettoken = getParamsByName('token');
        setToken(gettoken)
        //console.log("hy", gettoken); // b
        // Component Will mount code will go here
        return () => {
            // Component Will unmount will go here
        }
    });

    const resetPass = async () => {
        let email = ''
        let data = { email, password, cpassword };
        if (validateRegisterData(data).password) {
            notifier.warning('Password', validateRegisterData(data).password);
        } else if (validateRegisterData(data).cpassword) {
            notifier.warning('Password', validateRegisterData(data).cpassword);
        } else try {
            setLoader(true);
            const requestBody = {
                "token": token,
                "password": password
            }
            const response = await UPDATE_RESETPASS(requestBody);
            if (response.data.status === 'success') {
                notifier.success('Success', MESSAGE.SUCCESS.PASSWORD_CHANGED_SUCESSFULLY);
                setTimeout(() => {
                    props.history.push('/login');
                }, 1000);
            }
        } catch (e) {
            setLoader(false);
            const errorString = getErrorString(e);
            if (errorString === 'Session expired please login again !') {
                notifier.error('Error', 'Your reset password link is expired. Try again recover your password.', 10000);
                props.history.push('/forgot-password');
            } else {
                notifier.error('Error', errorString);
            }
        }
    }

    const handleInputChange = (event) => {
        const id = event.target.name;
        const value = event.target.value;
        switch (id) {
            case 'password':
                updatePassword(value);
                setPasswordValidit({
                    minChar: password.length >= 8 ? true : false,
                    number: isNumberRegx.test(password) ? true : false,
                    lower: LowerRegx.test(password) ? true : false,
                    upper: UpperRegx.test(password) ? true : false,
                    specialChar: specialCharacterRegx.test(password) ? true : false
                });
                break;
            case 'cpassword':
                updateConfirmPassword(value);
                break;
            default:
                console.warn('Something is not right !');
        }
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleCPasswordVisiblity = () => {
        setCPasswordShown(CpasswordShown ? false : true);
    };

    const isNumberRegx = /\d/;
    const LowerRegx = /(?=.*?[a-z])/;
    const UpperRegx = /(?=.*?[A-Z])/
    const specialCharacterRegx = /(?=.*[!@#$%^&*])/;

    return (
        <div className="forgot-pass-page">
            <div className="forgot-pass-logo">
                <Link to={`/`}>
                    <img src={Images.Logo} alt={"Logo"} />
                </Link>
            </div>
            <div className="forgot-pass-layout">
                <div className="new-pass-content-wrapper">
                    <div className="new-pass-content">
                        <h2>Set a strong password</h2>
                        <p>It’s a good idea to have a strong password that you don’t use elsewhere.</p>
                        <div className="login-form__control">
                            <div className={'field-container-pass'}>
                                <TextField label="Password" variant="outlined" name="password" onBlur={() => setPasswordFocused(false)} onFocus={() => setPasswordFocused(true)} onChange={handleInputChange} type={passwordShown ? "text" : "password"}
                                    value={password} />
                                <div className="eye">
                                    {passwordShown ?
                                        <Visibility onClick={togglePasswordVisiblity} /> :
                                        <VisibilityOff onClick={togglePasswordVisiblity} />
                                    }
                                </div>
                                {passwordFocused && (
                                    <div className="password-meter">
                                        <div class="arrow-right"></div>
                                        <p className="text-dark">Password must contain:</p>
                                        <div className="text-muted">
                                            <div className="pass-valid"> {password.length >= 8 ? <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} /> : "-"}{"   "}Have at least 8 characters</div>
                                            <div className="pass-valid"> {passwordValidit.number ? <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} /> : "-"}{"   "}Have at least 1 number</div>
                                            <div className="pass-valid">{passwordValidit.upper ? <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} /> : "-"}{"   "}One upper case</div>
                                            <div className="pass-valid"> {passwordValidit.lower ? <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} /> : "-"}{"   "}One lower case</div>
                                            <div className="pass-valid"> {passwordValidit.specialChar ? <DoneIcon style={{ fontSize: "14px", color: "#05CF5B" }} /> : "-"}{"   "}Have at least 1 special character from <strong>!@#$%^&*</strong></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="login-form__control">
                            <PasswordStrengthMeter password={password} />
                        </div>

                        <div className="login-form__control">
                            <div className={'field-container'}>
                                <TextField label="Confirm Password" variant="outlined" name="cpassword" error={errors.cpassword} helperText={errors.cpassword} onChange={handleInputChange} type={CpasswordShown ? "text" : "password"} value={cpassword} />
                                <div className="eye"> {CpasswordShown ? <Visibility onClick={toggleCPasswordVisiblity} /> : <VisibilityOff onClick={toggleCPasswordVisiblity} />}</div>
                            </div>
                        </div>
                        <div className="btn">
                            <button onClick={resetPass}>
                                {loading ? <img alt='' src={Images.ellipsis_loader} /> :
                                    <span> Submit </span>}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SetNewPassword;