import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom";
import './index.scss';
import { Images } from '../../../assets/common/index';
import RouterOutlet from './router-outlet'
import './index.scss'
import Hide from '../../../components/Hide';
import { BreakPoints } from '../../../lib/validators';

class EditProfile extends Component {
    constructor() {
        super();
        this.state = {
            editUi: 'superSkill',
            editSuperskill: [],
            publicView: true,
        };
    }


    handleClick = (type) => {
        switch (type) {
            case 'superSkill':
                this.setState({ editUi: type })
                break;
            case 'about':
                this.setState({ editUi: type })
                break;
            case 'addnew':
                this.setState({ editUi: type })
                break;
            default:
                console.warn('Something is not right !');
        }
    }

    editSuperskills = (superSkill) => {
        this.setState({ editSuperskill: superSkill }, () => {
            this.setState({ editUi: 'edit-superskill' })
        })
    }

    render() {
        // let { userDetails, fName, lName, checked, email, nickName, country, City, Nationality } = this.state;
        const createSuperskill = this.props.location.pathname.split('/')[3] === 'create-superskill'
        return (
            <div className="edit-page-content">
                <div className="filter-content">
                    <div className="edit-profile-container">
                        <h1>EDIT PROFILE</h1>
                        <NavLink exact to={`/home/edit-profile`} className={`superskill-container ${createSuperskill ? 'activeNavLink' : ''} `} activeClassName="activeNavLink">
                            <img src={Images.lightBulb} className="lightbulbimg" alt="lightbulbimg" />
                            Superskills
                        </NavLink>
                        <NavLink exact to={`/home/edit-profile/about`} className="superskill-container" activeClassName="activeNavLink">
                            <img src={Images.user} className="lightbulbimg" alt="userimg" />
                            About me
                        </NavLink>
                    </div>
                    <Hide type="down" size={BreakPoints.sm}>
                        <div className="public-view">
                            <Link to={`/${JSON.parse(localStorage.getItem('loggedInUser')).nickName}`} >See Public View</Link>
                        </div>
                    </Hide>
                </div>
                <>
                    <RouterOutlet  {...this.props} />
                </>
                <Hide type="up" size={BreakPoints.sm}>
                    <div className="public-view">
                        <Link to={`/${JSON.parse(localStorage.getItem('loggedInUser')).nickName}`} target="_blank">See Public View</Link>
                    </div>
                </Hide>
            </div>
        )
    }
}
export default EditProfile;
