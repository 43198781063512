import { Fade, makeStyles, Modal, useTheme, useMediaQuery } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import { useHashConnect } from "../../Hooks";
import { StyledBackdrop } from "../../lib/validators";
import { LINK_WALLET_ID, REQ_SIGN_TRANSACTION } from "../../services/api-service";
import notifier from "../notifier";
import "./index.scss";

const SummaryReportModal = (props) => {
  const theme = useTheme();
  const isDeviceIsSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { feesPrice } = props;
  const {
    feeBreakDown: { bidAmount, paymentType, taskDuration, taskbarFee, weeklyLimit },
  } = feesPrice;
  const classes = Styles();
  const [pay] = useState(false);

  const {
    connect,
    handleTransaction: transferHbar,
    installedExtensions,
    walletData: { accountIds },
    transactionResponse,
    resetTransactionResponse,
    acknowledge,
    resetAcknowledge,
    status,
    loading: connecting,
  } = useHashConnect();

  useEffect(() => {
    if (acknowledge) notifier.warning("Wallet Connected", "You can pay now!", 5000);
    if (resetAcknowledge) resetAcknowledge();
  }, [acknowledge]);

  useEffect(() => {
    if (transactionResponse && transactionResponse?.success) {
      notifier.success("Transaction Successful", "Hbar transferer successfully", 3000);
      handleClose();
    }
    if (transactionResponse && transactionResponse?.error) {
      notifier.error("Error while transaction", transactionResponse.error, 3000);
      handleClose();
    }
    if (resetTransactionResponse) resetTransactionResponse();
  }, [transactionResponse]);

  const handleClose = () => {
    props.onClose();
  };

  const connectHashpack = async () => {
    try {
      if (isDeviceIsSm) {
        return alert("Please connect with HashPack extension on your desktop to make a payment");
      }
      if (installedExtensions) {
        connect();
      } else {
        await sendMarkOFwalletInstall();
        // Taskbar Alert - Hashpack browser extension not installed, please click on <Go> to visit HashPack website and install their wallet on your browser
        alert(
          "Alert - HashPack browser extension not installed, please click on <<OK>> to visit HashPack website and install their wallet on your browser.  Once installed you might need to restart your browser for Taskbar to detect wallet extension first time."
        );
        window.open("https://www.hashpack.app");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendMarkOFwalletInstall = async () => {
    try {
      const requestBody = {
        isClickedOnWalletLink: true,
      };
      await LINK_WALLET_ID(requestBody);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTransaction = async ({ walletId, memo, amount }) => {
    console.log(walletId, memo, amount);
    try {
      const reqBody = {
        walletId,
        memo,
        amount,
      };
      const response = await REQ_SIGN_TRANSACTION(reqBody);
      console.log(response.data.result.transactionSign);
      // let transactionBytes = response.data.result.transactionSign.toBytes();
      transferHbar(response.data.result.transactionSign, accountIds[0]);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      aria-labelledby="Add hbars(ℏ) In Wallet"
      aria-describedby="hbars (ℏ) in Escrow Account"
      className={`${classes.modal} summaryModal`}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`summaryModal__paper ${classes.paper} ${!pay ? classes.confirm : ""}`}>
          <div className="summryPopup">
            <div className="summryDeatils">
              <div className="heading">
                <h4>
                  <img alt="" src={Images.summryReportIcon} />
                  Summary
                </h4>
              </div>
              <div className="entry">
                <div className="entry__item">
                  <span className="entry__item--left">Task Price</span>
                  <span className="entry__item--right">{bidAmount} ℏ(s)/hr</span>
                </div>
                {paymentType === "hourly" && (
                  <React.Fragment>
                    <div className="entry__item">
                      <span className="entry__item--left">Hours per week</span>
                      <span className="entry__item--right">* {weeklyLimit} Hours</span>
                    </div>
                    <div className="entry__item">
                      <span className="entry__item--left">Task duration</span>
                      <span className="entry__item--right">* {taskDuration} Weeks</span>
                    </div>
                  </React.Fragment>
                )}
                <div className="entry__item">
                  <span className="entry__item--left">Mytaskbar Fee</span>
                  <span className="entry__item--right">+{taskbarFee} &#8463; (s)</span>
                </div>
                <div className="entry__item entry__item--total">
                  <span className="entry__item--left">Total Amount</span>
                  <span className="entry__item--right">{feesPrice.totalAmount} &#8463; (s)</span>
                </div>
                <span className="hint">
                  {" "}
                  Please pay the <b>ℏ</b> amount to Escrow account in order to confirm the task. <br /> <br />
                  <b>
                    Note: the remaining <b>ℏ</b> amount will be refunded back to you if the task has successfully been completed before the above mentioned timeline estimate. Please pay the <b>ℏ</b>(s) amount to Escrow account in order to confirm the
                    task
                  </b>
                </span>
              </div>
            </div>

            {/* {pay ?'':''} */}

            <div className="qrcodebox">
              <div className="qrcodebox__content">
                <div className="qrcodebox__content__QRBox">
                  <span>{isEmpty(accountIds) ? "Pay with hashpack wallet" : `You are connected with ${accountIds[0]}`}</span>
                  {isEmpty(accountIds) ? (
                    <button onClick={connectHashpack} className="connect_hashpack_button" style={{ margin: "0 auto" }}>
                      <img src={Images.hashPackIcon} alt="Hahconnect" />
                      {connecting ? "Connecting...." : "Connect & pay"}
                    </button>
                  ) : (
                    <button className="connect_hashpack_button" onClick={() => handleTransaction({ walletId: accountIds[0], memo: feesPrice.memoCode, amount: feesPrice.totalAmount })} style={{ margin: "0 auto" }}>
                      <img src={Images.hashPackIcon} alt="Hahconnect" />
                      Pay with hashpack
                    </button>
                  )}
                  <div className="orDevider">Or</div>
                </div>
                <h5
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: 17,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Pay Manually
                </h5>
                <div className="esDetails">
                  <div className="account">
                    <span>Escrow Account Number</span>
                    <span> {feesPrice.accountId}</span>
                  </div>
                  <span className="divider"></span>
                  <div className="memoCode">
                    <span>Task Memo Code</span>
                    <span>{feesPrice.memoCode}</span>
                  </div>
                </div>
                <div className="hintInfo">
                  {/* <p>Enter this memo in order to let taskbar recognise the task
                against which it is paid.</p> */}
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Enter this <strong>memo code</strong> in order to let taskbar recognize the task against which it is paid.
                  </Alert>
                </div>
              </div>
              <span className="closeModal" onClick={handleClose}>
                <img alt="" src={Images.IconForCloseModal} />
              </span>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const Styles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 1000,
    // minHeight: 500,
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 6,
    // padding: '45px 45px 0 45px',
    position: "relative",
    "& .summryPopup": {
      display: "flex",
    },
    "& .qrcodebox": {
      width: "40%",
      borderLeft: "0.5px solid #dddddd",
      paddingTop: 15,
      paddingBottom: 10,
      "&__content": {
        width: "100%",
        "&--inst": {
          fontSize: 14,
          color: "#2C2C2C",
          marginBottom: 27,
          textAlign: "center",
          paddingLeft: 25,
          paddingRight: 25,
        },
        "&__QRBox": {
          textAlign: "center",
          marginBottom: 0,
          "& canvas": {
            padding: 10,
            borderRadius: 3,
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          },
          "& span": {
            fontSize: 13,
            color: "#2c2c2c",
            fontWeight: 700,
            display: "block",
            marginBottom: 15,
          },
          "& img": {
            marginRight: "10px",
            height: "24px",
            width: "auto",
          },
        },
        "&--EscrowDetails": {
          width: 190,
          height: "auto",
          textAlign: "center",
          fontSize: 12,
          color: "#2C2C2C",
          // padding: "12px",
          margin: "0  auto",
          display: "flex",
          flexFlow: "column",
          border: "1px solid #EBEBEB",
          borderRadius: 8,
          padding: 5,
          background: "#F7F7F7",
          "& span": {
            "&:last-child ,  &:nth-child(2)": {
              fontSize: 17,
              fontWeight: 500,
            },
            "&.border": {
              height: 2,
              background: "#2c2c2c",
            },
          },
        },
      },
      "& .closeModal": {
        position: "absolute",
        width: 36,
        height: 36,
        top: 20,
        right: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 3,
        cursor: "pointer",
        "& img": {
          width: 14,
          height: 14,
        },
        "&:hover": {
          backgroundColor: "#F0F0F0",
        },
      },
    },
    "& .summryDeatils": {
      width: "55%",
      borderRight: "0.5px solid #dddddd",
      paddingLeft: 45,
      paddingTop: 45,
      paddingRight: 45,
      "& .heading": {
        display: "flex",
        alignItems: "center",
        "& h4": {
          marginBottom: 0,
          fontSize: 16,
          color: "#2c2c2c",
          textTransform: "uppercase",
          "& img": {
            marginRight: 7,
          },
        },
      },
      "& .entry": {
        marginTop: 30,
        "&__item": {
          marginBottom: 8,
          "&--total": {
            borderTop: "1px solid #dddddd",
            borderBottom: "1px solid #dddddd",
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
          },
          "&>span": {
            fontSize: 14,
            fontWeight: 400,
          },
          display: "flex",
          alignItems: "center",
          "&--left": {
            marginRight: "auto",
          },
          "&--right": {
            marginleft: "auto",
          },
        },
      },
    },
    "& .buttons": {
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
      "& button": {
        margin: "0 7.5px",
        width: 148,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        outline: "none!important",
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 3,
        "&.cancel": {
          color: "#ED0B0B",
          border: "1px solid #ED0B0B",
        },
        "&.del": {
          backgroundColor: "#6DC7BE",
          color: "white",
          border: "none",
          "&:hover": {
            backgroundColor: "#4DACA2",
          },
        },
      },
    },
    "& .total": {
      fontWeight: 600,
      fontSize: 20,
      marginTop: 15,
      color: "#2c2c2c",
      textAlign: "center",
      marginBottom: 25,
    },
    "& .footer": {
      borderRadius: 6,
      display: "flex",
      // alignItems:'center',
      backgroundColor: "#F05A22",
      height: 65,
      "&__left": {
        color: "white",
        paddingTop: 5,
        paddingLeft: 45,
        "& p": {
          fontSize: 15,
          fontWeight: 600,
          marginBottom: 5,
        },
        "& span": {
          fontSize: 13,
          color: "#ffffff",
        },
      },
      "&__right": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40%",
        fontSize: 26,
        color: "#ffffff",
        letterSpacing: 11.2,
        marginLeft: "auto",
      },
    },
    "& .hint": {
      // backgroundColor: '#FEC21C1A',
      fontStyle: "italic",
      fontSize: 12,
      color: "#2c2c2c",
      display: "inline-block",
      lineHeight: 1.25,
      letterSpacing: "1.1px",
    },
  },
  confirm: {
    minHeight: 340,
  },
});

export default SummaryReportModal;
