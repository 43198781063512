import { makeStyles } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import {
  paginationLimit
} from "../validator";
import notifier from "../notifier";

const pagination_theme = '#F05A22';

const Pagination = (props) => {
  const {
    //Varient,
    neighbours,
    totalRecord,
    pageLimit,
    showControls,
    showFirstLast,
    showJump,
    pageNumber
  } = props;

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [jumpInput, setJumpInput] = useState(0);
  const innerNeighbours = neighbours ? neighbours : 2;

  useEffect(() => {
    setCurrentPage(pageNumber)
    setJumpInput(pageNumber)
    setTotalPages(Math.ceil(totalRecord / (pageLimit ? pageLimit :paginationLimit)))
  }, [pageNumber, totalRecord , pageLimit])

  //Handle next
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    setJumpInput(currentPage + 1);
    sendCurrentPage(currentPage + 1);
  };

  const sendCurrentPage = (currentPage) => {
    if (props.sendCurrentPage) {
      props.sendCurrentPage(currentPage);
    }
  };

  //Handle Prev
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
    setJumpInput(currentPage - 1);
    sendCurrentPage(currentPage - 1);
  };

  // Handle click on Current Page
  const handPageClick = (currentPage) => {
    setCurrentPage(currentPage);
    setJumpInput(currentPage);
    sendCurrentPage(currentPage);
  };

  //Handle JumpInput
  const handlejumpInput = (event) => {
    setJumpInput(event.target.value);
  };

  //handleJumpInput
  const handleJump = () => {
    if (parseInt(jumpInput) <= totalPages) {
      setCurrentPage(parseInt(jumpInput));
      sendCurrentPage(parseInt(jumpInput));
    } else {
      notifier.warning("Warning", "Invalid Page Input. It must be less than or equal to total pages.");
    }
  };

  //Handle First and last
  const handleFnLast = (type) => {
    if (type === "first") {
      setCurrentPage(1);
      sendCurrentPage(1);
    } else {
      setCurrentPage(totalPages);
      sendCurrentPage(totalPages);
    }
  };

  const classes = Styles();

  return (
    <div className={classes.preroot}>
      <ul className={classes.root}>
        {!showFirstLast ? null : (
          <li className="f_n_l">
            <button disabled={currentPage === 1 ? true : false} onClick={handleFnLast.bind(this, "first")}>First</button>
          </li>
        )}
        {currentPage <= 1 || !showControls ? null : (
          <li className="prev">
            <button onClick={() => handlePrev()}>
              <KeyboardBackspaceIcon />
            </button>
          </li>
        )}
        {[...Array(2 * innerNeighbours + 1)].map((item, index) => {
          if (
            currentPage - innerNeighbours + index > 0 &&
            currentPage - innerNeighbours + index <= totalPages
          ) {
            return (
              <li
                key={`pagination${index}`}
                className={
                  currentPage - innerNeighbours + index === parseInt(currentPage)
                    ? `c${currentPage - innerNeighbours + index} active`
                    : `c${currentPage - innerNeighbours + index}`
                }
              >
                <button
                  onClick={handPageClick.bind(
                    this,
                    currentPage - innerNeighbours + index
                  )}
                >
                  {currentPage - innerNeighbours + index}
                </button>
              </li>
            );
          }
          return null;
        })}
        {currentPage >= totalPages || !showControls ? null : (
          <li className="next">
            <button onClick={() => handleNext()}>
              <KeyboardBackspaceIcon />
            </button>
          </li>
        )}
        {!showFirstLast ? null : (
          <li className="f_n_l">
            <button disabled={currentPage === totalPages ? true : false} onClick={handleFnLast.bind(this, "last")}>Last</button>
          </li>
        )}
      </ul>
      {showJump ?
        <div className="des">
          <b>Showing page</b>
          <input
            name="jumpInput"
            className="currentPage"
            value={jumpInput}
            onChange={handlejumpInput}
          />
        OF {totalPages}
          <button onClick={handleJump}>Jump</button>
        </div>
        : null}
    </div>
  );
};

const Styles = makeStyles({
  preroot: {
    display: "flex",
    justifyContent: 'flex-end',
    marginRight: '20px',
    "& .des": {
      paddingLeft: 24,
      marginLeft: 24,
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      borderLeft: "1px solid #dddddd",
      "& .currentPage": {
        display: "inline-block",
        width: 50,
        height: 30,
        border: "1px solid #DADCE0",
        borderRadius: 4,
        textAlign: "center",
        margin: "0 10px",
      },
      "& button": {
        width: 50,
        height: 30,
        alignItem: "center",
        justifyContent: "center",
        backgroundColor: pagination_theme,
        color: "#FFFFFF",
        border: "none",
        borderRadius: 4,
        marginLeft: 10,
      },
    },
  },
  root: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    "& li": {
      marginLeft: 5,
      marginRight: 5,
      "&.active": {
        "& button": {
          backgroundColor: pagination_theme,
          color: "#ffffff",
        },
      },
      "&.f_n_l": {
        marginLeft: 15,
        marginRight: 15,
        "& button": {
          backgroundColor: "transparent",
          color: pagination_theme,
          fontWeight: 500,
          '&:disabled': {
            color: '#AAAAAA'
          }
        },
      },
      "& button": {
        border: "none",
        width: 26,
        height: 26,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        outline: "none",
      },
    },
    "& .next": {
      marginLeft: 10,
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
    "& .prev": {
      marginRight: 10,
    },
    "& .next, & .prev": {
      "& button": {
        backgroundColor: "transparent",
      },
      "& svg": {
        width: 26,
        fill: pagination_theme,
      },
    },
  },
});

export default Pagination;
