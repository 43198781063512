import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import BidNow from './CreateBid';
import TaskFinder from './Find-Taskers';
import ListTask from './list-tasks';
import ProfileDescription from './Profile-Description';
import TaskCreator from './Task-creator';
import TaskerDetails from './Tasker-details';


class RouterOutlet extends Component {

  render() {
    const { match } = this.props;
    return (
      <Switch>
        
        <Route path={`${match.url}/find-tasker`} render={props => (<TaskFinder {...props} />)} />
        <Route path={`${match.url}/list-task`} render={props => (<ListTask {...props} />)} />
        <Route path={`${match.url}/profile-description`} render={props => (<ProfileDescription {...props} />)} />
        <Route path={`${match.url}/TaskerDetails`} render={props => (<TaskerDetails {...props} />)} />
        <Route path={`${match.url}/actionOnTasks`} render={props => (<BidNow {...props} />)} />
        <Route path={`${match.url}/creator`} render={props => (<TaskCreator {...props} />)} />

        <Route path={`${match.url}/:taskId`} render={props => (<BidNow {...props} />)} />
        <Route path={`${match.url}/`} render={props => (<ListTask {...props} />)} />
      </Switch>
    );
  }
}

export default RouterOutlet;
