import { makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import React, { useEffect } from "react";
import "./index.scss";

// ***** Default svg data
const defaultSvgWidth = 225;
const defaultSvgHeight = 165;
const defaultSvgMargin = { top: 10, right: 5, bottom: 30, left: 5 };

const BarChart = (props) => {
  const { data, width, height, margin, colors, id } = props;

  const svgWidth = width ? width : defaultSvgWidth;
  const svgHeight = height ? height : defaultSvgHeight;
  const svgMargin = {
    top: margin && margin.top ? margin.top : defaultSvgMargin.top,
    right: margin && margin.right ? margin.right : defaultSvgMargin.right,
    bottom: margin && margin.bottom ? margin.bottom : defaultSvgMargin.bottom,
    left: margin && margin.left ? margin.left : defaultSvgMargin.left,
  };

  const barPadding = 0.4;
  const axisTicks = { qty: 0, outerSize: 0, dateFormat: "%m-%d" };

  const yScalInputs = Object.keys(data[0])
    .map((item) => {
      return typeof data[0][item] === "number" ? item : false;
    })
    .filter(Boolean);

  //   //console.log("groupItem", yScalInputs);

  useEffect(() => {
    drawChart();
  }, [data]);

  const drawChart = () => {
    d3.select(`#${id}`).select("svg").remove();

    //ToolTipBox
    const toolTip = d3.select(`#${id}`).append('div');
    toolTip.attr('class' , 'hide chartToolTip').style("opacity", 0);

    // Create new svg
    const svg = d3
      .select(`#${id}`)
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .append("g")
      .attr("class", "scrollTest")
      .style("fill", "#ffffff")
      .attr("transform", `translate(${svgMargin.left},${svgMargin.top})`);

    //Add Scales
    const xScale0 = d3
      .scaleBand()
      .range([
        0,
        60 * data.length < svgWidth - svgMargin.left - svgMargin.right
          ? svgWidth - svgMargin.left - svgMargin.right
          : 60 * data.length,
      ])
      .padding(barPadding);

    const xScale1 = d3.scaleBand();

    const yScale = d3
      .scaleLinear()
      .range([svgHeight - svgMargin.top - svgMargin.bottom, 0]);

    //Adding Axis
    const xAxis = d3.axisBottom(xScale0).tickSizeOuter(axisTicks.outerSize);
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(axisTicks.qty)
      .tickSizeOuter(axisTicks.outerSize);

    const zoomed = (event) => {
      let tx;
      tx = event.transform.x;
      if (xScale0.range()[1] === svgWidth) {
        return;
      }
      if (xScale0.range()[1] - svgWidth + tx > 0 && tx <= 0) {
        innerSvg
          .selectAll(".title")
          .attr("transform", function (d) {
            return `translate(${xScale0(d.title) + tx},0)`;
          });

        innerSvg
          .select(".x.axis")
          .attr(
            "transform",
            `translate(${tx},${svgHeight - svgMargin.top - svgMargin.bottom})`
          );
      }
    };

    const zoomstarted = () => {
      d3.select(this).attr("cursor", "grabbing");
    };
    const zoomend = () => {
      d3.select(this).attr("cursor", "grab");
    };

    //Adding Zoom Pannel
    svg
      .append("rect")
      .attr("class", "zoom-panel")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .call(
        d3
          .zoom()
          .on("start", zoomstarted)
          .on("zoom", zoomed)
          .on("end", zoomend)
      );

    //Defs
    const defs = svg
      .append("svg")
      .attr("width", 0)
      .attr("height", 0)
      .append("defs");

    //ClipPath
    defs
      .append("clipPath")
      .attr("id", "clipper")
      .append("rect")
      .style("fill", "transparent")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", svgWidth)
      .attr("height", svgHeight);

    const innerSvg = svg
      .append("g")
      .attr("class", "stacked-bar-chart")
      .attr("transform", `translate(${svgMargin.left},${svgMargin.top})`)
      .attr("class", "chart-canvas")
      .attr("clip-path", "url(#clipper)");

    //Mapping the Axis with data
    xScale0.domain(data.map((d) => d.title));
    xScale1.domain(yScalInputs).range([0, xScale0.bandwidth()]);
    yScale.domain([
      0,
      d3.max(data, (d) => Math.max(...yScalInputs.map((item) => d[item]))),
    ]);

    //Create and Transform Group Element
    const title = innerSvg
      .selectAll(".title")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "title")
      .attr("transform", (d) => `translate(${xScale0(d.title)},0)`);

    //Appending The Bars
    yScalInputs.map((item, i) => {
      title
        .selectAll(`.bar.${item}`)
        .data((d) => [d])
        .enter()
        .append("rect")
        .attr("class", `bar ${item}`)
        .style("fill", colors[i])
        .attr("x", (d) => xScale1(item))
        .attr("y", (d) => yScale(d[item]))
        .attr("width", xScale1.bandwidth())
        .attr("height", (d) => {
          return svgHeight - svgMargin.top - svgMargin.bottom - yScale(d[item]);
        }).on("mouseover", function (e,d) {
          //console.log('hover',d)
          toolTip.transition().attr('class','show chartToolTip').duration(500).style("opacity", 1).text( d[item]);
  
        })
        .on("mousemove", function (event) {
         // console.log('move',event)
          toolTip.style("left", (event.pageX ) + "px")
                .style("top", (event.pageY ) + "px");
        })
        .on("mouseout", function () {
          toolTip.transition().attr('class','hide chartToolTip').duration(500).style("opacity",  1e-6);
        });
        return false;
    });

innerSvg
      .append("g")
      .attr("class", "x axis")
      .attr(
        "transform",
        `translate(0,${svgHeight - svgMargin.top - svgMargin.bottom})`
      )
      .call(xAxis);

    // Add the Y Axis
   innerSvg.append("g").attr("class", "y axis").call(yAxis);
  };
  const classes = Styles();
  return (
    <div id={id} className={classes.root}>
      <div>
        <p>Months</p>
        {yScalInputs.map((item, i) => {
          return (
            <span className="fillColor" key={i}>
              <span
                className="colorBox"
                style={{ backgroundColor: colors[i] }}
              ></span>
              <span className="label">{item}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

const Styles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column-reverse",
    "& >div": {
      fontSize: 11,
      marginBottom: 7,
      "& span": {
        display: "inline-block",
        fontSize: 11,
      },
      width: "100%",
      "& p": {
        marginBottom: "10px!important",
        color: "#2c2c2c",
        fontWeight: 600,
      },
      "& .colorBox": {
        display: "inline-block",
        width: 10,
        height: 10,
        marginRight: 7,
      },
      "& .label": {
        marginRight: 15,
      },
    },
    '& .chartToolTip':{
      position:'fixed',
      width:'auto',
      height:'auto',
      marginBottom:0,
     '&.show':{
       backgroundColor:'rgba(0,0,0,.7)',
       color:'#fff',
       padding:5,
       transform:'translate(-50% , -100px)',
       borderRadius:3,
       minWidth:25,
     }
    }
  },
});

export default BarChart;
