import React, { useCallback } from "react";
import { useDispatch, batch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import useDataLayer from "../../../Hooks/useDataLayer";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import useConfirmationModal from "../../../context/ConfirmationModal/useConformationModal";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hook";
import { addTaskForReview, getChatIdForTaskAsync, getSummaryReportAsync, getBidDetailsAsync, getTasksAsync, openPromoteTaskModal, openNegotiationModal } from "../../../redux/features/my-tasks/myTasksSlice";
import { Task, TaskActionString, PaymentTerms } from "../../../types";
import { CANCEL_TASK, RAISE_DISPUTE, APPROVE_TASK_COMPLETE, CLOSE_BIDDING } from "../../../services/api-service";
import notifier from "../../../components/notifier";
import { MESSAGE } from "../../../constants/messages";
import { getErrorString } from "../../../components/validator";

export const checkBidAllowed = (paymentTerms: PaymentTerms) => {
  const { bidExpiryDate, fixed, hourly, paymentType } = paymentTerms;
  if (paymentType === "fixed") {
    return moment(fixed?.applyBefore ?? bidExpiryDate).isAfter(Date.now());
  } else {
    return moment(hourly?.applyBefore ?? bidExpiryDate).isAfter(Date.now());
  }
};

export const useTaskOperation = () => {
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { openModal, setSuccessCallBack, closeModal, setLoading } = useConfirmationModal();

  const { addEventToDataLayer } = useDataLayer();

  const {
    taskUIView: { section, taskStatus },
  } = useAppSelector((state) => state.myTask);

  const reaLoadTask = () => {
    dispatch(getTasksAsync({ myTasks: { section, taskStatus } }));
  };

  const handleViewBids = useCallback(
    (task: Task, type: "bids" | "proposals") => {
      history.push({
        pathname: `/home/my-tasks/${type}/${task._id}`,
        state: {
          taskTitle: task.heading,
        },
      });
    },
    [history]
  );

  //Handle cancel task API call
  const handleCancelTask = async (event: React.MouseEvent, taskId: string) => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await CANCEL_TASK(requestBody);
      if (response.data.status === "success") {
        notifier.success(MESSAGE.SUCCESS.TASK_CANCELLED_SUCESSFULLY);
        setLoading(false);
        reaLoadTask();
        closeModal();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      closeModal();
      notifier.error("Error", getErrorString(error));
    }
  };

  //Raise dispute API call
  const handleRaiseDispute = async (event: React.MouseEvent, taskId: string) => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await RAISE_DISPUTE(requestBody);
      if (response.data.status === "success") {
        notifier.success(MESSAGE.SUCCESS.DISPUTE_RAISED_SUCESSFULLY);
        setLoading(false);
        reaLoadTask();
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      notifier.error("Error", getErrorString(error));
    }
  };

  //Approve task api call
  const approveTask = async (taskId: string) => {
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await APPROVE_TASK_COMPLETE(requestBody);
      if (response.data.status === "success") {
        if (section === "created") {
          notifier.success(MESSAGE.SUCCESS.APPROVED_SUCESSFULLY);
          addEventToDataLayer("completed_task");
        } else {
          notifier.success(MESSAGE.SUCCESS.MARKED_COMPLETED_SUCESSFULLY);
        }
        reaLoadTask();
      }
    } catch (error) {
      notifier.error("Error", getErrorString(error));
    }
  };

  // Approve close close bidding API call
  const handleCloseBiding = async (taskId: string) => {
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await CLOSE_BIDDING(requestBody);
      if (response.data.status === "success") {
        notifier.success(MESSAGE.SUCCESS.BIDDING_CLOSED_SUCESSFULLY);
        reaLoadTask();
      }
    } catch (error) {
      notifier.error("Error", getErrorString(error));
    }
  };

  //Return function
  return async ({ task, actionString }: { task: Task; actionString: TaskActionString }) => {
    switch (actionString) {
      case "VIEW_BID":
        handleViewBids(task, "bids");
        break;

      case "VIEW_PROPOSALS":
        handleViewBids(task, "proposals");
        break;

      case "SHARE_TASK":
        const taskShortUrl = `https://tsk.bar/t/${task.numericId}`;
        const textContent = `I completed my #Hedera22 Hackathon quest. See my results on @mytaskbar. \n 👇🏼 \n ${taskShortUrl} \n #H22Quest`;
        navigator.clipboard.writeText(textContent);
        if (navigator.share) {
          navigator.share({
            title: "Taskbar task",
            text: textContent,
            url: taskShortUrl,
          });
        }
        notifier.success("Task url is copied to clipboard", `https://tsk.bar/t/${task.numericId}`);
        break;

      case "EDIT_TASK":
        history.push({
          pathname: `/home/edit-task`,
          state: {
            task,
          },
        });
        break;

      case "PROMOTE_TASK":
        if (checkBidAllowed(task.paymentTerms)) appDispatch(openPromoteTaskModal({ ...task }));
        else notifier.warning("Warning: Bid Closed", "Bid is closed for this task.");
        break;

      case "INVITE_TASKER":
        if (checkBidAllowed(task.paymentTerms)) history.push(`/home/favourite/taskers/?taskId=${task._id}`);
        else notifier.warning("Warning: Bid Closed", "Bid is closed for this task.");
        break;

      case "CANCEL_TASK":
        openModal({
          open: true,
          message: "Are you Sure ? You want to Cancel this task",
          successBtnLabel: "Cancel Task",
          cancelBtnLabel: "Not now",
        });
        setSuccessCallBack(() => (event: React.MouseEvent) => handleCancelTask(event, task._id));
        break;

      case "PAY_NOW":
        dispatch(getSummaryReportAsync({ bidId: task.finalBid ?? "", pay: true }));
        break;

      case "RAISE_DISPUTE":
        openModal({
          open: true,
          message: "`Are you Sure ? You want to raise dispute`",
          successBtnLabel: "Raise Dispute",
          cancelBtnLabel: "Cancel",
        });
        setSuccessCallBack(() => (event: React.MouseEvent) => handleRaiseDispute(event, task._id));
        break;

      case "SEND_MESSAGES":
        dispatch(getChatIdForTaskAsync(task._id));
        break;

      case "MARK_COMPLETE":
        approveTask(task._id);
        break;

      case "WITHDRAW":
        openModal({
          open: true,
          message: `Are you Sure ? You want to withdrow your ${task.paymentTerms.biddingEnabled ? "bid." : "application."}`,
          successBtnLabel: `Withdrow ${task.paymentTerms.biddingEnabled ? "bid" : "application"}`,
          cancelBtnLabel: "Cancel",
        });
        setSuccessCallBack(() => (event: React.MouseEvent) => handleCancelTask(event, task._id));
        break;

      case "CLOSE_BIDDING":
        if (checkBidAllowed(task.paymentTerms)) handleCloseBiding(task._id);
        else notifier.warning("Warning: Bid Closed", "Bid is closed for this task.");
        break;

      case "WRITE_REVIEW":
        appDispatch(addTaskForReview(task));
        break;

      case "NEGOTIATE":
        batch(async () => {
          dispatch(getBidDetailsAsync(task._id));
          appDispatch(openNegotiationModal());
        });
        break;
      case "EMAIL_ADMIN":
        navigator.clipboard.writeText("hello@mytaskbar.io");
        notifier.info("Admin email copied !", "hello@mytaskbar.io", 6000);

        window.location.href = "mailto:hello@mytaskbar.io";
        break;

      default:
        return null;
    }
  };
};
