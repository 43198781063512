import React from 'react';
import { Tabs ,Tab, withStyles , Box} from "@material-ui/core";
import PropTypes from 'prop-types';


export const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #6dc7be',
    },
    indicator: {
      backgroundColor: '#6dc7be',
    },
  })(Tabs);
  
  export const  AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#6dc7be',
        opacity: 1,
      },
      '&$selected': {
        color: '#6dc7be',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#6dc7be',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  export const TabPanel = (props)  => {
    const { children, value, index, boxPadding ,  ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`pointSystem-tabpanel-${index}`}
        aria-labelledby={`pointSystem-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={boxPadding}>
           {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };