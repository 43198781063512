import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import HeaderBoard from "../home/header-board";
import "./index.scss";

const TermsOfUse = (props) => {
  return (
    <div className="terms-page">
      <Header {...props}  />
      <div className="terms-page-container">
      {localStorage.getItem('currentUser') && localStorage.getItem('currentUser').trim() !=='' ?
        <HeaderBoard {...props}/> :null }
        <div className="terms-page-content">
          <Paper style={{padding: 15, marginBottom:30}} component="article">
            <Typography variant="h4" component="h1" style={{marginBottom: 12}}>
              Terms of Use
            </Typography>
            <Typography variant="body1" style={{marginBottom: 12}}>
              Taskbar Limited operates the{" "}
              <Link to={`www.mytaskbar.com`} target="_blank">
                mytaskbar.com
              </Link>{" "}
              website and various associated social media accounts and
              applications (the “Taskbar Site”). Taskbar Limited provides the
              Taskbar Site subject to the following terms of use conditional on
              your acceptance without modification of the terms, conditions, and
              notices contained herein (the "Terms"). Your use of the Taskbar
              Site constitutes your agreement to all such Terms. Please read
              these terms carefully, and keep a copy of them for your reference.
            </Typography>
            <Typography variant="body1" style={{marginBottom: 12}}>
              The Taskbar Site operates as a marketplace to allow and facilitate
              users to advertise their services, connect and contract with each
              other to carry out and pay for tasks. Taskbar does not have any
              role in the tasks created on the Taskbar Site, does not control
              how tasks are created, does not approve or reject tasks or other
              transactions on the Taskbar network, and does not have the ability
              to modify or cancel tasks or related payments. Taskbar does not
              have the power to censor, restrict, or curate tasks on the Taskbar
              Site and does not have the ability to spend funds that are held in
              escrow.
            </Typography>
            <Typography variant="body1" style={{marginBottom: 12}}>
              PLEASE READ THESE TERMS CAREFULLY. THIS WEBSITE AND ANY OTHER
              ASSOCIATED WEBSITES, AND THE INFORMATION ON IT ARE CONTROLLED BY
              TASKBAR. THESE TERMS OF USE GOVERN THE USE OF THE TASKBAR SITE AND
              APPLY TO ALL INTERNET USERS VISITING THE TASKBAR SITE BY ACCESS OR
              USING THE TASKBAR SITE IN ANY WAY, INCLUDING USING THE SERVICES
              AND RESOURCES AVAILABLE OR ENABLED VIA THE TASKBAR SITE (EACH A
              "SERVICE" AND COLLECTIVELY, THE "SERVICES"). BY BROWSING THE
              TASKBAR SITE, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND,
              AND AGREE TO BE BOUND BY THE TERMS OF USE, (2) YOU ARE OF LEGAL
              AGE TO FORM A BINDING CONTRACT WITH TASKBAR, AND (3) YOU HAVE THE
              AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY OR ON BEHALF
              OF COMPANY YOU HAVE NAMED AS THE USER, AND TO BIND THAT COMPANY TO
              THE TERMS OF USE. THE TERM "YOU" REFERS TO THE INDIVIDUAL OR LEGAL
              ENTITY, AS APPLICABLE, IDENTIFIED AS THE USER WHEN YOU'RE
              REGISTERED ON THE TASKBAR SITE. IF YOU DO NOT AGREE TO BE BOUND BY
              THE TERMS OF USE, YOU MAY NOT ACCESS OR USE THIS TASKBAR SITE OR
              THE SERVICES.
            </Typography>
            <Typography variant="body1" style={{marginBottom: 12}}>
              PLEASE BE AWARE THAT THE DISPUTE RESOLUTION SECTION OF THIS
              AGREEMENT, BELOW, CONTAINS PROVISIONS GOVERNING HOW CLAIMS THAT
              YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING,
              WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED PRIOR
              TO THE EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, IT
              CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED
              EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING
              AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
              AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND
              SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF
              OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
              PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A
              COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. ANY DISPUTE
              OR CLAIM RELATING IN ANY WAY TO YOUR USE OF THE TASKBAR SITE WILL
              BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF ENGLAND &
              WALES.
            </Typography>
            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                {" "}
                Electronic Communications
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You consent to receive electronic communications and you agree
                that all agreements, notices, disclosures and other
                communications that we provide to you electronically, via the
                Taskbar Site, satisfy any legal requirement that such
                communications be in writing. The foregoing does not affect your
                statutory rights. If you are under 18, you may not use the
                Taskbar Site unless you have the permission of your parent or
                guardian.
              </Typography>
            </section>
            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                {" "}
                Updates
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You understand that the Taskbar Site and the related services
                are evolving. As a result, Taskbar may require you to accept
                updates to the Taskbar Site and the related services that you
                have previously installed or used. You acknowledge and agree
                that Taskbar may update the Taskbar Site with or without
                notifying you.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                User Services
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                Services made available via the Taskbar Site relating to listed
                tasks are delivered by third party users, not by Taskbar.
                Accordingly, Taskbar is not responsible for the provision of
                such user services and does not accept any liability for the
                same.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                The Taskbar Site may also contain links to other websites
                ("Linked Sites"). The Linked Sites are not under the control of
                Taskbar and Taskbar is not responsible for the contents of any
                Linked Site, including without limitation any link contained in
                a Linked Site, or any changes or updates to a Linked Site. The
                inclusion of any link does not imply endorsement by Taskbar of
                the site or any association with its operators.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Intellectual Property
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You are granted a non-exclusive, non-transferable, revocable
                license to access and use Taskbar Site strictly in accordance
                with these terms of use. As a condition of your use of the
                Taskbar Site, you warrant to Taskbar that you will not use the
                Taskbar Site for any purpose that is unlawful or prohibited by
                these Terms. You may not use the Taskbar Site in any manner that
                could damage, disable, overburden, or impair the Taskbar Site or
                interfere with any other party's use and enjoyment of the
                Taskbar Site. You may not obtain or attempt to obtain any
                materials or information through any means not intentionally
                made available or provided for through the Taskbar Site.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                All content included on the Taskbar Site, such as text,
                graphics, logos, images, as well as the compilation thereof, and
                any software used on the Taskbar Site, is the property of
                Taskbar or its suppliers and protected by copyright, trademark
                and other laws that protect intellectual property and
                proprietary rights. You agree to observe and abide by all
                copyright and other proprietary notices or other restrictions
                contained in any such content and will not make any changes
                thereto.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You will not modify, publish, transmit, reverse engineer,
                participate in the transfer or sale, create derivative works, or
                in any way exploit any of the content, in whole or in part,
                found on the Taskbar Site. Taskbar content is not for resale.
                Your use of the Taskbar Site does not entitle you to make any
                unauthorized use of any protected content, and in particular you
                will not delete or alter any proprietary rights or attribution
                notices in any content. You will use protected content solely
                for your personal use, and will make no other use of the content
                without the express written permission of Taskbar and the
                copyright owner. You agree that you do not acquire any ownership
                rights in any protected content.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You will not modify, publish, transmit, reverse engineer,
                participate in the transfer or sale, create derivative works, or
                in any way exploit any of the content, in whole or in part,
                found on the Taskbar Site. Taskbar content is not for resale.
                Your use of the Taskbar Site does not entitle you to make any
                unauthorized use of any protected content, and in particular you
                will not delete or alter any proprietary rights or attribution
                notices in any content. You will use protected content solely
                for your personal use, and will make no other use of the content
                without the express written permission of Taskbar and the
                copyright owner. You agree that you do not acquire any ownership
                rights in any protected content.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                International Users
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                The Taskbar Site may be accessed from countries around the world
                and may contain references to Services and content that are not
                available in your country. The Taskbar Site is controlled and
                offered by Taskbar from Dubai, United Arab Emirates. Taskbar
                makes no representations that the Taskbar Site is appropriate or
                available for use in other locations. Those who access or use
                the Services or content from other countries do so of their own
                volition and are responsible for compliance with their
                applicable local laws.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Indemnification
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You agree to indemnify, defend and hold harmless Taskbar, its
                officers, directors, employees, agents and third parties, for
                any losses, costs, liabilities and expenses (including
                reasonable attorneys' fees) relating to or arising out of your
                use of or inability to use the Taskbar Site or services, any
                postings or submissions made by you or other users, your
                violation of any terms of this Agreement or your violation of
                any rights, including intellectual property rights, of a third
                party, or your violation of any applicable laws, rules or
                regulations. Taskbar reserves the right, at its own cost, to
                assume the exclusive defense and control of any matter otherwise
                subject to indemnification by you, in which event you will fully
                cooperate with Taskbar in asserting any available defenses.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Disclaimer
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
                AVAILABLE THROUGH THE TASKBAR SITE MAY INCLUDE INACCURACIES OR
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. TASKBAR AND/OR ITS SUPPLIERS MAY MAKE
                IMPROVEMENTS AND/OR CHANGES IN THE TASKBAR SITE AT ANY TIME.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                TASKBAR AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
                SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
                OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                GRAPHICS CONTAINED ON THE TASKBAR SITE FOR ANY PURPOSE. TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
                TASKBAR AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
                CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES
                OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE AND NON-INFRINGEMENT.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                SHALL TASKBAR AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
                INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES
                OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES
                FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
                CONNECTED WITH THE USE OR PERFORMANCE OF THE TASKBAR SITE, WITH
                THE DELAY OR INABILITY TO USE THE TASKBAR SITE OR RELATED
                SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR
                FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                GRAPHICS OBTAINED THROUGH THE TASKBAR SITE, OR OTHERWISE ARISING
                OUT OF THE USE OF THE TASKBAR SITE, WHETHER BASED ON CONTRACT,
                TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF TASKBAR
                OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
                DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
                DISSATISFIED WITH ANY PORTION OF THE TASKBAR SITE, YOUR SOLE AND
                EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE TASKBAR SITE.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                General
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                Taskbar reserves the right, in its sole discretion, to terminate
                your access to the Taskbar Site and its related services or any
                portion thereof at any time, without notice. This agreement is
                governed by the laws of England & Wales.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                Unless otherwise specified herein, this agreement constitutes
                the entire agreement between the user and Taskbar with respect
                to the Taskbar Site and it supersedes all prior or
                contemporaneous communications and proposals, whether
                electronic, oral or written, between the user and Taskbar with
                respect to the Taskbar Site. A printed version of this agreement
                and of any notice given in electronic form shall be admissible
                in judicial or administrative proceedings based upon or relating
                to this agreement to the same extent and subject to the same
                conditions as other business documents and records originally
                generated and maintained in printed form. It is the express wish
                to the parties that this agreement and all related documents be
                written in English.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Dispute Resolution
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You agree that any dispute or claim relating in any way to your
                access or use of the Taskbar Site, to any Services through the
                Taskbar Site, or to any aspect of your relationship with
                Taskbar, will be resolved by binding arbitration, rather than in
                court, except that (1) you may assert claims in small claims
                court if your claims qualify; and (2) you or Taskbar may seek
                equitable relief in court for infringement or other misuse of
                intellectual property rights (such as trademarks, trade dress,
                domain names, trade secrets, copyrights, and patents). The
                arbitration will be conducted in accordance with and subject to
                the rules of the London Court of International Arbitration and
                seated in DIFC, Dubai, United Arab Emirates. This Arbitration
                Agreement shall apply, without limitation, to all claims that
                arose or were asserted before the Effective Date of this
                Agreement or any prior version of this Agreement and will
                survive the termination of your relationship with Taskbar.
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                You may choose to have the arbitration conducted by telephone,
                based on written submissions, or in person in the country where
                you live or at another mutually agreed location. Any judgment on
                the award rendered by the arbitrator may be entered in any court
                of competent jurisdiction.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Waiver of Jury Trial
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                YOU AND COMPANY HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY
                RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A
                JURY. You and Taskbar are instead electing that all claims and
                disputes shall be resolved by arbitration under this Arbitration
                Agreement.
              </Typography>
            </section>

            <section>
              <Typography variant="h6" style={{marginBottom: 12}}>
                Changes to Terms
              </Typography>
              <Typography variant="body1" style={{marginBottom: 12}}>
                Taskbar reserves the right, in its sole discretion, to change
                the Terms under which Taskbar Site is offered. The most current
                version of the Terms will supersede all previous versions.
                Taskbar encourages you to periodically review the Terms to stay
                informed of our updates.P
              </Typography>
            </section>
          </Paper>
        
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default TermsOfUse;
