import { MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Link, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Loader from "../../../components/Loader";
import NoDataToShow from "../../../components/NoDataToShow";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { getErrorString, paginationLimit, serialize } from "../../../components/validator";
import { dragonURl } from "../../../constants";
import { ALL_TRANSACTIONS } from "../../../services/api-service";
import "./index.scss";

const TransactionHistory = (props) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionType, setTransactionType] = useState("");
  const [username, setUsername] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    search();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "from") {
      setFrom(value);
    } else if (name === "to") {
      setTo(value);
    }
  };

  const search = async (reqObj) => {
    setIsLoading(true);
    try {
      const params = {
        pageNo: pageNumber,
        limit: paginationLimit,
      };
      if (from) params["startDate"] = from;
      if (to) params["endDate"] = to;

      if (!reqObj) {
        if (transactionType !== "") params["transactionType"] = transactionType;
        if (username !== "") params["username"] = username;
      } else {
        if (reqObj.pageNo) params["pageNo"] = reqObj.pageNo;
        if (reqObj.transactionType) params["transactionType"] = reqObj.transactionType;
        if (reqObj.username) params["username"] = reqObj.username;
      }

      const response = await ALL_TRANSACTIONS(serialize(params));
      setIsLoading(false);
      const transactions = response.data.result.transactions;
      transactions.forEach((d) => {
        if (d.transactionOperation === "credit") {
          d["transactionTitle"] = `Payment received for task "${d.task.heading}"`;
          d["totalAmountC"] = `+${d.totalAmount ? d.totalAmount.toFixed(4) : "0"}`;
        } else if (d.transactionOperation === "debit") {
          d["totalAmountC"] = `-${d.totalAmount ? d.totalAmount.toFixed(4) : "0"}`;
          if (d.transactionType === "PROMOTE_TASK") {
            d["transactionTitle"] = `Payment for promote task "${d.task.heading}"`;
          } else if (d.transactionType === "PROMOTE_TASKER") {
            d["transactionTitle"] = `Payment for promote tasker "${d.user.firstName} ${d.user.lastName}"`;
          } else {
            d["transactionTitle"] = `Payment for task "${d.task.heading}"`;
          }
        }
      });
      setTransactions(transactions);
      setTotalRecord(response.data.result.totalCount);
    } catch (error) {
      setIsLoading(false);
      notifier.error("Error", getErrorString(error));
    }
  };

  const handleTransactionTypeChnage = (e) => {
    ////console.log(e.target.value);
    setTransactionType(e.target.value);
    search({
      transactionType: e.target.value,
    });
    //setTimeout(search() , 500)
  };

  const handlePageChange = (pageNo) => {
    setPageNumber(pageNo);
    search({
      pageNo: pageNo,
    });
  };

  return (
    <div className="transactions">
      <div className="heading">
        <h4>Transaction History</h4>
        <div className="user-type">
          <TextField id="Select-transaction-type" select label="Select Transaction Type" name="transactionType" value={transactionType} fullWidth onChange={handleTransactionTypeChnage} variant="outlined" size="small">
            <MenuItem value="">ALL</MenuItem>
            <MenuItem value="ESCROW">ESCROW</MenuItem>
            <MenuItem value="PROMOTE_TASK">PROMOTE TASK</MenuItem>
            <MenuItem value="PROMOTE_TASKER">PROMOTE TASKER</MenuItem>
            <MenuItem value="RECEIVE_PAYMENT">RECEIVE PAYMENT</MenuItem>
            {/* <MenuItem value="SEND_PAYMENT">SEND PAYMENT</MenuItem> */}
            <MenuItem value="REMAINING_AMOUNT_RETURN">REMAINING AMOUNT RETURN</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="filterArea">
        <div className="right-side">
          <h4>From :</h4>
          <div className="input-container">
            <input type="date" name="from" value={from} onChange={handleInputChange} min={"2000-01-01"} />
          </div>
          <h4>To :</h4>
          <div className="input-container">
            <input type="date" name="to" value={to} onChange={handleInputChange} min={from} />
          </div>
          <div className="username">
            <TextField
              size="small"
              variant="outlined"
              name="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                if (e.target.value === "") {
                  search({
                    username: "",
                  });
                }
              }}
              label="Filter by Username"
              fullWidth
            />
          </div>
          <button onClick={() => search()}>Search</button>
        </div>
      </div>
      <TableContainer className="transactions__content">
        {isLoading ? (
          <Loader />
        ) : transactions && transactions.length > 0 ? (
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="116px" />
              <col width="260px" />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Transaction</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Transaction ID</StyledTableCell>
                {/* <StyledTableCell>Transaction Type</StyledTableCell> */}
                <StyledTableCell>Status</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {transactions.map((data, index) => {
                return (
                  <StyledTableRow key={`transectionItem${index}`}>
                    <StyledTableCell>
                      <Moment format={"DD/MM/YYYY"}>{data.createdAt}</Moment>
                    </StyledTableCell>
                    <StyledTableCell>{data.transactionTitle}</StyledTableCell>
                    <StyledTableCell>{`${data.user.firstName} ${data.user.lastName}`}</StyledTableCell>
                    <StyledTableCell>{`${data.totalAmountC}ℏ(s)`}</StyledTableCell>
                    <StyledTableCell className="transactionId" title={data.vendorTransactionId}>
                      <Link target="_blank" href={`${dragonURl}/hedera/transactions/${data.vendorTransactionId.replace(/[@.-]/g, "")}`}>
                        {data.vendorTransactionId}
                      </Link>
                    </StyledTableCell>
                    {/* <StyledTableCell>{data.transactionType} </StyledTableCell> */}
                    <StyledTableCell>
                      <span className="pending">{data.transactionStatus}</span>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <NoDataToShow />
        )}
      </TableContainer>
      <div className="pagination">
        <Pagination totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={handlePageChange} showControls={true} />
      </div>
    </div>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    border: "none",
    fontSize: 13,
    color: "#2c2c2c",
    "&:last-child": {
      textAlign: "right",
      textTransform: "uppercase",
    },
    "&:nth-child(3),&:nth-child(4),&:nth-child(5)": {
      textAlign: "center",
    },
  },
  head: {
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #dddddd",
    textTransform: "uppercase",
  },
  stickyHeader: {
    zIndex: "auto",
  },
  body: {
    "& span.cnf": { color: "#07D62F" },
    "& span.rej": { color: "#ED0B0B" },
    "& span.pending": { color: "#2C2C2C" },
  },
}))(TableCell);

// const StyledTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#2c2c2c",
//     color: "#ffffff",
//     fontSize: 12,
//   },
// }))(Tooltip);

export default TransactionHistory;
