import React, { Component, forwardRef } from "react";
import "./index.scss";
import { Images } from "../../../assets/common/index";
import { REORDER_SUPERSKILL, REQ_SUPERSKILLSDETAILS } from "../../../services/api-service";
import ReviewPopUp from "../../../components/review-popup";
import HCSPopup from "../../../components/HCS-popup";
import { rateConversionUSDTasker } from "../../../components/validator";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class MySuperskills extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      superskills: [],
      popup: false,
      portfolioIndex: null,
      ReviewPopUp: false,
      HCSPopup: false,
      reviewSuperSkill: "",
      superskillId: "",
      taskId: "",
      rearrange: false,
      savingReorder: false,
    };
    this.handleReviewPopUpClose = this.handleReviewPopUpClose.bind(this);
  }

  componentDidMount = () => {
    this.getSuperskills();
  };

  getSuperskills = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    try {
      this.setState({ loading: true });
      const requestBody = {
        userId: currentUser,
      };
      const response = await REQ_SUPERSKILLSDETAILS(requestBody);
      const details = response.data.result;
      this.setState({
        superskills: rateConversionUSDTasker(details),
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  openReviewPopup = (e, id) => {
    e.stopPropagation();
    this.setState({ superskillId: id }, () => this.setState({ ReviewPopUp: true }));
  };

  addSuperSkill = () => {
    this.props.history.push("edit-profile/create-superskill");
  };

  editSuperskills = (superSkill) => {
    // let link =edit-profile/edit-superskill`
    let id = superSkill._id;
    if (!this.state.rearrange)
      this.props.history.push({
        pathname: `edit-profile/edit-superskill`,
        state: {
          superSkill,
        },
      });
  };

  portfolioPopup = (e, i) => {
    e.stopPropagation();
    this.setState({ portfolioIndex: i });
  };

  handleReviewPopUpClose = () => {
    this.setState({ ReviewPopUp: false });
  };

  openHCSPopup = (id) => {
    this.setState({ taskId: id }, () => this.setState({ HCSPopup: true }));
  };

  rearrangeSuperskills = () => {
    if (this.state.superskills.length > 1) {
      this.setState({
        rearrange: !this.state.rearrange,
      });
    } else {
      notifier.warning("This action cannot be performed beacuse, You have only one superskills.");
    }
  };
  dragEnded = (param) => {
    const { source, destination } = param;
    if (source == null || destination == null) return;
    let _arr = [...this.state.superskills];
    //extracting the source item from the list
    const _item = _arr.splice(source.index, 1)[0];
    //inserting it at the destination index.
    _arr.splice(destination.index, 0, _item);
    this.setState({ superskills: _arr });
  };

  saveRearrangedSuperskills = async () => {
    //set loading true
    this.setState({ savingReorder: true });
    const { superskills } = this.state;
    const order = superskills.map((d, i) => {
      return { _id: d._id, order: i + 1 };
    });
    try {
      //console.log('ReOrdered',order)
      const reqBody = {
        superskills: order,
      };
      const response = await REORDER_SUPERSKILL(reqBody);
      if (response.data.status === "success") {
        this.setState({ savingReorder: false, rearrange: false });
        this.getSuperskills();
        notifier.success("Success", "Changes Saved Successfully");
      }
    } catch (e) {
      console.log(e.toString());
    }
  };

  renderSuperskill = (superskills, i, snapshot) => {
    return (
      <div className={this.state.rearrange ? `shortable task-details ${snapshot.isDragging ? "nowdraging" : ""}` : "task-details"} onClick={() => this.editSuperskills(superskills)} key={`skills-${superskills._id}`}>
        {superskills.title ? <div className="task-name">{superskills.title}</div> : null}
        <div className="task-info">
          {superskills.description ? <p>{superskills.description}</p> : null}
          {superskills.skills ? (
            <div className="skills-details">
              {superskills.skills.map((skill) => (
                <div className="top-skill" key={skill._id}>
                  {skill.name}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="task-bottom">
          <span className="rating-container">
            <span className="heading">Rating </span>
            <span className="number">
              {superskills.avgRating ? <>{superskills.avgRating}</> : "0"} <img alt="" src={Images.Rating} />
            </span>
          </span>
          <span className="portfolio-container">
            <span className="heading">
              Portfolio
              <img alt="" src={Images.downArrow} className="downarrow" />
            </span>
            {superskills.portfolio ? (
              <div className="portfoilo">
                {superskills.portfolio[0].portfolioName}
                {"  "}
                <span onClick={(e) => this.portfolioPopup(e, i)}>
                  {"+"}
                  {superskills.portfolio.length}
                </span>
              </div>
            ) : null}
            {this.state.portfolioIndex === i ? (
              <div className="popup">
                {superskills.portfolio.map((portfo, i) => (
                  <span className="popup-list">{portfo.portfolioName}</span>
                ))}
              </div>
            ) : null}
          </span>
          <span className="review-container">
            <span className="heading">Reviews </span>
            <span className="text" onClick={(e) => this.openReviewPopup(e, superskills._id)}>
              view
            </span>
          </span>
          <div className="task-bottom-right-block">
            <p>Hourly Rate</p>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div className="hbars" title={superskills.fullHbars}>
                {superskills.ratePerHour}
              </div>
              &nbsp;{"ℏ"}/hr
              <span>{`(${superskills.usdRate} USD)`}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { superskills, rearrange } = this.state;

    return (
      <div className="edit-superskill-container">
        <div className="edit-add-container">
          <div className="editsuperskill_div">SUPERSKILLS</div>
          <div className="rearrange-btn">
            <button onClick={() => this.addSuperSkill()} disabled={rearrange}>
              Add Superskill
            </button>
            {rearrange ? <button onClick={() => this.saveRearrangedSuperskills()}>{this.state.savingReorder ? <img src={Images.ellipsis_loader} alt="" /> : "Save Changes"}</button> : null}
            {superskills.length > 1 ? (
              <button className={rearrange ? `reorderBtn active` : "reorderBtn"} onClick={() => this.rearrangeSuperskills()}>
                <img alt="" src={Images.RearrangeSuperskill} />
              </button>
            ) : null}
          </div>
        </div>
        {/* {superskills.length===0 ? <> */}
        {this.state.loading ? (
          <Loader />
        ) : superskills ? (
          rearrange ? (
            <DragDropContext onDragEnd={(event) => this.dragEnded(event)}>
              <Droppable droppableId="superskills-wrapper">
                {(provided, snapshot) => (
                  <SuperskillsList ref={provided.innerRef} {...provided.droppableProps}>
                    {superskills.map((superskills, i) => (
                      <Draggable draggableId={`superskill-${superskills._id}`} index={i} key={superskills._id}>
                        {(_provided, _snapshot) => (
                          <div ref={_provided.innerRef} {..._provided.draggableProps}>
                            <div {..._provided.dragHandleProps}>{this.renderSuperskill(superskills, i, _snapshot)}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </SuperskillsList>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            superskills.map((superskills, i) => this.renderSuperskill(superskills, i))
          )
        ) : null}
        <ReviewPopUp superskillId={this.state.superskillId} open={this.state.ReviewPopUp} onClose={this.handleReviewPopUpClose} superskillReview={this.state.superskillReview} openHCSPopup={this.openHCSPopup} />
        <HCSPopup taskId={this.state.taskId} open={this.state.HCSPopup} onClose={() => this.setState({ HCSPopup: false })} />
      </div>
    );
  }
}

const SuperskillsList = forwardRef(({ children, ...props }, ref) => {
  return (
    <div ref={ref} className="superskillsList" {...props}>
      {children}
    </div>
  );
});

export default MySuperskills;
