import axios from "axios";
import { API } from "../constants/api";
import cookieStorage from "../services/cookie-storage";

const JWTtoken = cookieStorage.getCookie("token");
// AUTH ORG API
const header = { Authorization: JWTtoken ? JWTtoken : "" };

export const REQ_LOGIN = async (body) => await axios.post(API.LOGIN, body);
export const LOGOUT = async (body) => await axios.post(API.LOGOUT, body, { headers: header });
export const REQ_REGISTER = async (body) => await axios.post(API.REGISTER, body);
export const REQ_RESETPASS = async (body) => await axios.post(API.RESET_PASSWORD_REQ, body);
export const REQ_REGISTER_EMAILVERIFY = async (body) => await axios.post(API.RESEND_EMAIL_VERIFY, body);
export const UPDATE_RESETPASS = async (body) => await axios.post(API.RESET_PASSWORD, body);
export const CHANGE_PASSWORD = async (body) => await axios.put(API.CHANGE_PASSWORD, body, { headers: header });
export const DELETE_ACCOUNT = async (body) => await axios.delete(API.DELETE_ACCOUNT, { headers: header, data: body });
export const VERIFY_EMAIL = async (url) => await axios.get(url);
export const UPDATE_BASIC_INFO = async (body) => await axios.put(API.BASIC_INFO, body, { headers: header });
export const UPDATE_BASIC_INFOFILL = async (body) => await axios.put(API.BASIC_INFOFILL, body, { headers: header });
export const CHECK_USER_NAME_AVAIL = async (body) => await axios.post(API.CHECK_USER_NAME, body);
export const REQ_USER_DETAILS = async (body) => await axios.post(API.USER_DETAILS, body);
export const UPDATE_PROFILE_IMAGE = async (body) => await axios.post(API.UPDATE_PROFILE, body, { headers: header });
export const INVITE_FRIEND = async (body) => await axios.post(API.INVITE_FRIEND, body, { headers: header });

export const REQ_TASKS = async (body) => await axios.post(API.FIND_TASK, body, { headers: header });
export const REQ_CREATE_TASK = async (body) => await axios.post(API.CREATE_TASK, body, { headers: header });
export const REQ_CREATE_WALLET_TASK = async (body) => await axios.post(API.CREATE_WALLET_TASK, body, { headers: header });
export const REQ_FAVOURITE_TASK = async (body) => await axios.put(API.FAVOURITE_TASK, body, { headers: header });
export const GET_TASKS = async (body) => await axios.post(API.REQ_TASK, body, { headers: header });
export const REQ_UPDATE_TASK = async (body) => await axios.post(API.UPDATE_TASK, body, { headers: header });
export const CANCEL_TASK = async (body) => await axios.patch(API.CANCEL_TASK, body, { headers: header });

export const REQ_SKILLS = async (params) => await axios.post(API.REQ_SKILL, params);
export const REQ_CREATE_SUPERSKILL = async (body) => await axios.post(API.CREATE_SUPERSKILL, body, { headers: header });
export const REQ_UPDATE_SUPERSKILL = async (body) => await axios.put(API.UPDATE_SUPERSKILL, body, { headers: header });
export const REQ_DELETE_SUPERSKILL = async (body) => await axios.delete(API.DELETE_SUPERSKILL, { headers: header, data: body });
export const REQ_SUPERSKILLSDETAILS = async (params) => await axios.post(API.REQ_SUPERSKILL, params);
export const REQ_TASKERS = async (body) => await axios.post(API.FIND_TASKER, body, { headers: header });
export const REQ_FAVOURITE_TASKER = async (body) => await axios.put(API.FAVOURITE_TASKER, body, { headers: header });
export const INVITE_TASKER = async (body) => await axios.post(API.INVITE_TASKER, body, { headers: header });
export const HIRE_NOW = async (body) => await axios.put(API.HIRE_NOW, body, { headers: header });

//Bids
export const REQ_BID_DETAILS = async (body) => await axios.post(API.GET_BIDS, body, { headers: header });
export const REQ_BIDS_FOR_MY_TASK = async (body) => await axios.post(API.GET_BIDS_FOR_MY_TASK, body, { headers: header });
export const CREATE_BID = async (body) => await axios.post(API.CREATE_BIDS, body, { headers: header });
export const CLOSE_BIDDING = async (body) => await axios.patch(API.CLOSE_BIDDING, body, { headers: header });
export const END_CONTRACT = async (body) => await axios.patch(API.END_CONTRACT, body, { headers: header });
export const RAISE_DISPUTE = async (body) => await axios.patch(API.RAISE_DISPUTE, body, { headers: header });
export const APPROVE_TASK_COMPLETE = async (body) => await axios.patch(API.APPROVE_TASK_COMPLETE, body, { headers: header });
export const GET_NEGOTIATION_HISTORY = async (body) => await axios.post(API.GET_NEGOTIATION_HISTORY, body, { headers: header });
export const SEND_NEGOTIATION_HISTORY = async (body) => await axios.post(API.SEND_NEGOTIATION_HISTORY, body, { headers: header });
export const RECOMAND_TASK_TO_TASKER = async (body) => await axios.post(API.RECOMAND_TASK_TO_TASKER, body, { headers: header });

//REVIEWS
export const GET_REVIEWS = async (body) => await axios.post(API.GET_REVIEWS, body, { headers: header });
export const CREATE_REVIEW = async (body) => await axios.post(API.CREATE_REVIEW, body, { headers: header });

//Notifications
export const GET_NOTIFICATIONS = async () => await axios.get(API.GET_NOTIFICATIONS, { headers: header });
export const UPDATE_NOTIFICATIONS = async (body) => await axios.post(API.UPDATE_NOTIFICATIONS, body, { headers: header });

//Payments
export const LINK_WALLET_ID = async (body) => await axios.put(API.LINK_WALLET_ID, body, { headers: header });
export const REQ_SIGN_TRANSACTION = async (body) => await axios.post(API.SIGN_TRANSACTION, body, { headers: header });

//Helper API's
export const GET_CONVERSION_RATE = async () => await axios.get(API.GET_CONVERSION_RATE, { headers: header });
export const GET_PROMOTE_TASK_FEE = async (numericId) => await axios.get(`${API.GET_PROMOTE_TASK_FEE}?numericTaskId=${numericId}`, { headers: header });
export const GET_PROMOTE_TASKER_FEE = async () => await axios.get(API.GET_PROMOTE_TASKER_FEE, { headers: header });
export const GET_FEES_FOR_TASK = async (bidId, pay) => await axios.get(`${API.GET_FEES_FOR_TASK}?bidId=${bidId}&pay=${pay}`, { headers: header });
export const GET_ALL_FEES = async () => await axios.get(API.GET_ALL_FEES, { headers: header });
export const GET_USER_ALL_TIME_DETAILS = async (body) => await axios.get(`${API.USER_ALL_TIME_DETAILS}?${body.userId ? `userId=${body.userId}` : ""}${body.nickName ? `nickName=${body.nickName}` : ""}`, { headers: header });
export const REORDER_SUPERSKILL = async (body) => await axios.patch(API.REORDER_SUPERSKILL, body, { headers: header });

//Timer
export const TIMER_ACTIONS = async (body, action) => await axios.post(`${API.TIMER}/${action}`, body, { headers: header });
export const GET_TIMER_HISTORY = async (taskId) => await axios.get(`${API.TIMER}?taskId=${taskId}`, { headers: header });

//DashBoard
export const GET_ANALYTICS = async (userId) => await axios.get(`${API.DASHBOARD_ANALYTICS}?userId=${userId}`, { headers: header });
export const GET_TRANSACTION_HISTRORY = async (params) => await axios.get(`${API.TRANSACTION_HISTRORY}?${params}`, { headers: header });

//LeaderBoard
export const GET_LEADERBOARD = async (params) => await axios.get(`${API.LEADERBOARD_API}?pageNo=${params.pageNo}&userId=${params.userId}`, { headers: header });

//Issues
export const CREATE_ISSUES = async (body) => await axios.post(API.CREATE_ISSUES, body, { headers: header });
export const GET_ISSUES = async (body) =>
  await axios.get(`${API.GET_ISSUES}?pageNo=${body.pageNo ? body.pageNo : 1}&limit=${body.limit ? body.limit : 10}${body.issueId ? `&issueId=${body.issueId}` : ""}${body.searchPhrase ? `&searchPhrase=${body.searchPhrase}` : ""}`, {
    headers: header,
  });
export const MARK_SOLVED = async (body) => await axios.put(API.MARK_SOLVED, body, { headers: header });
export const DELETE_ISSUE = async (body) => await axios.delete(API.DELETE_ISSUE, { headers: header, data: body });

//Admin
export const ADMIN_UPDATE_FEES = async (body) => await axios.put(API.ADMIN_UPDATE_TASKBAR_FEE, body, { headers: header });
export const ADMIN_GET_ALL_USER = async (body) =>
  await axios.get(`${API.ADMIN_GET_ALL_USER}?pageNo=${body.pageNo ? body.pageNo : 1}&limit=${body.limit ? body.limit : 10}${body.userId ? `&userId=${body.userId}` : ""}${body.searchPhrase ? `&searchPhrase=${body.searchPhrase}` : ""}`, {
    headers: header,
  });
export const ADMIN_USER_ACCOUNT_ACCESS = async (body) => await axios.patch(API.ADMIN_USER_ACOUNT_ACCESS, body, { headers: header });
export const CHANGE_USER_ROLE = async (body) => await axios.patch(API.CHANGE_USER_ROLE, body, { headers: header });
export const ALL_TRANSACTIONS = async (params) => await axios.get(`${API.ALL_TRANSACTIONS}?${params}`, { headers: header });
export const GET_ALL_UNHANDLED_TRANSACTION = async (params) => await axios.get(`${API.GET_UNHANDLED_TRANSACTION}?${params}`, { headers: header });
export const UNHANDLED_TRANSACTION_OPERATION = async (body) => await axios.post(API.UNHANDLED_TRANSACTION_OPERATION, body, { headers: header });
export const GET_DISPUTED_TASK = async (params) => await axios.get(`${API.GET_ALL_DISPUTES}?${params}`, { headers: header });
export const DISPUTED_TASK_OPERATION = async (body) => await axios.post(API.DISPUTE_OPERATION, body, { headers: header });
export const UPDATE_WALLET_ID = async (body) => await axios.put(API.ADMIN_UPDATE_WALLET_ID, body, { headers: header });
export const GET_ALL_SKILLS = async () => await axios.get(API.GET_ALL_SKILLS, { headers: header });
// Predefined Task
export const ADMIN_PREDEFINED_TASK = async (body) =>
  await axios.get(`${API.ADMIN_GET_PREDEFINED_TASK}?pageNo=${body.pageNo ? body.pageNo : 1}&limit=${body.limit ? body.limit : 10}${body.userId ? `&userId=${body.userId}` : ""}${body.searchPhrase ? `&searchPhrase=${body.searchPhrase}` : ""}`, {
    headers: header,
  });
export const ADMIN_ADD_PREDEFINED_TASK = async (body) => await axios.post(API.ADMIN_ADD_NEW_PREDEFINED_TASK, body, { headers: header });
export const ADMIN_UPDATE_PREDEFINED_TASK = async (body) => await axios.post(API.ADMIN_UPDATE_PREDEFINED_TASK, body, { headers: header });
export const ADMIN_DELETE_PREDEFINED_TASK = async (body) => await axios.delete(API.ADMIN_DELETE_PREDEFINED_TASK, { headers: header, data: body });
export const CHANGE_BRAND_STATUS = async (body) => await axios.patch(API.CHANGE_BRAND, body, { headers: header });

//Prohibited words
export const GET_PROHIBITED_WORDS = async () => await axios.get(API.GET_PROHIBITED_WORDS, { headers: header });
export const ADD_PROHIBITED_WORDS = async (body) => await axios.post(API.ADD_PROHIBITED_WORDS, body, { headers: header });
export const DELETE_PROHIBITED_WORDS = async (body) => await axios.delete(API.DELETE_PROHIBITED_WORDS, { headers: header, data: body });
export const GET_PROHIBITED_TASK = async (params) => await axios.get(`${API.GET_PROHIBITED_TASK}?${params}`, { headers: header });
export const DELETE_PROHIBITED_TASK = async (body) => await axios.delete(API.DELETE_PROHIBITED_TASK, { headers: header, data: body });
export const APPROVE_PROHIBITED_TASK = async (body) => await axios.put(API.APPROVE_PROHIBITED_TASK, body, { headers: header });

//WalletTask
export const GET_ALL_WALLET_TASK = async (params) => await axios.get(`${API.GET_WALLET_TASK}?${params}`, { headers: header });

//ADmin PointSystem

export const GET_ALL_EVENTS_FROM_POINT_TABLE = async () => await axios.get(API.GET_POINT_TABLE, { headers: header });
export const GET_ALL_ACTIVITY_LIST = async () => await axios.get(API.GET_ACTIVITY_LIST, { headers: header });
export const UPDATE_EVENTS_IN_POINT_TABLE = async (body) => await axios.put(API.UPDATE_POINT_TABLE, body, { headers: header });
export const ADD_NEW_EVENTS_IN_POINT_TABLE = async (body) => await axios.post(API.ADD_EVENT_IN_POINT_TABLE, body, { headers: header });
export const ADD_POINTS_TO_AN_USER = async (body) => await axios.post(API.ADD_POINTS_TO_USER, body, { headers: header });

export const CREATE_NEW_USER_FROM_ADMIN = async (body) => await axios.post(API.CREATE_NEW_USER, body, { headers: header });

//!chat
export const GET_CHAT_ID = async (body) => await axios.post(API.GET_CHAT_BOX_ID, body, { headers: header });
export const GET_CHAT_HISTORY = async (body) => await axios.post(API.GET_CHAT_HISTORY, body, { headers: header });
export const SEND_MESSAGE = async (body) => await axios.post(API.SEND_MESSAGE, body, { headers: header });
