import React from 'react';

interface Props {
	className?: string;
}

export const MYTaskPendingIcon = (props: Props) => {
	return (
		<svg height="15.531" width="15.53" viewBox="0 0 15.53 15.531" xmlns="http://www.w3.org/2000/svg">
			<defs />
			<path className="a" d="M-13333.32-3490.67h-.017a7.6,7.6,0,0,1-5.417-2.247,7.6,7.6,0,0,1-2.247-5.417,7.6,7.6,0,0,1,2.247-5.421,7.615,7.615,0,0,1,5.417-2.247,7.693,7.693,0,0,1,6.469,3.549v-1.661a.6.6,0,0,1,.6-.6.6.6,0,0,1,.6.6v1.764a1.92,1.92,0,0,1-.866,1.6l-.054.034a1.917,1.917,0,0,1-1,.281h-1.828a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6h1.647a6.482,6.482,0,0,0-5.568-3.174,6.474,6.474,0,0,0-6.465,6.469,6.471,6.471,0,0,0,6.465,6.465h.03c.185,0,.368-.007.549-.023a.6.6,0,0,1,.646.549.6.6,0,0,1-.546.646c-.214.017-.432.027-.649.027Zm2.374-.974a.6.6,0,0,1,.3-.793,5.1,5.1,0,0,0,.489-.248.6.6,0,0,1,.816.231.6.6,0,0,1-.23.817c-.191.1-.385.2-.583.291a.575.575,0,0,1-.244.053A.6.6,0,0,1-13330.946-3491.645Zm2.538-1.563a.606.606,0,0,1-.05-.847c.12-.134.234-.278.345-.425a.6.6,0,0,1,.837-.12.6.6,0,0,1,.124.837c-.128.171-.269.342-.409.5a.6.6,0,0,1-.448.2A.605.605,0,0,1-13328.408-3493.208Zm-7.721-2.334a.6.6,0,0,1,0-.847l2.2-2.193v-4.543a.6.6,0,0,1,.6-.6.6.6,0,0,1,.6.6v4.791a.588.588,0,0,1-.174.422l-2.371,2.371a.6.6,0,0,1-.425.177A.6.6,0,0,1-13336.129-3495.542Zm9.4-.134a.6.6,0,0,1-.4-.747c.054-.174.1-.352.141-.529a.6.6,0,0,1,.71-.458.6.6,0,0,1,.459.713c-.047.211-.1.422-.164.626a.6.6,0,0,1-.573.422A.536.536,0,0,1-13326.73-3495.676Z" transform="translate(13341.101 3506.102)" />
		</svg>
	)
}

