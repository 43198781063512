import { Backdrop, Fade, Modal, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Images } from "../../../assets/common";
import Hide from "../../../components/Hide";
import history from "../../../components/history";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { getErrorString, getFavriteTask, paginationLimit, rateConversionUSDTask } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { BreakPoints, checkWalletAvailablity, getParamsByName } from "../../../lib/validators";
import { REQ_FAVOURITE_TASK, REQ_SKILLS, REQ_TASKS, RECOMAND_TASK_TO_TASKER, REQ_USER_DETAILS } from "../../../services/api-service";
import cookieStorage from "../../../services/cookie-storage";
import { checkBidAllowed } from "../../home/my-tasks/useTasksOperation";
import "./index.scss";

class ListTask extends Component {
  constructor() {
    super();
    this.userDetails = {};
    this.state = {
      tasks: [],
      loading: false,
      sortBy: "",
      findtask: true,
      findtaskers: false,
      taskOrTasker: "task",
      AllSkills: [],
      search: "",
      count: "",
      skills: [],
      favTasks: [],
      paymentType: " ",
      brand: "",
      minRate: "",
      maxRate: "",
      conversionRate: null,
      conversionDate: "",
      manual: false,
      pageNumber: 1,
      totalRecord: 0,
      isMobileFilterOpen: false,
      rctModal: {
        open: false,
        taskId: "",
        taskerId: "",
        sendingRC: false,
      },
      windowWidth: 0,
      WindowHeight: 0,
    };
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.updateDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    this.setState({ WindowWidth: window.innerWidth, WindowHeight: window.innerHeight });
  };

  componentDidMount = () => {
    let conversionRate = localStorage.getItem("conversionRate");
    let conversionDate = localStorage.getItem("conversionDate");
    var searchValue = getParamsByName("search");
    let skillsId = getParamsByName("skillsId");
    this.getUserDetails();
    this.setState(
      {
        search: skillsId ? "" : searchValue ? searchValue : " ",
        conversionDate,
        conversionRate: parseFloat(conversionRate),
        skills: skillsId ? { _id: skillsId } : [],
      },
      () => {
        this.getTasks();
      }
    );
  };

  setFiltersDefault = () => {
    this.setState({
      AllSkills: [],
      count: "",
      skills: [],
      favTasks: [],
      paymentType: " ",
      brand: "",
      minRate: "",
      maxRate: "",
    });
  };

  getUserDetails = async (id) => {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      try {
        const requestBody = {
          userId: currentUser,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        this.userDetails = response.data.result;
        this.setState({
          favTasks: getFavriteTask(this.userDetails).map((d) => d._id),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  handleChange = (type, min, max) => {
    // this.setState({ manual: false })
    //console.log("yash", type, min, max);
    switch (type) {
      case "RANGE":
        //console.log("1");
        this.setState({ minRate: min, maxRate: max }, () => this.getTasks());
        break;
      case "MIN_VALUE":
        //console.log("2");
        this.setState({ minRate: min }, () => this.getTasks());
        break;
      case "MAX_VALUE":
        //console.log("3");
        this.setState({ maxRate: max }, () => this.getTasks());
        break;
      default:
        //console.log("not right");
        break;
    }
    // this.getTasks();
  };

  handlePaymentType = (type) => {
    //console.log("handlePaymentType", type);
    switch (type) {
      case "hourly":
        this.setState({ paymentType: type }, () => {
          this.getTasks();
        });
        break;
      case "fixed":
        this.setState({ paymentType: type }, () => {
          this.getTasks();
        });
        break;
      default:
        //console.log("not right");
        break;
    }
  };

  taskOrTaskerChange = (type) => {
    // this.setState({ search: "" });
    switch (type) {
      case "task":
        this.setState({ taskOrTasker: type }, () => {
          this.props.history.push(`/Task/list-task?search=${this.state.search}`);
        });
        break;
      case "tasker":
        this.setState({ taskOrTasker: type }, () => {
          this.props.history.push(`/Task/find-tasker?search=${this.state.search}`);
        });
        break;
      default:
        //console.log("not right");
        break;
    }
  };

  getTasks = async () => {
    let {
      skills,
      paymentType,
      //count,
      brand,
      minRate,
      maxRate,
      sortBy,
      search,
      //favTasks,
      pageNumber,
    } = this.state;

    try {
      this.setState({ loading: true });
      const requestBody = {
        skills: [skills?._id].filter((e) => e).length ? [skills?._id].filter((e) => e) : undefined,
        minRate: minRate ? minRate : undefined,
        maxRate: maxRate ? maxRate : undefined,
        searchPhrase: search ? search : undefined,
        paymentType: paymentType ? paymentType : undefined,
        sortBy: sortBy ? sortBy : undefined, // relevance, newest, priceSort
        brand: brand ? brand : undefined,
        pageNumber: pageNumber,
        noOfDocuments: paginationLimit,
      };
      const response = await REQ_TASKS(requestBody);
      this.setState({ loading: false });
      let Task = response.data.result;
      let taskData = Task.data;
      if (Task.pinnedTask) {
        const pinnedTaskId = Task.pinnedTask._id;
        taskData.forEach((d, i) => {
          if (d._id === pinnedTaskId) {
            d["priority"] = 1;
          } else {
            d["priority"] = i + 2000;
          }
        });
        taskData.sort((a, b) => a.priority - b.priority);
      }
      this.setState({
        tasks: rateConversionUSDTask(taskData),
        count: Task.totalCount,
        totalRecord: Task.totalCount,
        isMobileFilterOpen: false,
      });
      this.getUserDetails();
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };

  getCurrentPage = (currentPage) => {
    this.setState({ pageNumber: currentPage }, () => this.getTasks());
  };

  handleInputChange = async (event) => {
    const id = event.target.name;
    const value = event.target.value;
    // const checked = event.target.checked;
    //console.log("yash", id, value);
    switch (id) {
      case "brand":
        this.setState({ brand: value }, () => this.getTasks(value));
        break;
      case "skill":
        this.setState({ skills: value }, () => this.getAllSkills(value));
        this.getTasks(value);
        break;
      case "sortBy":
        this.setState({ sortBy: value }, () => this.getTasks(value));
        break;
      default:
        console.warn("Something is not right !");
    }
    // this.getTasks();
  };
  handleInputChangeMobile = async (event) => {
    const id = event.target.name;
    const value = event.target.value;
    switch (id) {
      case "brand":
        this.setState({ brand: value });
        break;
      case "skill":
        this.setState({ skills: value }, () => this.getAllSkills(value));
        break;
      default:
        console.warn("Something is not right !");
    }
    // this.getTasks();
    // case "sortBy":
    //   this.setState({sortBy: value}, () => this.getTasks(value));
    //   break;
  };

  searchTasks = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    if (event.key === "Enter") {
      switch (id) {
        case "search":
          this.setFiltersDefault();
          this.setState({ search: value, skills: [] }, () => this.getTasks(value));
          let searchValue = value;
          this.props.history.push(`/Task/list-task?search=${searchValue}`);
          //console.log("handlePageRedirection");
          break;
        default:
          console.warn("Something is not right !");
      }
    }
  };

  clearAll = () => {
    this.setState(
      {
        sortBy: "",
        findtask: true,
        findtaskers: false,
        taskOrTasker: "task",
        AllSkills: [],
        search: "",
        count: "",
        skills: [],
        paymentType: "",
        brand: "",
        minRate: "",
        maxRate: "",
        manual: false,
      },
      () => this.getTasks()
    );
  };

  clearAllMobile = () => {
    this.clearAll();
    this.setState({
      isMobileFilterOpen: false,
    });
  };

  getAllSkills = async (skill) => {
    this.setState({ loading: true });
    try {
      let allSkills;
      if (skill.length > 2) {
        const param = {
          searchPhrase: skill,
        };
        const response = await REQ_SKILLS(param);
        allSkills = response.data.result;
        this.setState({ AllSkills: allSkills });
      }
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };

  FavouriteOpration = async (superSkillID, op) => {
    try {
      const requestBody = {
        favouriteTaskId: superSkillID,
        operation: op,
      };
      const response = await REQ_FAVOURITE_TASK(requestBody);
      const fav = response.data.result.profileStats.favouriteTasks;
      this.setState({ favouriteTasks: fav, favTasks: fav });
      //console.log("fav", fav);
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  };

  onchangeAddSkill = (event, newValue) => {
    this.setState({ skills: newValue }, () => {
      this.getTasks();
    });
  };

  applyBid = (taskId) => {
    const token = cookieStorage.getCookie("token");
    const isWalletLinked = checkWalletAvailablity();

    if (!_.isEmpty(token)) {
      this.props.history.push(isWalletLinked ? `/Task/actionOnTasks?action=apply&taskId=${taskId}` : "/home/wallet?walletLinked=false");
    } else {
      notifier.warning("Warning", MESSAGE.WARN.NOT_LOGGED_IN);
    }
  };

  placeBid = (taskId) => {
    const token = cookieStorage.getCookie("token");
    const isWalletLinked = checkWalletAvailablity();

    if (!_.isEmpty(token)) {
      this.props.history.push(isWalletLinked ? `/Task/actionOnTasks?action=bid&taskId=${taskId}` : "/home/wallet?walletLinked=false");
    } else {
      notifier.warning("Warning", MESSAGE.WARN.NOT_LOGGED_IN);
    }
  };

  openMobileFilter = () => {
    this.setState({ isMobileFilterOpen: true });
  };

  closeFilter = () => {
    this.setState({ isMobileFilterOpen: false });
  };

  sendRecomandation = async () => {
    const _rctModal = this.state.rctModal;
    _rctModal["sendingRC"] = true;
    this.setState({ rctModal: _rctModal });

    const reqBody = {
      nickName: _rctModal.taskerId,
      taskId: _rctModal.taskId,
    };

    try {
      const response = await RECOMAND_TASK_TO_TASKER(reqBody);
      if (response.data.status === "success") {
        notifier.success("Recomndation Email Send Successfully");
        _rctModal["sendingRC"] = false;
        _rctModal["open"] = false;
        _rctModal["taskId"] = "";
        _rctModal["taskerId"] = "";
        this.setState({ rctModal: _rctModal });
      }
    } catch (e) {
      _rctModal["sendingRC"] = false;
      this.setState({ rctModal: _rctModal });
      notifier.error("Error", getErrorString(e));
    }
  };

  handleRctClick = (id) => {
    const _rctModal = this.state.rctModal;
    _rctModal["taskId"] = id;
    _rctModal["open"] = true;
    this.setState({
      rctModal: _rctModal,
    });
  };

  hadnleRctModalClose = () => {
    const _rctModal = this.state.rctModal;
    _rctModal["open"] = false;
    this.setState({
      rctModal: _rctModal,
    });
  };

  setRcTasker = (val) => {
    const _rctModal = this.state.rctModal;
    _rctModal["taskerId"] = val;
    this.setState({
      rctModal: _rctModal,
    });
  };

  renderLeftSide = () => {
    let { AllSkills, paymentType, brand, minRate, maxRate, manual, taskOrTasker, windowWidth } = this.state;
    return (
      <div className="filter-page">
        <div className="filters-container">
          <div className="filterHeader">
            <div className={`filterHeader__findTask ${taskOrTasker === "task" ? "active" : ""}`} onClick={(e) => this.taskOrTaskerChange("tasker")}>
              <span>Find Task</span>
            </div>
            <div className={`filterHeader__findTasker  ${taskOrTasker === "tasker" ? "active" : ""}`} onClick={(e) => this.taskOrTaskerChange("tasker")}>
              <span>Find Tasker</span>
            </div>
          </div>
          <Hide type="down" size={BreakPoints.sm}>
            <div className="filterclear-container">
              <h3>FILTERS</h3>
              <span className="clearall-btn" onClick={() => this.clearAll()}>
                Clear all
              </span>
            </div>
          </Hide>
          <div className="show-container">
            {/* <h4>Show Results</h4> */}
            <Hide type="up" size={BreakPoints.sm}>
              <button className="mobilefilter" onClick={this.openMobileFilter} style={{ marginTop: 6 }}>
                More Filters
              </button>
            </Hide>
          </div>

          <div className={this.state.isMobileFilterOpen ? "mobile__filter open" : "mobile__filter"}>
            <Hide type="up" size={BreakPoints.sm}>
              <div className="filterclose">
                <h5>FILTERS</h5>
                <span onClick={() => this.clearAllMobile()}>Clear All</span>
                <span className="closeFilter" onClick={this.closeFilter}>
                  <img src={Images.IconForCloseModal} alt="" />
                </span>
              </div>
            </Hide>

            <div className="brand-container">
              <h4>Brand</h4>
              <Hide type="up" size={BreakPoints.sm}>
                <Button className="mobileApplyButton" size="small" color="primary" variant="contained" onClick={() => this.getTasks()}>
                  Apply
                </Button>
              </Hide>
              <div className="input-container">
                <input placeholder={"Brand name"} name="brand" value={brand} onChange={(e) => (windowWidth > BreakPoints.sm ? this.handleInputChange(e) : this.handleInputChangeMobile(e))} />
                <img className="search-icon" src={Images.search} alt={""} />
              </div>
            </div>
            <br />
            <div className="brand-container">
              <h4>Subskills</h4>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                // options={AllSkills.map((option) => option.name)}
                options={AllSkills}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => this.onchangeAddSkill(event, newValue)}
                renderInput={(params) => (
                  <TextField {...params} margin="normal" variant="outlined" placeholder="Subskill name" name="skill" onChange={(e) => (windowWidth > BreakPoints.sm ? this.handleInputChange(e) : this.handleInputChangeMobile(e))} />
                )}
              />
              <p>eg.Logo,php,mobile</p>
            </div>
            <br />
            <div className="payrate-container">
              <div className="payment-container">
                <h4>Pay rate (ℏ)</h4>
                <div className="btn-container">
                  <div className="type-change-btn">
                    <div className={` ${paymentType === "hourly" ? "active-type" : "disable-type"} `} onClick={(e) => this.handlePaymentType("hourly")}></div>
                    <div className="radio-btn-label">Hourly (Rate/hr)</div>
                  </div>
                  <div className="type-change-btn">
                    <div className={` ${paymentType === "fixed" ? "active-type" : "disable-type"} `} onClick={(e) => this.handlePaymentType("fixed")}></div>
                    <div className="radio-btn-label">Fixed</div>
                  </div>
                </div>
              </div>
              <div className="rate-container">
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "", "")} className={`${minRate === "" && maxRate === "" ? "active-type" : "disable-type"}`}></div>
                  <div>Any Rates</div>
                  {/* <p className="num_p">(20)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "", "100")} className={`${minRate === "" && maxRate === "100" ? "active-type" : "disable-type"}`}></div>
                  <div>100 & below</div>
                  {/* <p className="num_p">(60)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "100", "300")} className={`${minRate === "100" && maxRate === "300" ? "active-type" : "disable-type"}`}></div>
                  <div>100-300</div>
                  {/* <p className="num_p">(50)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "300", "600")} className={`${minRate === "300" && maxRate === "600" ? "active-type" : "disable-type"}`}></div>
                  <div>300-600 </div>
                  {/* <p className="num_p">(30)</p> */}
                </div>

                <div className="any">
                  <div onClick={() => this.handleChange("RANGE", "600", "1000")} className={`${minRate === "600" && maxRate === "1000" ? "active-type" : "disable-type"}`}></div>
                  <div>600-1000</div>
                  {/* <p className="num_p">(10)</p> */}
                </div>
                <div className="any">
                  <div onClick={() => this.setState({ manual: true })} className={`${manual ? "active-type" : "disable-type"}`}></div>
                  <div>Enter the range</div>
                </div>
              </div>
              <div className="rect_div">
                <div className="hbar-container">
                  <input disabled={manual ? false : true} type="number" placeholder="min" name="minValue" onChange={(e) => this.handleChange("MIN_VALUE", e.target.value, null)} />
                  <div className="hbar_div">ℏ</div>
                </div>
                <div className="hbar-container">
                  <input disabled={manual ? false : true} type="number" placeholder="max" name="maxValue" onChange={(e) => this.handleChange("MAX_VALUE", null, e.target.value)} />
                  <div className="hbar_div">ℏ</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTasks = () => {
    let { tasks, totalRecord, pageNumber } = this.state;
    return (
      <div>
        {tasks.map((task, i) => (
          <div className="task-details" key={`td${i}`}>
            {task.priority === 1 ? <img src={Images.pinTask} className="pintask" alt="" /> : null}
            <div className="task-details-top">
              <div className="tasker-profile">
                <div className="profile-content">
                  {task.author ? (
                    <div className="info">
                      <Hide type="down" size={BreakPoints.sm}>
                        <span>
                          {" "}
                          Posted &nbsp;<Moment fromNow>{task.createdAt}</Moment>
                        </span>
                        <div className="sepration"></div>
                      </Hide>
                      <div className="info-name">
                        <Link to={`/${task.author.nickName}`}>
                          <img src={Images.user} alt={""} className="info-img" />
                          {task.author.nickName}
                          {/* {task.author.firstName} {task.author.lastName} */}
                        </Link>
                      </div>
                      <div className="sepration"></div>
                      <div className="tasker-location">
                        <img src={Images.location} alt={""} />
                        {task.author.address ? task.author.address.country : ""}
                      </div>
                    </div>
                  ) : null}
                  <div className="brandCompany-name">{task.heading}</div>
                </div>
              </div>
              {this.state.favTasks.includes(task._id) ? (
                <FavoriteIcon style={{ color: "#6DC7BE" }} className="favicon" onClick={() => this.FavouriteOpration(task._id, "unfavourite")} />
              ) : (
                <img src={Images.favIcon} alt={""} className="favicon" onClick={() => this.FavouriteOpration(task._id, "favourite")} />
              )}
              <span onClick={() => this.handleRctClick(task._id)} className="recommendsTask-btn">
                <img src={Images.RecommendTaskBtn} alt="Invite" />
              </span>
            </div>
            <div className="List-Task-info">
              <p>{task.description}</p>
              <div className="design-details">
                {task.skills.map((skill, ski) => (
                  <div className="design" key={`skill${ski}`}>
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>
            {task.paymentTerms.hourly ? (
              <div className="task-bottom">
                {!task.paymentTerms.biddingEnabled ? (
                  <span className="hoursneed">
                    <span className="hours">Hours Needed </span>
                    <span className="weektime">
                      {task.paymentTerms.hourly.hoursPerWeek} {"hrs/week"}
                    </span>
                  </span>
                ) : null}

                <span className="duration">
                  <span className="hours">Duration </span>
                  <span className="weektime">{task.paymentTerms.hourly.taskDuration}</span>
                </span>

                {!task.paymentTerms.biddingEnabled ? (
                  <span className="hourlyrate">
                    <span className="hours">Hourly Rate </span>
                    <span className="weektime">
                      <span>{task.paymentTerms.hourly.ratePerHour} ℏ(s)/hr</span>
                      <span className="usd-form">{`(${task.usdRate} USD)`}</span>
                    </span>
                  </span>
                ) : null}

                <div className="task-bottom-right">
                  {task.paymentTerms.biddingEnabled ? (
                    <>
                      <div className="bidclose_div">
                        <div>{checkBidAllowed(task.paymentTerms) ? "Bid Closes in" : "Bid closed"}</div>
                        <div>
                          <img src={Images.time} alt={""} />
                          <Moment fromNow>{task.paymentTerms.hourly?.applyBefore ?? task.paymentTerms.bidExpiryDate}</Moment>
                        </div>
                      </div>
                      <button disabled={!checkBidAllowed(task.paymentTerms)} onClick={() => this.placeBid(task._id)}>
                        {checkBidAllowed(task.paymentTerms) ? "Bid Now" : "Biding Closed"}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="bidclose_div">
                        <div>Apply Before</div>
                        <div>
                          <img src={Images.time} alt={""} />
                          <Moment format="DD/MM/YYYY">{task.paymentTerms.bidExpiryDate}</Moment>
                        </div>
                      </div>
                      <button onClick={() => this.applyBid(task._id)}>Apply</button>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="task-bottom">
                <span className="hoursneed">
                  <span className="hours">Task Deadline </span>
                  <span className="weektime">
                    <Moment format="DD/MM/YYYY">{task.paymentTerms.fixed.taskDeadline}</Moment>
                  </span>
                </span>

                <span className="duration">
                  <span className="hours"> {!task.paymentTerms.biddingEnabled ? "Fixed Amount" : "Accepting Bids"} </span>
                  {!task.paymentTerms.biddingEnabled ? (
                    <span className="weektime">
                      {task.paymentTerms.fixed.rate} ℏ(s)
                      <span className="usd-form">{`(${task.usdRate} USD)`}</span>
                    </span>
                  ) : null}
                </span>
                <div className="task-bottom-right">
                  {task.paymentTerms.biddingEnabled ? (
                    <>
                      <div className="bidclose_div">
                        <div>{checkBidAllowed(task.paymentTerms) ? "Bid Closes in" : "Bid closed"}</div>
                        <div>
                          <img src={Images.time} alt={""} />
                          <Moment fromNow>{task.paymentTerms.fixed?.applyBefore ?? task.paymentTerms.bidExpiryDate}</Moment>
                        </div>
                      </div>
                      <button disabled={!checkBidAllowed(task.paymentTerms)} onClick={() => this.placeBid(task._id)}>
                        {checkBidAllowed(task.paymentTerms) ? "Bid Now" : "Biding Closed"}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="bidclose_div">
                        <div>Apply Before</div>
                        <div>
                          <img src={Images.time} alt={""} />
                          <Moment format="DD/MM/YYYY">{task.paymentTerms.bidExpiryDate}</Moment>
                        </div>
                      </div>
                      <button onClick={() => this.applyBid(task._id)}>Apply</button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="pagination_box">
          <Hide type="down" size={BreakPoints.sm}>
            <Pagination neighbours={5} totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={this.getCurrentPage} showControls={true} showJump={true} />
          </Hide>
          <Hide type="up" size={BreakPoints.sm}>
            <Pagination neighbours={3} totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={this.getCurrentPage} showControls={true} />
          </Hide>
        </div>
      </div>
    );
  };

  render() {
    let { tasks, search, count, loading } = this.state;

    return (
      <div className="List-Task-page">
        <div className="task-filter">
          <Hide type="down" size={BreakPoints.sm}>
            <div className="back" onClick={() => history.goBack()}>
              <img src={Images.back} alt={""} />
              {"Go Back"}
            </div>
          </Hide>
          {this.renderLeftSide()}
        </div>
        <div className="right">
          <Hide type="down" size={BreakPoints.sm}>
            <h2>Find Tasks</h2>
          </Hide>
          <div className="List-Task-page-container">
            <div className="List-Task-page-search">
              <div className="search">
                <input
                  placeholder={"Search for task title or description..."}
                  defaultValue={search}
                  name="search"
                  onKeyPress={(e) => this.searchTasks(e)}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      this.setFiltersDefault();
                      this.setState({ search: "", skills: [] }, () => this.getTasks(""));
                      this.props.history.push(`/Task/list-task?search= `);
                    }
                  }}
                />
                <img className="search-icon" src={Images.search} alt={""} />
              </div>
              <div className="task-search-filter">
                <div>{count ? count : 0} tasks found</div>
                <div className="filter-options">
                  <div>
                    <img src={Images.sorting} alt={""} />
                    Sort by:
                  </div>
                  <select className="select" name="sortBy" onChange={(e) => this.handleInputChange(e)}>
                    <option className="select-option" value="newest">
                      Newest
                    </option>
                    <option className="select-option" value="relevance">
                      Relevance
                    </option>
                    <option className="select-option" value="priceSort">
                      Price Short
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="tasks-container" style={{ position: loading ? "relative" : "inherit" }}>
              {loading ? <Loader height={"300px"} /> : tasks && tasks.length > 0 ? this.renderTasks() : <div className="no-task">No Task to show</div>}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="Recommend-task"
          aria-describedby="Recommend this task to other user"
          className="recomandTaskModal"
          open={this.state.rctModal.open}
          onClose={() => this.hadnleRctModalClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* {loading ? <Loader /> : */}
          <Fade in={this.state.rctModal.open}>
            <div className="recomandTaskModal__paper">
              <p>Whom do you want to recommend this task?</p>
              <div className="from-feild">
                <TextField variant="outlined" name="tasker_nicknamr" fullWidth label="Enter Username" value={this.state.rctModal.taskerId} onChange={(e) => this.setRcTasker(e.target.value)} />
              </div>
              <div className="btns">
                <button className="cencel" onClick={() => this.hadnleRctModalClose()}>
                  Cancel
                </button>
                <button className="send" onClick={() => this.sendRecomandation()}>
                  {this.state.rctModal.sendingRC ? <img src={Images.ellipsis_loader} alt="loader" /> : "Send"}
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default ListTask;
