import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import RouterOutlet from "./router-outlet";
import { GET_ANALYTICS, GET_TRANSACTION_HISTRORY } from '../../../services/api-service'
import "./index.scss";
import { Images } from "../../../assets/common";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import Hide from '../../../components/Hide'

const Dashboard = (props) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDashboardData();
    getTransactionHistory();
  }, [])

  const getDashboardData = async () => {
    setIsLoading(true)
    try {
      const userId = localStorage.getItem('currentUser')
      const response = await GET_ANALYTICS(userId);
      setIsLoading(false);
      const dashboardData = response.data.result;
      setDashboardData(dashboardData);
    } catch (error) {
      setIsLoading(false);
      notifier.error('Error', getErrorString(error))
    }
  }

  const getTransactionHistory = async () => {
    setIsLoading(true)
    try {
      const params = `pageNo=${1}`
      const response = await GET_TRANSACTION_HISTRORY(params);
      setIsLoading(false);
      const TransactionHistory = response.data.result.transactionHistory;
      setTransactionHistory(TransactionHistory);
    } catch (error) {
      setIsLoading(false);
      notifier.error('Error', getErrorString(error))
    }
  }

  return (
    <div className="dashboard">
      <div className="dashboard__left">
        <div className="dashboard-menu">
          <h1>Menu</h1>
          <div className="dashboard-menu__box">
            <NavLink
              exact
              activeClassName="dashboard-menu__item--active"
              className="dashboard-menu__item"
              to={`/home/dashboard`}
            >
              <span className="icon">
                <img src={Images.piChartIcon} alt="" />
              </span>
              <span>Account Analytics</span>
            </NavLink>

            <NavLink
              exact
              activeClassName="dashboard-menu__item--active"
              className="dashboard-menu__item"
              to={`/home/dashboard/transaction-history`}
            >
              <span className="icon">
                <img src={Images.transectionHistoryIcon} alt="" />
              </span>
              <span>Transaction History</span>
            </NavLink>
            <span className="dashboard-menu__item--active--bg"></span>
          </div>
        </div>
        <Hide type="down" size={576}>
          <div className="userAction">
            <p>
              Do you have something in mind needs solving, create new task now!
              and let the community help you out.
            </p>
            <Link to="/home/create-task/">Create Task</Link>
          </div>
          <div className="userAction">
            <p>
              Looking for work to do, find and apply to tasks submitted by other
              members of the community{" "}
            </p>
            <Link to="/Task/list-task?search=">Click Here</Link>
          </div>
        </Hide>
      </div>
      <div className="dashboard__content">
        <RouterOutlet
          dashboardData={dashboardData}
          transactionHistory={transactionHistory}
          loading={isLoading}
          {...props}
        />
      </div>
      
    </div>
  );
};
export default Dashboard;
