import { GET_TASKS, REQ_BID_DETAILS, GET_FEES_FOR_TASK, GET_CHAT_ID, GET_NEGOTIATION_HISTORY, REQ_BIDS_FOR_MY_TASK } from "../../../services/api-service";
import { API_getTasksParams, TaskStatuses } from "../../../types";

// API request for getting user token while connecting wallet
export async function getTasks(prams: API_getTasksParams) {
  return await GET_TASKS(prams);
}

export async function getBidsOrProposalsDetails(taskId: string) {
  return await REQ_BID_DETAILS({ taskId });
}

export async function getTaskSummary(bidId: string, pay: boolean) {
  return await GET_FEES_FOR_TASK(bidId, pay);
}

export async function getChatModalIForTask(taskId: string) {
  return await GET_CHAT_ID({ taskId });
}
export async function getNegotiationHistory(bidId: string) {
  return await GET_NEGOTIATION_HISTORY({ bidId });
}

export async function getBidsForMyTask(bidStatus: TaskStatuses) {
  return await REQ_BIDS_FOR_MY_TASK({ bidStatus });
}
