import React, { Children } from "react";
import { Redirect } from "react-router-dom";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface PropsType {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<PropsType, ErrorBoundaryState> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to={"/"} />;
    }

    return this.props.children;
  }
}
