import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Link } from 'react-router-dom'

export const ResendVerificationAlert = ({ email }) => (
    <Alert severity="warning">
        <AlertTitle>Alert:</AlertTitle>
        <strong>Please verify your email, before login!</strong><br />
        If you want to resend your email verification link. <Link to={`/email-verifying?resendEmail=true${email ? `&email=${email}` : ""}`}> Click here</Link>
    </Alert >
) 