import React from 'react';
import Header from '../../components/header';
import HeaderBoard from '../home/header-board';
import './index.scss';
import InviteForm from './InviteForm';



const InviteOnTaskbar = (props) =>{
    return(
        <div className="invite-page">
            <Header {...props} />
            <div className="invite-page-container">
                <HeaderBoard {...props}/>
                <div className="invite-page-content">
                    <div className="invite-form-box">
                        <InviteForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InviteOnTaskbar;