import React from "react";
import propType from "prop-types";

const TabPane = (props) => {
  return <div className="TabPaneArea">{props.children}</div>;
};

TabPane.propType = {
  name: propType.string,
};
export default TabPane;
