import {Collapse, Slide} from "@material-ui/core";
import {MESSAGE} from "../../constants/messages";
import React from 'react';

export const getErrorString = (
  e,
  defaultErrorText = "Something went wrong!"
) => {
  return e.response &&
  e.response.data &&
  e.response.data.message &&
  typeof e.response.data.message === "string"
      ? e.response.data.message
      : e.message && typeof e.message === "string"
          ? e.message
          : typeof e === "string"
              ? e
              : defaultErrorText;
};

export const validateRegisterData = (data) => {
  //newPassword, confirmNewPassword
  const {email, password, cpassword} = data;
  let error = {};
  if (email.length > 0) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    if (!email.match(re)) {
      error.email = MESSAGE.ERROR.EMAIL_INVALID;
    }
  } else {
    error.email = MESSAGE.ERROR.EMAIL_EMPTY;
  }
  if (password) {
    var pass = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (!password.match(pass)) {
      error.password = MESSAGE.ERROR.PASSWORD_CRITERIA;
      //console.log("hy", password)
    } else if (password !== cpassword) {
      error.cpassword = MESSAGE.ERROR.CPASSWORD_INVALID;
    }
  } else {
    error.password = MESSAGE.ERROR.PASSWORD_EMPTY;
  }

  return Object.keys(error).length > 0 ? error : false;
};

export const validatePasswordData = (data) => {
  const {newPassword, confirmNewPassword} = data;
  let error = {};
  if (newPassword) {
    var pass = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (!newPassword.match(pass)) {
      error.newPassword = MESSAGE.ERROR.PASSWORD_CRITERIA;
    } else if (newPassword !== confirmNewPassword) {
      error.confirmNewPassword = MESSAGE.ERROR.CPASSWORD_INVALID;
    }
  } else {
    error.newPassword = MESSAGE.ERROR.PASSWORD_EMPTY;
  }
  return Object.keys(error).length > 0 ? error : false;
};

export const validateSuperSkills = (data) => {
  //paymentType, portfolio
  const {title, description, skills,  rate, } = data;
  let error = {};
  //console.log("superskill data", data)
  if (title) {
    if (title.length < 3) {
      error.title = MESSAGE.ERROR.TITLE_LENGTH;
    }
  } else {
    error.title = MESSAGE.ERROR.TITLE_EMPTY;
  }
  if (description) {
    if (description.length < 3) {
      error.description = MESSAGE.ERROR.TITLE_LENGTH;
    }
  } else {
    error.description = MESSAGE.ERROR.DESCRIPTION_EMPTY;
  }
  if (skills.length === 0) {
    error.skills = MESSAGE.ERROR.SKILLS_EMPTY;
  }
  if (!rate) {
    error.rate = MESSAGE.ERROR.RATE_EMPTY;
  }

  return Object.keys(error).length > 0 ? error : false;
};

export const getFavriteTaskers = (userDetails) => {
  let favTaskers = [];
  favTaskers = userDetails.profileStats.favouriteTaskers;
  return favTaskers;
};

export const getFavriteTask = (userDetails) => {
  let favTask = [];
  favTask = userDetails.profileStats.favouriteTasks;
  return favTask;
};

export const rateConversionUSDTask = (tasks) => {
  let conversionRate = localStorage.getItem("conversionRate");
  const data = tasks.filter((task) => {
    task["usdRate"] = (task.rate * conversionRate).toFixed(4);
    task["fullHbars"] = task.rate;
    task["rate"] =
      countDecimals(task.rate) >= 4 ? task.rate.toFixed(4) : task.rate;
    return task;
  });
  return data;
};

export const rateConversionUSDBid = (tasks) => {
  let conversionRate = localStorage.getItem("conversionRate");
  const data = tasks.filter((task) => {
    task["usdRate"] = (task.amount * conversionRate).toFixed(4);
    task["fullHbars"] = task.amount;
    task["amount"] =
      countDecimals(task.amount) >= 4 ? task.amount.toFixed(4) : task.amount;
    return task;
  });
  return data;
};

export const rateConversionUSDTasker = (tasks) => {
  let conversionRate = localStorage.getItem("conversionRate");
  const data = tasks.filter((task) => {
    if (task.currency === "hbar") {
      task["usdRate"] = (task.ratePerHour * conversionRate).toFixed(4);
      task["fullHbars"] = task.ratePerHour;
      task["ratePerHour"] =
        countDecimals(task.ratePerHour) >= 4
          ? task.ratePerHour.toFixed(4)
          : task.ratePerHour;
    } else {
      task["usdRate"] = task.ratePerHour.toFixed(4);
      task["fullHbars"] = (task.ratePerHour / conversionRate).toFixed(4);
      task["ratePerHour"] = (task.ratePerHour / conversionRate).toFixed(4);
    }
    return task;
  });
  return data;
};

export const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
};

export const convertSecondsToHoursMin = (n) => {
  // let day = parseInt(n / (24 * 3600));
  n = n % (24 * 3600);
  let hour = parseInt(n / 3600);
  n %= 3600;
  let minutes = parseInt(n / 60);
  n %= 60;
  let seconds = n;
  //console.log(day + "days" + hour + "hours" + minutes + "minutes" + seconds + "seconds")
  return `${hour}:${minutes}:${seconds}`;
  // return `${hour}hrs ${minutes}min ${seconds}sec`
};

export const paginationLimit = 10;

export const QRCodeURL = (account, amount, memoCode) => {
  return `hbarprice://pay?acc=${account}&amt=${amount*Math.pow(10, 8)}&memo=${memoCode}`;
};

export const dateToLocalString = (isoDate) => {
  let date = new Date(isoDate);
  return `${date.toLocaleDateString()} At ${date.toLocaleTimeString()}`;
};

export const serialize = (params) => {
  var str = [];
  for (var p in params)
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  return str.join("&");
};


export const popOverSlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" style={{transitionDelay:'50ms'}}  ref={ref} {...props} />;
});
export const popOverCollapseTransition = React.forwardRef(function Transition(props, ref) {
  return <Collapse  ref={ref} {...props} />;
});