import {Popover} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {Images} from "../../assets/common";
import {dynamicSort, useWindowSize , StyledBackdrop} from "../../lib/validators";
import { popOverSlideTransition } from "../validator";
import "./index.scss";

function NotificationPopup(props) {
  // const profileImg = localStorage.getItem('profileImagePath')
  const size = useWindowSize();
  const [notifications, setNotifications] = useState([]);
  const {notificationArray, newNotification} = props;
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setNotifications(notificationArray.reverse().slice(0, 5));
  }, [notificationArray]);

  const renderMessage = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText;
  };

  const markAsComplete = () => {
    props.markAsComplete(false);
  };

  const openNotificationPopover = (e) => {
    setAnchorEl(e.currentTarget);
    // console.log(notificationArray)
  };
  const closeNotificationPopover = () => {
    setAnchorEl(null);
  };

  const notificationPopupOpen = Boolean(anchorEl);
  const notificationPopupId = notificationPopupOpen
    ? "notificationPopover"
    : undefined;

  const renderNotificationContent = () => {
    return (
      <div className="Notification-page">
        <div className="Notification-page-container">
          <div className="Notification-container">
            {notificationArray.length > 0 ? (
              <div className="Notification-div">Notifications</div>
            ) : null}
            {notificationArray.length > 0 ? (
              <div className="read-div" onClick={() => markAsComplete()}>
                Mark all as read
              </div>
            ) : null}
          </div>
          <div className="first-container">
            {notificationArray && notificationArray.length > 0 ? (
              notificationArray
                .sort(dynamicSort("timeStamp", "desc"))
                .slice(0, 5)
                .map((data, i) => {
                  return (
                    <div className="designer-container" key={i}>
                      {/* <div className="profile-div">
                                    <img src={profileImg} className="profile-img" />
                                </div> */}
                      <div className="details">
                        <div className="details-container">
                          <div className="para">
                            {data.isViewed ? (
                              <b style={{color: "#c6c6c6"}}>
                                {renderMessage(data.notification.body)}
                              </b>
                            ) : (
                              <b>{renderMessage(data.notification.body)}</b>
                            )}
                            {/* <span>Click to know more</span> */}
                          </div>
                          <div className="duration">
                            <Moment fromNow>{data.timeStamp}</Moment>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="no-notification">No notifications to show</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="notification">
      <button onClick={openNotificationPopover} className="notificationBtn">
        <div className={`circle ${newNotification ? "active" : ""}`}></div>
        <img src={Images.notification} className="notification-img" alt="" />
      </button>
      {size.width < 577 ? (
        <Popover
          id={notificationPopupId}
          open={notificationPopupOpen}
          anchorEl={anchorEl}
          BackdropComponent={StyledBackdrop}
          onClose={closeNotificationPopover}
          anchorReference="anchorPosition"
          anchorPosition={{top: 0, left: 0}}
          PaperProps={{
            className: "notificationPopoverMobile",
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          TransitionComponent={popOverSlideTransition}
          >
          {renderNotificationContent()}
        </Popover>
      ) : (
        <Popover
          id={notificationPopupId}
          open={notificationPopupOpen}
          anchorEl={anchorEl}
          onClose={closeNotificationPopover}
          PaperProps={{
            className: "notificationPopover",
          }}
          anchorOrigin={{
            vertical: 50,
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          {renderNotificationContent()}
        </Popover>
      )}
    </div>
  );
}
export default NotificationPopup;
