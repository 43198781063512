import { Backdrop, Fade, FormControlLabel, Modal, Radio, RadioGroup, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Images } from "../../../assets/common";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { StyledBackdrop } from "../../../lib/validators";
import { DELETE_ACCOUNT } from "../../../services/api-service";

const CloseAccount = (props) => {
  const [value, setValue] = useState("1/I don’t need your service right now.");
  const [loading, setLoader] = useState(false);
  const [isOpenAlertPopup, setAlertModal] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const closeAccount = async () => {
    // const user_Id = localStorage.getItem("currentUser");
    try {
      setLoader(true);
      const requestBody = {
        disableReasonId: parseInt(value.split("/")[0]),
        disableReason: value.split("/")[1],
      };
      const response = await DELETE_ACCOUNT(requestBody);
      if (response.data.status === "success") {
        setLoader(false);
        notifier.success(
          "Success",
          MESSAGE.SUCCESS.ACCOUNT_DELETED_SUCESSFULLY
        );
         setAlertModal(false);
        setTimeout(() => {
          props.history.push("/login");
        }, 1000);
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const handleAlertClose = () =>{
    setAlertModal(false);
  }

  return (
    <>
      <div className="setting-opt-content__heading">
        <h2>Close Account</h2>
      </div>
      <div className="setting-opt-content__main">
        <div className="closeAccount">
          <RadioGroup
            aria-label="close-account"
            name="closeAccountReason"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"1/I don’t need your service right now."}
              control={<Radio />}
              label="I don’t need your service right now."
            />
            <FormControlLabel
              value={"2/There’s too much competition."}
              control={<Radio />}
              label="There’s too much competition."
            />
            <FormControlLabel
              value={"3/The service is too expensive."}
              control={<Radio />}
              label="The service is too expensive."
            />
            <FormControlLabel
              value={"4/I’m having trouble breaking into the marketplace."}
              control={<Radio />}
              label="I’m having trouble breaking into the marketplace."
            />
            <FormControlLabel
              value={"5/Other"}
              control={<Radio />}
              label="Other"
            />
          </RadioGroup>
        </div>
      </div>
      <div className="setting-opt-content__footer">
        {/* <button className="cancel">Cancel</button> */}
        <button className="save" onClick={() => setAlertModal(true)}>
          Save
        </button>
      </div>
      <Modal
        aria-labelledby="alert-modal"
        aria-describedby="Alert Modal before closing account."
        className="alertModal"
        open={isOpenAlertPopup}
        onClose={handleAlertClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenAlertPopup}>
          <div className="alertModal__paper">
            <p>Are you sure? you want to close your account.</p>
            <div className="btns">
              <button className="cancel" onClick={handleAlertClose}>
                Cancel
              </button>
              <button className="yes" onClick={closeAccount}>
                {loading ? (
                  <img alt="" src={Images.ellipsis_loader} />
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CloseAccount;
