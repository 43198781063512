import React from 'react'

function SingleSuperSkill() {
    return (
        <div>
            SingleSuperSkill
        </div>
    )
}

export default SingleSuperSkill
