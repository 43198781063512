import React from "react";
import { NavLink } from "react-router-dom";
import { Images } from "../../../assets/common";
import "./index.scss";
import RouterOutlet from "./router-outlet";



const Favourite = (props) => {
  return (
    <div className="favourite-page-content">
      <div className="favourite-options">
        <div className="favourite-opt-menu">
          <h1>Favorite</h1>
          <div className="favourite-opt-menu__box">
            <NavLink
              exact
              activeClassName="favourite-opt-menu__item--active"
              className="favourite-opt-menu__item"
              to={`/home/favourite/tasks`}
            >
              <span className="icon">
                {/* <VpnKeyIcon /> */}
                <img src={Images.EditProfileIcon} alt=""/>
              </span>
              <span>Tasks</span>
            </NavLink>

            <NavLink
              exact
              activeClassName="favourite-opt-menu__item--active"
              className="favourite-opt-menu__item"
              to={`/home/favourite/taskers`}
            >
              <span className="icon">
               <img src={Images.user}  alt=""/>
              </span>
              <span>Taskers</span>
            </NavLink>
            <span className="favourite-opt-menu__item--active--bg"></span>
          </div>
        </div>
      </div>
      <div className="favourite-opt-content">
        <RouterOutlet {...props} />
      </div>
    </div>
  );
};
export default Favourite;
