import React, {Component} from "react";
import {Link, NavLink, withRouter } from "react-router-dom";
import "./index.scss";
import {Images} from "../../../assets/common";
import Hide from "../../../components/Hide";
import {BreakPoints} from "../../../lib/validators";
import {MobileMenuContext} from "../../../context/MobileMenuContext";

export const HeaderBoard = withRouter(({ history, location }) =>{

})

class HeaderBoardComp extends Component {
  static contextType = MobileMenuContext;

  componentDidMount() {
    const {setMobileMenuOpenStatus} = this.context;
    const {history} = this.props
    history.listen((location) => {
      setMobileMenuOpenStatus(false);
    });
  }

  render() {
    const logedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const userRole = localStorage.getItem("loggedInUser")
      ? logedInUser.role
      : null;
    const isAdmin =
        userRole === "Admin" || userRole === "Super-Admin";
    const {isMobileMenuOpen, setMobileMenuOpenStatus} = this.context;

    return (
      <>
        <div
          className={
            isMobileMenuOpen
              ? "dashboard-container mobileOpen"
              : "dashboard-container"
          }>
          <Hide type="up" size={BreakPoints.sm}>
            <div className="logo_heading">
              <Link to={`/`}>
                <img src={Images.Logo} alt="logo" />
              </Link>
              <span onClick={() => setMobileMenuOpenStatus(false)}>
                <img src={Images.IconForCloseModal} alt="Close Icon" />
              </span>
            </div>
          </Hide>
          <div className="design-details">
            <NavLink
              to={`/home/dashboard`}
              className="design"
              activeClassName="activeNavLink">
              <span>
                <img src={Images.menuDrawerIcons.dashboard} alt="" />
              </span>
              Dashboard
            </NavLink>
            <NavLink
              to={`/home/leaderboard`}
              className="design"
              activeClassName="activeNavLink">
              <span>
                <img src={Images.menuDrawerIcons.leaderBoard} alt="" />
              </span>
              Leaderboard
            </NavLink>
            <NavLink
              to={`/home/profile`}
              className="design"
              activeClassName="activeNavLink">
              <span>
                <img src={Images.menuDrawerIcons.myProfile} alt="" />
              </span>
              My Profile
            </NavLink>
            <NavLink
              to={`/home/my-tasks`}
              className="design"
              activeClassName="activeNavLink">
              <span>
                <img src={Images.menuDrawerIcons.myTasks} alt="" />
              </span>
              My Tasks
            </NavLink>
            <NavLink
              to={`/home/wallet`}
              className="design"
              activeClassName="activeNavLink">
              <span>
                <img src={Images.menuDrawerIcons.wallet} alt="" />
              </span>
              Wallet
            </NavLink>
            {isAdmin ? (
              <NavLink
                to={`/admin/users`}
                className="design"
                activeClassName="activeNavLink">
                <span>
                  <img height="22" src={Images.menuDrawerIcons.admin} alt="" />
                </span>
                Admin
              </NavLink>
            ) : null}
          </div>
          <div className="create-fav">
            <div className="plus">
              <NavLink
                to={`/home/create-task`}
                className="create_div"
                activeClassName="activeNavLink">
                {" "}
                <span>
                  <img src={Images.menuDrawerIcons.createTask} alt="" />
                </span>
                <img src={Images.plus} alt={""} className="addicon" />
                Create Task
              </NavLink>
            </div>
            <div className="plus">
              <NavLink
                to={`/home/favourite`}
                className="create_div"
                activeClassName="activeNavLink">
                {" "}
                <span>
                  <img src={Images.menuDrawerIcons.fav} alt="" />
                </span>
                <img src={Images.fav} alt="" /> Favourite
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(HeaderBoardComp);
