import React from "react"

const NoDataToShow = () => {

    return (
        <div className='no-data'>
            No data to show
        </div>
    )
}

export default NoDataToShow;