import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { HashConnect } from "hashconnect";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Provider } from "react-redux";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundy";
import { IS_STAGING, NETWORK } from "./constants";
import HashConnectProvider from "./Hooks/useHashConnect/HashConnectAPIProvider";
import "./index.scss";
import { store } from "./redux/app/store";
import * as serviceWorker from "./serviceWorker";
import StagingApp from "./StagingApp";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashConnectProvider
        metaData={{
          name: "Taskbar",
          description: "Allow your extension to connect with taskbar dApp",
          icon: "https://mytaskbar.io/images/apple-icon-114x114.png",
        }}
        netWork={NETWORK}
        debug
      >
        <ErrorBoundary>
          <ReactNotification />
          {IS_STAGING ? <StagingApp /> : <App />}
        </ErrorBoundary>
      </HashConnectProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
IS_STAGING ? serviceWorker.unregister() : serviceWorker.register();
