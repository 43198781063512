import { IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Link, withStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect, useState } from "react";
import ConfirmModal from "../../../components/deleteConfirmModal";
import Loader from "../../../components/Loader";
import NoDataToShow from "../../../components/NoDataToShow";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { dragonURl } from "../../../constants";
import { getErrorString, paginationLimit, serialize } from "../../../components/validator";
import { GET_ALL_UNHANDLED_TRANSACTION, UNHANDLED_TRANSACTION_OPERATION } from "../../../services/api-service";
import "../transactions/index.scss";

const UnhandledTransection = (props) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [operationDetails, setOperationDetails] = useState({
    id: "",
    operation: "",
    text: "",
    yesBtnText: "",
  });

  useEffect(() => {
    search();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "from") {
      setFrom(value);
    } else if (name === "to") {
      setTo(value);
    }
  };

  const search = async (reqObj) => {
    setIsLoading(true);
    try {
      const params = {
        pageNo: pageNumber,
        limit: paginationLimit,
      };
      if (from) params["startDate"] = from;
      if (to) params["endDate"] = to;
      if (!reqObj) {
        params["all"] = true;
      } else {
        if (reqObj.all) params["all"] = reqObj.all;
        if (reqObj.pageNo) params["pageNo"] = reqObj.pageNo;
      }

      const response = await GET_ALL_UNHANDLED_TRANSACTION(serialize(params));

      const transactions = response.data.result.transactions;
      setTransactions(transactions);
      setTotalRecord(response.data.result.totalCount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifier.error("Error", getErrorString(error));
    }
  };

  const handlePageChange = (pageNo) => {
    setPageNumber(pageNo);
    search({
      pageNo: pageNo,
    });
  };

  const askOperation = (id, operation) => {
    setOperationDetails({
      id: id,
      operation: operation,
      text: operation === "keep" ? "Are you sure? you want to keep this transaction." : "Are you sure? you want to revert this transaction",
      yesBtnText: operation === "keep" ? "Yes Keep" : "Yes Revert",
    });
    setConfirmModal(true);
  };

  const handleOperation = async (transactionDocumentId, operation) => {
    try {
      const reqBody = {
        operation: operation,
        transactionDocumentId: transactionDocumentId,
      };

      const response = await UNHANDLED_TRANSACTION_OPERATION(reqBody);
      if (response.data.status === "success") {
        notifier.success("Operation Performed Successfully");
        setConfirmModal(false);
        search();
      }
    } catch (e) {
      setConfirmModal(false);
      notifier.error("Error", getErrorString(e));
    }
  };

  const doOperation = () => {
    handleOperation(operationDetails.id, operationDetails.operation);
  };

  return (
    <div className="transactions">
      <div className="heading">
        <h4>Transaction History</h4>
        <div className="user-type">
          <TextField id="Select-transaction-type" select label="Select Transaction Type" name="transactionType" fullWidth onChange={(e) => search({ all: e.target.value })} variant="outlined" size="small">
            <MenuItem value={true}>ALL</MenuItem>
            <MenuItem value={false}>Pending Transaction</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="filterArea" style={{ padding: "10px 12px" }}>
        <div className="right-side">
          <h4>From :</h4>
          <div className="input-container">
            <input type="date" name="from" value={from} onChange={handleInputChange} min={"2000-01-01"} />
          </div>
          <h4>To :</h4>
          <div className="input-container">
            <input type="date" name="to" value={to} onChange={handleInputChange} min={from} />
          </div>
          <button onClick={() => search()}>Search</button>
        </div>
      </div>
      <TableContainer className="transactions__content">
        {isLoading ? (
          <Loader />
        ) : transactions && transactions.length > 0 ? (
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="200px" />
              <col />
              <col />
              <col />
              <col />
              <col width="120px" />
            </colgroup>

            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Transaction ID</StyledTableCell>
                <StyledTableCell>Transaction Amount</StyledTableCell>
                <StyledTableCell>Sender Account</StyledTableCell>
                <StyledTableCell> Consensus Timestamp</StyledTableCell>
                <StyledTableCell>Memo</StyledTableCell>
                {/* <StyledTableCell>Transaction Type</StyledTableCell> */}
                <StyledTableCell>Operations</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {transactions.map((data, index) => {
                return (
                  <StyledTableRow key={data._id}>
                    <StyledTableCell>
                      <Link target="_blank" href={`${dragonURl}/hedera/transactions/${data.transactionId.replace(/[@.-]/g, "")}`}>
                        {data.transactionId}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>{data.transactionAmountReceived.toFixed(4)}</StyledTableCell>
                    <StyledTableCell>{data.senderAccount}</StyledTableCell>
                    <StyledTableCell>{data.consensusTimestamp}</StyledTableCell>
                    <StyledTableCell>{data.memoCode.trim() === "" ? "Not available" : data.memoCode}</StyledTableCell>
                    {/* <StyledTableCell>{data.transactionType} </StyledTableCell> */}
                    <StyledTableCell>
                      {data.reverted || data.keep ? (
                        data.keep ? (
                          "Keeped"
                        ) : (
                          "Reverted"
                        )
                      ) : (
                        <>
                          <StyledTooltip title="Keep" placement="top" arrow>
                            <IconButton size="small" style={{ color: "green" }} onClick={askOperation.bind(this, data._id, "keep")}>
                              <CheckCircleOutlineIcon />
                            </IconButton>
                          </StyledTooltip>
                          &nbsp;&nbsp;
                          <StyledTooltip title="Revert" placement="top" arrow>
                            <IconButton size="small" onClick={askOperation.bind(this, data._id, "revert")}>
                              <RefreshIcon />
                            </IconButton>
                          </StyledTooltip>
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <NoDataToShow />
        )}
      </TableContainer>
      <div className="pagination">
        <Pagination totalRecord={totalRecord} pageNumber={pageNumber} sendCurrentPage={handlePageChange} showControls={true} />
      </div>
      <ConfirmModal modal={confirmModal} yesCallback={() => doOperation()} cancelCallback={() => setConfirmModal(false)} text={operationDetails.text} yesBtnLabel={operationDetails.yesBtnText} />
    </div>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    border: "none",
    fontSize: 13,
    color: "#2c2c2c",
    "&:last-child": {
      textAlign: "right",
      textTransform: "uppercase",
    },
    "&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(2)": {
      textAlign: "center",
    },
  },
  head: {
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #dddddd",
    textTransform: "uppercase",
  },
  stickyHeader: {
    zIndex: "auto",
  },
  body: {
    "& span.cnf": { color: "#07D62F" },
    "& span.rej": { color: "#ED0B0B" },
    "& span.pending": { color: "#2C2C2C" },
  },
}))(TableCell);

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2c2c2c",
    color: "#ffffff",
    fontSize: 12,
  },
}))(Tooltip);

export default UnhandledTransection;
