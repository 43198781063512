import React, { Component } from "react";
import { Switch } from "react-router";
import PrivateRoute from "../../../components/privateRoute";
import FavTaskers from "./taskers";
import FavTasks from "./tasks";


class RouterOutlet extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <PrivateRoute path={`${match.url}/tasks`} component={FavTasks} />
        <PrivateRoute path={`${match.url}/taskers`} component={FavTaskers} />

        {/*Deafult Page For Setting*/}
        <PrivateRoute exact path={`${match.url}/`} component={FavTasks} />
      </Switch>
    );
  }
}

export default RouterOutlet;
