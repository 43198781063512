import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets/common";
import notifier from "../../components/notifier";
import { getErrorString } from "../../components/validator";
import { MobileMenuContext } from "../../context/MobileMenuContext";
import { onMessageListener } from "../../firebase";
import {
  GET_CONVERSION_RATE,
  REQ_USER_DETAILS
} from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import Hide from "../Hide";
import MobileSearchBoxPopOver from "../mobileSearchBoxPopOver";
import NotificationPopup from "../notification-popup";
import Popup from "../popup";
import "./index.scss";

class Header extends Component {
  static contextType = MobileMenuContext;
  constructor(props) {
    super(props);
    this.state = {
      profilePopup: false,
      notification: false,
      search: "",
      userDetails: [],
      notificationArray: [],
      newNotification: false,
      showMobileSearch: false,
    };
    this.profielIcon = React.createRef();
  }

  componentDidMount = () => {
    const token = cookieStorage.getCookie("token");
    const notificationMessage = localStorage.getItem("Notification Message");
    if (!_.isEmpty(token)) {
      this.getLoggedInUserDetails();
    }
    if (notificationMessage) {
      let notificationArray = JSON.parse(notificationMessage);
      this.setState({notificationArray}, () => this.getFireBaseNotifications());
    } else {
      this.getFireBaseNotifications();
    }

    this.getConversionRate();
    
    // console.log(isMobileMenuOpen);
  };

  getFireBaseNotifications = () => {
    try {
      // const messaging = firebase.messaging()
      this.checkNewNotification();
      onMessageListener().then((payload) => {
        let notificationArray = this.state.notificationArray;
        payload.isViewed = false;
        payload.timeStamp = new Date().getTime();
        notificationArray.push(payload);
        localStorage.setItem(
          "Notification Message",
          JSON.stringify(notificationArray)
        );
        this.setState({notificationArray}, () => this.checkNewNotification());
        //console.log('Message received. ', payload);
        // ...
        new Audio(Images.notificationSound).play();
      });
    } catch (e) {
      console.log("Firebase Error", e);
    }
  };

  checkNewNotification = () => {
    let newNotification = false;
    this.state.notificationArray.forEach((d) => {
      if (!d.isViewed) {
        newNotification = true;
      }
    });
    this.setState({newNotification});
  };

  getConversionRate = async () => {
    try {
      const response = await GET_CONVERSION_RATE();
      let result = response.data.result;
      localStorage.setItem("conversionRate", result.conversionRateHbarToUsd);
      localStorage.setItem(
        "conversionDate",
        result.conversionRateHbarToUsdTime
      );
    } catch (e) {
      getErrorString(e);
      console.log("rahul", e);
    }
  };

  getLoggedInUserDetails = async () => {
    const userId = localStorage.getItem("currentUser");
    try {
      const requestBody = {
        userId: userId,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      let userDetails = response.data.result;
      localStorage.setItem("loggedInUser", JSON.stringify(userDetails));
      this.setState({userDetails});
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    this.setState({search: value});
    if (event.key === "Enter") {
      switch (id) {
        case "search":
          this.handlePageRedirection(value);
          break;
        default:
          console.warn("Something is not right !");
      }
    }
  };

  handlePageRedirection = (data) => {
    const searchValue = data;
    this.props.history.push(`/Task/list-task?search=${searchValue}`);
  };

  onSearchClick = () => {
    if (this.state.search) this.handlePageRedirection(this.state.search);
  };

  NotificationPopup = () => {
    this.setState({
      notification: !this.state.notification,
      profilePopup: false,
    });
  };

  Popup = () => {
    this.setState({
      profilePopup: !this.state.profilePopup,
      notification: false,
    });
  };

  markAsComplete = (status) => {
    let notificationArray = [...this.state.notificationArray].filter((d) => {
      d.isViewed = true;
      return d;
    });
    localStorage.setItem(
      "Notification Message",
      JSON.stringify(notificationArray)
    );
    this.setState({newNotification: status, notification: status});
  };

  //Show Mobile search
  showMobileSearch = () => {
    this.setState({showMobileSearch: !this.state.showMobileSearch});
  };

  handleAviablityChange = () => {
    const userString = localStorage.getItem("loggedInUser");
    this.setState({
      userDetails:
        userString && userString !== "" ? JSON.parse(userString) : [],
    });
  };

  render() {
    const {shouldShrink} = this.props;
    const currentUser = !_.isEmpty(cookieStorage.getCookie("token"))
      ? true
      : false;
    const {userDetails, newNotification, notificationArray} = this.state;
    const profileImg = localStorage.getItem("profileImagePath");
    const {setMobileMenuOpenStatus} = this.context;


    return (
      <header className="header">
        <div className={`site-branding ${shouldShrink ? "shrink" : ""}`}>
          <div className="header-right">
            <Link to={`/`} className="login-btn">
              <img alt={""} className="logo" src={Images.Logo} />
            </Link>
            {currentUser ? (
              <div
                className={ "search"}>
                <input
                  placeholder="Find Task/Tasker"
                  name="search"
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyPress={(e) => this.handleInputChange(e)}
                />
                <img
                  className="search-icon"
                  src={Images.search}
                  alt={""}
                  onClick={() => this.onSearchClick()}
                />
              </div>
            ) : null}
          </div>
          {currentUser ? (
            <div className="header-left">
              <MobileSearchBoxPopOver {...this.props}/> 
              <NotificationPopup
                notificationArray={notificationArray}
                markAsComplete={this.markAsComplete}
                newNotification={newNotification}
              />
              <Popup
                userDetails={userDetails}
                availabilityChanged={this.handleAviablityChange}
                profileImg={profileImg}
                UserAvailability={userDetails.availability}
              />
              <Hide type="up" size={576}>
                <div
                  className="mobileMenuIcon"
                  onClick={() => setMobileMenuOpenStatus(true)}>
                  <img src={Images.mobileMenu} alt="" />
                </div>
              </Hide>
            </div>
          ) : (
            <div className="button-container">
              <Link to={`/login`} className="login-btn">
                Log in
              </Link>
              <Link to={`/register`} className="sign-up-btn">
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </header>
    );
  }
}
export default Header;
