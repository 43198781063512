import { Button, TextField, Modal } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Images } from "../../../assets/common";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { ActivityListContext } from "../../../context/ActivityListContext";
import { useDebounce } from "../../../lib/validators";
import { ADD_POINTS_TO_AN_USER, ADMIN_GET_ALL_USER } from "../../../services/api-service";

export default function AddPointsToUser() {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [des, setDes] = useState('');
  const [value, setValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { activityList } = useContext(ActivityListContext);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        searchUsers(debouncedSearchTerm).then((results) => {
          setIsSearching(false);
          setUsers(results);
        });
      } else {
        setUsers([]);
        setIsSearching(false);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const searchUsers = async (searchTerms) => {
    try {
      const reqBody = {
        searchPhrase: searchTerms,
      };
      const response = await ADMIN_GET_ALL_USER(reqBody);
      return response.data.result.userDetails;
    } catch (e) {
      notifier.error("Error", getErrorString(e));
      return [];
    }
  };

  const onModalClose = () => {
    setSelectedUser(null);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);


    try {
      const reqBody = {
        userId: selectedUser._id,
        description: des,
        points: value,
        activity: activityList.admin.pointsRewarded
      }

      const response = await ADD_POINTS_TO_AN_USER(reqBody);
      if (response.data.status === "success") {
        setSelectedUser(null);
        setDes(''); setValue(0); setIsLoading(false);
        notifier.success('Points Added::', response.data.result);
      }
    }
    catch (e) {
      console.log(e);
      notifier.error('Error::', getErrorString(e));
      setSelectedUser(null);
      setDes(''); setValue(0); setIsLoading(false);
    }

    console.log()
  }

  return (
    <div className="addPointsToUser">
      <div className="searchUser">
        <div className="searchUser__inner">
          <TextField
            label="Search user"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      {isSearching && <img src={Images.ellipsis_loader} alt="Searching..." />}
      <div className="userList">
        {users.map((user, index) => user?.nickName && <div className="userList__item" key={user?._id}>
          <p>
            <span className="label">Name: </span>
            <span className="val">
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          </p>
          <p>
            <span className="label">Username: </span>
            <span className="val">{`@${user?.nickName}`}</span>
          </p>
          <p>
            <span className="label">Email:</span>
            <span className="val"> {`${user?.email}`}</span>
          </p>
          <Button size="small" variant="contained" color="primary" onClick={() => setSelectedUser(user)}>
            + Points
          </Button>
        </div>
        )}
      </div>
      <Modal
        open={selectedUser ? true : false}
        aria-labelledby="Add-point"
        aria-describedby="Add Point to na user"
        className={`editPoint`}
        onClose={() => onModalClose()}>
        <div className="editPoint__paper">
          <div className="editPoint__header">
            <b>Add Point to  <Link target="_blank" to={`/${selectedUser?.nickName}`}>@{selectedUser?.nickName}</Link></b>
          </div>
          <div className="editPoint__body">
            <form className="editPoint__form" onSubmit={handleSubmit}>
              <div className="editPoint__inputBox">
                <p>Reason For Adding Point to this user</p>
                <TextField
                  label="Reason"
                  value={des}
                  onChange={(e) => setDes(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </div>

              <div className="editPoint__inputBox">
                <p>How much point you want to add this user?</p>
                <TextField
                  label="Points Value"
                  type="number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className="editPoint__inputBox">
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => onModalClose()}
                  color="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {isLoading ? <img src={Images.ellipsis_loader} alt="loading..." /> : 'Submit'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
