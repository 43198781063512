import React from 'react';

interface Props {
	className?: string;
}

export const ArrowDownIcon = (props: Props) => {
	return (
		<svg height="7.407" width="12.993" viewBox="0 0 12.993 7.407" xmlns="http://www.w3.org/2000/svg">
			<defs />
			<path className="a" d="M6.5,104.547a.907.907,0,0,1-.643-.266L.267,98.694a.91.91,0,0,1,1.287-1.287L6.5,102.351l4.943-4.943a.91.91,0,0,1,1.287,1.287l-5.587,5.587A.907.907,0,0,1,6.5,104.547Z" transform="translate(-0.001 -97.14)" />
		</svg>
	)
}

