export const SERVER_URL = process.env.REACT_APP_API_URL;

export const API = {
  //External
  REST_COUNTRIES_API: "https://restcountries.com/v3.1/all",

  // Auth
  REGISTER: `${SERVER_URL}/auth/register`,
  LOGIN: `${SERVER_URL}/auth/login`,
  LOGOUT: `${SERVER_URL}/auth/logout`,
  BASIC_INFOFILL: `${SERVER_URL}/user/fillBasicInfo`,
  BASIC_INFO: `${SERVER_URL}/user/update`,
  CHECK_USER_NAME: `${SERVER_URL}/user/checkNickname`,
  DELETE_ACCOUNT: `${SERVER_URL}/user/delete`,
  CHANGE_PASSWORD: `${SERVER_URL}/user/changePassword`,
  EMAIL_VERIFY: `${SERVER_URL}/auth/verifyEmail`,
  RESEND_EMAIL_VERIFY: `${SERVER_URL}/auth/resendVerifyMail`,
  RESET_PASSWORD_REQ: `${SERVER_URL}/auth/resetPasswordRequest`,
  RESET_PASSWORD: `${SERVER_URL}/auth/resetPassword`,
  INVITE_FRIEND: `${SERVER_URL}/email/inviteFriend`,

  //TASK
  CREATE_TASK: `${SERVER_URL}/task/create`,
  UPDATE_TASK: `${SERVER_URL}/task/update`,
  REQ_TASK: `${SERVER_URL}/task/getTasks`,
  FIND_TASK: `${SERVER_URL}/task/findTasks`,
  FAVOURITE_TASK: `${SERVER_URL}/task/favouriteTask`,
  CANCEL_TASK: `${SERVER_URL}/task/operations/cancelTask`,
  INVITE_TASKER: `${SERVER_URL}/task/operations/inviteTasker`,
  HIRE_NOW: `${SERVER_URL}/task/operations/hireNow`,
  CREATE_WALLET_TASK: `${SERVER_URL}/task/createWalletTask`,

  //SUPERSKILL
  CREATE_SUPERSKILL: `${SERVER_URL}/superskill/create`,
  UPDATE_SUPERSKILL: `${SERVER_URL}/superskill/update`,
  FIND_TASKER: `${SERVER_URL}/superskill/findTasker`,
  REQ_SUPERSKILL: `${SERVER_URL}/superskill/get`,
  DELETE_SUPERSKILL: `${SERVER_URL}/superskill/delete`,
  FAVOURITE_TASKER: `${SERVER_URL}/superskill/favouriteTaskers`,
  REORDER_SUPERSKILL: `${SERVER_URL}/superskill/reorder`,

  //SKILLS
  REQ_SKILL: `${SERVER_URL}/skill/get`,

  //UserDetails
  USER_DETAILS: `${SERVER_URL}/user/details`,
  UPDATE_PROFILE: `${SERVER_URL}/user/updateProfileImage`,
  USER_ALL_TIME_DETAILS: `${SERVER_URL}/user/allTimeDetails`,

  //Leaderboard
  LEADERBOARD_API: `${SERVER_URL}/user/leaderboard`,

  //Dashboard
  DASHBOARD_ANALYTICS: `${SERVER_URL}/user/dashboard`,
  TRANSACTION_HISTRORY: `${SERVER_URL}/user/transactionHistory`,

  //Bids
  GET_BIDS: `${SERVER_URL}/bid/get`,
  GET_BIDS_FOR_MY_TASK: `${SERVER_URL}/bid/forMyTask`,
  CREATE_BIDS: `${SERVER_URL}/bid/create`,
  GET_NEGOTIATION_HISTORY: `${SERVER_URL}/bid/getNegotiationHistory`,
  SEND_NEGOTIATION_HISTORY: `${SERVER_URL}/bid/negotiate`,
  CLOSE_BIDDING: `${SERVER_URL}/task/operations/closeBidding`,
  END_CONTRACT: `${SERVER_URL}/task/operations/endContract`,
  RAISE_DISPUTE: `${SERVER_URL}/task/operations/raiseDispute`,
  APPROVE_TASK_COMPLETE: `${SERVER_URL}/task/operations/approveTaskComplete`,
  RECOMAND_TASK_TO_TASKER: `${SERVER_URL}/email/recommendTask`,

  //REVIEWS
  GET_REVIEWS: `${SERVER_URL}/review/get`,
  CREATE_REVIEW: `${SERVER_URL}/review/create`,

  //Notifications
  GET_NOTIFICATIONS: `${SERVER_URL}/notification/get`,
  UPDATE_NOTIFICATIONS: `${SERVER_URL}/notification/update`,

  //Payments
  LINK_WALLET_ID: `${SERVER_URL}/payment/linkHederaWallet`,
  SIGN_TRANSACTION: `${SERVER_URL}/payment/createTransactionSign`,

  //Helper API
  GET_CONVERSION_RATE: `${SERVER_URL}/helper/conversionRate`,
  GET_FEES_FOR_TASK: `${SERVER_URL}/helper/feesForTask`,
  GET_PROMOTE_TASK_FEE: `${SERVER_URL}/helper/feesForPromoteTask`,
  GET_PROMOTE_TASKER_FEE: `${SERVER_URL}/helper/feesForPromoteTasker`,
  GET_ALL_FEES: `${SERVER_URL}/helper/fees`,

  //Timer
  TIMER: `${SERVER_URL}/timer`,

  //Issues
  CREATE_ISSUES: `${SERVER_URL}/issue/create`,
  GET_ISSUES: `${SERVER_URL}/issue/get`,
  MARK_SOLVED: `${SERVER_URL}/issue/markSolved`,
  DELETE_ISSUE: `${SERVER_URL}/issue/delete`,

  //Admin
  ADMIN_UPDATE_TASKBAR_FEE: `${SERVER_URL}/admin/updateFees`,
  ADMIN_GET_ALL_USER: `${SERVER_URL}/admin/userDetails`,
  ADMIN_USER_ACOUNT_ACCESS: `${SERVER_URL}/admin/userAccountAccess`,
  CHANGE_USER_ROLE: `${SERVER_URL}/admin/changeRoles`,
  ALL_TRANSACTIONS: `${SERVER_URL}/admin/transactions`,
  CHANGE_BRAND: `${SERVER_URL}/admin/brand`,
  GET_UNHANDLED_TRANSACTION: `${SERVER_URL}/admin/unhandledTransactions/get`,
  UNHANDLED_TRANSACTION_OPERATION: `${SERVER_URL}/admin/unhandledTransactions/operation`,
  GET_ALL_DISPUTES: `${SERVER_URL}/admin/dispute/get`,
  DISPUTE_OPERATION: `${SERVER_URL}/admin/dispute/operation`,
  ADMIN_UPDATE_WALLET_ID: `${SERVER_URL}/admin/updateWallet`,
  GET_ALL_SKILLS: `${SERVER_URL}/admin/allSkills`,
  CREATE_NEW_USER: `${SERVER_URL}/admin/addNewUser`,

  //Predefined Task
  ADMIN_GET_PREDEFINED_TASK: `${SERVER_URL}/predefinedTasks/get`,
  ADMIN_ADD_NEW_PREDEFINED_TASK: `${SERVER_URL}/predefinedTasks/create`,
  ADMIN_UPDATE_PREDEFINED_TASK: `${SERVER_URL}/predefinedTasks/update`,
  ADMIN_DELETE_PREDEFINED_TASK: `${SERVER_URL}/predefinedTasks/delete`,

  GET_PROHIBITED_WORDS: `${SERVER_URL}/prohibitedWords/getAllProbhitedWords`,
  ADD_PROHIBITED_WORDS: `${SERVER_URL}/prohibitedWords/addProbhitedWords`,
  DELETE_PROHIBITED_WORDS: `${SERVER_URL}/prohibitedWords/deleteProbhitedWords`,
  GET_PROHIBITED_TASK: `${SERVER_URL}/prohibitedWords/getAllProhibitedTasks`,
  DELETE_PROHIBITED_TASK: `${SERVER_URL}/task/delete`,
  APPROVE_PROHIBITED_TASK: `${SERVER_URL}/prohibitedWords/approveProhibitedTasks`,

  //Admin Wallet task

  GET_WALLET_TASK: `${SERVER_URL}/admin/walletTask/get`,

  //API SPRINT 01-
  GET_ACTIVITY_LIST: `${SERVER_URL}/admin/pointSystem/getAllActivity`,

  GET_POINT_TABLE: `${SERVER_URL}/admin/pointSystem/getPointsForAll`,
  UPDATE_POINT_TABLE: `${SERVER_URL}/admin/pointSystem/updateActivityPoints`,
  DELETE_EVENT_FROM_POINT_TABLE: `${SERVER_URL}/admin/pointSystem/deleteActivity`,
  ADD_EVENT_IN_POINT_TABLE: `${SERVER_URL}/admin/pointSystem/addNewActivity`,
  GET_ALL_ACTIVITY_LIST: `${SERVER_URL}/admin/pointSystem/getAllActivity`,
  ADD_POINTS_TO_USER: `${SERVER_URL}/admin/pointSystem/addPointToUser`,

  //Chat
  GET_CHAT_BOX_ID: `${SERVER_URL}/chat/getChatId`,
  GET_CHAT_HISTORY: `${SERVER_URL}/chat/getChatHistory`,
  SEND_MESSAGE: `${SERVER_URL}/chat/sendMessage`,
};
