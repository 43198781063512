import {
  Button, FormControlLabel, IconButton, Modal, Radio, RadioGroup, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useContext, useEffect, useState } from "react";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { ActivityListContext } from "../../../context/ActivityListContext";
import {
  ADD_NEW_EVENTS_IN_POINT_TABLE, GET_ALL_EVENTS_FROM_POINT_TABLE,
  UPDATE_EVENTS_IN_POINT_TABLE
} from "../../../services/api-service";

//import style



const PointTable = (props) => {


  const [pointTable, setPointTable] = useState(null);
  const [editablePoint, setEditablePoint] = useState(null);
  const [addNewModal, setAddNewModal] = useState(false);

  const [availableActivityList, setActivityList] = useState([]);


  useEffect(() => {
    getPointTable();
  }, []);

  const getPointTable = async () => {
    try {
      const response = await GET_ALL_EVENTS_FROM_POINT_TABLE();
      setPointTable(response.data.result);
      const _activities = response.data.result.map((d) => d.eventName);
      setActivityList(_activities)
    } catch (e) {
      console.log(e);
      notifier.error("Error::", getErrorString(e));
    }
  };

  const doEditOn = async (point) => {
    setEditablePoint(point);
  };

  const handleEditModalClose = () => {
    setEditablePoint(null);
    setAddNewModal(false);
  };

  const handleUpdate = async (reqBody) => {
    try {
      const response = await UPDATE_EVENTS_IN_POINT_TABLE(reqBody);

      if (response.data.status === "success") {
        notifier.success(
          "Updated Successfully::",
          response.data.result.message
        );
        setEditablePoint(null);
        setPointTable(response.data.result.data);
        const _activities = response.data.result.data.map((d) => d.eventName);
        setActivityList(_activities)
      }
    } catch (e) {
      console.log(e);
      notifier.error("Error::", getErrorString(e));
    }
  };

  //Add new

  const openAddNewModal = () => {
    setEditablePoint({});
    setAddNewModal(true);
  };

  const handleAddNew = async (reqBody) => {
    //   console.log(reqBody);
    try {
      const response = await ADD_NEW_EVENTS_IN_POINT_TABLE(reqBody);

      if (response.data.status === "success") {
        notifier.success(
          "Updated Successfully::",
          response.data.result.message
        );
        setEditablePoint(null);
        setPointTable(response.data.result.data);
        setAddNewModal(false);
        const _activities = response.data.result.data.map((d) => d.eventName);
        setActivityList(_activities)
      }
    } catch (e) {
      console.log(e);
      notifier.error("Error::", getErrorString(e));
    }
  };

  return (
    <>
      <div className="pointTable__header">
        {/* <h4>Point Table</h4> */}
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={openAddNewModal}>
          Add New
        </Button>
      </div>
      <div className="pointTable">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointTable &&
                pointTable.map((point, index) => (
                  <TableRow key={point._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{point?.eventName}</TableCell>
                    <TableCell>{point?.value?.description}</TableCell>
                    <TableCell>{point?.value?.points}</TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => doEditOn(point)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <EditPointModal
        open={editablePoint ? true : false}
        addNew={addNewModal}
        point={editablePoint}
        onClose={handleEditModalClose}
        doUpdate={handleUpdate}
        doAddNew={handleAddNew}
        availableActivityList={availableActivityList}
      />
    </>
  );
};

const EditPointModal = (props) => {
  const { activityList } = useContext(ActivityListContext);
  const { open, point, onClose, doUpdate, doAddNew, addNew, availableActivityList } = props;

  console.log("availableActivityList::", availableActivityList)

  const [value, setValue] = useState(0);
  const [des, setDes] = useState("");
  const [eventName, setEventName] = useState("");
  const [allActivityList, setAllActivityList] = useState(null)

  useEffect(() => {
    setValue(addNew ? 0 : point?.value?.points);
    setDes(addNew ? "" : point?.value?.description);
  }, [point, addNew]);

  useEffect(() => {
    arrangeActivityList(activityList)
  }, [activityList]);


  const arrangeActivityList = (activityList) => {
    if (activityList) {
      const _activityList = [];
      Object.keys(activityList).map((key_1) => {
        Object.keys(activityList[key_1]).map((key_2) => _activityList.push(activityList[key_1][key_2]))
      })
      setAllActivityList([..._activityList])
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const reqBody = {
      eventName: addNew ? eventName : point?.eventName,
      value: {
        points: value,
        description: des,
      },
    };
    if (addNew) {
      await doAddNew(reqBody);
    } else {
      await doUpdate(reqBody);
    }
  };

  const handleActivityChange = (e) => {
    console.log(e.target.value);
    setEventName(e.target.value);
  }

  const handleModalClose = () => {
    setAllActivityList(null);
    setEventName(""); setDes(""); setValue(0);

    onClose();
  }

  return (
    <Modal
      open={open}
      aria-labelledby="Edit-pointSystem"
      aria-describedby="Edit value and description  of an Point"
      className={`editPoint`}
      onClose={() => handleModalClose}>
      <div className="editPoint__paper">
        <div className="editPoint__header">
          <b>{addNew ? "Add New Event in Point Table" : point?.eventName}</b>
        </div>
        <div className="editPoint__body">
          <form className="editPoint__form" onSubmit={handleSubmit}>
            {addNew && (<div className="editPoint__inputBox">
              <RadioGroup row aria-label="ActivityList" name="eventName" value={eventName} onChange={handleActivityChange} >
                {allActivityList.map((activity, index) => <FormControlLabel key={index} value={activity} control={<Radio disabled={availableActivityList.includes(activity)} />} label={activity} />)}
              </RadioGroup>
            </div>
            )}
            <div className="editPoint__inputBox">
              <p>
                This description will show to an user why x point is added to
                their points.
              </p>
              <TextField
                label="Description"
                value={des}
                onChange={(e) => setDes(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>

            <div className="editPoint__inputBox">
              <p>How much points to be added to user to this event?</p>
              <TextField
                label="Points Value"
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
            <div className="editPoint__inputBox">
              <Button
                type="button"
                variant="contained"
                onClick={() => onClose()}
                color="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default PointTable;
