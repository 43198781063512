import { IconButton, makeStyles } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Images } from '../../assets/common';
import Header from '../../components/header';
import HeaderBoard from '../home/header-board';
import './index.scss';
import RouterOutlet from './router-outlet';
import BugReportIcon from '@material-ui/icons/BugReport';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import notifier from '../../components/notifier';
import { getErrorString } from '../../components/validator';
import { GET_ALL_ACTIVITY_LIST } from '../../services/api-service';
import { ActivityListContext } from '../../context/ActivityListContext';


const Admin = (props) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [activityList, setActivityList] = useState(null);

  const value = useMemo(() => ({ activityList, setActivityList}), [activityList,setActivityList]);

  useEffect(() => {
    getAllActivity();
  }, []);

  const getAllActivity = async () => {
    try {
      const response = await GET_ALL_ACTIVITY_LIST();
      setActivityList(response.data.result);
    }
    catch (e) {
      console.log(e);
      notifier.error("Error", getErrorString(e));
    }
  }

  return (
    <div className="admin">
      <div className="admin__header">
        <Header {...props} />
        <HeaderBoard {...props} />
      </div>
      <div className="admin__body">
        <div className="admin__body__continer">
          <Sidebar
            isMenuOpen={menuOpen}
            onMenuClick={() => setMenuOpen(!menuOpen)}
          />
          <div
            className={
              menuOpen
                ? `admin__body__continer__content`
                : `admin__body__continer__content admin__body__continer__content--menuClose`
            }
            style={{
              // animation: `${menuOpen ? "expandIn" : "expandOut"} 0.3s`,
            }}
          >
            {!menuOpen ? (
              <IconButton
                onClick={() => setMenuOpen(!menuOpen)}
                className="menuButton"
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <ActivityListContext.Provider value={value}>
              <RouterOutlet {...props} />
            </ActivityListContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}

const Sidebar = (props) => {
  const { isMenuOpen, onMenuClick } = props;

  // const [show, setShow] = useState(isMenuOpen);
  const [shouldRender, setRender] = useState(isMenuOpen);

  useEffect(() => {
    if (isMenuOpen) setRender(true);
  }, [isMenuOpen]);

  const onAnimationEnd = () => {
    if (!isMenuOpen) setRender(false);
  };
  const handleClick = () => {
    onMenuClick();
  }

  const classes = Styles();
  return (
    shouldRender && (
      <div
        className="admin__body__continer__sidebar"
        style={{ animation: `${isMenuOpen ? "slideIn" : "slideOut"} 1s` }}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="admin__body__continer__sidebar--heading">
          <span className="admin__body__continer__sidebar--heading__menuIcon">
            <IconButton onClick={handleClick}>
              <MenuOpenIcon />
            </IconButton>
          </span>
          <span className="admin__body__continer__sidebar--label">
            Admin Panel
          </span>
        </div>
        <div className={classes.root}>
          <NavLink to={"/admin/users"} activeClassName="active">
            <span className="icon">
              <img src={Images.user} alt="" />
            </span>
            <span className="label">User List</span>
          </NavLink>

          <NavLink to={"/admin/my-taskbar-fee"} activeClassName="active">
            <span className="icon">
              <img src={Images.headersWalletIcon} alt="" />
            </span>
            <span className="label">My Taskbar Fee</span>
          </NavLink>
          <NavLink to={"/admin/pointSystem"} activeClassName="active">
            <span className="icon">
              <img src={Images.headersWalletIcon} alt="" />
            </span>
            <span className="label">Point System</span>
          </NavLink>

          <NavLink to={"/admin/prohibited-words"} activeClassName="active">
            <span className="icon">
              <img src={Images.noSuperSkill} alt="" />
            </span>
            <span className="label">Prohibited Words</span>
          </NavLink>

          <NavLink to={"/admin/prohibited-tasks"} activeClassName="active">
            <span className="icon">
              <img src={Images.EditProfileIcon} alt="" />
            </span>
            <span className="label">Prohibited Tasks</span>
          </NavLink>

          <NavLink to={"/admin/predefined-tasks"} activeClassName="active">
            <span className="icon">
              <img src={Images.EditProfileIcon} alt="" />
            </span>
            <span className="label">Predefined Tasks </span>
          </NavLink>

          <NavLink to={"/admin/walletTask"} activeClassName="active">
            <span className="icon">
              <img src={Images.hederaIcon} alt="" />
            </span>
            <span className="label">Hedera Wallet Task</span>
          </NavLink>


          <NavLink to={"/admin/issues"} activeClassName="active">
            <span className="icon">
              <img src={Images.negotiationIcon} alt="" />
            </span>
            <span className="label">Issues and suggestions</span>
          </NavLink>

          <NavLink to={"/admin/transactions"} activeClassName="active">
            <span className="icon">
              <img src={Images.transectionHistoryIcon} alt="" />
            </span>
            <span className="label">Transactions</span>
          </NavLink>
          <NavLink to={"/admin/unhandledTransaction"} activeClassName="active">
            <span className="icon">
              <img src={Images.transectionHistoryIcon} alt="" />
            </span>
            <span className="label">Unhandled Transaction</span>
          </NavLink>
          <NavLink to={"/admin/disputeManagement"} activeClassName="active">
            <span className="icon">
              <img src={Images.menuDrawerIcons.admin} alt="" />
            </span>
            <span className="label">Dispute Management</span>
          </NavLink>
          <NavLink to={"/admin/raise-an-issue"} activeClassName="active">
            <span className="icon">
              <BugReportIcon />
            </span>
            <span className="label">Raise a new Issue/CR</span>
          </NavLink>
          <NavLink to={"/admin/exportExcel"} activeClassName="active">
            <span className="icon">
              <ImportExportIcon />
            </span>
            <span className="label">Export TO Excel</span>
          </NavLink>
        </div>
      </div>
    )
  );
}

const Styles = makeStyles({
  root: {
    // borderBottom: "1px solid #dddddd",
    width: "100%",
    padding: 0,
    maxHeight: 'calc(100vh - 260px )',
    overflowY: 'auto',
    "&&::-webkit-scrollbar": {
      width: 2,
    },
    "&>a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "12px 8px",
      paddingLeft: 20,
      width: "100%",
      border: '1px solid transparent',
      "&.active": {
        background: "#f2fffe 0% 0% no-repeat padding-box",
        border: "1px solid #6dc7be",
        borderRightWidth: 8,
      },
      "& .icon": {
        marginRight: 18,
        width: 20,
        height: 20,
        display: "flex",
        alignItems: "center",
        "& img": {
          width: 25,
          height: 20,
        },
      },
      "& .label": {
        // textTransform: "uppercase",
        fontWeight: 400,
        color: "#2c2c2c",
        fontSize: 14,
      },
    },
  },
});

export default Admin;