export  const NATIONALITY_LIST = [
  {
    Country: "",
    Nationality: "",
    Nationality_noun: "",
    Language: "",
  },
  {
    Country: "Afghanistan",
    Nationality: "Afghan",
    Nationality_noun: "an Afghan",
    Language: "Dari (Persian) - Pashto",
  },
  {
    Country: "Albania",
    Nationality: "Albanian",
    Nationality_noun: "an Albanian",
    Language: "Albanian",
  },
  {
    Country: "Algeria",
    Nationality: "Algerian",
    Nationality_noun: "an Algerian",
    Language: "Arabic",
  },
  {
    Country: "Argentina",
    Nationality: "Argentine\nArgentinian",
    Nationality_noun: "an Argentine\nan Argentinian",
    Language: "Spanish",
  },
  {
    Country: "Australia",
    Nationality: "Australian",
    Nationality_noun: "an Australian",
    Language: "English",
  },
  {
    Country: "Austria",
    Nationality: "Austrian",
    Nationality_noun: "an Austrian",
    Language: "German",
  },
  {
    Country: "Bangladesh",
    Nationality: "Bangladeshi",
    Nationality_noun: "a Bangladeshi",
    Language: "Bengali",
  },
  {
    Country: "Belgium",
    Nationality: "Belgian",
    Nationality_noun: "a Belgian",
    Language: "French / Flemish",
  },
  {
    Country: "Bolivia",
    Nationality: "Bolivian",
    Nationality_noun: "a Bolivian",
    Language: "Spanish",
  },
  {
    Country: "Botswana",
    Nationality: "Batswana",
    Nationality_noun: "a Botswanan",
    Language: "English, Setswana",
  },
  {
    Country: "Brazil",
    Nationality: "Brazilian",
    Nationality_noun: "a Brazilian",
    Language: "Portuguese",
  },
  {
    Country: "Bulgaria",
    Nationality: "Bulgarian",
    Nationality_noun: "a Bulgarian",
    Language: "Bulgarian",
  },
  {
    Country: "Cambodia",
    Nationality: "Cambodian",
    Nationality_noun: "a Cambodian",
    Language: "Cambodian",
  },
  {
    Country: "Cameroon",
    Nationality: "Cameroonian",
    Nationality_noun: "a Cameroonian",
    Language: "French / English",
  },
  {
    Country: "Canada",
    Nationality: "Canadian",
    Nationality_noun: "a Canadian",
    Language: "English / French",
  },
  {
    Country: "Chile",
    Nationality: "Chilean",
    Nationality_noun: "a Chilean",
    Language: "Spanish",
  },
  {
    Country: "China",
    Nationality: "Chinese",
    Nationality_noun: "a Chinese person",
    Language: "Chinese",
  },
  {
    Country: "Colombia *",
    Nationality: "Colombian",
    Nationality_noun: "a Colombian",
    Language: "Spanish",
  },
  {
    Country: "Costa Rica",
    Nationality: "Costa Rican",
    Nationality_noun: "a Costa Rican",
    Language: "Spanish",
  },
  {
    Country: "Croatia",
    Nationality: "Croatian",
    Nationality_noun: "a Croat",
    Language: "Croatian",
  },
  {
    Country: "Cuba",
    Nationality: "Cuban",
    Nationality_noun: "a Cuban",
    Language: "Spanish",
  },
  {
    Country: "Czech Republic",
    Nationality: "Czech",
    Nationality_noun: "a Czech person",
    Language: "Czech",
  },
  {
    Country: "Denmark",
    Nationality: "Danish",
    Nationality_noun: "a Dane",
    Language: "Danish",
  },
  {
    Country: "Dominican Republic",
    Nationality: "Dominican",
    Nationality_noun: "a Dominican",
    Language: "Spanish",
  },
  {
    Country: "Ecuador",
    Nationality: "Ecuadorian",
    Nationality_noun: "an Ecuadorian",
    Language: "Spanish",
  },
  {
    Country: "Egypt",
    Nationality: "Egyptian",
    Nationality_noun: "an Egyptian",
    Language: "Arabic",
  },
  {
    Country: "El Salvador",
    Nationality: "Salvadorian",
    Nationality_noun: "a Salvadoran",
    Language: "Spanish",
  },
  {
    Country: "England",
    Nationality: "English",
    Nationality_noun: "an Englishman\nan Englishwoman",
    Language: "English",
  },
  {
    Country: "Estonia",
    Nationality: "Estonian",
    Nationality_noun: "an Estonian",
    Language: "Estonian",
  },
  {
    Country: "Ethiopia",
    Nationality: "Ethiopian",
    Nationality_noun: "an Ethiopian",
    Language: "Amharic",
  },
  {
    Country: "Fiji",
    Nationality: "Fijian",
    Nationality_noun: "a Fijian",
    Language: "English, Fijian",
  },
  {
    Country: "Finland",
    Nationality: "Finnish",
    Nationality_noun: "a Finn",
    Language: "Finnish",
  },
  {
    Country: "France",
    Nationality: "French",
    Nationality_noun: "a Frenchman  \na Frenchwoman",
    Language: "French",
  },
  {
    Country: "Germany",
    Nationality: "German",
    Nationality_noun: "a German",
    Language: "German",
  },
  {
    Country: "Ghana",
    Nationality: "Ghanaian",
    Nationality_noun: "a Ghanaian",
    Language: "English",
  },
  {
    Country: "Greece",
    Nationality: "Greek",
    Nationality_noun: "a Greek",
    Language: "Greek",
  },
  {
    Country: "Guatemala",
    Nationality: "Guatemalan",
    Nationality_noun: "a Guatemalan",
    Language: "Spanish",
  },
  {
    Country: "Haiti",
    Nationality: "Haitian",
    Nationality_noun: "a Haitian",
    Language: "French / Creole",
  },
  {
    Country: "Honduras",
    Nationality: "Honduran",
    Nationality_noun: "a Honduran",
    Language: "Spanish",
  },
  {
    Country: "Hungary",
    Nationality: "Hungarian",
    Nationality_noun: "a Hungarian",
    Language: "Hungarian",
  },
  {
    Country: "Iceland",
    Nationality: "Icelandic",
    Nationality_noun: "an Icelander",
    Language: "Icelandic",
  },
  {
    Country: "India",
    Nationality: "Indian",
    Nationality_noun: "an Indian",
    Language: "Hindi\t/ English",
  },
  {
    Country: "Indonesia",
    Nationality: "Indonesian",
    Nationality_noun: "an Indonesian",
    Language: "Indonesian",
  },
  {
    Country: "Iran",
    Nationality: "Iranian",
    Nationality_noun: "an Iranian",
    Language: "Persian",
  },
  {
    Country: "Iraq",
    Nationality: "Iraqi",
    Nationality_noun: "an Iraqi",
    Language: "Arabic, Kurdish",
  },
  {
    Country: "Ireland",
    Nationality: "Irish",
    Nationality_noun: "an Irishman\nan Irishwoman",
    Language: "Irish / English",
  },
  {
    Country: "Israel",
    Nationality: "Israeli",
    Nationality_noun: "an Israeli",
    Language: "Hebrew",
  },
  {
    Country: "Italy",
    Nationality: "Italian",
    Nationality_noun: "an Italian",
    Language: "Italian",
  },
  {
    Country: "Jamaica",
    Nationality: "Jamaican",
    Nationality_noun: "a Jamaican",
    Language: "English",
  },
  {
    Country: "Japan",
    Nationality: "Japanese",
    Nationality_noun: "a Japanese person",
    Language: "Japanese",
  },
  {
    Country: "Jordan",
    Nationality: "Jordanian",
    Nationality_noun: "a Jordanian",
    Language: "Arabic",
  },
  {
    Country: "Kenya",
    Nationality: "Kenyan",
    Nationality_noun: "a Kenyan",
    Language: "Swahili",
  },
  {
    Country: "Kuwait",
    Nationality: "Kuwaiti",
    Nationality_noun: "a Kuwaiti",
    Language: "Arabiv",
  },
  {
    Country: "Laos",
    Nationality: "Lao",
    Nationality_noun: "a Laotain",
    Language: "Laotian",
  },
  {
    Country: "Latvia",
    Nationality: "Latvian",
    Nationality_noun: "a Latvian",
    Language: "Latvian",
  },
  {
    Country: "Lebanon",
    Nationality: "Lebanese",
    Nationality_noun: "a Lebanese",
    Language: "Arabic",
  },
  {
    Country: "Libya",
    Nationality: "Libyan",
    Nationality_noun: "a Libyan",
    Language: "Arabic",
  },
  {
    Country: "Lithuania",
    Nationality: "Lithuanian",
    Nationality_noun: "a Lithuanian",
    Language: "Lithuanian",
  },
  {
    Country: "Madagascar",
    Nationality: "Malagasy",
    Nationality_noun: "a Malagasy",
    Language: "Malagasy / French",
  },
  {
    Country: "Malaysia",
    Nationality: "Malaysian",
    Nationality_noun: "a Malaysian",
    Language: "Malay / Malaysian",
  },
  {
    Country: "Mali",
    Nationality: "Malian",
    Nationality_noun: "a Malian",
    Language: "French",
  },
  {
    Country: "Malta",
    Nationality: "Maltese",
    Nationality_noun: "a Maltese",
    Language: "English, Maltese",
  },
  {
    Country: "Mexico",
    Nationality: "Mexican",
    Nationality_noun: "a Mexican",
    Language: "Spanish",
  },
  {
    Country: "Mongolia",
    Nationality: "Mongolian",
    Nationality_noun: "a Mongolian",
    Language: "Mongolian",
  },
  {
    Country: "Morocco",
    Nationality: "Moroccan",
    Nationality_noun: "a Moroccan",
    Language: "Arabic / French",
  },
  {
    Country: "Mozambique",
    Nationality: "Mozambican",
    Nationality_noun: "a Mozambican",
    Language: "Portuguese",
  },
  {
    Country: "Namibia",
    Nationality: "Namibian",
    Nationality_noun: "a Nambian",
    Language: "English",
  },
  {
    Country: "Nepal",
    Nationality: "Nepalese",
    Nationality_noun: "a Nepalese",
    Language: "Nepali, English",
  },
  {
    Country: "Netherlands",
    Nationality: "Dutch",
    Nationality_noun: "a Dutchman\na Dutchwoman",
    Language: "Dutch",
  },
  {
    Country: "New Zealand",
    Nationality: "New Zealand",
    Nationality_noun: "a New Zealander",
    Language: "English / Maori",
  },
  {
    Country: "Nicaragua",
    Nationality: "Nicaraguan",
    Nationality_noun: "a Nicaraguan",
    Language: "Spanish",
  },
  {
    Country: "Nigeria",
    Nationality: "Nigerian",
    Nationality_noun: "a Nigerian",
    Language: "English",
  },
  {
    Country: "Norway",
    Nationality: "Norwegian",
    Nationality_noun: "a Norwegian",
    Language: "Norwegian",
  },
  {
    Country: "Pakistan",
    Nationality: "Pakistani",
    Nationality_noun: "a Pakistani",
    Language: "Urdu, English",
  },
  {
    Country: "Panama",
    Nationality: "Panamanian",
    Nationality_noun: "a Panamanian",
    Language: "Spanish",
  },
  {
    Country: "Paraguay",
    Nationality: "Paraguayan",
    Nationality_noun: "a Paraguayan",
    Language: "Spanish",
  },
  {
    Country: "Peru",
    Nationality: "Peruvian",
    Nationality_noun: "a Peruvian",
    Language: "Spanish",
  },
  {
    Country: "Philippines",
    Nationality: "Philippine",
    Nationality_noun: "a Filipino",
    Language: "Tagalog / Filipino",
  },
  {
    Country: "Poland",
    Nationality: "Polish",
    Nationality_noun: "a Pole",
    Language: "Polish",
  },
  {
    Country: "Portugal",
    Nationality: "Portuguese",
    Nationality_noun: "a Portuguese person",
    Language: "Portuguese",
  },
  {
    Country: "Romania",
    Nationality: "Romanian",
    Nationality_noun: "a Romanian",
    Language: "Romanian",
  },
  {
    Country: "Russia",
    Nationality: "Russian",
    Nationality_noun: "a Russian",
    Language: "Russian",
  },
  {
    Country: "Saudi Arabia",
    Nationality: "Saudi",
    Nationality_noun: "a Saudi (Arabian)",
    Language: "Arabic",
  },
  {
    Country: "Scotland",
    Nationality: "Scottish",
    Nationality_noun: "a Scot",
    Language: "English",
  },
  {
    Country: "Senegal",
    Nationality: "Senegalese",
    Nationality_noun: "a Senegalese person",
    Language: "French",
  },
  {
    Country: "Serbia",
    Nationality: "Serbian",
    Nationality_noun: "a Serbian",
    Language: "Serbian",
  },
  {
    Country: "Singapore",
    Nationality: "Singaporean",
    Nationality_noun: "a Singaporean",
    Language: "English, Malay, Mandarin, Tamil",
  },
  {
    Country: "Slovakia",
    Nationality: "Slovak",
    Nationality_noun: "a Slovak",
    Language: "Slovak",
  },
  {
    Country: "South Africa",
    Nationality: "South African",
    Nationality_noun: "a South African",
    Language: "Afrikaans, English, + 9 more",
  },
  {
    Country: "South Korea",
    Nationality: "Korean",
    Nationality_noun: "a Korean",
    Language: "Korean",
  },
  {
    Country: "Spain",
    Nationality: "Spanish",
    Nationality_noun: "a Spaniard",
    Language: "Spanish",
  },
  {
    Country: "Sri Lanka",
    Nationality: "Sri Lankan",
    Nationality_noun: "a Sri Lankan",
    Language: "Sinhala, Tamil",
  },
  {
    Country: "Sudan",
    Nationality: "Sudanese",
    Nationality_noun: "a Sudanese person",
    Language: "Arabic, English",
  },
  {
    Country: "Sweden",
    Nationality: "Swedish",
    Nationality_noun: "a Swede",
    Language: "Swedish",
  },
  {
    Country: "Switzerland",
    Nationality: "Swiss",
    Nationality_noun: "a Swiss person",
    Language: "German, French, Italian, Romansh",
  },
  {
    Country: "Syria",
    Nationality: "Syrian",
    Nationality_noun: "a Syrian",
    Language: "Arabic",
  },
  {
    Country: "Taiwan",
    Nationality: "Taiwanese",
    Nationality_noun: "a Taiwanese person",
    Language: "Chinese",
  },
  {
    Country: "Tajikistan",
    Nationality: "Tajikistani",
    Nationality_noun: "a Tajikistani",
    Language: "Tajik (Persian)",
  },
  {
    Country: "Thailand",
    Nationality: "Thai",
    Nationality_noun: "a Thai person",
    Language: "Thai",
  },
  {
    Country: "Tonga",
    Nationality: "Tongan",
    Nationality_noun: "a Tongan",
    Language: "English, Tongan",
  },
  {
    Country: "Tunisia",
    Nationality: "Tunisian",
    Nationality_noun: "a Tunisian",
    Language: "Arabic",
  },
  {
    Country: "Turkey",
    Nationality: "Turkish",
    Nationality_noun: "a Turk",
    Language: "Turkish",
  },
  {
    Country: "Ukraine",
    Nationality: "Ukrainian",
    Nationality_noun: "a Ukranian",
    Language: "Ukrainian",
  },
  {
    Country: "United Arab Emirates",
    Nationality: "Emirati",
    Nationality_noun: "an Emirati",
    Language: "Arabic",
  },
  {
    Country: "United Kingdom",
    Nationality: "British",
    Nationality_noun: "a Brit",
    Language: "English",
  },
  {
    Country: "United States",
    Nationality: "American",
    Nationality_noun: "an American",
    Language: "English",
  },
  {
    Country: "Uruguay",
    Nationality: "Uruguayan",
    Nationality_noun: "a Uruguayan",
    Language: "Spanish",
  },
  {
    Country: "Venezuela",
    Nationality: "Venezuelan",
    Nationality_noun: "a Venezuelan",
    Language: "Spanish",
  },
  {
    Country: "Vietnam",
    Nationality: "Vietnamese",
    Nationality_noun: "a Vietnamese person",
    Language: "Vietnamese",
  },
  {
    Country: "Wales",
    Nationality: "Welsh",
    Nationality_noun: "a Welshman\na Welshwoman",
    Language: "Welsh / English",
  },
  {
    Country: "Zambia",
    Nationality: "Zambian",
    Nationality_noun: "a Zambian",
    Language: "English",
  },
  {
    Country: "Zimbabwe",
    Nationality: "Zimbabwean",
    Nationality_noun: "a Zimbabwean",
    Language: "16 languages",
  },
];