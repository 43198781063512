import {TextField, useMediaQuery, Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, {useContext, useRef, useState} from "react";
import {Images} from "../../assets/common";
import ConfirmModal from "../../components/deleteConfirmModal";
import notifier from "../../components/notifier";
import {getErrorString} from "../../components/validator";
import {MESSAGE} from "../../constants/messages";
import {TaskbarUserContext} from "../../context/TaskbarUserContext";
import {validEmail} from "../../lib/validators";
import {INVITE_FRIEND} from "../../services/api-service";

const InviteForm = (props) => {
  const {currentUser} = useContext(TaskbarUserContext);
  const [loading, setLoader] = useState(false);
  const [inviteeList, setInviteeList] = useState([{email: ""}]);
  const [Index, setIndex] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const handleInviteeMailList = (index, event) => {
    let value = event.target.value;
    inviteeList[index].email = value;
    setInviteeList([...inviteeList]);
  };
  const clickToCopy = useRef(null);
  const [copiedText, setCopiedText] = useState(null);
  const isMobileDevice = useMediaQuery("(max-width:576px)");
  const domain = new URL(window.location.href);

  //For adding or Removing one more inviteFeild
  const addOrRemoveFeild = (index) => {
    if (inviteeList.length === index + 1) {
      setInviteeList([...inviteeList, {email: ""}]);
    } else {
      inviteeList.splice(index, 1);
      setInviteeList([...inviteeList]);
      setConfirmModal(false);
    }
  };

  const openModal = (index) => {
    const email = inviteeList[index].email.trim();
    if (email === "") {
      addOrRemoveFeild(index);
    } else {
      setIndex(index);
      setConfirmModal(true);
    }
  };

  const closeModal = () => setConfirmModal(false);

  const inviteFriend = async () => {
    const mailList = inviteeList.map((d) => d.email);
    if (Object.keys(mailList[0]).length === 0) {
      notifier.warning("Warning", MESSAGE.ERROR.EMAIL_EMPTY);
    } else if (!isValidEmail()) {
      notifier.warning("Warning", MESSAGE.ERROR.EMAIL_INVALID);
    } else
      try {
        setLoader(true);
        const requestBody = {
          emails: mailList,
        };
        const response = await INVITE_FRIEND(requestBody);
        if (response.data.status === "success") {
          setLoader(false);
          setInviteeList([{email: ""}]);
          notifier.success("Success", MESSAGE.SUCCESS.INVITATION_SEND);
        }
      } catch (e) {
        setLoader(false);
        let errorString = getErrorString(e);
        // if (errorString === 'Request failed with status code 400') {
        //     errorString = MESSAGE.ERROR.EMAIL_INVALID
        // }
        notifier.error("Error", errorString);
      }
  };

  const isValidEmail = () => {
    let valid = false;
    inviteeList.map((mails) => {
      valid = validEmail(mails.email);
      return mails;
    });
    return valid;
  };

  // console.log(currentUser);
  const handleCopyToClipBoard = async (elm) => {
    try {
      const copyText = `${domain.protocol}//${domain.host}/register?refId=${currentUser?.referralsId}`;
      await navigator.clipboard.writeText(copyText);
      setCopiedText(copyText);
      elm.current.classList.add("copied");
    } catch (err) {
      console.error("Failed to copy: ", err);
      notifier.warning("Failed to copy: ", `Open site with https protocol please. https://${domain.host}`);
    }
  };

  const nativeShare = async (elm) => {
    
    try {
      const copyText = `${domain.protocol}//${domain.host}/register?refId=${currentUser?.referralsId}`;
      await navigator.share({
        title: "Taskbar referral link",
        text:'One of your friend is invited you on mytaskbar.io',
        url: copyText,
      });setCopiedText(copyText);
      elm.current.classList.add("copied");
    } catch (err) {
      console.error("Failed to copy: ", err);
      notifier.warning("Failed to copy: ", `Open site with https protocol please. https://${domain.host}`);
    }
  };

  console.log("logic" ,currentUser?.referralsId , !isMobileDevice )
  return (
    <>
      <div className="heading-text">
        <img src={Images.inviteAfriendFormIcon} alt="Invite a friend icone" />
        <h1>Invite a Friend</h1>
      </div>
      {currentUser?.referralsId && !isMobileDevice ? (
        <div
          className="referrals"
          onClick={() => handleCopyToClipBoard(clickToCopy)}
          ref={clickToCopy}>
          <span className="code">
            {!copiedText ? "Click to copy referral link " : "Copied"}
          </span>
        </div>
      ) : null}
      {isMobileDevice ? (
           <div
           className="referrals"  ref={clickToCopy}>
        <Button size="small" onClick={() => nativeShare(clickToCopy)}>
          Share Referral link
        </Button></div>
      ) : null}
      <ConfirmModal
        modal={confirmModal}
        yesCallback={() => addOrRemoveFeild(Index)}
        cancelCallback={closeModal}
        text={"Are you sure? you want to delete the email id"}
      />
      <div className="inviteForm">
        {inviteeList.map((invitee, index) => {
          return (
            <div
              className={
                inviteeList.length === index + 1
                  ? "login-form__control "
                  : " login-form__control RemoveBtn"
              }
              key={`inviteMail${index}`}>
              <div className={"field-container"}>
                <TextField
                  label="Email Id"
                  variant="outlined"
                  onChange={handleInviteeMailList.bind(this, index)}
                  type="email"
                  fullWidth
                  size="small"
                  value={invitee.email}
                />
              </div>

              {inviteeList.length === index + 1 ? (
                <button onClick={addOrRemoveFeild.bind(this, index)}>
                  {" "}
                  <span className="icon">
                    <AddIcon />{" "}
                  </span>
                  <span>Add More</span>
                </button>
              ) : (
                <button
                  className="RemoveBtn"
                  onClick={openModal.bind(this, index)}>
                  <RemoveIcon />
                </button>
              )}
            </div>
          );
        })}
      </div>
      <div className="inviteForm-submit">
        <button className="" onClick={inviteFriend}>
          {loading ? (
            <img alt="" src={Images.ellipsis_loader} />
          ) : (
            <span> Invite </span>
          )}
        </button>
      </div>
    </>
  );
};

export default InviteForm;
