import { InputAdornment, withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
// import '../../../App.scss'
import { Images } from "../../../assets/common/index";
import notifier from "../../../components/notifier";
import { validateSuperSkills } from "../../../components/validator";
import {
  REQ_CREATE_SUPERSKILL,
  REQ_SKILLS,
} from "../../../services/api-service";
import history from ".././../../components/history";
import "./index.scss";

function NewSuperSkill(props) {
  const [title, setTitle] = useState("");
  const [errors, updateErrors] = useState({});
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState("hbar");
  const [rate, setRate] = useState("");
  const [portfolio, setportfolio] = useState([
    { portfolioName: "", portfolioLink: "" },
  ]);
  const [loading, setLoader] = useState(false);
  const [skills, setSkills] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [checkSkillField, setCheckSkillField] = useState({
    error: false,
    helperText: "",
  });

  const handlePaymentTypeHourly = (e) => {
    e.stopPropagation();
    setPaymentType("hbar");
    // setHourly(true)
    // setFixed(false)
  };

  const handlePaymentTypeFixed = (e) => {
    e.stopPropagation();
    setPaymentType("usd");
    // setFixed(true)
    // setHourly(false)
  };

  const AddSuperSkill = async (event) => {
    try {
      const data = { title, description, skills, paymentType, rate, portfolio };
      const errors = validateSuperSkills(data);
      if (!errors) {
        // Good to go!
        const requestBody = {
          title: title,
          description: description,
          skills: skills,
          currency: paymentType,
          ratePerHour: rate,
          portfolio: portfolio,
          draft: false,
        };
        const response = await REQ_CREATE_SUPERSKILL(requestBody);
        if (response.data.status === "success") {
          notifier.success("Great", "Superskill is added");
          if (window.dataLayer) {
            window.dataLayer.push({ event: "completed_addskill" });
          }
          props.history.push(`/home/profile`);
        }
      } else {
        updateErrors(errors);
        if (errors.skills) {
          setCheckSkillField({
            error: true,
            helperText: "Subskills Felid should not be empty.",
          });
        }
        notifier.warning("Warning", "Something is not right");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePortfolioChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...portfolio];
    list[index][name] = value;
    setportfolio(list);
  };

  const handleInputChange = (event, newValue) => {
    const id = event.target.name;
    const value = event.target.value;
    // const checked = event.target.checked;
    switch (id) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "rate":
        setRate(value);
        break;
      case "skills":
        // setSkills(value);
        getAllSkills(value);
        //console.log("hyskill", skills, newValue)
        break;
      case "skillValue":
        //console.log("hyskills", newValue)
        break;
      case "paymentType":
        setPaymentType(value);
        break;

      default:
        console.warn("Something is not right !");
    }
  };

  const getAllSkills = async (skill) => {
    try {
      let allSkills;
      if (skill.length > 2) {
        const param = {
          searchPhrase: skill,
        };
        const response = await REQ_SKILLS(param);
        allSkills = response.data.result;
        setAllSkills(allSkills);
      }
      // else{
      //     await timeout(2000);
      //     const param = {
      //         "searchPhrase": skill
      //     }
      //     const response = await REQ_SKILLS(param);
      //      allSkills=response.data.result
      //     //console.log("skill",allSkills)
      // }
    } catch (e) {
      setLoader(false);
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };

  // const Draft = () => {
  //   setdraft(true);
  // };

  const addMore = () => {
    setportfolio([...portfolio, { portfolioName: "", portfolioLink: "" }]);
  };

  const onchangeAddSkill = (event, newValue) => {
    let newArray = newValue.map((d, i) => {
      if (typeof d === "string") {
        return { name: d };
      } else {
        return d;
      }
    });

    if (newArray.length <= 10) {
      setSkills(newArray);
    } else {
      notifier.warning("Warning", "Max No of skills allowed is 10");
      //console.log('yash', newArray)
    }
  };

  const checkSkillsOnBlur = (e) => {
    if (e.target.value.trim() !== "") {
      notifier.warning(
        "Warning",
        "Please Press Enter to add New subskill, Which is not in the List.",
        6000
      );
      setCheckSkillField({
        error: true,
        helperText:
          "Please Press Enter to add New subskill, Which is not in the List.",
      });
    } else {
      setCheckSkillField({
        error: false,
        helperText: "",
      });
    }
  };

  return (
    <div className="add-superskill-container">
      <div className="add-superskill-title">Add New superskill</div>
      <div className="add-superskill-content">
        <h3>
          Get started by listing your main skills that you would like to
          monetize. This will help to receive Task matches and be found by other
          uses who seek your skill.
        </h3>
        <div className="add-superskill-form">
          <div className={"field-container"}>
            <div className="fieldName">Title</div>
            <TextField
              error={errors.title}
              helperText={errors.title}
              variant="outlined"
              label=""
              type="text"
              placeholder={"Provide a title for your Superskill"}
              name="title"
              onChange={handleInputChange}
            />
          </div>
          <div className={"field-container"}>
            <div className="fieldName">Description</div>
            <TextField
              multiline={true}
              rows={2}
              error={errors.description}
              helperText={errors.description}
              variant="outlined"
              type="text"
              name="description"
              onChange={handleInputChange}
              placeholder={"Add a detail information of your service"}
            />
          </div>
          <div className={"field-container"}>
            <div className="fieldName">Subskills</div>
            <StyledAutocomplete
              multiple
              id="tags-filled"
              options={AllSkills}
              limitTags={10}
              freeSolo
              onChange={(event, newValue) => onchangeAddSkill(event, newValue)}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <>
                    {typeof option === "string" ? (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        deleteIcon={<img src={Images.chipCloseIcon} alt="" />}
                      />
                    ) : (
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                        deleteIcon={<img src={Images.chipCloseIcon} alt="" />}
                      />
                    )}
                  </>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Type a skill and press 'enter' to add it to subskills."
                  name="skills"
                  onChange={handleInputChange}
                  error={checkSkillField.error}
                  helperText={checkSkillField.helperText}
                  onBlur={checkSkillsOnBlur}
                />
              )}
            />
            {skills.length ? (
              <span className="skillsCounter">{skills.length}/10</span>
            ) : null}
          </div>
          <div className={"field-container"}>
            <div className="fieldName">Rate/hr</div>
            <div className="rate-type">
              <div className="currency-select">
                Currency
                <div className="btn-container">
                  <div className="type-change-btn">
                    <div
                      className={` ${
                        paymentType === "hbar" ? "active-type" : "disable-type"
                      } `}
                      onClick={handlePaymentTypeHourly}
                    ></div>
                    <div
                      className={` ${
                        paymentType === "hbar"
                          ? "active-type-label"
                          : "disable-type-label"
                      } `}
                    >
                      Hbar
                    </div>
                  </div>
                  <div className="type-change-btn">
                    <div
                      className={` ${
                        paymentType === "usd" ? "active-type" : "disable-type"
                      } `}
                      onClick={handlePaymentTypeFixed}
                    ></div>
                    <div
                      className={` ${
                        paymentType === "usd"
                          ? "active-type-label"
                          : "disable-type-label"
                      } `}
                    >
                      US dollar
                    </div>
                  </div>
                </div>
              </div>
              <TextField
                error={errors.rate}
                helperText={errors.rate}
                variant="outlined"
                type="number"
                placeholder={"400"}
                name="rate"
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#2C2C2C" }}>
                      {paymentType === "usd" ? "$" : "hbars"}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className={"field-container"}>
            <div className="fieldName">Portfolio</div>
            <div className="portfolio">
              {portfolio.map((x, i) => {
                return (
                  <>
                    <div className="box">
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder={"Portfolio Name"}
                        value={x.portfolioName}
                        name="portfolioName"
                        onChange={(e) => handlePortfolioChange(e, i)}
                      />
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder={"Portfolio link"}
                        value={x.portfolioLink}
                        name="portfolioLink"
                        onChange={(e) => handlePortfolioChange(e, i)}
                      />
                    </div>
                    <div className="btn-box">
                      {portfolio.length - 1 === i && (
                        <div onClick={addMore} className={"add-btn"}>
                          <img src={Images.plusActive} alt="" />
                          {"Add More link"}{" "}
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="add-superskill-content-footer">
        <div className="btn-container">
          <button className="cancel" onClick={() => history.goBack()}>
            Cancel
          </button>
          <button
            className="publish"
            name="addSuperSkill"
            onClick={AddSuperSkill}
          >
            {loading ? <img src={Images.ellipsis_loader} alt="" /> : "Publish"}
          </button>
        </div>
      </div>
    </div>
  );
}

const StyledAutocomplete = withStyles((theme) => ({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
}))(Autocomplete);

export default NewSuperSkill;
