import { createMuiTheme } from "@material-ui/core";

export const taskbarTheme = createMuiTheme({
    overrides:{
        MuiPopover:{
            paper:{
                '&.notificationPopover':{
                    boxShadow:'0 6px 15px rgba(0, 0, 0, 0.15)'
                },
               '&.profilePopover':{
                boxShadow:'0 6px 15px rgba(0, 0, 0, 0.15)'
               },
               '&.searchBoxPopover':{
                boxShadow:'0 5px 5px  rgba(0, 0, 0, 0.15)'
               }
            }
        }
    },
})