import { Backdrop, Portal } from "@material-ui/core";
import React, { useState } from "react";
import { ArrowDownIcon } from "../../assets/common";
import { TaskActionString } from "../../types";

import "./styles.scss";

type MyTaskActionObject = {
  action: TaskActionString;
  label: string;
  value: string;
  description?: string;
};

interface MyTaskDropDownMenuPorps {
  label: string;
  description: string;
  children: React.ReactNode;
  onChange?: (actionString: TaskActionString) => any;
}

interface MyTaskDropDownMenuItemPorps {
  children: React.ReactNode;
  value: string;
  action: TaskActionString;
  description?: string;
}

const getListStyles = (anchorEl: HTMLElement) => {
  const { top, left, bottom, height, width } = anchorEl.getBoundingClientRect();
  const thresholdBottom = window.innerHeight - bottom;
  const thresholdRight = window.innerWidth - left;

  const listStyles: {
    top: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  } = { top: top + height + 2 };

  if (thresholdRight < 200) {
    listStyles.left = "unset";
    listStyles.right = window.innerWidth - left - width;
  } else {
    listStyles.left = left;
  }

  if (top < 140) {
    listStyles.top = top + height + 2;
    listStyles.bottom = "unset";
  }

  if (thresholdBottom < 140) {
    listStyles.bottom = thresholdBottom + height + 2;
    listStyles.top = "unset";
  }

  return listStyles;
};

export const MyTaskDropDownMenu = ({
  label,
  description,
  onChange,
  children,
}: MyTaskDropDownMenuPorps) => {
  const [childContent, setChildContent] = useState<MyTaskActionObject[] | null>(
    null
  );
  const [selectedAction, setSelectedAction] =
    useState<MyTaskActionObject | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const childCnt: MyTaskActionObject[] = [];

    React.Children.forEach(children, (element: React.ReactNode, index) => {
      if (!React.isValidElement(element)) return;

      const { value, action, description } = element.props;
      const label = element.props.children;
      childCnt.push({ value, action, label });

      if (index === 0) setSelectedAction({ value, action, label, description });
    });

    if (childCnt.length > 1) setChildContent([...childCnt]);
  }, [children]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    actionString: TaskActionString | undefined
  ) => {
    event.preventDefault();
    if (onChange && actionString) onChange(actionString);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div
        className="myTaskOptions"
        title={description ?? "Available options for a task"}
      >
        <button
          className="myTaskOptions_btnPrimary "
          onClick={(event) => handleMenuClick(event, selectedAction?.action)}
        >
          {selectedAction?.label ?? label}
        </button>

        {childContent && (
          <button
            className={`myTaskOptions_btnDropDown ${
              !!anchorEl ? "active" : ""
            }`}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <ArrowDownIcon
              className={`myTaskOptions_btnDropDown--icon ${
                !!anchorEl ? "active" : ""
              }`}
            />
          </button>
        )}
      </div>
      {anchorEl && (
        <Portal>
          <Backdrop
            open={!!anchorEl}
            invisible
            style={{ zIndex: 1001 }}
            onClick={() => setAnchorEl(null)}
          />
          <div
            className="myTaskOptions__dropDown"
            style={getListStyles(anchorEl)}
          >
            <ul className="myTaskOptions__menuList">
              {childContent &&
                childContent.map((child) => (
                  <li
                    className="myTaskOptions__menuItem"
                    onClick={(event) => handleMenuClick(event, child.action)}
                    title={child.description ?? ""}
                    key={child.value}
                  >
                    {child.label}
                  </li>
                ))}
            </ul>
          </div>
        </Portal>
      )}
    </React.Fragment>
  );
};

export const MyTaskDropDownMenuItem = ({
  children,
  ...restProps
}: MyTaskDropDownMenuItemPorps) => (
  <React.Fragment {...restProps}>{children}</React.Fragment>
);
