import React from "react";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import "./index.scss";

const GoBack = (props) => {
  let history = useHistory();
  return (
    <div className="goBack">
      <span onClick={() => history.goBack()}>
        <span className="lable">
          {" "}
          <KeyboardBackspaceIcon />
          Back
        </span>
      </span>
    </div>
  );
};

export default GoBack;
