import { makeStyles } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import { Images } from "../../assets/common";

const SingleReview = (props) => {
  const classes = styles();
  const { review } = props;

  const openHCSPopup = (id) => {
    props.openHCSPopup(id);
  };

  return (
    <div className={classes.reviewSingle}>
      <div className={classes.profileDetails}>
        {review && Object.keys(review).length > 0 ? (
          <div className={classes.profileDetails__profile}>
            <div className="profilePic">
              <img src={review.from.profileImgUrl} alt="Profile Name" />
              <span className="box active "></span>
            </div>
            <div className="details">
              <div className="name_and_address">
                <span className="name">{review.from.fnameVisibilty ? `${review.from.firstName} ${review.from.lastName}` : `@${review.from.nickName}`}</span>
                <span className="locaionText">
                  {`Posted on `}
                  <Moment format="MMM D, YYYY">{review.createdAt}</Moment>
                </span>
              </div>
              <div className="ratingText">
                <p>{`“${review.review}”`}</p>
              </div>
              <div className={classes.metaBox}>
                <div className="rating metaDetails">
                  <span className="label">Rating:</span>
                  {[...Array(5)].map((d, i) => (
                    <span key={i} className={`star ${i + 1 <= review.rating ? "fill" : ""}`}></span>
                  ))}
                  <span className="ratingVal">{`(${review.rating}/5)`}</span>
                </div>
                <div className="metaDetails">
                  <span className="label">{`Rate: `}</span>
                  <span>{review.task.paymentTerms.hourly ? review.task.paymentTerms.hourly.ratePerHour : review.task.paymentTerms.fixed.rate} &#8463;(s)</span>
                </div>
                <div className="metaDetails">
                  <span className="label">{`Type: `}</span>
                  <span>{review.task.paymentTerms.paymentType}</span>
                </div>
                {props.hcsInfo ? (
                  <div className="metaDetails">
                    <span className="label">{`HCS Info: `}</span>
                    <span>
                      View <img src={Images.openIcon} onClick={() => openHCSPopup(review.task._id)} alt="" />
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const styles = makeStyles((theme) => ({
  reviewSingle: {
    padding: "24px 0  17px 0",
    borderBottom: "1px solid #dddddd",
    "&:last-child": {
      borderBottom: "none",
      paddingBottom: 25,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    },
  },
  profileDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    "& .favBtnArea": {
      width: 175,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  profileDetails__profile: {
    flex: "0 0 100%",
    maxWidth: "100%",
    display: "flex",

    "& .profilePic": {
      width: 48,
      height: 48,
      position: "relative",
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        marginRight: 5,
      },
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: 3,
        objectFit: "cover",
        objectPosition: "center",
        [theme.breakpoints.down("sm")]: {
          width: 48,
          //marginRight:5
        },
      },
      "& .box": {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: 10,
        height: 10,
        borderRadius: 3,
        "&.active": {
          backgroundColor: "#1bc11b",
        },
        "&.inactive": {
          backgroundColor: "#ED0B0B",
        },
      },
    },
    "& .details": {
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: "100%",

      "& .name_and_address": {
        marginBottom: 2,
        "&>span": {
          display: "inline-block",
        },
        "& .name": {
          paddingRight: "10px",
          color: "#4DACA2",
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: 500,
        },
        "& .locaionText": {
          fontSize: 13,
          marginLeft: 10,
          lineHeight: "15px",
          [theme.breakpoints.down("sm")]: {
            float: "right",
          },
        },
      },
      "& .ratingText": {
        "& p": {
          fontSize: 14,
          color: "#2c2c2c",
          fontStyle: "italic",
        },
      },
    },
  },
  metaBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    "& .metaDetails": {
      display: "flex",
      alignItems: "center",
      // marginRight: 48,
      [theme.breakpoints.down("sm")]: {
        marginRight: 30,
      },
      "&>span": {
        display: "inline-block",
      },
      "& .label": {
        fontWeight: 600,
        color: "#2c2c2c",
        fontSize: 14,
        marginRight: "4px",
      },
      "& img": {
        cursor: "pointer",
      },
    },
    "& .rating": {
      "& .star": {
        width: 20,
        height: 20,
        backgroundImage: `url('${Images.Rating}')`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        "&.fill": {
          backgroundImage: `url('${Images.GreenRating}')`,
        },
      },
      "& .ratingVal": {
        color: "#2C2C2C",
        fontWeight: 500,
        fontSize: 13,
        marginLeft: 6,
      },
    },
  },
}));

export default SingleReview;
