import {Popover} from "@material-ui/core";
import React, {useState} from "react";
import {Images} from "../../assets/common";
import {  popOverCollapseTransition } from "../validator";


const MobileSearchBoxPopOver = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");

  const openSearchPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const closeSearchPopover = () => {
    setAnchorEl(null);
  };

  const handlePageRedirection = (data) => {
    const searchValue = data;
    props.history.push(`/Task/list-task?search=${searchValue}`);
  };

  const onSearchClick = () => {
    if (searchText) handlePageRedirection(searchText);
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    setSearchText({search: value});
    if (event.key === "Enter") {
      switch (id) {
        case "search":
          handlePageRedirection(value);
          break;
        default:
          console.warn("Something is not right !");
      }
    }
  };

  const searchBoxPopoverOpen = Boolean(anchorEl);
  const searchBoxPopoverId = searchBoxPopoverOpen
    ? "searchBoxPopover"
    : undefined;

  return (
    <div className="mobileSearchBoxPopover mobileSearch-btn m-search">
      <button onClick={openSearchPopover}>
        <img
          src={searchBoxPopoverOpen ? Images.IconForCloseModal : Images.search}
          alt="" className={searchBoxPopoverOpen ? 'closeIcon' : ''}
        />
      </button>
      <Popover
        id={searchBoxPopoverId}
        open={searchBoxPopoverOpen}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{top: 100, left: 0}}
        onClose={closeSearchPopover}
        TransitionComponent={popOverCollapseTransition}
        PaperProps={{
          className: "searchBoxPopover",
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}>
        <div className={searchBoxPopoverOpen ? "search mobileView" : "search"}>
          <input
            placeholder="Find Task/Tasker"
            name="search"
            onChange={handleInputChange}
            onKeyPress={handleInputChange}
          />
          <img
            className="search-icon"
            src={Images.search}
            alt={""}
            onClick={onSearchClick}
          />
        </div>
      </Popover>
    </div>
  );
};

export default MobileSearchBoxPopOver;
