import React, { Component } from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../../../components/privateRoute';
import Analytics from './analytics';
import TransactionHistory from './transaction-history';




class RouterOutlet extends Component {

  render() {
    const { match, dashboardData, loading, transactionHistory } = this.props;
    return (
      <Switch>
        <PrivateRoute
          path={`${match.url}/analytics`}
          component={() => <Analytics dashboardData={dashboardData} loading={loading} />}
        />
        <PrivateRoute
          exact path={`${match.url}/transaction-history`}
          component={() => <TransactionHistory transactionHistory={transactionHistory} />}
        />

        {/*Deafult Page*/}
        <PrivateRoute
          exact path={`${match.url}/`}
          component={() => <Analytics dashboardData={dashboardData} loading={loading} />}
        />
      </Switch>
    )
  }
}

export default RouterOutlet;