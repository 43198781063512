import {
  Fade,
  makeStyles,
  Modal
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import { StyledBackdrop } from "../../lib/validators";
import { GET_TRANSACTION_HISTRORY } from "../../services/api-service";
import Loader from "../Loader";
import notifier from "../notifier";
import { getErrorString } from "../validator";
import './index.scss';

const HCSPopup = (props) => {
  const classes = Styles()
  const [loading, setLoading] = useState(false)
  const [transactionHistory, setTransactionHistory] = useState({})

  useEffect(() => {
    getTransactionHistory();
  }, [props.taskId])

  const handleClose = () => {
    props.onClose();
  };

  const getTransactionHistory = async () => {
    if (props.taskId) {
      setLoading(true)
      try {
        const params = `taskId=${props.taskId}`
        const response = await GET_TRANSACTION_HISTRORY(params);
        setLoading(false);
        const TransactionHistory = response.data.result.transactionHistory;
        setTransactionHistory(TransactionHistory);
      } catch (error) {
        setLoading(false);
        notifier.error('Error', getErrorString(error))
      }
    }
  }

  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          {loading ? <Loader /> : ''}
          <div className={classes.reviews}>
            <div className="heading">
              <h4 className="heading__label">{`HCS Information`}</h4>
              <span className="closeBtn" onClick={handleClose}>
                <img src={Images.IconForCloseModal} alt=""/>
              </span>
            </div>
            {transactionHistory && Object.keys(transactionHistory).length > 0 ?
              <div className="info">
                <div className='input-container'>
                  <label>Transaction Hash</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={transactionHistory.transactionResponseObject.transactionHash}
                  />
                </div>

                <div className='input-container'>
                  <label>Transaction Id</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={transactionHistory.vendorTransactionId}
                  />
                </div>

                <div className='input-container'>
                  <label>Operator</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label>Node</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label>Consensus</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label>Status</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={transactionHistory.transactionOutcome}
                  />
                </div>

                <div className='input-container'>
                  <label>Type</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={transactionHistory.transactionType}
                  />
                </div>

                <div className='input-container'>
                  <label>Message</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label>Memo</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label>Total Amount</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={transactionHistory.totalAmount}
                  />
                </div>

                <div className='input-container'>
                  <label>Transaction Fee</label>
                  <TextField
                    variant="outlined"
                    name="skills"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div> : ''}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const Styles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    position: 'relative',
    height: "calc(100vh - 75px - 30px)",
    width: 952,
    backgroundColor: "#ffffff",
    marginTop: 75,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
    outline: 'none'
  },
  reviews: {
    height: "100%",
    border: "none!important",
    outline: "none !important",
    "&:focus , &:focus-visible": {
      border: "none!important",
      outline: "none !important",
    },
    "& .heading": {
      padding: "16px 20px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&__label": {
        fontSize: 16,
        color: "rgba(44, 44, 44, 1)",
        textTransform: "uppercase",
        fontWeight: 600,
        marginBottom: 0,
        "& img": {
          width: 26,
          marginRight: 12,
        },
      },
      "& .closeBtn": {
        marginLeft: "auto",
        cursor: "pointer",
        "& img": {
          width: 14,
        },
      },
    },
    '& .info': {
      maxHeight: 'calc(100% - 57px)',
      overflowY: 'auto',
      paddingBottom: '30px',
      "&::-webkit-scrollbar": {
        width: 5,
        "&-track": {
          background: "transparent",
        },
        "&-thumb": {
          backgroundColor: "#DDDDDD",
          borderRadius: 10,
        },
      },
    }
  },
});



export default HCSPopup;
