import { PopupButton, Sidetab } from "@typeform/embed-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import Footer from "../../components/footer";
import TypeWriter from "../../components/TypeWriter";
import { REQ_SKILLS } from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import HeaderBoard from "../home/header-board";
import Header from "./../../components/header";
import "./index.scss";

function LandingPage(props) {
  const [search, updateSearch] = useState("");
  const [skills, setSkills] = useState([]);
  // const [typeWriterCursor , setTypeWriterCursor] = useState(true)

  useEffect(() => {
    getSkills();
    // Component Will mount code will go here
    return () => {
      // Component Will unmount will go here
    };
  }, []);

  const getSkills = async (id) => {
    try {
      const response = await REQ_SKILLS();
      let skills = response.data.result;
      skills.length = 10;
      setSkills(skills);
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    updateSearch(value);
    if (event.key === "Enter") {
      switch (id) {
        case "search":
          handlePageRedirection(value);
          break;
        default:
          console.warn("Something is not right !");
      }
    }
  };

  const handlePageRedirection = (data) => {
    let searchValue = data;
    props.history.push(`/Task/list-task?search=${searchValue}`);
  };

  const handleClickPopularSearch = (data, id) => {
    let searchValue = data;
    props.history.push(`/Task/list-task?search=${searchValue}&skillsId=${id}`);
  };

  const handleClickSearchSkills = (searchValue) => {
    props.history.push(`/Task/find-tasker?search=${searchValue}`);
  };

  const onSearchClick = () => {
    if (search) handlePageRedirection(search);
  };

  return (
    <div className="landing-page">
      <Header {...props} />
      <div className="landing-page-container" style={{ backgroundImage: `url('${Images.landingPageImage}')` }}>
        {!_.isEmpty(cookieStorage.getCookie("token")) ? <HeaderBoard {...props} /> : null}
        <div className="main-container">
          <div className="title-container">
            <div className="title">
              <TypeWriter items={["Find a Task", "Submit a Task", "Earn and Spend Crypto"]} typingInterval={100} pause={2000} cursor={false} />
            </div>

            <div className="Earn_div">Earn crypto and grow your income on our secure and decentralised Web3 platform</div>

            <div className="task-search">
              <div className="search-icon" onClick={onSearchClick}>
                <img src={Images.SearchLandingpage} alt={""} />
              </div>
              <input placeholder="Search task you are looking for eg. Web design" name="search" onChange={handleInputChange} onKeyPress={handleInputChange} />
            </div>

            <div className="popular-search-container">
              {/* <h3>Popular Searches :</h3> */}
              <div className="popular-searches">
                <div className="view-all-search-item" onClick={() => handlePageRedirection("")}>
                  View all tasks
                </div>
                <div className="view-all-search-item" onClick={() => handleClickSearchSkills("")}>
                  View all Superskills
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <section className="whyJT">
        <div className="whyJT__inner">
          <div className="heading">
            <h2>Why Join Taskbar?</h2>
          </div>
          <div className="whyJT__points">
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.monitize} className="defImg" alt="" />
                <img src={Images.whyJTIcons.monitizeHovered} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">Monetize your skills</p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.buildReputation} className="defImg" alt="" />
                <img src={Images.whyJTIcons.buildReputationHovered} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">Build your reputation</p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.achieveIndipendence} className="defImg" alt="" />
                <img src={Images.whyJTIcons.achieveIndipendenceHoverd} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">Achieve independence</p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.noBank} className="defImg" alt="" />
                <img src={Images.whyJTIcons.noBankHovered} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">No bank account required</p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.earnHbars} className="defImg" alt="" />
                <img src={Images.whyJTIcons.earnHbarsHoverd} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">
                Earn <b>ℏ(s)</b> for your work/tasks
              </p>
            </div>
            <div className="whyJT__points__item whyJT__points__item--heighlight">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.spendHbar} className="defImg" alt="" />
                <img src={Images.whyJTIcons.spendHbarHoverd} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">
                Spend <b>ℏ(s)</b> to receive services/tasks
              </p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.ultrLow} className="defImg" alt="" />
                <img src={Images.whyJTIcons.ultrLowHoverd} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">Ultra low transaction fees</p>
            </div>
            <div className="whyJT__points__item">
              <div className="whyJT__points__item--icon">
                <img src={Images.whyJTIcons.noPlatform} className="defImg" alt="" />
                <img src={Images.whyJTIcons.noPlatformHovered} className="hoverImg" alt="" />
              </div>
              <p className="whyJT__points--label">No platform commission *</p>
            </div>
          </div>
          <div className="whyJT__offer">
            <p>
              <img src={Images.whyJTIcons.watch} alt="" /> <b>*Limited time offer</b> - for early Community adopters
            </p>
          </div>
        </div>
      </section>
      <section className="howTW">
        <div className="howTW__inner">
          <div className="heading">
            <h2> How Taskbar Works? </h2>
            <p>YOU CAN BE BOTH</p>
          </div>
          <div className="howTW__content">
            <div className="needer">
              <div className="needer__inner">
                <h3 className="heading">NEEDER</h3>
                <ul style={{ listStyle: `url('${Images.listTick}')` }}>
                  <li>Create and promote a task</li>
                  <li>Review offers or bids</li>
                  <li>Assign tasker (payment is escrowed with Taskbar)</li>
                  <li>Approve completed task and review tasker</li>
                  <li>Release escrowed payment to tasker</li>
                </ul>
              </div>
            </div>
            <div className="tasker">
              <div className="tasker__inner">
                <h3 className="heading">TASKER</h3>
                <ul style={{ listStyle: `url('${Images.listTick}')` }}>
                  <li>Search for a task</li>
                  <li>Apply to deliver a task (fixed amount or hourly rate)</li>
                  <li>Deliver task to needer satisfaction (payment is escrowed with taskbar</li>
                  <li>Completed task approved by needer</li>
                  <li>Claim escrowed payment</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* <Sidetab
        id="O36mBoNx"
        width={320}
        height={400}
        customIcon={"https://images.typeform.com/images/Cw5BH9WdZJud"}
        buttonColor={"#F15A22"}
        buttonText={"Taskman"}
        chat
        medium="snippet"
        style={{ all: "unset" }}
        buttonProps={{ color: "#F15A22", title: "Hey 👋  Are you looking to hire Tasketeers 🤺 ? " }} 
      />*/}
    </div>
  );
}

export default LandingPage;

{
  /* <div data-tf-popover="O36mBoNx" data-tf-custom-icon="https://images.typeform.com/images/Cw5BH9WdZJud" data-tf-button-color="#F15A22" data-tf-tooltip="" data-tf-chat data-tf-medium="snippet" style={{ all: "unset" }} />; */
}
