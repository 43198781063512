import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { Images } from '../../assets/common';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";

import './index.scss';

class Footer extends Component {
    render() {
        return (
            <footer className="siteFooter">
        <div className="siteFooter__inner">
          <div className="siteFooter__logo">
            <Link
              to="/"
              onClick={() => {
                window.scroll({ top: 0, behavior: "smooth" });
              }}
            >
              <img src={Images.logoWhite} alt="logo" />
            </Link>
          </div>
          <div className="siteFooter__top">
            <div className="left">
              <p className="hederalogo">
                <a href="https://www.hedera.com/" target="_blank"  rel="noopener noreferrer">
                  <img src={Images.hederaLogo} alt="Hedera Hasgraph" />
                </a>
              </p>
              <div className="social__media__links">
                <a target="_balnk" href="https://t.me/mytaskbarcommunity">
                  <TelegramIcon />
                </a>
                <a target="_blank"  rel="noopener noreferrer" href="https://twitter.com/mytaskbar">
                  <TwitterIcon />
                </a>
                <a target="_blank"  rel="noopener noreferrer" href="https://www.facebook.com/mytaskbar/">
                  <FacebookIcon />
                </a>
                <a target="_blank"  rel="noopener noreferrer" href="https://www.instagram.com/mytaskbar">
                  <InstagramIcon />
                </a>
              </div>
            </div>
            <div className="rihgt">
              <div className="heading">Links</div>
              <div className="menu">
                <Link to={"#"}>Privacy Policy</Link>
              </div>
              <div className="menu">
                <Link to={"/terms-of-use"} target="_blank"  rel="noopener noreferrer">Terms of Use</Link>
              </div>
              <div className="menu">
                <Link to={"#"}>FAQ'S</Link>
              </div>
            </div>
          </div>
          <div className="siteFooter__bottom">
            <div>
              <p>
                * To learn more about us or would like to connect with the core
                team, please visit{" "}
                <a href="https://www.mytaskbar.com/" target="_blank"  rel="noopener noreferrer">
                  mytaskbar.com
                </a>
              </p>
              {/* <p>** To share an idea or report a bug, please head to <a href="https://roadmap.mytaskbar.io" target="_blank">roadmap.mytaskbar.io</a>  </p> */}
            </div>
            <span>
              © TASKBAR 2021 All Rights Reserved | hello (at) mytaskbar (dot) io
            </span>
          </div>
        </div>
      </footer>
        );
    }
}

export default Footer;
