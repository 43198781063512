import { ActionObjectForStatus } from "../types";

/***
 * @description These Options are common  for task status Open in `PENDING` section of `CREATED TASKS`.
 */
const created_pending_open: ActionObjectForStatus[] = [
  {
    label: "Share Task",
    key: "pending_fixed_open_apply_share",
    action: "SHARE_TASK",
  },
  {
    label: "Edit Task",
    key: "pending_fixed_open_apply_edit",
    action: "EDIT_TASK",
  },
  {
    label: "Promote Task",
    key: "pending_fixed_open_apply_promote",
    action: "PROMOTE_TASK",
  },
  {
    label: "Invite Tasker",
    key: "pending_fixed_open_apply_invite",
    action: "INVITE_TASKER",
  },
  {
    label: "Cancel Task",
    key: "pending_fixed_open_apply_cancel",
    action: "CANCEL_TASK",
  },
];

/***
 * @description Task options for `BIDDING ENABLED` `CREATED` tasks in `PENDING` section with  task status `OPEN`.
 */
const created_pending_open_bidding: ActionObjectForStatus[] = [
  {
    label: "View Bids",
    key: "pending_fixed_open_bid_view",
    action: "VIEW_BID",
  },
  {
    label: "Close Bidding",
    key: "pending_fixed_open_bid_close_bid",
    action: "CLOSE_BIDDING",
    description: "Needer don't want to receive any more bids on this task.",
  },
  ...created_pending_open,
];

/***
 * @description Task options for `APPLY ENABLED` `CREATED` tasks in `PENDING` section with  task status `OPEN`.
 */
const created_pending_open_apply: ActionObjectForStatus[] = [
  {
    label: "View Proposals",
    key: "pending_fixed_open_apply_view",
    action: "VIEW_PROPOSALS",
  },
  ...created_pending_open,
];

/***
 * @description Task options for  `CREATED` tasks in `PENDING` section with  task status `WAITING FOR PAYMENT`.
 */
const created_pending_waiting_for_payment: ActionObjectForStatus[] = [
  { label: "Pay Now", key: "created_waiting_for_payment", action: "PAY_NOW" },
];

/***
 * @description Task options for  `CREATED` tasks in `ACTIVE` section with  task status `RUNNING`.
 */
const created_active_running: ActionObjectForStatus[] = [
  {
    label: "Send Messages",
    key: "created_active_running_sendMessage",
    action: "SEND_MESSAGES",
  },
  {
    label: "Raise Dispute",
    key: "created_active_running_dispute",
    action: "RAISE_DISPUTE",
  },
];

/**
 * @description Task options for  `CREATED` tasks in `ACTIVE` section with  task status `WAITING FOR APPROVAL`.
 */
const created_active_waiting_for_approval: ActionObjectForStatus[] = [
  {
    label: "Send Message",
    key: "created_active_waiting_for_approval_sendMessages",
    action: "SEND_MESSAGES",
  },
  {
    label: "Approve task complete",
    key: "created_active_waiting_for_approval_approve",
    action: "MARK_COMPLETE",
  },
  {
    label: "Raise Dispute",
    key: "created_active_waiting_for_approval_raiseDispute",
    action: "RAISE_DISPUTE",
  },
];

/**
 * @description Task options for  `CREATED` tasks in `ACTIVE` section with  task status `APPROVE EXTENSION REQUEST`.
 */
const created_active_approve_extension: ActionObjectForStatus[] = [
  {
    label: "Approve",
    key: "created_Active_approve_extension_approve",
    action: "APPROVE_TASK_COMPLETE",
  },
  {
    label: "Deny",
    key: "created_Active_approve_extension_denay",
    action: "DENY",
  },
  {
    label: "Raise Dispute",
    key: "created_Active_approve_extension_raiseDispute",
    action: "RAISE_DISPUTE",
  },
];

/**
 * @description Task options for  `CREATED` tasks in `ACTIVE` section with  task status `WAITING FOR REVIEW`.
 */
const created_active_waiting_for_review: ActionObjectForStatus[] = [
  {
    label: "Write Review",
    key: "created_Active_waiting_for_payment_writeReview",
    action: "WRITE_REVIEW",
  },
  {
    label: "Send Messages",
    key: "created_Active_waiting_for_payment_sendMessages",
    action: "SEND_MESSAGES",
  },
];

/***
 * @description Task options for `APPLIED` tasks in `PENDING` section with  task status `OPEN` with biddingEnabled.
 */
const applied_pending_open_bidding: ActionObjectForStatus[] = [
  {
    label: "Withdraw",
    key: "applied_pending_open_withdraw",
    action: "WITHDRAW",
  },
];
/***
 * @description Task options for `APPLIED` tasks in `PENDING` section with  task status `OPEN`  with apply task.
 */
const applied_pending_open_apply: ActionObjectForStatus[] = [
  {
    label: "Negotiate",
    key: "applied_pending_open_negotiate",
    action: "NEGOTIATE",
  },
  {
    label: "Withdraw",
    key: "applied_pending_open_withdraw",
    action: "WITHDRAW",
  },
];

/***
 * @description Task options for `APPLIED` tasks in `ACTIVE` section with  task status `RUNNING`.
 */
const applied_active_running: ActionObjectForStatus[] = [
  {
    label: "Send Message",
    key: "applied_active_running_sendMessages",
    action: "SEND_MESSAGES",
  },
  {
    label: "Mark complete",
    key: "applied_active_running_markComplete",
    action: "MARK_COMPLETE",
  },
  {
    label: "Rquest Extension",
    key: "applied_active_request_extension",
    action: "REQUEST_EXTENSION",
  },
  {
    label: "Raise Dispute",
    key: "applied_active_running_raiseDispute",
    action: "RAISE_DISPUTE",
  },
];

/**
 * @description Task options for All `Archived` tasks.
 */
const archive_completed: ActionObjectForStatus[] = [
  {
    label: "Chat History",
    key: "created_Archive_completed",
    action: "CHAT_HISTORY",
  },
];

/***
 * @description Task options for `DISPUTED` tasks in both sections. And in  `APPLIED SECTION` for task statuses `WAITING FOR REVIEW`, `WAITING FOR APPROVAL`, `WAITING_FOR_EXTENSION`
 */
const send_messages: ActionObjectForStatus[] = [
  {
    label: "Send Messages",
    key: "created_active_disputed_sendMessages",
    action: "SEND_MESSAGES",
  },
];

const created_active_disputed: ActionObjectForStatus[] = [
  ...send_messages,
  {
    label: "Email Admin",
    key: "created_active_disputed_email_admin",
    action: "EMAIL_ADMIN",
  },
];
const applied_active_disputed: ActionObjectForStatus[] = [
  ...send_messages,
  {
    label: "Email Admin",
    key: "applied_active_disputed_email_admin",
    action: "EMAIL_ADMIN",
  },
];
const applied_active_waiting_for_approval = send_messages;
const applied_active_waiting_for_review = send_messages;

/***
 *@description Task is expired while task running. | This will same for both needer and tasker | For all contract types (Fixed | Hired) .
 */
const created_archived_expired: ActionObjectForStatus[] = [
  {
    label: "Reactivate",
    key: "",
    action: "REACTIVATE_TASK",
  },
];

//

const exportObject: {
  [key: string]: ActionObjectForStatus[];
} = {
  send_messages,
  archive_completed,
  applied_active_running,
  applied_pending_open_apply,
  applied_pending_open_bidding,
  created_active_waiting_for_review,
  created_active_approve_extension,
  created_active_waiting_for_approval,
  created_active_running,
  created_pending_waiting_for_payment,
  created_pending_open_apply,
  created_pending_open_bidding,
  created_pending_open,
  created_archived_expired,
  created_active_disputed,
  applied_active_disputed,
  applied_active_waiting_for_approval,
  applied_active_waiting_for_review,
};

export default exportObject;
