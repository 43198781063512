import { TextField, Chip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Images } from '../../../assets/common';
import _ from "lodash";
import { ADMIN_ADD_PREDEFINED_TASK, ADMIN_UPDATE_PREDEFINED_TASK, REQ_SKILLS } from '../../../services/api-service';
import notifier from "../../../components/notifier";
import { MESSAGE } from "../../../constants/messages";
import './form.scss';
import { getErrorString } from "../../../components/validator";


const PredefinedTaskForm = (props) => {
    const {isEditForm,editFormData} = props;
    //console.log(editFormData);
    const [AllSkills, setAllSkills] = useState([]);
    const [skills, setSkills] = useState([]);
    const [values, setValues] = useState({
      heading: "",
      description: "",
    });

    useEffect(() => {
      getAllSkills();
      if (isEditForm) {
          setValues({
              heading:editFormData.heading,
              description:editFormData.description
          })
          setSkills([...editFormData.skills])
      }
    }, [isEditForm]);

      const getAllSkills = _.debounce(async (skill) => {
        try {
          let allSkills;
          const param = {
            searchPhrase: skill ? skill : undefined,
          };
          const response = await REQ_SKILLS(param);
          allSkills = response.data.result;
          setAllSkills(allSkills);
        } catch (e) {
        //   setLoader(false);
          console.log(e);
          console.log("ERROR in handleLogin:::");
        }
      }, 1000);

      const onchangeAddSkill = (event, newValue) => {
        let newArray = newValue.map((d, i) => {
          if (typeof d === "string") {
            return { name: d };
          } else {
            return d;
          }
        });
        setSkills(newArray);
      };


    const handleInputChage = (event) => {
        const id = event.target.name;
        const value = event.target.value;
        const newVal = values;

      if(id === 'skills'){
        setSkills(value);
        getAllSkills(value);
      }else{
        newVal[id] = value;
        setValues({...newVal});
      }
    }


    const validateField = () => {
         let error = false;
        if (!values.heading) {
            notifier.warning(MESSAGE.ERROR.TITLE_EMPTY);
            error = true;
        } else if (values.heading.length <= 2) {
          notifier.warning(MESSAGE.ERROR.TITLE_LENGTH);
          error = true;
        } else if (!values.description) {
          notifier.warning(MESSAGE.ERROR.DESCRIPTION_EMPTY);
          error = true;
        } else if (skills.length === 0) {
          notifier.warning(MESSAGE.ERROR.SKILLS_EMPTY);
          error = true;
        }
        return error;
    }
    const addNewTask = async () =>{
         const checkValid = validateField();
         if(checkValid){
             return;
         }
         try{
            const reqBody = {
                ...values,
                skills: skills
            };
            
            const response = await ADMIN_ADD_PREDEFINED_TASK(reqBody);
             if (response.data.status === 'success') {
                    notifier.success(MESSAGE.SUCCESS.TASK_CREATED_SUCESSFULLY)
                    setValues({ heading: "", description: "",});
                    setSkills([]);
             }
         }
         catch(e){
            notifier.error("Error", getErrorString(e));
         }
    }

    const updateTask = async () =>{
        const checkValid = validateField();
         if(checkValid){
             return;
         }
         try{
            const reqBody = {
                taskId: editFormData.id,
                ...values,
                skills: skills
            };
            
            const response = await ADMIN_UPDATE_PREDEFINED_TASK(reqBody);
             if (response.data.status === 'success') {
                    notifier.success(MESSAGE.SUCCESS.TASK_UPDATED_SUCESSFULLY)
                    setValues({ heading: "", description: "",});
                    setSkills([]);
             }
         }
         catch(e){
            notifier.error("Error", getErrorString(e));
         }
    }

    const handleCancel = () => {
        setValues({
          ...{
            heading: "",
            description: "",
          }
        });
        setSkills([])
      props.handleCancel();
    }

    return (
      <div className="form-edit-add">
        <div className="heading">
          <h4>Add A new Task</h4>
          <button className="cancelBtn" onClick={handleCancel}>
            <img src={Images.cancel} alt="" /> Cancel
          </button>
        </div>
        <p>Task Title</p>
        <TextField
          label="Enter Tasktitle for Predefined Task"
          variant="outlined"
          fullWidth
          name="heading"
          value={values.heading}
          onChange={handleInputChage}
        />
        <p>Description of the task</p>
        <TextField
          label="Provide brief description of the Project"
          variant="outlined"
          multiline
          rows={7}
          name="description"
          fullWidth
          value={values.description}
          onChange={handleInputChage}
        />
        <p>Search And Add Task Subskills</p>
        <Autocomplete
          multiple
          id="tags-filled"
          value={skills}
          options={AllSkills}
          freeSolo
          onChange={(event, newValue) => onchangeAddSkill(event, newValue)}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <>
                {typeof option === "string" ? (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={index}
                    {...getTagProps({ index })}
                    deleteIcon={<img src={Images.chipCloseIcon} alt=""/>}
                  />
                ) : (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    key={index}
                    {...getTagProps({ index })}
                    deleteIcon={<img src={Images.chipCloseIcon} alt="" />}
                  />
                )}
              </>
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="What skills and expertise are you looking for?"
              name="skills"
              onBlur={(e) => {
                if (e.target.value.trim() !== "")
                  notifier.warning(
                    "Please Press Enter to add New skill, That is not in the List"
                  );
              }}
            />
          )}
        />

        <div className="submitBtn">
          {isEditForm ? (
            <button onClick={updateTask}>Update Task</button>
          ) : (
            <button onClick={addNewTask}>Add Task</button>
          )}
        </div>
      </div>
    );
}

export default PredefinedTaskForm;