import { Modal,Fade} from '@material-ui/core'
import  * as React  from 'react';
import { Images } from '../../assets/common';
import { StyledBackdrop } from '../../lib/validators';
import './index.scss'
const ConfirmModal=({text,yesCallback=f=>f,cancelCallback=f=>f,modal,delBtnLabel,yesBtnLabel , loading})=>
{
// //console.log(modal);
return(
<Modal
aria-labelledby="Issue Reporting"
aria-describedby="If you found any issue Report Here"
className="alertModal"
open={modal}
onClose={cancelCallback}
closeAfterTransition
BackdropComponent={StyledBackdrop}
BackdropProps={{
  timeout: 500,
}}
>
<Fade in={modal}>
  <div className="alertModal__paper">
    <p>{text}</p>
    <div className="actions">
      <button className="yes" disabled={loading} onClick={yesCallback}>{!loading?(yesBtnLabel ?? 'Yes Delete') : <img src={Images.ellipsis_loader} alt='Loading...'/> }</button>
      <button className="cancel" disabled={loading} onClick={cancelCallback}>
        {delBtnLabel ??'Cancel'}
      </button>
    </div>
  </div>
</Fade>
</Modal>
)
}


export default ConfirmModal;