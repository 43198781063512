import { Fade, IconButton, makeStyles, Modal } from '@material-ui/core';
import SendIcon from "@material-ui/icons/Send";
import React, { useContext, useEffect, useRef, useState } from 'react';
import Moment from "react-moment";
import { Images } from '../../assets/common';
import { TaskbarUserContext } from '../../context/TaskbarUserContext';
import { StyledBackdrop } from '../../lib/validators';
import { GET_CHAT_HISTORY, SEND_MESSAGE } from '../../services/api-service';
import notifier from '../notifier';
import { getErrorString } from '../validator';

const ChatModel = (props) => {
    const { chatId, open, onClose } = props;
    const chatBox = useRef();
    const classes = ChatModelStyles();
    const { currentUser } = useContext(TaskbarUserContext);


    const [messages, setMessages] = useState([]);
    const [receiver, setReceiver] = useState(null);
    const [taskDetails, setTaskDetails] = useState({})


    const getChatHistory = async () => {
        try {
            const reqBody = {
                chatId: chatId,
            }

            const response = await GET_CHAT_HISTORY(reqBody);
            setMessages(response.data.result.messages.map((m) => {
                m.sender === currentUser._id ? m.type = "send" : m.type = "received";
                return m;
            }))
            const receiverData = response.data.result.members.filter(m => m.type === 'receiver')
            setReceiver(receiverData[0]);
            setTaskDetails(response.data.result.taskId)
            handleScroll();
        }
        catch (e) {
            console.log(e);
            notifier.error("Error while getting chatHistory::", getErrorString(e));
        }
    }

    const sendMessage = async (messageText , setTextAreaText) => {
        try {
            const reqBody = {
                message: messageText,
                chatId: chatId
            }
            const response = await SEND_MESSAGE(reqBody);
            if (response.data.status === "success") {
                setMessages(response.data.result.map((m) => {
                    m.sender === currentUser._id ? m.type = "send" : m.type = "received";
                    return m;
                }));
            }
            handleScroll();
            setTextAreaText('')
        }
        catch (e) {
            console.log(e);
            notifier.error("Error while getting chatHistory::", getErrorString(e));
        }
    }

    const handleClose = () => {
        onClose();
    }

    const handleScroll = () => {
        if (chatBox) {
            chatBox.current.addEventListener("DOMNodeInserted", (event) => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: "smooth" });
            });
        }
        chatBox.current.scroll({
            top: chatBox.current.scrollHeight,
        });
    }

    console.log('is rerendring')
    return (
        <Modal
            aria-labelledby="Active task chat"
            aria-describedby="Chat on active state Task"
            className={`${classes.modal} negotiationModal`}
            open={open}
            onClose={handleClose}
            onRendered={getChatHistory}
            closeAfterTransition
            BackdropComponent={StyledBackdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className={`${classes.paper} negotiationModal__paper`}>
                    <div className={`${classes.negotiation} negotiationModal__box`}>
                        <div className="heading">
                            <h4 className="heading__label">
                                <img src={Images.negotiationIcon} alt="" /> Send message
                            </h4>
                            <span className="closeBtn" onClick={handleClose}>
                                <img src={Images.IconForCloseModal} alt="" />
                            </span>
                        </div>
                        {receiver && (
                            <div className="profileDes">
                                <div className="profileIcon">
                                    <img src={receiver?.profileImgUrl} alt="" />
                                    <span className={`box ${receiver?.availability ? 'active' : ""}`}></span>
                                </div>
                                <span className="name">{`${receiver?.firstName} ${receiver?.lastName}`}</span>
                            </div>
                        )}

                        <div className="chatBox" ref={chatBox}>
                            <div className="chatBox__conversation">
                                {messages.reverse().map((data, index) => {
                                    return (
                                        <div
                                            className={
                                                data.type === 'send'
                                                    ? `conversation__item conversation__item--right`
                                                    : `conversation__item conversation__item--left`
                                            }
                                            key={data._id}>
                                            <section>
                                                <p>{data.message}</p>
                                                <div className="conversation__item__date">
                                                    <span>
                                                        <img src={Images.calenderIcon} alt="" />
                                                        <Moment format="DD-MM-YYYY h:mm:ss a">{data.createdAt}</Moment>
                                                    </span>
                                                </div>
                                            </section>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <TextAreaForm sendMessage={sendMessage} />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}


const TextAreaForm = (props) => {
    const { sendMessage } = props;
    const [textareaText, setTextAreaText] = useState('');
    const handleFromSubmit = (event) => {
        event.preventDefault();
        sendMessage(textareaText , setTextAreaText);
    }
    return (
        <form className="chatReply__new" onSubmit={handleFromSubmit}>
            <div className="textArea">
                <textarea
                    rows="2"
                    name={"description"}
                    value={textareaText}
                    onChange={(e) => setTextAreaText(e.target.value)}></textarea>
            </div>
            <div className="send">
                <IconButton color="primary" type="submit">
                    <SendIcon />
                </IconButton>
            </div>
        </form>
    )
}

const ChatModelStyles = makeStyles({
    modal: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    paper: {
        height: "calc(100vh - 75px - 30px)",
        width: 952,
        backgroundColor: "#ffffff",
        marginTop: 75,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: 5,
    },
    negotiation: {
        height: "100%",
        "& .heading": {
            padding: "16px 20px",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #dddddd",
            "&__label": {
                fontSize: 16,
                color: "rgba(44, 44, 44, 1)",
                textTransform: "uppercase",
                fontWeight: 600,
                "& img": {
                    width: 26,
                    marginRight: 12,
                },
            },
            "& .closeBtn": {
                marginLeft: "auto",
                cursor: "pointer",
                "& img": {
                    width: 14,
                },
            },
        },
        "& .profileDes": {
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            borderBottom: "1px solid #dddddd",
            boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.08)",
            backgroundColor: "white",
            marginBottom: "10px",
            "& .profileIcon": {
                width: 40,
                marginRight: 18,
                borderRadius: 3,
                position: "relative",
                "& img": {
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: 3,
                },
                "& .box": {
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: 10,
                    height: 10,
                    borderRadius: 3,
                    "&.active": {
                        backgroundColor: "#1bc11b",
                    },
                    "&.inactive": {
                        backgroundColor: "#ED0B0B",
                    },
                },
            },
            "& .name": {
                fontSize: 14,
                color: "rgba(101, 101, 101, 1)",
                fontWeight: 500,
            },
        },
        "& .chatReply": {
            display: "flex",
            alignItems: "center",
            padding: "19px 20px 10px 20px",
            backgroundColor: "rgba(248, 248, 248, 1)",
            borderRadius: "0 0 5px 5px",
            "& .input_control": {
                width: 120,
                marginRight: 10,
                "& label, & input , & span , & p": {
                    fontSize: 13,
                    lineHeight: "17px",
                },
                "& .MuiOutlinedInput-inputMarginDense": {
                    paddingTop: 13.5,
                    paddingBottom: 13.5,
                },
                "& .Mui-focused": {
                    backgroundColor: "#FFFFFF",
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    " -webkit-appearance": "none",
                },
                "& input[type=number]": {
                    " -moz-appearance": "textfield",
                },
                "&:nth-child(3)": {
                    width: "auto",
                    maxWidth: "100%",
                    flexBasis: 0,
                    flexGrow: 1,
                },
            },
            "& .send_btn": {
                width: 105,
                "& button": {
                    width: "100%",
                    height: 40,
                    backgroundColor: "#6DC7BE",
                    borderRadius: 3,
                    color: "#ffffff",
                    border: "none!important",
                    outline: "none!important",
                    alignItems: "center",
                    justifyContent: "center",
                },
            },
        },
        "& .chatBox": {
            height: "calc(100% - 197px)",
            overflowY: "auto",
            padding: "0 20px 60px 20px",
            position: "relative",
            "-webkit-user-select": "none",
            " -moz-user-select": "none",
            " -ms-user-select": "none",
            "&::-webkit-scrollbar": {
                width: 5,
                "&-track": {
                    background: "#DDDDDD",
                },
                "&-thumb": {
                    backgroundColor: "#6DC7BE",
                    borderRadius: 10,
                },
            },
            "&__conversation": {
                minHeight: "100%",
                display: "flex",
                flexFlow: "column-reverse",
                "& .conversation__item": {
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor: '#f2f2f2',
                    width: '55%',
                    borderRadius: 5,
                    padding: '3px 6px',
                    "& p": {
                        wordBreak: 'break-all'
                    },
                    "&--right": {
                        justifyContent: "flex-end",
                        marginLeft: 'auto',
                        "& .conversation__item__content": {
                            "&>section": {
                                flexDirection: "row-reverse",
                                "& p": {
                                    paddingLeft: 10,
                                    wordBreak: 'break-all'
                                },
                            },
                            "& .meta": {
                                justifyContent: "flex-end",
                                "& span": {
                                    marginRight: 10,
                                },
                            },
                        },
                        "& .conversation__item__date": {
                            justifyContent: "flex-end",
                        },
                    },
                    "&--left": {
                        justifyContent: "flex-start",
                        "& .conversation__item__content .meta": {
                            justifyContent: "flex-start",
                            "& span": {
                                marginRight: 10,
                            },
                        },
                        "& .conversation__item__date": {
                            justifyContent: "flex-end",
                        },
                    },
                    "&__content": {
                        width: 350,
                        padding: 10,
                        backgroundColor: "#FAFAFA",
                        borderRadius: 3,
                        "&>div": {
                            display: "flex",
                            "& .hireTick": {
                                width: 25,
                                position: "relative",
                                cursor: "pointer",
                                "& .circle-loader": {
                                    // position:'relative',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "25px",
                                    height: "25px",
                                    background: "#e8592a",
                                    borderRadius: "50%",
                                    "& img": {
                                        width: "20px",
                                    },
                                },
                                "& img": {
                                    width: 25,
                                    height: "auto",
                                },
                                "& span": {
                                    position: "absolute",
                                    display: "inline-block",
                                    width: "max-content",
                                    fontSize: 13,
                                    color: "#ffffff",
                                    backgroundColor: "#2c2c2c",
                                    borderRadius: 3,
                                    top: -30,
                                    padding: "3px 10px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    visibility: "hidden",
                                },
                                "&:hover span": {
                                    visibility: "visible",
                                },
                            },
                        },
                        "& p": {
                            color: "#656565",
                            fontSize: 13,
                            fontWeight: 400,
                            flexGrow: 1,
                            flexBasis: 0,
                        },
                        "& .meta": {
                            display: "flex",
                            "& span": {
                                border: "1px solid #DDDDDD",
                                borderRadius: 20,
                                display: "inline-block",
                                backgroundColor: "#ffffff",
                                color: "#2c2c2c",
                                fontSize: 13,
                                padding: "3px 15px",
                            },
                        },
                    },
                    "&__date": {
                        display: "flex",
                        padding: " 0 5px",
                        "& span": {
                            display: "inline-block",
                            fontSize: 12,
                            color: "#656565",
                        },
                    },
                },
            },
        },
    },
});

export default ChatModel;