import { jsPDF } from "jspdf";
import moment from "moment";
import React from "react";
import { Images } from "../../../../assets/common";
export const PDFInvoice = (props) => {
  const { data, currentUser } = props;

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      let doc = new jsPDF();
      const splitTitle = doc.setFontSize(10).splitTextToSize(data.transactionTitle, 120);

      doc.addImage(Images.LogoPng, 10, 11);
      doc.setFontSize(25).setTextColor(240, 90, 34).text("Transaction Invoice", 110, 15);

      //User details
      doc.setFontSize(30).setTextColor(77, 172, 162).text("User Details", 10, 40);
      doc.setFontSize(20).setTextColor(44, 44, 44).text(`User Id:`, 10, 55).text(`@${currentUser.nickName}`, 55, 55);
      doc.text(`Email:`, 10, 65).text(`${currentUser.email}`, 55, 65);
      doc.text(`Name:`, 10, 75).text(`${currentUser.firstName} ${currentUser.lastName}`, 55, 75);
      doc.text(`Account Id:`, 10, 85).text(`${currentUser.hederaAccountDetails.id}`, 55, 85);

      //devider line
      doc.setDrawColor(240, 90, 34).setLineWidth(1.5).line(10, 95, 200, 95);

      //Transaction details
      doc
        .setFontSize(18)
        .text(`Date`, 10, 110)
        .text(`${moment(data.createdAt).format("DD/MM/yyyy")}`, 62, 110);
      doc.text(`Transaction:`, 10, 122);
      doc.text(`Amount:`, 10, 134).text(`${data.totalAmountC} ℏ(s)`, 62, 134);
      doc.text(`Transaction Time:`, 10, 146).text(`${moment(data.createdAt).format("DD/MM/yyyy HH:mm:ss")}`, 62, 146);
      doc.text(`Transaction Id:`, 10, 158).text(`${data.vendorTransactionId}`, 62, 158);

      //title for transaction
      doc.setFontSize(12).text(splitTitle, 62, 122);
      //doc.text(`Transaction Hash:` , 10 , 170).text(`${data.transactionResponseObject.transaction_hash.toString()}` , 62 , 170);

      //Payment status
      doc.setFontSize(30).text("STATUS:", 10, 270);
      data.transactionOutcome === "Successful" ? doc.setTextColor(75, 181, 67).text("SUCCESS", 67, 270) : doc.setTextColor(240, 90, 34).text("FAILED", 67, 270);

      doc.save(`taskbarTransaction${data.vendorTransactionId}.pdf`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <a href="/#" onClick={handleDownload}>
      <img src={Images.downloadInvoice} alt="" />
    </a>
  );
};
