import React from 'react';
import loader from '../../assets/common/resources/loader.png';

const Loader = ({ height, className, id, inlineStyles, ...restProps }) => {
  return (
    <div className={`loader ${className ?? ""}`} id={id ?? "loaderImage"} style={{ ...inlineStyles, height: height ? height : '100%' }}>
      <img src={loader} className="App-loader" alt="logo" />
    </div>
  );
}

export default Loader;
