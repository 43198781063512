import React, {Component} from "react";
import "./PasswordStrengthMeter.scss";

class PasswordStrengthMeter extends Component {
  createPasswordLabel = (email) => {
    // var decimal = new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    // );
    var weak = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})"
    );
    var Fair = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{9,10})"
    );
    var Good = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{11,15})"
    );
    var Strong = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{16,20})"
    );

    if (email.match(Strong)) {
      // //console.log("pass", email)
      return "Excellent";
    } else if (email.match(Good)) {
      // //console.log("pass", email)
      return "Good";
    } else if (email.match(Fair)) {
      // //console.log("pass", email)
      return "50-50";
    } else if (email.match(weak)) {
      // //console.log("pass", email)
      return "Very-Week";
    } else {
      // //console.log('Wrong...!')
      return null;
    }

    // if (result.match(weak)) {
    //     //console.log(' Weak Correct, try another...')
    //     return 'Weak';
    //   } else if (result.match(Fair)) {
    //     //console.log(' Fair Correct, try another...')
    //     return 'Fair';
    //   } else if (result.match(Good)) {
    //     //console.log('Good Correct, try another...')
    //     return 'Good';
    //   } else if (result.match(Strong)) {
    //     //console.log(' Strong Correct, try another...')
    //     return 'Strong'
    //   } else {
    //     //console.log('Wrong...!', result)
    //     return false;
    //   }
  };

  render() {
    const {password} = this.props;
    const testedResult = password;
    // const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        {/* <div className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}> */}
        {/* <div className={`${this.createPasswordLabel(testedResult)}`}></div> */}
        {/* </div> */}
        <div
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(
            testedResult
          )}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <label
          className={`password-strength-meter-label label-${this.createPasswordLabel(
            testedResult
          )}`}>
          {password && (
            <>
              {/* <strong>Password strength:</strong>  */}
              {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );
  }
}
export default PasswordStrengthMeter;
