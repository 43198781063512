import {
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import ConfirmModal from "../../../components/deleteConfirmModal";
import Loader from "../../../components/Loader";
import notifier from "../../../components/notifier";
import Pagination from "../../../components/Pagination";
import { getErrorString } from "../../../components/validator";
import { MESSAGE } from "../../../constants/messages";
import { validWalletID, useDebounce } from "../../../lib/validators";
import {
  ADMIN_GET_ALL_USER,
  ADMIN_USER_ACCOUNT_ACCESS,
  CHANGE_BRAND_STATUS,
  CHANGE_USER_ROLE,
  UPDATE_WALLET_ID,
} from "../../../services/api-service";

const UserList = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [btnDisable, setBtnDiable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const userRoles = ["Super-Admin", "Admin", "Normal"];
  const role = JSON.parse(localStorage.getItem("loggedInUser")).role;
  const [userData, setUserData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [enable, setEnable] = useState(false);
  const [editWalletModal, setEditWalletModal] = useState(false);
  const [editWalletData, setEditWalletData] = useState({
    walletId: "",
    userId: "",
  });
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  //

  useEffect(() => {
    getUsers({});
  }, []);

  // useEffect(async () =>  debouncedSearchTerm ?  await getUsers({ searchPhrase: debouncedSearchTerm }) : await getUsers({})  ,[debouncedSearchTerm]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        searchUsers(debouncedSearchTerm).then((results) => {
          setAllUsers(results.userDetails);
          setTotalRecord(results.totalCount);
        });
      } else {
        getUsers({});
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const searchUsers = async (searchTerms) => {
    try {
      const reqBody = {
        searchPhrase: searchTerms,
      };
      const response = await ADMIN_GET_ALL_USER(reqBody);
      return response.data.result;
    } catch (e) {
      notifier.error("Error", getErrorString(e));
      return [];
    }
  };


  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const openConfirmModal = (val, data) => {
    setEnable(val ? true : false);
    setConfirmModal(true);
    setUserData(data);
  };

  //Fetch All User
  const getUsers = async (reqBody) => {
    // const reqBody = {};

    try {
      const response = await ADMIN_GET_ALL_USER(reqBody);
      setAllUsers(response.data.result.userDetails);
      setTotalRecord(response.data.result.totalCount);
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };


  //enbaleDisableClick
  const enbaleDisableClick = async (reqBody) => {
    try {
      const response = await ADMIN_USER_ACCOUNT_ACCESS(reqBody);
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getUsers({ pageNo: pageNumber });
        setBtnDiable(false);
        setConfirmModal(false);
        setUserData({});
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  const changeUserRole = async (e, user) => {
    const reqBody = {
      userId: user._id,
      role: e.target.value,
    };
    try {
      const response = await CHANGE_USER_ROLE(reqBody);
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getUsers({ pageNo: pageNumber });
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
    getUsers({ pageNo: page });
  };

  const chandeBrandStatus = async (e, id) => {
    const reqBody = {
      userId: id,
      brand: parseInt(e.target.value)? true : false,
    };

    try {
      const response = await CHANGE_BRAND_STATUS(reqBody);
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getUsers({ pageNo: pageNumber });
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  //WalletUpdate Ew

  const handleWalletUpdate = async () => {
    if (!validWalletID(editWalletData.walletId)) {
      notifier.warning("Warning", MESSAGE.WARN.NOT_VALID_WALLET_ID);
    } else
      try {
        setFormSubmitted(true);
        const reqBody = {
          accountId: editWalletData.walletId,
          userId: editWalletData.userId,
        };

        const response = await UPDATE_WALLET_ID(reqBody);
        if (response.data.status === "success") {
          setFormSubmitted(false);
          notifier.success("Success", response.data.result);
          setEditWalletModal(false);
          setEditWalletData({
            walletId: "",
            userId: "",
          });
          getUsers({ pageNo: pageNumber });
        }
      } catch (e) {
        setFormSubmitted(false);
        notifier.error("Error", getErrorString(e));
      }
  };

  return (
    <div id={"usersList"}>

      <div className="searchUser">
        <div className="searchUser__inner">
          <TextField
            label="Search user"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <TableContainer className="data-table-user">
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small">Username</StyledTableCell>
              <StyledTableCell size="small">Email</StyledTableCell>
              <StyledTableCell size="small">Role</StyledTableCell>
              <StyledTableCell size="small">Brand</StyledTableCell>
              <StyledTableCell size="small">Account Status</StyledTableCell>
              <StyledTableCell size="small">Points</StyledTableCell>
              <StyledTableCell size="small">Wallet Id</StyledTableCell>
              <StyledTableCell size="small">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers.map((user, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell size="small"><span>{user.nickName}</span></StyledTableCell>
                  <StyledTableCell size="small"><span>{user.email}</span></StyledTableCell>
                  <StyledTableCell size="small">
                    <select
                      style={{ height: "35px" }}
                      onChange={(e) => changeUserRole(e, user)}
                      disabled={role === "Admin"}
                      value={user.role}>
                      {userRoles.map((roles, i) => (
                        <option key={i} value={roles}>
                          {roles}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <select
                      style={{ height: "35px" }}
                      onChange={(e) => chandeBrandStatus(e, user._id)}
                      value={user.profileStats.brand ? 1 : 0}>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <span>
                      {user.active
                        ? user.accountEnabled
                          ? "Activated"
                          : "Blocked"
                        : "Deactivated"}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {user?.points}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {user.hederaAccountDetails ? (
                      user.hederaAccountDetails.id
                    ) : (
                      <Tooltip title="Not Available" placement="top" arrow>
                        <span style={{ color: "red" }}>N/A</span>
                      </Tooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <div style={{ display: "flex" }}>
                      {user.active ? (
                        user.accountEnabled ? (
                          <Tooltip
                            title="Block User Access"
                            placement="top"
                            arrow>
                            <IconButton
                              className="diableUserBtn"
                              size="small"
                              disabled={btnDisable}
                              onClick={() => {
                                openConfirmModal(true, {
                                  userId: user._id,
                                  operation: "disable",
                                });
                              }}>
                              <BlockIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Unblock User Access"
                            placement="top"
                            arrow>
                            <IconButton
                              className="enableBtn"
                              size="small"
                              disabled={btnDisable}
                              onClick={() => {
                                openConfirmModal(false, {
                                  userId: user._id,
                                  operation: "enable",
                                });
                              }}>
                              <PersonSharpIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title="User has not activated their account yet."
                          placement="left"
                          arrow>
                          <IconButton className="enableBtn" disabled={true}>
                            <PersonSharpIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.hederaAccountDetails ? (
                        <Tooltip title="Edit Wallet" placement="top" arrow>
                          <IconButton
                            size="small"
                            disabled={btnDisable}
                            onClick={() => {
                              setEditWalletModal(true);
                              setEditWalletData({
                                walletId: user.hederaAccountDetails.id,
                                userId: user._id,
                              });
                            }}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination">
        <Pagination
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          sendCurrentPage={handlePageChange}
          showControls={true}
        />
      </div>
      <Modal
        open={editWalletModal}
        aria-labelledby="Edit-wallet-id"
        aria-describedby="Edit wallet id for a user"
        className={`editWalletModal`}
        onClose={() => setEditWalletModal(false)}>
        <div className="editWalletModal__paper">
          <div className="heading">
            <h4>Edit Wallet Id</h4>
            <span
              className="btnClose"
              onClick={() => setEditWalletModal(false)}>
              <img src={Images.IconForCloseModal} alt="X" />
            </span>
          </div>
          <div className="body">
            <TextField
              variant="outlined"
              size="small"
              name="editWalletId"
              fullWidth
              label="Edit Wallet Id"
              value={editWalletData.walletId}
              onChange={(e) => {
                editWalletData.walletId = e.target.value;
                setEditWalletModal({ ...editWalletData });
              }}
              placeholder="0.0.8870"
            />
          </div>
          <div className="bottom">
            <button className="update" onClick={handleWalletUpdate}>
              {isFormSubmitted ? (
                <img src={Images.ellipsis_loader} alt="" />
              ) : (
                "Update"
              )}{" "}
            </button>
            <button
              className="cancel"
              onClick={() => setEditWalletModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        modal={confirmModal}
        yesCallback={() => enbaleDisableClick(userData)}
        cancelCallback={() => closeConfirmModal()}
        text={
          enable
            ? "Are you sure you want to disable it ?"
            : "Are you sure you want to enable it ?"
        }
        yesBtnLabel={enable ? "Yes Block" : "Yes Unblock"}
      />
    </div>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "none",
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  head: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);

export default UserList;
