import _ from 'lodash';
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkWalletAvailablity } from "../../lib/validators";
import cookieStorage from '../../services/cookie-storage';
export default function WalletRoute({ component: Component, ...rest }) {
  const currentUser = !_.isEmpty(cookieStorage.getCookie("token"));
  // //console.log("yash token",cookieStorage.getCookie("token"));
  // //console.log("yash from privateR",currentUser);
  const walletLinked = checkWalletAvailablity();
  return (

    <Route
      {...rest}
      render={props => {
        return currentUser && walletLinked? <Component {...props} /> : <Redirect to="/home/wallet?walletLinked=false" />
      }}
    ></Route>
  )
}