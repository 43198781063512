import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import React, { useContext } from "react";
import { Images } from "../assets/common";
import notifier from "../components/notifier";
import ReportModal from "../components/reportModal";
import { getErrorString } from "../components/validator";
import { IS_STAGING } from "../constants";
import ConfirmationModalProvider from "../context/ConfirmationModal";
import ConfirmationModalApi from "../context/ConfirmationModal/confirmationModalApi";
import { UserContext } from "../context/ProductionUserContext";
import { firebaseAuth } from "../firebase";
import { useAuth } from "../Hooks/useAuth";
import cookieStorage from "../services/cookie-storage";
import "./index.scss";
import RouterOutlet from "./router-outlet";

function Pages(props) {
  const [open, setOpen] = React.useState(false);
  // const { currentUser } = useAuth();
  // const upvotySSOAuth = localStorage.getItem("upvotySSOAuth");

  const redirectToUpvoty = () => {
    setOpen(true)
    // window.open(currentUser ? upvotySSOAuth : "https://roadmap.mytaskbar.io");
  };

  return (
    <>
      <ConfirmationModalProvider>
        <RouterOutlet {...props} />
        <ConfirmationModalApi />
      </ConfirmationModalProvider>
      {!_.isEmpty(cookieStorage.getCookie("token")) ? (
        <>
          <div className="report">
            <Tooltip title="Report Or Suggestion" placement="left" arrow>
              <button onClick={redirectToUpvoty}>
                {/* <ReportProblemIcon /> */}
                <img src={Images.reportAndSuggestions} alt="" style={{ width: "auto" }} />
              </button>
            </Tooltip>
            <ReportModal open={open} onClose={() => setOpen(false)} />
          </div>
        </>
      ) : null}
      {IS_STAGING ? <LogoutButtonForProductionUser /> : null}
    </>
  );
}

const LogoutButtonForProductionUser = () => {
  const { productionUser, setProductionUser } = useContext(UserContext);
  const logoutProductionUser = () => {
    try {
      firebaseAuth().signOut();
      cookieStorage.deleteCookieWithProdUser();
      setProductionUser(null);
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };
  return (
    <>
      {productionUser && (
        <button className="topLogOutButton" title="Logout Form Production Environment" onClick={logoutProductionUser}>
          Logout
        </button>
      )}
    </>
  );
};

export default Pages;
