import {
    Link,
    TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { Images } from '../../assets/common';
import { ResendVerificationAlert } from '../../components/Alerts';
import notifier from '../../components/notifier';
import { getErrorString } from '../../components/validator';
import { MESSAGE } from '../../constants/messages';
import {
    validEmail
} from '../../lib/validators';
import { REQ_RESETPASS } from '../../services/api-service';
import './index.scss';

function ForgotPassword(props) {

    const [emailui, updateEmailui] = useState(true);
    const [email, updateEmail] = useState('');
    const [resendEmailVerifyLink, setResendEmailVerifyLink] = useState(false);
    const [emailVerify, setEmailVerify] = useState(false);
    // const [step, setStep] = useState('1')
    const [loading, setLoader] = useState(false);
    const LoginRedirect = () => {
        props.history.push('/login');
    }


    const handleInputChange = (event) => {
        const id = event.target.name;
        const value = event.target.value;
        switch (id) {
            case 'email':
                updateEmail(value);
                break;
            default:
                console.warn('Something is not right !');
        }
    }

    const handleRequestPass = async (resend) => {
        if (!email) {
            notifier.warning(MESSAGE.ERROR.EMAIL_EMPTY)
        } else if (!validEmail(email)) {
            notifier.warning(MESSAGE.ERROR.EMAIL_INVALID)
        } else try {
            setLoader(true);
            const requestBody = {
                email: email,
                route: `new-password`
                // url: `${SERVER_URL}:4000/new-password`
            }
            //console.log("yash", requestBody)
            const response = await REQ_RESETPASS(requestBody);
            if (response.data.status === 'success') {
                updateEmailui(false);
                setEmailVerify(true)
                if (resend === 'resend') {
                    notifier.success('Link resend sucessfully!');
                }
            }
        } catch (e) {
            setLoader(false);
            const errorString = getErrorString(e);

            if (errorString === "Please verify your email, before login!") {
                setResendEmailVerifyLink(true);
                // updateEmailui(false);
            } else {
                notifier.error('Error', errorString);
            }
        }
    }

    return (
        <div className="forgot-pass-page">
            <div className="forgot-pass-logo">
                <Link href="/"><img src={Images.Logo} alt={""} /></Link>
            </div>
            <div className="forgot-pass-layout">
                {emailui ? <div className="forgot-pass-content-wrapper">
                    <h2>Forgot Password</h2>
                    <div className="msg">Don't panic, we're here for you!
                    </div>
                    <p>Enter your account e-mail and you will receive a link to reset your password.
                        Please also check spam folder</p>
                    {resendEmailVerifyLink && <ResendVerificationAlert email={email} />}
                    <div className={'field-container'}>
                        <TextField label="Enter Email" variant="outlined" name="email" onChange={handleInputChange} />
                    </div>
                    <div className="btn">
                        <button onClick={() => handleRequestPass('forget')}>
                            {loading ? <img alt='' src={Images.ellipsis_loader} /> : 'Submit'}
                        </button>
                    </div>
                    <div className="redirect">
                        <div>Remember your password?</div>
                        <p onClick={LoginRedirect}>Login</p>
                    </div>
                </div> : null}
                {emailVerify ? <div className="email-pass-content-wrapper">
                    <div className="email-pass-content">
                        <h2>Check your email!</h2>
                        {/* <div className="msg">Fulfil your needs & Earn Crypto</div><br /><br /> */}
                        {/* <p>We have sent a link on your email address yourmail@mail.com,</p> */}
                        <p>Please use the link sent to your e-mail to reset your password</p>
                        <p class='spam'>Please do check your spam folder too</p>
                        <div className="email-pass-image"><img src={Images.emailVerify} alt={''} /></div>
                        <div className="resend-link">
                            <div>Didn’t receive the link?</div>
                            <p onClick={() => handleRequestPass('resend')}>Re-send</p>                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}
export default ForgotPassword;