import { Button, ButtonGroup, Fade, makeStyles, Modal, useTheme, useMediaQuery } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import { useHashConnect } from "../../Hooks";
import { StyledBackdrop } from "../../lib/validators";
import { GET_PROMOTE_TASKER_FEE, GET_PROMOTE_TASK_FEE, LINK_WALLET_ID, REQ_SIGN_TRANSACTION } from "../../services/api-service";
import Loader from "../Loader";
import notifier from "../notifier";
import { getErrorString } from "../validator";
import "./index.scss";

const PromoteTaskModal = (props) => {
  const theme = useTheme();
  const isDeviceIsSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { modelFor, taskDetails } = props;
  const [loading, setLoader] = useState(false);
  const [promoteTaskFee, setPromoteTaskFee] = useState(0);
  const [days, setDays] = useState(1);
  const {
    connect,
    handleTransaction: transferHbar,
    installedExtensions,
    resetAcknowledge,
    resetTransactionResponse,
    walletData: { accountIds },
    status,
    loading: connecting,
    transactionResponse,
    acknowledge,
  } = useHashConnect();

  useEffect(() => {
    if (modelFor === "task") {
      if (taskDetails && Object.keys(taskDetails).length > 0) {
        getTaskFee(taskDetails.numericId);
      }
    } else {
      getTaskFee();
    }
  }, [taskDetails]);

  useEffect(() => {
    if (acknowledge) notifier.warning("Wallet Connected", "You can pay now!", 5000);
    if (resetAcknowledge) resetAcknowledge();
  }, [acknowledge]);

  useEffect(() => {
    if (transactionResponse && transactionResponse?.success) {
      notifier.success("Transaction Successful", "Hbar transferer successfully", 3000);
      handleClose();
    }
    if (transactionResponse && transactionResponse?.error) {
      notifier.error("Error while transaction", transactionResponse.error, 3000);
      handleClose();
    }
    if (resetTransactionResponse) resetTransactionResponse();
  }, [transactionResponse]);

  const getTaskFee = async (numericId) => {
    try {
      setLoader(true);
      const response = modelFor === "task" ? await GET_PROMOTE_TASK_FEE(numericId) : await GET_PROMOTE_TASKER_FEE();
      setLoader(false);
      const value = response.data.result;
      setPromoteTaskFee(value);
    } catch (e) {
      setLoader(false);
      notifier.error("Error", getErrorString(e));
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  //Send mark of wallet connect
  const sendMarkOFwalletInstall = async () => {
    try {
      const requestBody = {
        isClickedOnWalletLink: true,
      };
      await LINK_WALLET_ID(requestBody);
    } catch (e) {
      console.log(e);
    }
  };

  const connectHashpack = async () => {
    try {
      if (isDeviceIsSm) {
        return alert("Please connect with HashPack extension on your desktop to make a payment");
      }
      if (installedExtensions) {
        connect();
      } else {
        await sendMarkOFwalletInstall();
        // Taskbar Alert - Hashpack browser extension not installed, please click on <Go> to visit HashPack website and install their wallet on your browser
        alert(
          "Alert - HashPack browser extension not installed, please click on <<OK>> to visit HashPack website and install their wallet on your browser.  Once installed you might need to restart your browser for Taskbar to detect wallet extension first time."
        );
        window.open("https://www.hashpack.app");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTransaction = async ({ walletId, memo, amount }) => {
    console.log(walletId, memo, amount);
    try {
      const reqBody = {
        walletId,
        memo,
        amount,
      };
      const response = await REQ_SIGN_TRANSACTION(reqBody);
      console.log(response.data.result.transactionSign);
      // let transactionBytes = response.data.result.transactionSign.toBytes();
      transferHbar(response.data.result.transactionSign, accountIds[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const classes = Styles();

  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={`${classes.modal} promoteModal`}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`${classes.paper} promoteModal__paper`}>
          {loading ? (
            <Loader />
          ) : promoteTaskFee ? (
            <div className="PromoteTaskModal">
              <div className="heading">
                <span className="heading__label">{modelFor === "task" ? "Promote Task" : "Promote Tasker"}</span>
                <span className="closeBtn" onClick={handleClose}>
                  <img src={Images.IconForCloseModal} alt="" />
                </span>
              </div>
              <div className="PromoteTaskModal__body">
                {modelFor === "task" ? <p>It’s a great idea to promote the task to reach out maximum tasker</p> : <p>It’s a great idea to promote yourself to reach out maximum tasker</p>}
                <div className="PromoteTaskModal__body__permotedTaskView">
                  <img src={Images.permotedTaskView} alt="" />
                </div>
              </div>
              <div className="PromoteTaskModal__body__permotion">
                <div className="PromoteTaskModal__body__permotionText--text PromoteTaskModal__body__permotionText--left">
                  <div className="PromoteTaskModal__body__permotionText--memo">
                    <div className="item">
                      <span className="left">
                        <span>{modelFor === "task" ? "Promote Task" : "Promote Tasker"} Fee</span>
                        <span>(Per Day Charge)</span>
                      </span>
                      <span className="right">{promoteTaskFee.value.fees} &#8463;</span>
                    </div>
                    <div className="item">
                      <span className="left">Days</span>
                      {/* <div className="center">{1}</div> */}
                      <ButtonGroup>
                        <Button size="small" onClick={() => setDays(days + 1)}>
                          +
                        </Button>
                        {days - 1 && (
                          <Button size="small" disabled>
                            {days}
                          </Button>
                        )}
                        {days - 1 && (
                          <Button size="small" onClick={() => setDays(days - 1)}>
                            -
                          </Button>
                        )}
                      </ButtonGroup>
                      <span className="right">{days}</span>
                    </div>
                    <div className="item total">
                      <span className="left">
                        <span>Total Amount</span>
                      </span>
                      <span className="right">{promoteTaskFee.value.fees * days} &#8462; (s)</span>
                    </div>
                  </div>
                  <span className="PromoteTaskModal__body__permotionText--inst">Please pay the &#8462;(s) amount to Mytaskbar Escrow account in order to promote {modelFor === "task" ? "the task" : " Yourself"}</span>
                </div>
                <hr />
                <div className="PromoteTaskModal__body__permotionText--QRcode PromoteTaskModal__body__permotionText--right">
                  <p>{isEmpty(accountIds) ? "Pay with hashpack wallet" : `You are connected with ${accountIds[0]}`}</p>
                  <div className="PromoteTaskModal__body__permotionText__QRcodeBox">
                    {isEmpty(accountIds) ? (
                      <button onClick={connectHashpack} className="connect_hashpack_button" style={{ margin: "0 auto" }}>
                        <img src={Images.hashPackIcon} alt="Hahconnect" />
                        {connecting ? "Connecting...." : "Connect & pay"}
                      </button>
                    ) : (
                      <button className="connect_hashpack_button" onClick={() => handleTransaction({ walletId: accountIds[0], memo: promoteTaskFee.memoCode, amount: promoteTaskFee.value.fees * days })} style={{ margin: "0 auto" }}>
                        <img src={Images.hashPackIcon} alt="Hahconnect" />
                        Pay with hashpack
                      </button>
                    )}
                    <div className="orDevider">Or</div>
                  </div>
                  <div className="PromoteTaskModal__body__permotionText--acountNumber">
                    <h5>Pay Manually</h5>
                    <div className="esDetails">
                      <div className="account">
                        <span>Escrow Account Number</span>
                        <span> {promoteTaskFee.accountId}</span>
                      </div>
                      <span className="divider"></span>
                      <div className="memoCode">
                        <span>Task Memo Code</span>
                        <span>{promoteTaskFee.memoCode}</span>
                      </div>
                    </div>
                  </div>
                  <div className="hintCode">
                    {/* <p>
                    Enter this memo in order to let taskbar recognize the task
                    against which it is paid.
                  </p> */}
                    <Alert severity="info">
                      <AlertTitle>Info</AlertTitle>
                      Enter this <strong>memo code</strong> in order to let taskbar recognize the task against which it is paid.
                    </Alert>
                  </div>
                </div>
              </div>
              {/* <div className="PromoteTaskModal__body__footer">
                <div className="PromoteTaskModal__body__footer--left">
                  <p>Task Memo code</p>
                  <p>
                    Enter this memo in order to let taskbar recognise the task
                    against which it is paid.
                  </p>
                </div>
                <div className="PromoteTaskModal__body__footer--right">
                  <span>{promoteTaskFee.memoCode}</span>
                </div>
              </div> */}
            </div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

const Styles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    minHeight: "550px",
    width: 1000,
    // backgroundColor: "#ffffff",
    background: "linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 225px, #ffffff 225px, #ffffff 100%)",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
    position: "relative",

    "& .PromoteTaskModal": {
      height: "100%",
      "& .heading": {
        height: 51,
        display: "flex",
        paddingLeft: 24,
        paddingRight: 24,
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRadius: "5px 5px 0 0",
        borderBottom: "1px solid #dddddd",
        "&__label": {
          fontSize: 16,
          color: "rgba(44, 44, 44, 1)",
          textTransform: "uppercase",
          fontWeight: 600,
          "& img": {
            width: 26,
            marginRight: 12,
          },
        },
        "& .closeBtn": {
          marginLeft: "auto",
          width: 28,
          height: 28,
          borderRadius: 3,
          transition: "0.3s all",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
          cursor: "pointer",
          "& img": {
            width: 14,
          },
        },
      },
    },
  },
});

export default PromoteTaskModal;
