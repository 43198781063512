import { makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import React, { useEffect } from "react";

const PieChart = (props) => {
  const { data, outerRadius, innerRadius , colors ,labelColor} = props;
  const margin = {
    top: 25,
    right: 10,
    bottom: 25,
    left: 10,
  };
  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;
  let total = 0;
  data.map(d => total+= d.value);
  // //console.log('total', total);

  //Color selection
 d3.scaleSequential()
    .interpolator(d3.interpolateCool)
    .domain([0, data.length]);

  useEffect(() => {
    drawChart();
  }, []);

  const drawChart = () => {
    //Removing Old Svg chart
    d3.select("#pie-container").select("svg").remove();

    // Create new svg
    const svg = d3
      .select("#pie-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);


      const toolTip = d3.select("#pie-container").append('div');
      toolTip.attr('class' , 'hide chartToolTip').style("opacity", 0);


    //Genrate Arc
    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);



    //Genrate Arc on Hover
    const arcGenerator2 = d3
      .arc()
      .innerRadius(innerRadius ? innerRadius + 5 : innerRadius)
      .outerRadius(outerRadius + 5);

    //Pie Genrator
    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d) => d.value);

    const arc = svg.selectAll().data(pieGenerator(data)).enter();

    // Append arcs
    arc
      .append("path")
      .attr("d", arcGenerator)
      .style("fill", (_, i) => colors[i])
      .style("stroke", "#ffffff")
      .style("stroke-width", 0)
      .on("mouseover", function (e,d) {
        //console.log('hover',d)
        d3.select(this).attr("d", arcGenerator2).style("stroke-width", 5);
        toolTip.transition().attr('class','show chartToolTip').duration(500).style("opacity", 1).text(d.data.value+"  "+"Tasks");

      })
      .on("mousemove", function (event) {
        //console.log('move',event)
        toolTip.style("left", (event.pageX ) + "px")
              .style("top", (event.pageY ) + "px");
      })
      .on("mouseout", function () {
        d3.select(this).attr("d", arcGenerator).style("stroke-width", 0);
        toolTip.transition().attr('class','hide chartToolTip').duration(500).style("opacity",  1e-6);
      });
      //.style("fill", (_, i) => colorScale(i))
    // Append text labels
    arc
      .append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .text((d) => `${((d.data.value / total) * 100).toFixed(2)}%`)
      .style("fill", labelColor)
      .attr("transform", (d) => {
        const [x, y] = arcGenerator.centroid(d);
        return `translate(${x}, ${y})`;
      });
  };

  const classes = Styles();

  return (
  <div className={classes.root} id="pie-container">
    <div>
      <p>Results</p>
      {data.map((item,i)=>{
        return(
          <span className="fillColor" key={i}>
              <span className="colorBox" style={{backgroundColor:colors[i]}}></span>
              <span className="label">{item.label}</span>
            </span> 
        )
      })}
    </div>
  </div>
  );
};

const Styles = makeStyles({
  root: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexFlow:'column-reverse',
    position:'relative',
   '& >div':{
     fontSize:11,
     marginBottom:7,
     '& span':{
       display:'inline-block',
       fontSize:11,
     },
    width:'100%',
    '& .colorBox':{
      display:'inline-block',
      width:10,
      height:10,
      marginRight:7,
      
    },
    '& .label':{
      marginRight:15,
    }
   },
   '& .chartToolTip':{
     position:'fixed',
     width:'auto',
     height:'auto',
     marginBottom:0,
    '&.show':{
      backgroundColor:'rgba(0,0,0,.7)',
      color:'#fff',
      padding:5,
      transform:'translate(-50% , -50px)',
      borderRadius:3,
    }
   }
  },
});

export default PieChart;
