import React, { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import ChatModel from "../../../components/chatModal";
import CreateReviewPopupModal from "../../../components/CreateReviewModal";
import Loader from "../../../components/Loader";
import NegotiationModal from "../../../components/NegotiationModal";
import PromoteTaskModal from "../../../components/promoteTaskModal";
import SummaryReportModal from "../../../components/Summary";
import TimerPopUp from "../../../components/TimerPopup";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hook";
import { getBidsForMyTask } from "../../../redux/features/my-tasks/myTasksAPI";
import { closeChatModal, closeNegotiationModal, closePromoteTaskModal, closeSummaryReportModal, closeTimer, getBidsForMyTaskAsync, getTasksAsync, removeTaskForReview, updateTaskUIView } from "../../../redux/features/my-tasks/myTasksSlice";
import { TaskSections, TaskStatuses } from "../../../types";
import MyTasksList from "./TasksTables";

const currentUser: string | null = localStorage.getItem("currentUser");

const TaskLists = (props: { section: TaskSections; taskStatus: TaskStatuses }) => {
  const { section, taskStatus } = props;

  const {
    tasks,
    loading,
    taskUIView: { paymentType },
    taskToPromote,
    summaryReport,
    taskChatId,
    taskToReview,
    bidDetails,
    showNegotiationBox,
    timerForTask,
    bids,
  } = useAppSelector((state) => state.myTask);
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      appDispatch(updateTaskUIView({ section, taskStatus, isTaskDetailsPage: false }));
      if (section === "created") dispatch(getTasksAsync({ myTasks: { section, taskStatus } }));
      if (section === "applied") dispatch(getBidsForMyTaskAsync(taskStatus));
    });
  }, []);

  const handlePromoteTaskModalClose = () => {
    appDispatch(closePromoteTaskModal());
  };

  const handleReportModalClose = () => {
    appDispatch(closeSummaryReportModal());
  };
  const handleChatModalClose = () => {
    appDispatch(closeChatModal());
  };
  const handleCloseReviewModal = () => {
    appDispatch(removeTaskForReview());
  };

  const handleNegotiationClose = () => {
    appDispatch(closeNegotiationModal());
  };

  const handleTimerModalClose = () => {
    batch(() => {
      appDispatch(closeTimer());
      dispatch(getTasksAsync({ myTasks: { section, taskStatus } }));
    });
  };

  if (loading) return <Loader inlineStyles={{ position: "relative" }} height={"calc(100% - 60px)"} className={"taskLoader"} id={"taskLoader"} />;

  return (
    <React.Fragment>
      <MyTasksList
        tasks={paymentType === "fixed" ? tasks?.fixed : tasks?.hourly}
        bids={paymentType === "fixed" ? bids?.fixed : bids?.hourly}
        taskUIView={{
          section,
          taskStatus,
          paymentType,
        }}
        currentUser={currentUser}
      />
      <PromoteTaskModal open={!!taskToPromote} onClose={handlePromoteTaskModalClose} modelFor={"task"} taskDetails={taskToPromote ?? {}} />
      {!!summaryReport ? <SummaryReportModal open={!!summaryReport} onClose={handleReportModalClose} feesPrice={summaryReport} /> : null}
      {taskChatId ? <ChatModel open={!!taskChatId} onClose={handleChatModalClose} chatId={taskChatId} /> : null}
      {taskToReview ? <CreateReviewPopupModal open={!!taskToReview} onClose={handleCloseReviewModal} taskDetails={taskToReview} getTasks={() => dispatch(getTasksAsync({ myTasks: { section, taskStatus } }))} /> : null}
      {showNegotiationBox && bidDetails && (
        <NegotiationModal
          open={showNegotiationBox}
          bidDetails={bidDetails[0]}
          // negotiationData={negotiationData}
          // getNegotiationHistory={async() => await getNegotiationHistory(bidDetails[0]._id)}
          sender={currentUser === bidDetails[0].task.author?._id ? "needer" : "tasker"}
          hireNow={() => {}}
          onClose={handleNegotiationClose}
        />
      )}
      {timerForTask && <TimerPopUp taskDetails={timerForTask} open={!!timerForTask} onClose={handleTimerModalClose} taskType={section} />}
    </React.Fragment>
  );
};

export default TaskLists;
