import React from 'react';
import './FileUploadBlob.scss';


const FileUploadBlob = (props) => {

    const previewFile = () => {
        
        const preview = document.getElementById('preview');
        const file = document.querySelector(props.id? '#'+props.id :`#chooseFile`).files[0];
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            preview.src = reader.result;
            if (props.getFile) {
                props.getFile(file);
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }


    }

    return (
        <input
            id={props.id? props.id :`chooseFile`}
            className={`file-upload ${props.className && props.className}`}
            onChange={() => previewFile()}
            type="file"
            name={props.name ?? 'profilePic'}
            accept={props.acceptType ? props.acceptType : ``}
        />
    );

}

export default FileUploadBlob;