import { Popover } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../assets/common/index";
import notifier from "../../components/notifier";
import { getErrorString, popOverSlideTransition } from "../../components/validator";
import { useHashConnect } from "../../Hooks";
import { StyledBackdrop, useWindowSize } from "../../lib/validators";
import { LOGOUT, UPDATE_BASIC_INFO } from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import "./index.scss";

function Popup(props) {
  const size = useWindowSize();
  const [availability, setUserAvailability] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { profileImg, UserAvailability, userDetails } = props;
  const history = useHistory();
  const { disConnect } = useHashConnect();

  useEffect(() => {
    setAvailability();
    return history.listen((location) => {
      closeProfilePopover();
    });
  }, []);

  const logout = async () => {
    const fcmToken = cookieStorage.getCookie("fcmToken");
    try {
      const requestBody = {
        deviceToken: fcmToken,
      };
      const response = await LOGOUT(requestBody);
      cookieStorage.deleteCookie();
      if (response.data.status === "success") {
        // cookieStorage.deleteCookie();
        disConnect();
        window.location.href = "/login";
      }
    } catch (error) {
      if (getErrorString(error) === "Session expired please login again !") {
        cookieStorage.deleteCookie();
        window.location.href = "/login";
      } else {
        notifier.error("Error", getErrorString(error));
      }
    }
  };

  const setAvailability = async (availability) => {
    const reqBody = {
      availability: availability,
    };
    try {
      const response = await UPDATE_BASIC_INFO(reqBody);
      if (response.data.status === "success") {
        const userDetails = response.data.result;
        setUserAvailability(userDetails.availability);
        localStorage.setItem("loggedInUser", JSON.stringify(userDetails));
        props.availabilityChanged();
      }
    } catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  };

  //events
  const openProfilePopover = (e) => {
    setAnchorEl(e.currentTarget);
    // console.log(notificationArray)
  };
  const closeProfilePopover = () => {
    setAnchorEl(null);
  };

  //ids
  const profilePopupOpen = Boolean(anchorEl);
  const profilePopupId = profilePopupOpen ? "notificationPopover" : undefined;

  const renderPopupInner = () => {
    return (
      <div className="popup-page">
        <div className="popup-container">
          <div className="popup-content">
            <div className="available-container">
              <div className="available-div">I am available</div>
              <div className="btn-container">
                <button className={`yes ${availability ? "active" : ""}`} onClick={() => setAvailability(true)}>
                  yes
                </button>
                <button className={`no ${availability ? "" : "active"}`} onClick={() => setAvailability(false)}>
                  No
                </button>
              </div>
            </div>
          </div>
          <div className="pointsEarned">
            <span className="label">Taskbar Points:</span> <span className="value">{userDetails?.points}</span>
          </div>
          <div className="popup-option">
            {/* <NavLink exact to={`/home/dashboard`} className="invite-content" activeClassName="activeNavLink">Dashboard</NavLink> */}
            <div className="invite-content">
              <Link to={"/invite-on-taskbar"}>
                <img src={Images.InviteAfriendIcon} style={{ marginRight: "6px" }} alt="" />
                <span>Invite a Friend</span>
              </Link>
            </div>
            <div className="invite-content">
              <Link to={"/home/edit-profile/about"}>
                <img src={Images.EditProfileIcon} style={{ marginRight: "6px" }} alt="" />
                <span>Edit Profile</span>
              </Link>
            </div>
            <div className="invite-content">
              <Link to={"/settings"}>
                <img src={Images.SettingIcon} style={{ marginRight: "6px" }} alt="" />
                <span>Settings</span>
              </Link>
            </div>
            <button
              className="invite-content"
              //activeClassName="activea"
              onClick={() => logout()}
            >
              <img src={Images.logoutIcon} className="lightbulbimg" alt="" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="profile">
      <div className={`box ${UserAvailability ? "active" : ""}`}></div>
      <img src={profileImg} alt="" className="profile-img" onClick={openProfilePopover} />
      {size.width < 577 ? (
        <Popover
          id={profilePopupId}
          open={profilePopupOpen}
          anchorReference="anchorPosition"
          anchorEl={anchorEl}
          anchorPosition={{ top: -16, left: -16 }}
          onClose={closeProfilePopover}
          BackdropComponent={StyledBackdrop}
          PaperProps={{
            className: "profilePopover",
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          TransitionComponent={popOverSlideTransition}
        >
          {renderPopupInner()}
        </Popover>
      ) : (
        <Popover
          id={profilePopupId}
          open={profilePopupOpen}
          anchorEl={anchorEl}
          onClose={closeProfilePopover}
          PaperProps={{
            className: "profilePopover",
          }}
          anchorOrigin={{
            vertical: 50,
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {renderPopupInner()}
        </Popover>
      )}
    </div>
  );
}
export default Popup;
