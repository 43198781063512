import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Reaptcha from "reaptcha";
import { Images } from "../../assets/common";
import { ResendVerificationAlert } from "../../components/Alerts";
import notifier from "../../components/notifier";
import { getErrorString } from "../../components/validator";
import { MESSAGE } from "../../constants/messages";
import { TaskbarUserContext } from "../../context/TaskbarUserContext";
import { getParamsByName } from "../../lib/validators";
import { REQ_LOGIN, REQ_USER_DETAILS } from "../../services/api-service";
import cookieStorage from "../../services/cookie-storage";
import "./index.scss";

function Login(props) {
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoader] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [cmTestingAlert, setCmTestingAlert] = useState(false);
  const [resendEmailVerifyLink, setResendEmailVerifyLink] = useState(false);

  let ssoAuth = getParamsByName("ssoAuth");
  //context
  const { setUserLogIn, setCurrentUser } = useContext(TaskbarUserContext);

  useEffect(() => {
    cookieStorage.deleteCookie();
  }, []);

  const RegisterRedirect = () => {
    props.history.push("/register");
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    // const checked = event.target.checked;
    switch (id) {
      case "email":
        updateEmail(value);
        break;
      case "password":
        updatePassword(value);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onEnterPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const storeErrorCount = async (errorString) => {
    const loginErrorCount = localStorage.getItem("loginError");

    if (parseInt(loginErrorCount) >= 2) {
      setShowCaptcha(true);
    }
    if (errorString === "The entered password is wrong" || errorString === "Email is invalid") {
      if (typeof loginErrorCount == "object") {
        localStorage.setItem("loginError", 1);
      } else {
        localStorage.setItem("loginError", parseInt(loginErrorCount) + 1);
      }
    }
  };

  const OncaptchaVerify = () => {
    setShowCaptcha(false);
  };

  const handleLogin = async () => {
    const fcmToken = cookieStorage.getCookie("fcmToken");
    try {
      if (!email) {
        notifier.warning("Warning", MESSAGE.ERROR.EMAIL_EMPTY);
        return;
      } else if (!password) {
        notifier.warning("Warning", MESSAGE.ERROR.PASSWORD_EMPTY);
        return;
      } else if (email && password) {
        setLoader(true);
        const requestBody = {
          email: email,
          password: password,
          deviceToken: fcmToken,
          checked: checked,
        };
        if (ssoAuth === "upvoty") requestBody["ssoAuth"] = "upvoty";
        const response = await REQ_LOGIN(requestBody);
        const token = response.data.result.token;
        const userDetails = response.data.result.userData;
        const currentUser = userDetails._id;
        cookieStorage.createCookie("token", token, 1);
        localStorage.setItem("currentUser", currentUser);
        localStorage.setItem("upvotySSOAuth", response.data.result.upvotySSOAuth);
        localStorage.setItem("profileImagePath", userDetails.profileImgUrl);
        setUserLogIn(true);

        if (response.data.result.upvotyRedirect) {
          return (window.location.href = response.data.result.upvotySSOAuth);
        }
        getUserDetails(currentUser, response);
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      storeErrorCount(errorString);
      if (errorString === "Your account has been deactivated. Please contact the admin for more details.") {
        setCmTestingAlert(true);
      } else if (errorString === "Please verify your email, before login!") {
        setResendEmailVerifyLink(true);
      } else {
        notifier.error("Error", errorString);
      }
    }
  };

  const alertForVerifyCaptcha = () => {
    notifier.warning("Warning", "Please Verify captcha Before proceeding Further", 3500);
  };

  // , Email is invalid

  const getUserDetails = async (id, loginResponse) => {
    if (id) {
      try {
        const requestBody = {
          userId: id,
        };
        const response = await REQ_USER_DETAILS(requestBody);
        let userDetails = response.data.result;
        localStorage.setItem("loggedInUser", JSON.stringify(userDetails));
        setCurrentUser(userDetails);
        handlePageRedirection(loginResponse);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlePageRedirection = (response) => {
    try {
      setLoader(false);
      let redirectPath;
      if (response.data.result.userData.firstName === undefined) {
        redirectPath = "/basic-info";
      } else if (response.data.result.userData.requiredPasswordChange) {
        redirectPath = "/resetPassword";
      } else {
        redirectPath = "/home/dashboard";
      }
      window.location.href = redirectPath;
    } catch (e) {
      console.log("ERROR in path Re-direction:::", e);
    }
  };

  const forgotPass = () => {
    props.history.push("/forgot-password");
  };

  const handleKeepMeLoginChange = (e) => {
    setChecked(!checked);
    localStorage.setItem("Remember_Me", !checked);
  };

  return (
    <div className="login-page">
      <div className="login-logo">
        <Link href="/">
          <img src={Images.Logo} alt={""} />
        </Link>
      </div>
      <div className="login-layout">
        <div className="login-content-wrapper">
          <div className="login-content">
            {cmTestingAlert ? (
              <Alert severity="warning">
                <AlertTitle>Warning:</AlertTitle>
                Your account has been blocked by the website administrator, please contact support for further information.
              </Alert>
            ) : null}
            {resendEmailVerifyLink && <ResendVerificationAlert email={email} />}
            <h1>Login</h1>
            <h5>Ready to enter Your Taskbar?</h5>
            {/* <p>
              <button className="connect_hashpack_button">
                <img src={Images.hashPackIcon} alt="Hashpck" /> Signup With hashpack
              </button>
            </p> */}
            <p>Enter your login credentials to go to your account.</p>
            <div className="login-form">
              <div className="login-form__control">
                <div className={"field-container"}>
                  <TextField id="standard-basic" label="Enter Email" variant="outlined" name="email" onChange={handleInputChange} onKeyPress={onEnterPress} type={"email"} value={email} />
                </div>
              </div>
              <div className="login-form__control">
                <div className={"field-container"}>
                  <TextField label="Password" variant="outlined" name="password" onChange={handleInputChange} onKeyPress={onEnterPress} type={passwordShown ? "text" : "password"} value={password} />
                  <div className="eye">{passwordShown ? <Visibility onClick={togglePasswordVisiblity} /> : <VisibilityOff onClick={togglePasswordVisiblity} />}</div>
                </div>
              </div>

              <div className="forgot-pass-block">
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleKeepMeLoginChange} name="checkedB" />} label="Keep me logged in" />
                <div className="forgot-pass" onClick={forgotPass}>
                  Forgot Password?
                </div>
              </div>
              {showCaptcha ? <Reaptcha sitekey="6Lc5kYsaAAAAAGgXObyBG97ICFIzPfsXon_PBesZ" onVerify={OncaptchaVerify} /> : null}
              <div className="login-form-btn">
                <button onClick={showCaptcha ? alertForVerifyCaptcha : handleLogin}>{loading ? <img alt="" src={Images.ellipsis_loader} /> : <span> Login </span>}</button>
              </div>
              <div className="register-link">
                <div className="text-dark">Don’t have an account?</div>
                <p onClick={RegisterRedirect}>Register</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
