import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../../../assets/common";
import BarChart from "../../../../components/Barchart";
import Hide from "../../../../components/Hide";
import Loader from "../../../../components/Loader";
import NoDataToShow from "../../../../components/NoDataToShow";
import PieChart from "../../../../components/Piechart";
import "./index.scss";

const Analytics = (props) => {
  const { dashboardData, loading } = props;

  //Pie colors
  const colors = ["#FEC21C", "#F05A22"];

  //Bar colors
  const barColors = ["rgba(254, 194, 28, 1)", "rgba(240, 90, 34, 1)", "rgba(46, 111, 234, 1)"];

  const renderTotalEarning = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">Total Earning</div>
        <div className="analytics__content__item__data">
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.totalEarning.totalEarnedSinceJoining.toFixed(2)}</span>
            <p>Your total earnings since joining</p>
          </div>
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.totalEarning.totalEarnedLastMonth.toFixed(4)}</span>
            <p>Your total earnings last month</p>
          </div>
        </div>
      </div>
    );
  };

  const renderTotalSpending = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">TOTAL SPENDING</div>
        <div className="analytics__content__item__data">
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.totalSpending.totalSpentSinceJoining}</span>
            <p>Your total Spending since joining</p>
          </div>
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.totalSpending.totalSpentLastMonth}</span>
            <p>Your total Spending last month</p>
          </div>
        </div>
      </div>
    );
  };

  const renderTaskProficiency = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">Task proficiency</div>
        <div className="analytics__content__item__data analytics__content__item__data--taskProfiency">
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.taskProficiency.createdTasks}</span>

            <p>Created Tasks</p>
          </div>
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.taskProficiency.completedTasks}</span>
            <p>Completed Tasks</p>
          </div>
          <div className="analytics__content__item__data__item">
            <span>{dashboardData.taskProficiency.onTimeTasks}</span>
            <p>On Time Task</p>
          </div>
          <div className="analytics__content__item__data--taskProfiency__item">
            <span>{dashboardData.taskProficiency.proficiencyScore.toFixed(2)}</span>
            <p>
              <span>0</span>
              <img src={Images.scoreGraph} alt="" />
              <span>10</span>
            </p>
            <p>Proficiency Score</p>
          </div>
        </div>
      </div>
    );
  };

  const renderEarningPerSuperskill = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">
          Earnings per superskill
          <span>Over the LAST 3 MONTHS</span>
        </div>
        <div className="chartbox">
          <p>
            Earning
            <br />
            <span>(In ℏ s)</span>
          </p>
          {dashboardData.earningPerSuperskill.length > 0 ? <BarChart data={dashboardData.earningPerSuperskill} colors={barColors} id={`earningBarChart`} /> : <NoDataToShow />}
        </div>
      </div>
    );
  };

  const renderRatingPerSuperskill = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">
          Rating Per SuperSkill
          <span>Over the LAST 3 MONTHS</span>
        </div>
        <div className="chartbox">
          <p>
            Rating
            <br />
            <span>(Out of 5 Star)</span>
          </p>
          {dashboardData.ratingPerSuperskill.length > 0 ? <BarChart data={dashboardData.ratingPerSuperskill} colors={barColors} id={`ratingBarChart`} /> : <NoDataToShow />}
        </div>
      </div>
    );
  };

  const renderBidConversion = () => {
    return (
      <div className="analytics__content__item">
        <div className="analytics__content__item--label">Bid Conversion</div>
        {dashboardData.bidConversion.length > 0 ? <PieChart data={dashboardData.bidConversion} outerRadius={76} innerRadius={0} colors={colors} labelColor="#ffffff" /> : <NoDataToShow />}
      </div>
    );
  };

  return (
    <>
      <div className="analytics">
        <div className="dashboard__content__heading">
          <h2 className="dashboard__content__heading--label">Analytics</h2>
        </div>
        <div className="analytics__main">
          {loading ? (
            <Loader />
          ) : dashboardData && Object.keys(dashboardData).length > 0 ? (
            <div className="analytics__content">
              {renderTotalEarning()}

              {renderTotalSpending()}

              {renderTaskProficiency()}

              {renderEarningPerSuperskill()}

              {renderRatingPerSuperskill()}

              {renderBidConversion()}
            </div>
          ) : (
            <NoDataToShow />
          )}
        </div>
      </div>
      <Hide type="up" size={576}>
        <div className="userAction">
          <p>Do you have a gig that you need help with? Create a new task now! There's a lot of Taskateers ready to help you out.</p>
          <Link to="/home/create-task/">Create Task</Link>
        </div>
        <div className="userAction">
          <p>Looking for some work? Find and apply to tasks submitted by other members of the community!</p>
          <Link to="/Task/list-task/">Click Here</Link>
        </div>
      </Hide>
    </>
  );
};

export default Analytics;
