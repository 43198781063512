import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets/common";
import Footer from "../../components/footer";
import Header from "../../components/header";
import HeaderBoard from "../home/header-board";
import "./index.scss";

const PageNotFound = (props) => {
  return (
    <div className="page404">
      <Header {...props}  />
      <div className="page404-container">
        {localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser").trim() !== "" ? (
          <HeaderBoard  {...props}/>
        ) : null}
        <div className="page404-content">
          <div className="centered">
            <div>
              <img src={Images.img404} alt="404 Page Not Found" />
            </div>
            <p>
              Sorry, we can't find that page! It might be an old link or maybe
              it moved.
            </p>
            <Link  to={`/`}>Go Home</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PageNotFound;
