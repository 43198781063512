import { Fade, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, withStyles } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import notifier from '../../../components/notifier';
import Pagination from '../../../components/Pagination';
import { getErrorString, paginationLimit, serialize } from '../../../components/validator';
import { StyledBackdrop } from '../../../lib/validators';
import { APPROVE_PROHIBITED_TASK, DELETE_PROHIBITED_TASK, GET_PROHIBITED_TASK } from '../../../services/api-service';
import './index.scss';

const PredefinedTask = () => {
  const [tasks, setTasks] = useState([]);
  const [totalRecords, setTotalRecord] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)
  const [isAlertModal, setAlertModal] = useState(false);
  const [taskId, setTaskId] = useState('')

  useEffect(() => {
    getTasks()
  }, [])

  const getTasks = async () => {
    setIsLoading(true);
    const params = {
      'pageNo': pageNumber,
      'limit': paginationLimit
    }
    try {
      const response = await GET_PROHIBITED_TASK(serialize(params));
      setTasks(response.data.result.data);
      setTotalRecord(response.data.result.totalCount);
      setIsLoading(false);
    }
    catch (e) {
      notifier.error("Error", getErrorString(e));
      setIsLoading(false)
    }
  }

  //events
  const handlePageChange = (page) => {
    setPageNumber(page);
    getTasks();
  };

  const approveTask = async (taskId) => {
    const reqBody = {
      taskIds: [taskId],
    }
    try {
      const response = await APPROVE_PROHIBITED_TASK(reqBody)
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getTasks();
      }
    }
    catch (e) {
      notifier.error("Error", getErrorString(e));
    }
  }

  const taskAction = (id) => {
    setAlertModal(true)
    setTaskId(id)
  }

  const delTaskById = async () => {
    setBtnLoader(true)
    const reqBody = {
      taskId: taskId,
    }
    try {
      const response = await DELETE_PROHIBITED_TASK(reqBody)
      setBtnLoader(false)
      if (response.data.status === "success") {
        notifier.success(response.data.result);
        getTasks();
        setAlertModal(false);
        setTaskId('');
      }
    }
    catch (e) {
      setBtnLoader(false)
      notifier.error("Error", getErrorString(e));
    }
  }

  const handleDelAlertClose = () => {
    setAlertModal(false)
  }

  return (
    <>
      <div className="admin__body__continer__content__heading">
        <h4>
          Prohibited Task
        </h4>
      </div>
      <div style={{ height: "calc(100% - 50px)" }}>
        {/* <div className="search-from">
          <div className="input-feild">
            <TextField
              label="Search in Predefined Task"
              className="input"
              variant="outlined"
              size="small"
              fullWidth
              value={searchTerms}
              onChange={handleSearchTermsChange}
            />
          </div>
          <button
            className="searchbtn"
            onClick={() => getTasks({ searchPhrase: searchTerms })}
          >
            Search
              </button>
        </div> */}
        {isLoading ? (
          <Loader />
        ) : (
            <>
              <TableContainer className="data-table-tasks">
                {tasks.length > 0 ? (
                  <Table size="small" stickyHeader>
                    <colgroup>
                      <col width="50" />
                      <col width="200"/>
                      <col />
                      <col width="150" />
                      <col />
                      <col width="100" />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sr.</StyledTableCell>
                        <StyledTableCell>Heading</StyledTableCell>
                        <StyledTableCell>Descrption</StyledTableCell>
                        <StyledTableCell>Subskills</StyledTableCell>
                        <StyledTableCell>Payment</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tasks.map((task, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>
                              <span className='task-heading'>
                                {task.heading}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell>
                              {task.description}
                            </StyledTableCell>
                            <StyledTableCell>
                              {task.skills.map((skill) => `${skill.name}, `)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {task.paymentTerms.paymentType}
                            </StyledTableCell>
                            <StyledTableCell size="small">
                              <Tooltip
                                placement="top"
                                title="Approve this Task"
                                arrow
                              >
                                <IconButton
                                  size="small"
                                  onClick={approveTask.bind(this, task._id)}
                                >
                                  <Done />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title="Delete this Task"
                                arrow
                              >
                                <IconButton
                                  size="small"
                                  onClick={taskAction.bind(this, task._id)}
                                  style={{ color: "red" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                    <div>
                      <p style={{ textAlign: "center", padding: "10px 0" }}>
                        No data to show
                      </p>
                    </div>
                  )}
              </TableContainer>
              <div className="pagination">
                <Pagination
                  totalRecord={totalRecords}
                  pageNumber={pageNumber}
                  sendCurrentPage={handlePageChange}
                  showControls={true}
                />
              </div>
            </>
          )}
      </div>

      <Modal
        aria-labelledby="Issue Reporting"
        aria-describedby="If you found any issu Report Here"
        className="alertModal"
        open={isAlertModal}
        onClose={handleDelAlertClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isAlertModal}>
          <div className="alertModal__paper">
            <p>Are You Sure ! You want to Delete This Task.</p>
            <div className="actions">
              <button className="yes" onClick={delTaskById}>{`${btnLoader ? 'Deleting...' : ' Yes Delete'}`}</button>
              <button className="cancel" onClick={handleDelAlertClose}>
                Cancel
                </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}


const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "none",
  },
  head: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);


export default PredefinedTask;