import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import history from "../../../components/history";
import { getParamsByName } from "../../../lib/validators";
import { REQ_USER_DETAILS } from "../../../services/api-service";
import SingleSuperSkill from "../single-superskill";
import "./index.scss";

function ProfileDescription(props) {
  const [taskerId, setTaskerId] = useState("");
  const [userDetails, setUserrDetails] = useState([]);
  const [uniqSkill, setUniqSkills] = useState([]);
  const [showSkills, setSkillsShow] = useState("all");
  useEffect(() => {
    var taskerId = getParamsByName("taskerId");
    setTaskerId(taskerId);
    getUserDetails(taskerId);
    return () => {
      // Component Will unmount will go here
    };
  }, [taskerId]);

  const getUserDetails = async (taskerId) => {
    try {
      const requestBody = {
        userId: taskerId,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      const details = response.data.result;
      setUserrDetails(details);
      const Superskills = response.data.result.profileStats.superskills;
      getUniqSkills(Superskills);
    } catch (e) {
      console.log(e);
    }
  };

  const getUniqSkills = (Superskills) => {
    const uniqeSkills = new Set();
    Superskills.map((Superskill) => {
      return Superskill.skills.map((skill) =>
        uniqeSkills.add(skill.name)
        //console.log(skill)
      );
    });
    let myArr = Array.from(uniqeSkills);
    setUniqSkills(myArr);
    //console.log("uniq", uniqSkill, myArr)
  };

  const drillDownSingleSuperskill = (superskillID) => {
    //console.log("has", superskillID)
  };

  return (
    <div className="Profile-Description-page">
      <div className="back" onClick={() => history.goBack()}>
        <img src={Images.back} alt={""} />
        {"Go Back"}
      </div>
      <div className="Profile-Description-page-content">
        <div className="profile-description">
          <div className="profile">
            <div className="profile-top">
              <div className="profile-image">
                <img src={userDetails.profileImgUrl} alt="" />
                {/* <img src={Images.profileIcon} alt={""} /> */}
                <div className={`box ${userDetails.availability ? "active" : ""}`}></div>
              </div>
              <div className="profile-content">
                <div className="info">
                  <div className="info-name"> {userDetails.nickName}</div>
                  <div className="name">
                    {userDetails.firstName} {userDetails.lastName}
                  </div>
                  {/* <hr /> */}
                  {userDetails.address ? (
                    <>
                      <div className="tasker-location">
                        <img src={Images.location} alt={""} />
                        {"  "}
                        {userDetails.address.city}
                        {", "}
                        {userDetails.address.country}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {/* <br /> */}
            <div className="reputation">
              <h3>Reputations</h3>
              <div className="badges">
                <div className="badges-name">Overall Rating</div>
                <div className="badges-icon">
                  {userDetails.profileStats ? (
                    <>
                      {userDetails.profileStats.avgRating.toFixed(2)} {"  "}
                      <img src={Images.GreenRating} alt="" />
                    </>
                  ) : null}
                </div>
              </div>
              <div className="badges">
                <div className="badges-name">Badges Earned</div>
                {/* <div className="badges-icon"><img src={Images.Rating} /><img src={Images.Rating} /><img src={Images.Rating} /></div> */}
              </div>
            </div>
            {/* <br /> */}
            <div className="reputation">
              <h3>All time Details</h3>

              <div className="badges">
                <div className="badges-name">Total Tasks</div>
                <div className="badges-icon">45</div>
              </div>
              <div className="badges">
                <div className="badges-name">Total Hours</div>
                <div className="badges-icon">1240</div>
              </div>
              <div className="badges">
                <div className="badges-name">Response Time</div>
                <div className="badges-icon">{"< 24"}</div>
              </div>
              <div className="badges">
                <div className="badges-name">Member Since</div>
                <div className="badges-icon">2019</div>
              </div>
              <div className="btn-container">
                <button className="add-fav">Add To Favourites</button>
                <button className="hire">Hire Now</button>
                {/* <button className="draft" name="draft" onClick={UpdateSuperSkill}>Save as draft</button> */}
              </div>
            </div>
          </div>
          {/* <br /> */}
          <div className="profile-skill">
            <h3>Subskills</h3>
            {/* {console.log("jk", uniqSkill)} */}
            {uniqSkill ? (
              <div className="skills-details">
                {uniqSkill.map((uniq) => (
                  <div className="top-skill">{uniq}</div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {showSkills === "all" ? (
          <div className="profile-description-content">
            <div className="profile-description-content-header">
              <div className="profile-description-content-headerSlider">Superskills</div>
              <div className="profile-description-content-portfolioSlider">Portfolio</div>
            </div>
            <div className="profile-description-content-body">
              {/* <div className="super-skill-head">Superskills</div> */}
              {userDetails.profileStats ? (
                <>
                  {userDetails.profileStats.superskills.length ? (
                    <>
                      {userDetails.profileStats.superskills.map((superskill, i) => (
                        <div className="task-wrapper" onClick={() => drillDownSingleSuperskill(superskill._id)}>
                          <div className="profile-content">
                            <div className="task-name">
                              {superskill.title}
                              {/* Logo Designer & UI/UX Product Designer */}
                            </div>
                            <div className="task-pay">
                              <p>Hourly Rate</p>
                              <div>
                                {superskill.ratePerHour}
                                {"  "} {"ℏ(s)"}
                                {"  "}/hr<span>(5 USD)</span>
                              </div>
                            </div>
                          </div>
                          <div className="task-info">
                            <p>{superskill.description}</p>
                            <div className="skills">Subskills Required</div>
                            <div className="skills-details">
                              {superskill.skills.map((skill) => (
                                <div className="top-skill">{skill.name}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {showSkills === "single" ? <SingleSuperSkill /> : null}
      </div>
    </div>
  );
}
export default ProfileDescription;
