
import React, { useState } from "react";
import PointTable from "./PointTable";
import {AntTab , AntTabs , TabPanel} from '../../../components/componentList';

//Import styles;

import "./index.scss";
import AddPointsToUser from "./AddPointsToUser";

export default function PointSystem(props) {
    const [value , setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      function a11yProps(index) {
        return {
          id: `pointSystem-tab-${index}`,
          'aria-controls': `pointSystem-tabpanel-${index}`,
        };
      }

  return (
    <React.Fragment>
      <div className="admin__body__continer__content__heading">
      <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Point Table"  {...a11yProps(0)} />
          <AntTab label="Add Point To User"  {...a11yProps(1)} />
        </AntTabs>
      </div>
      <div className="pointSystem">
      <TabPanel value={value} index={0} boxPadding={2}>
        <PointTable {...props}/>
      </TabPanel>
      <TabPanel value={value} index={1} boxPadding={2}>
        <AddPointsToUser />
      </TabPanel>
      </div>
    </React.Fragment>
  );
}
  