import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import notifier from "../../../components/notifier";
import { getErrorString } from "../../../components/validator";
import { API } from "../../../constants/api";
import { MESSAGE } from "../../../constants/messages";
import { validNumber } from "../../../lib/validators";
import { REQ_CREATE_TASK, REQ_SKILLS } from "../../../services/api-service";
import cookieStorage from "../../../services/cookie-storage";
import history from ".././../../components/history";
import "./index.scss";

function CreateTask(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState("hourly");
  // const [hourly, setHourly] = useState(true)
  // const [fixed, setFixed] = useState(false)
  const [fixedRate, setFixedRate] = useState();
  const [fixedTaskDeadline, setFixedTaskDeadline] = useState("");
  const [applyBefore, setApplyBefore] = useState("");
  const [hourlyRate, setHourlyRate] = useState();
  const [hourlyTaskDeadline, setHourlyTaskDeadline] = useState("");
  const [hourlyTaskDuration, setHourlyTaskDuration] = useState("");
  const [checked, setChecked] = useState(false);
  const [skills, setSkills] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [bidExpiryDate, setBidExpiryDate] = useState("");
  const [loading, setLoader] = useState(false);
  const [todaysDate, setTodaysDate] = useState(null);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [isSugLoading, setIsSugLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedsuggestion, setselectedSuggestion] = useState(0);
  const [checkSkillField, setCheckSkillField] = useState({
    error: false,
    helperText: "",
  });

  useEffect(() => {
    const { cancel, token } = Axios.CancelToken.source();
    getTodayDate();
    getAllSkills();
    const timeOutId = setTimeout(async () => await fetchHits(title, token), 500);
    return () => cancel("No longer latest query") || clearTimeout(timeOutId);
  }, [title]);

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    switch (id) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "skills":
        setSkills(value);
        getAllSkills(value);
        break;
      case "paymentType":
        setPaymentType(value);
        break;
      case "hourlyRate":
        setHourlyRate(value);
        break;
      case "time":
        setHourlyTaskDeadline(value);
        break;
      case "applyBefore":
        setApplyBefore(value);
        break;
      case "hourlyTaskDuration":
        setHourlyTaskDuration(value);
        break;
      case "fixedRate":
        setFixedRate(value);
        break;
      case "fixedTaskDeadline":
        setFixedTaskDeadline(value);
        break;
      case "bidExpiryDate":
        setBidExpiryDate(value);
        break;
      case "checked":
        setChecked(checked);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const onchangeAddSkill = (event, newValue) => {
    let newArray = newValue.map((d, i) => {
      if (typeof d === "string") {
        return { name: d };
      } else {
        return d;
      }
    });
    setSkills(newArray);
  };

  const getAllSkills = _.debounce(async (skill) => {
    try {
      let allSkills;
      const param = {
        searchPhrase: skill ? skill : undefined,
      };
      const response = await REQ_SKILLS(param);
      allSkills = response.data.result;
      setAllSkills(allSkills);
    } catch (e) {
      setLoader(false);
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  }, 1000);

  const validateField = () => {
    let error = false;
    if (!title) {
      notifier.warning(MESSAGE.ERROR.TITLE_EMPTY);
      error = true;
    } else if (title.length <= 2) {
      notifier.warning(MESSAGE.ERROR.TITLE_LENGTH);
      error = true;
    } else if (!description) {
      notifier.warning(MESSAGE.ERROR.DESCRIPTION_EMPTY);
      error = true;
    } else if (skills.length === 0) {
      notifier.warning(MESSAGE.ERROR.SKILLS_EMPTY);
      error = true;
    } else if (paymentType === "hourly") {
      if (!hourlyRate) {
        notifier.warning(MESSAGE.ERROR.PRICE_EMPTY);
        error = true;
      } else if (!validNumber(hourlyRate)) {
        notifier.warning(MESSAGE.ERROR.PRICE_INVALID);
        error = true;
      } else if (Number(hourlyRate) <= 0) {
        notifier.warning(MESSAGE.ERROR.PRICE_MUST);
        error = true;
      } else if (!hourlyTaskDeadline) {
        notifier.warning(MESSAGE.ERROR.TIME_EMPTY);
        error = true;
      } else if (!validNumber(hourlyTaskDeadline)) {
        notifier.warning(MESSAGE.ERROR.TIME_INVALID);
        error = true;
      } else if (!hourlyTaskDuration) {
        notifier.warning(MESSAGE.ERROR.TASK_DURATION_EMPTY);
        error = true;
      }
    } else if (paymentType === "fixed") {
      if (!fixedRate) {
        notifier.warning(MESSAGE.ERROR.PRICE_EMPTY);
        error = true;
      } else if (!validNumber(fixedRate)) {
        notifier.warning(MESSAGE.ERROR.PRICE_INVALID);
        error = true;
      } else if (Number(fixedRate) <= 0) {
        notifier.warning(MESSAGE.ERROR.PRICE_MUST);
        error = true;
      } else if (!fixedTaskDeadline) {
        notifier.warning(MESSAGE.ERROR.TASK_DEADLINE_EMPTY);
        error = true;
      }
    }
    if (checked) {
      if (!bidExpiryDate) {
        notifier.warning(MESSAGE.ERROR.EXPIRY_DATE_EMPTY);
        error = true;
      }
    } else if (!applyBefore) {
      notifier.warning(MESSAGE.ERROR.EXPIRY_DATE_EMPTY);
      error = true;
    }
    return error;
  };

  const CreateTask = async () => {
    const checkValid = validateField();
    if (checkValid) {
      return;
    }
    try {
      const bidEnd = checked ? bidExpiryDate.concat(" ").concat("00:00:00") : applyBefore.concat(" ").concat("00:00:00");
      if (paymentType === "fixed") {
        setLoader(true);
        const requestBody = {
          heading: title,
          description: description,
          paymentTerms: {
            paymentType: paymentType,
            hourly: {},
            fixed: {
              fixed_rate: fixedRate,
              fixed_taskDeadline: fixedTaskDeadline,
              fixed_applyBefore: bidEnd,
            },
            biddingEnabled: checked,
            bidExpiryDate: bidEnd,
          },
          skills: skills,
        };
        const response = await REQ_CREATE_TASK(requestBody);
        setLoader(false);
        if (response.data.status === "success") {
          notifier.success(MESSAGE.SUCCESS.TASK_CREATED_SUCESSFULLY);
          if (window.dataLayer) {
            window.dataLayer.push({ event: "completed_addTask" });
          }
          props.history.push(`/home/my-tasks`);
          resetFields();
        }
      } else {
        setLoader(true);
        const requestBody = {
          heading: title,
          description: description,
          paymentTerms: {
            paymentType: paymentType,
            hourly: {
              hourly_ratePerHour: hourlyRate,
              hourly_hoursPerWeek: hourlyTaskDeadline,
              hourly_taskDuration: parseInt(hourlyTaskDuration),
              hourly_applyBefore: bidEnd,
            },
            // Fixed has to be sent in this manner i.e {} if hourly is chosen.
            fixed: {},
            biddingEnabled: checked,
            // Format : // YYYY-MM-DD HH:mm:ss
            bidExpiryDate: bidEnd,
          },
          skills: skills,
        };
        const response = await REQ_CREATE_TASK(requestBody);
        setLoader(false);
        if (response.data.status === "success") {
          notifier.success(MESSAGE.SUCCESS.TASK_CREATED_SUCESSFULLY);
          props.history.push(`/home/my-tasks`);
          resetFields();
        }
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const handlePaymentTypeHourly = (e) => {
    e.stopPropagation();
    setPaymentType("hourly");
    // setHourly(true)
    // setFixed(false)
  };

  const handlePaymentTypeFixed = (e) => {
    e.stopPropagation();
    setPaymentType("fixed");
    // setFixed(true)
    // setHourly(false)
  };

  const getTodayDate = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1; // getMonth() is zero-based
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;

    setTodaysDate(maxDate);
  };

  const resetFields = () => {
    setTitle("");
    setDescription("");
    setPaymentType("hourly");
    setFixedRate();
    setFixedTaskDeadline("");
    setHourlyRate();
    setHourlyTaskDeadline("");
    setHourlyTaskDuration("");
    setChecked(false);
    setSkills([]);
    setAllSkills([]);
    setBidExpiryDate("");
    setApplyBefore("");
    setLoader(false);
  };

  const fetchHits = async (query, CancelToken) => {
    setIsSugLoading(true);
    try {
      const response = await Axios.get(`${API.ADMIN_GET_PREDEFINED_TASK}?searchPhrase=${query}`, { headers: { Authorization: cookieStorage.getCookie("token") } }, { CancelToken });
      setSuggestions(response.data.result.data);
      setIsSugLoading(false);
      setShowSuggestion(true);
      // dispatch({ type: "FETCH_SUCCESS", payload: response.data.result.data });
    } catch (err) {
      console.error(err);
      Axios.isCancel(err);
    }
  };

  const onSuggestionclick = (index, data) => {
    console.log("selected", data);
    setTitle(data.heading);
    setDescription(data.description);
    setSkills(data.skills);
    setShowSuggestion(false);
  };

  const handleKeyEvent = (e) => {
    setShowSuggestion(true);
    // //console.log(e.keyCode);

    if (e.keyCode === 40) {
      selectedsuggestion < suggestions.length - 2 ? setselectedSuggestion(selectedsuggestion + 1) : setselectedSuggestion(suggestions.length - 1);
    }
    if (e.keyCode === 38) {
      selectedsuggestion > 1 ? setselectedSuggestion(selectedsuggestion - 1) : setselectedSuggestion(0);
    }

    if (e.keyCode === 13 && suggestions.length) {
      // //console.log(suggestions);
      setTitle(suggestions[selectedsuggestion].heading);
      setDescription(suggestions[selectedsuggestion].description);
      setSkills(suggestions[selectedsuggestion].skills);
      setShowSuggestion(false);
    }
  };

  const checkSkillsOnBlur = (e) => {
    if (e.target.value.trim() !== "") {
      notifier.warning("Warning", "Please Press Enter to add New subskill, Which is not in the List.", 6000);
      setCheckSkillField({
        error: true,
        helperText: "Please Press Enter to add New subskill, Which is not in the List.",
      });
    } else {
      setCheckSkillField({
        error: false,
        helperText: "",
      });
    }
  };

  return (
    <div className="main-container">
      <div className="create-task-top">
        <h1>Create New Tasks</h1>
        <div className="cancel" onClick={() => history.goBack()}>
          <img src={Images.cancel} alt="" /> Cancel
        </div>
      </div>

      <div className="give-task-title-container">
        <div className="give_span">Give your task a title</div>
        {/* <TitleInput /> */}
        <div className="designer-container" onMouseLeave={() => setShowSuggestion(false)}>
          <input
            autoComplete="off"
            placeholder="Eg. Required a graphic designer for branding"
            className="title"
            name="title"
            value={title}
            onFocus={() => setShowSuggestion(true)}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleKeyEvent}
          />
          {showSuggestion ? (
            <>
              {isSugLoading ? (
                <span className="loading">
                  <img src={Images.ellipsis_loader} alt="loader" />
                </span>
              ) : (
                <div className="taskSugestion">
                  {suggestions.map((item, i) => (
                    <div className={`taskSugestion__list ${selectedsuggestion === i ? "active" : ""}`} key={i} onClick={onSuggestionclick.bind(this, i, item)}>
                      <h5>{item.heading}</h5>
                      <p>{item.description.length > 100 ? `${item.description.substring(0, 100)}...` : item.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : null}
        </div>
        <br />

        <div className="list-example-container">
          <div className="see-example">See Examples:</div>
          <ul className="list-container">
            <li>Need a JAVA developer for mobile application</li>
            <li>Required a photo editor for 200 modelling photos to edit</li>
            <li>Flutter developer for android and iOS app</li>
          </ul>
        </div>
      </div>
      <div className="description-container">
        <div className="description-task">
          <span className="description_span">Provide a brief description of the task</span>
          <br />
          <textarea rows="5" className="description" name="description" onChange={handleInputChange} value={description} placeholder={"Give a detailed description about the task for maximum reach…"}></textarea>
        </div>
      </div>
      <div className="search-task-container">
        <div className="search_span">Define the required Subskills</div>
        <br />
        {/* <img src={Images.SearchLandingpage} className="search-icon" /> */}
        <Autocomplete
          multiple
          id="tags-filled"
          options={AllSkills}
          value={skills}
          freeSolo
          onChange={(event, newValue) => onchangeAddSkill(event, newValue)}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <>
                {typeof option === "string" ? (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} deleteIcon={<img src={Images.chipCloseIcon} alt="" />} />
                ) : (
                  <Chip variant="outlined" label={option.name} {...getTagProps({ index })} deleteIcon={<img src={Images.chipCloseIcon} alt="" />} />
                )}
              </>
            ))
          }
          renderInput={(params) => (
            <>
              <div className="input-container">
                {/* <SearchIcon style={{ position: 'absolute', left: 0, top: 15, width: 20, height: 20 }} /> */}
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="What subskills and expertise are you looking for?"
                  name="skills"
                  error={checkSkillField.error}
                  helperText={checkSkillField.helperText}
                  //onChange={handleInputChange}
                  onBlur={(e) => {
                    checkSkillsOnBlur(e);
                  }}
                />
              </div>
            </>
          )}
        />
        <div className="example">For Example : UI Designer, Web developer, Accountant, etc</div>
      </div>
      <div className="payment-container">
        <div className="payment-term">Payment Terms</div>
        <div className="contract-type-div">Select the contract type</div>
        <div className="btn-container">
          <div className="hourly-change-btn">
            <div className={` ${paymentType === "hourly" ? "active-type" : "disable-type"} `} onClick={handlePaymentTypeHourly}></div>
            <div className="hourly-btn-label">Hourly</div>
          </div>
          <div className="fixed-change-btn">
            <div className={` ${paymentType === "fixed" ? "active-type" : "disable-type"} `} onClick={handlePaymentTypeFixed}></div>
            <div className="fixed-btn-label">Fixed Price</div>
          </div>
        </div>
      </div>
      {paymentType === "hourly" ? (
        <div className="hourly-container">
          <div className="brand-container">
            <div className="rate-container">Rate/ hr</div>
            <div className="input-container">
              <input type="number" placeholder={"Enter Price"} name="hourlyRate" onChange={handleInputChange} />
              <div className="input_right">ℏ(s)</div>
            </div>
          </div>

          <div className="brand-container time">
            <span className="rate-container">Time (Hours/ Week)</span>
            <div className="input-container">
              <input type="number" placeholder={"Enter Time"} name="time" onChange={handleInputChange} />
              {/* <img src={Images.downArrow} alt={""} className="down" /> */}
            </div>
          </div>
          <div className="brand-container time">
            <span className="rate-container">Task Duration (In weeks)</span>
            <div className="input-container">
              <select name="hourlyTaskDuration" onChange={handleInputChange}>
                <option value="">Select Duration</option>
                <option value="1">One week</option>
                <option value="2">Two weeks</option>
                <option value="3">Three weeks</option>
                <option value="4">One Month</option>
                <option value="12">Three Months</option>
                <option value="24">Six Months</option>
              </select>
              {/* <img src={Images.downArrow} alt={""} className="down" /> */}
            </div>
          </div>
        </div>
      ) : null}
      {paymentType === "fixed" ? (
        <div className="hourly-container">
          <div className="brand-container">
            <div className="rate-container">Fixed Amount</div>
            <div className="input-container">
              <input type="number" placeholder={"Enter Price"} name="fixedRate" onChange={handleInputChange} />
              <div className="input_right">ℏ(s)</div>
            </div>
          </div>

          <div className="brand-container">
            <div className="rate-container">Task Deadline</div>
            <div className="input-container">
              <input type="date" name="fixedTaskDeadline" onChange={handleInputChange} min={todaysDate} />
            </div>
          </div>
        </div>
      ) : null}

      <div className="bid">
        <span className="allow-bid">Allow for Bidding</span>
        <FormControlLabel label="" labelPlacement="start" control={<Switch checked={checked} onChange={handleInputChange} name="checked" inputProps={{ "aria-label": "secondary checkbox" }} />} />
        <p>
          <b>Apply Before:</b>
        </p>
      </div>
      {checked ? (
        <div className="hourly-container bid-close">
          <div className="brand-container">
            <div className="bid-closes-container">Bid Closes by</div>
            <div className="input-container">
              <input type="date" name="bidExpiryDate" onChange={handleInputChange} min={todaysDate} />
            </div>
          </div>
        </div>
      ) : (
        <div className="hourly-container bid-close">
          <div className="brand-container">
            <div className="bid-closes-container">Select Date</div>
            <div className="input-container">
              <input type="date" name="applyBefore" onChange={handleInputChange} min={todaysDate} />
            </div>
          </div>
        </div>
      )}
      <div className="btn">
        <button onClick={CreateTask} disabled={loading}>
          {loading ? <img src={Images.ellipsis_loader} alt="" /> : "Create Task"}
        </button>
      </div>
    </div>
  );
}

export default CreateTask;
