import React from 'react';
import ConfirmModal from '../../components/deleteConfirmModal';
import useConfirmationModal from './useConformationModal';

const ConfirmationModalApi = () => {
    const {modal:{open , message , successBtnLabel , cancelBtnLabel}, onSuccess , closeModal , loading}  = useConfirmationModal();
    return   <ConfirmModal
    modal={open}
    yesCallback={onSuccess}
    cancelCallback={closeModal}
    text={message}
    yesBtnLabel={successBtnLabel}
    delBtnLabel={cancelBtnLabel}
    loading={loading}
    />
}
export default ConfirmationModalApi