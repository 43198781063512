import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MyTaskState, Task, API_getTasksParams, TaskStatuses } from "../../../types";
import { getTasks, getBidsOrProposalsDetails, getTaskSummary, getChatModalIForTask, getNegotiationHistory, getBidsForMyTask } from "./myTasksAPI";
import { groupBy } from "lodash";
import { rateConversionUSDBid } from "../../../components/validator";
import { BidsForMyTask } from "../../../types/bids";

const initialState: MyTaskState = {
  loading: false,
  status: "idle",
  tasks: {
    all: [],
    fixed: [],
    hourly: [],
  },
  bids: {
    all: [],
    fixed: [],
    hourly: [],
  },
  taskUIView: {
    section: "created",
    taskStatus: "Pending",
    paymentType: "hourly",
    isTaskDetailsPage: false,
  },
  bidDetails: null,
  taskToPromote: null,
  summaryReport: null,
  taskChatId: null,
  taskToReview: null,
  showNegotiationBox: false,
  timerForTask: null,
};

export const getTasksAsync = createAsyncThunk("getMyTasks", async (params: API_getTasksParams) => {
  const request = await getTasks(params);
  let result = request.data.result;

  return {
    ...params,
    result,
  };
});

export const getBidsForMyTaskAsync = createAsyncThunk("getBidsForMyTasks", async (bidStatus: TaskStatuses) => {
  const request = await getBidsForMyTask(bidStatus);
  return request.data;
});

export const getBidDetailsAsync = createAsyncThunk("getBidsOrProposals", async (taskId: string) => {
  const request = await getBidsOrProposalsDetails(taskId);
  const data = rateConversionUSDBid(request.data.result);
  return data;
});

export const getSummaryReportAsync = createAsyncThunk("getSummary", async ({ bidId, pay }: { bidId: string; pay: boolean }) => {
  const request = await getTaskSummary(bidId, pay);
  return request.data.result;
});

export const getChatIdForTaskAsync = createAsyncThunk("getChatId", async (taskId: string) => {
  const request = await getChatModalIForTask(taskId);
  return request.data.result[0]._id;
});
export const getNegotiationHistoryAsync = createAsyncThunk("getNegotiationData", async (bidId: string) => {
  const request = await getNegotiationHistory(bidId);
  return request.data.result;
});

const myTaskSlice = createSlice({
  name: "my-task",
  initialState,
  reducers: {
    addTask: (state, action) => {
      state.tasks = action.payload;
    },
    updateTaskUIView: (state, action) => {
      state.taskUIView = { ...state.taskUIView, ...action.payload };
    },
    openPromoteTaskModal: (state, action) => {
      state.taskToPromote = action.payload;
    },
    closePromoteTaskModal: (state) => {
      state.taskToPromote = null;
    },
    closeSummaryReportModal: (state) => {
      state.summaryReport = null;
    },
    closeChatModal: (state) => {
      state.taskChatId = null;
    },
    addTaskForReview: (state, action) => {
      state.taskToReview = action.payload;
    },
    removeTaskForReview: (state) => {
      state.taskToReview = null;
    },
    closeNegotiationModal: (state) => {
      state.showNegotiationBox = false;
      state.bidDetails = null;
    },
    openNegotiationModal: (state) => {
      state.showNegotiationBox = true;
    },
    openTimer: (state, action) => {
      state.timerForTask = action.payload;
    },
    closeTimer: (state) => {
      state.timerForTask = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasksAsync.pending, (state) => {
        state.status = "pending";
        state.loading = true;
      })
      .addCase(getTasksAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        if (action.payload?.myTasks) {
          const grouped = groupBy(action.payload.result, (data: Task) => data.paymentTerms.paymentType);
          state.tasks.all = action.payload.result;
          state.tasks.fixed = grouped.fixed ?? [];
          state.tasks.hourly = grouped.hourly ?? [];
        }

        if (action.payload.taskId) {
          state.tasks.singleTask = action.payload.result[0];
        }
      })
      .addCase(getTasksAsync.rejected, (state) => {
        state.status = "rejected";
        // state.loading = false;
      })
      .addCase(getBidDetailsAsync.pending, (state) => {
        state.status = "pending";
        // state.loading = true;
      })
      .addCase(getBidDetailsAsync.rejected, (state) => {
        state.status = "rejected";
        // state.loading = false;
      })
      .addCase(getBidDetailsAsync.fulfilled, (state, action) => {
        state.status = "success";
        // state.loading = false;
        state.bidDetails = action.payload;
      })
      .addCase(getSummaryReportAsync.pending, (state) => {
        state.status = "pending";
        // state.loading = true;
      })
      .addCase(getSummaryReportAsync.rejected, (state) => {
        state.status = "rejected";
        // state.loading = false;
      })
      .addCase(getSummaryReportAsync.fulfilled, (state, action) => {
        state.status = "success";
        // state.loading = false;
        state.summaryReport = action.payload;
      })
      .addCase(getChatIdForTaskAsync.pending, (state) => {
        state.status = "pending";
        // state.loading = true;
      })
      .addCase(getChatIdForTaskAsync.rejected, (state) => {
        state.status = "rejected";
        // state.loading = false;
      })
      .addCase(getChatIdForTaskAsync.fulfilled, (state, action) => {
        state.status = "success";
        // state.loading = false;
        state.taskChatId = action.payload;
      })
      .addCase(getBidsForMyTaskAsync.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getBidsForMyTaskAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(getBidsForMyTaskAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        const grouped = groupBy(action.payload.result, (data: BidsForMyTask) => data.task.paymentTerms?.paymentType);
        console.log(grouped);
        state.bids.all = action.payload.result;
        state.bids.fixed = grouped.fixed ?? [];
        state.bids.hourly = grouped.hourly ?? [];
      });
  },
});

export const { addTask, updateTaskUIView, openPromoteTaskModal, closePromoteTaskModal, closeSummaryReportModal, closeChatModal, addTaskForReview, removeTaskForReview, closeNegotiationModal, openNegotiationModal, openTimer, closeTimer } =
  myTaskSlice.actions;
export default myTaskSlice.reducer;
