import { Fade, makeStyles, Modal, TextField, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Images } from '../../assets/common';
import { MESSAGE } from '../../constants/messages';
import { StyledBackdrop } from '../../lib/validators';
import { CREATE_ISSUES } from '../../services/api-service';
import notifier from '../notifier';
import { getErrorString } from '../validator';
import './index.scss';
const ReportModal = (props) => {
    const { open, onClose } = props;
    const [reportContent, setReportContent] = useState('');
    const [disabled, setDisabled] = useState(false);



    const handleSubmit = async () => {
        setDisabled(true)
        const reqBody = {
            description: reportContent
        }
        try {
            const response = await CREATE_ISSUES(reqBody);
            if (response.data.status === "success") {
                setReportContent('');
                setDisabled(false);
                onClose();
                notifier.success(MESSAGE.SUCCESS.REPORT_GENRATED_SUCESSFULLY);
            }
        }
        catch (e) {
            notifier.error("Error", getErrorString(e));
            setDisabled(false);
        }
    }


    const whileRender = () => {
        //Use Insteed of useEffect;
    }

    const handleClose = () => {
        onClose()
    }



    const classes = Styles();
    return (
        <Modal
            aria-labelledby="Issue Reporting"
            aria-describedby="If you found any issu Report Here"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            onRendered={whileRender}
            BackdropComponent={StyledBackdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className="heding">
                        <h5>Report an Issue or a Suggestion</h5>
                    </div>
                    <div className="body">
                        <div className="form__feild">
                            <TextField
                                variant="outlined"
                                multiline={true}
                                rows={8}
                                placeholder="Write Here"
                                name="reportContent"
                                fullWidth
                                value={reportContent}
                                onChange={(e) => setReportContent(e.target.value)}
                                disabled={disabled}
                            />
                        </div>
                        <div className="actionButtons">
                            <button onClick={handleClose}>Cancel</button>
                            <button disabled={disabled} onClick={handleSubmit}>
                                {disabled ? (<img alt="" src={Images.ellipsis_loader} />) : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

const Styles = makeStyles({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        minHeight: 200,
        width: 500,
        backgroundColor: "#ffffff",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: 5,
        position: "relative",
        padding: '20px 25px',
        '& .heding': {
            marginBottom: 25,
            '& h5': {
                marginBottom: 0,
                fontSize: 17,
                color: '#2c2c2c',
                fontWeight: 500
            }
        },
        '& .body': {
            '& .form__feild': {
                marginBottom: 50,
                '& textarea': {
                    fontSize: 13,
                    lineHeight: 1.5,
                }
            },
            '& .actionButtons': {
                display: 'flex',
                justifyContent: 'center',
                '& button': {
                    width: 126,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 13,
                    fontWeight: 500,
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: 3,
                    outline: 'none!important',
                    '&:first-child': {
                        border: '1px solid red',
                        color: 'red',
                        marginRight: 8,
                    },
                    '&:last-child': {
                        backgroundColor: '#6dc7be',
                        color: '#ffffff',
                        marginLeft: 8,
                        '&:hover': {
                            backgroundColor: '#4daca2'
                        }
                    }
                }
            }
        }
    }
})



export default ReportModal