import { makeStyles } from "@material-ui/core";

export const WalletStyles = makeStyles({
  walletPage: {
    backgroundColor: "#ffffff",
    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    minHeight: "calc(100vh - 133px - 18px)",
    "& .walletNotLinked": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    "& .walletPage__content": {
      textAlign: "center",
      marginTop: 190,
      "&__icon": {
        marginBottom: 10,
        textAlign: "center",
        "& img": {
          width: 132,
          height: 122,
          margin: "0 auto",
        },
      },
      "& p": {
        fontSize: 16,
        color: "#2C2C2C",
        margin: "18px 0",
      },
      "&__button": {
        marginTop: 18,
        display: "flex",
        flexWrap: "wrap",
        "& button": {
          width: 230,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ffffff",
          backgroundColor: "#6DC7BE",
          border: "none",
          outline: "none",
          transition: "0.3s all",
          borderRadius: 3,
          margin: "0 7.5px",
          "& img": {
            marginRight: "10px",
          },
          "&:hover": {
            backgroundColor: "#4DACA2",
          },
        },
      },
    },
    "& .fullscreenForm": {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#FAFAFA",
      transition: "0.3s all",
      zIndex: "1100",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        height: 162,
        backgroundColor: "#4DACA2",
        width: "100%",
        zIndex: -1,
      },
      "&__logo": {
        maxWidth: 1140,
        margin: "0 auto",
        padding: "16px 0px",
      },
      "&__form": {
        minHeight: "calc(100vh - 70px - 68px)",
        width: 570,
        margin: "0 auto",
        marginTop: 5,
        boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.16)",
        backgroundColor: "#ffffff",
        padding: "15px 60px 20px 60px",
        "&--heading": {
          fontSize: 36,
          color: "#2C2C2C",
          marginBottom: 15,
        },
        "&--slogan": {
          fontSize: 23,
          fontWeight: 400,
          color: "#2C2C2C",
          marginBottom: 15,
        },
        "&--logo": {
          marginBottom: 10,
          textAlign: "center",
          "& img": {
            width: "auto",
            height: 75,
            margin: "0 auto",
          },
        },
        "& .form__control": {
          marginTop: 15,
        },
        "& .inst": {
          fontSize: 13,
          fontStyle: "italic",
          color: "#656565",
          backgroundColor: "#F7F7F7",
          borderRadius: 10,
          padding: "10px 15px",
          marginTop: 8,
        },
        "&--btn": {
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 20,
          "& button": {
            width: 148,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#6DC7BE",
            color: "#ffffff",
            border: "none",
            outline: "none!important",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#4DACA2",
            },
          },
        },
        "&--inst": {
          textAlign: "center",
          position: "relative",
          "&>span": {
            display: "inline-block",
          },
          "& p": {
            fontSize: 15,
            color: "#2c2c2c",
            textAlign: "center",
            marginBottom: 0,
          },
          "& .link": {
            "& a": {
              color: "#4DACA2",
              fontSize: 15,
              fontWeight: 600,
            },
          },
          "& .toolTipBox": {
            display: "inline-block",
            position: "relative",
            marginLeft: 8,
            cursor: "pointer",
            "&:hover .toolTipText": {
              visibility: "visible",
            },
          },
          "& .toolTipText": {
            position: "absolute",
            backgroundColor: "#2C2C2C",
            padding: "5px 15px",
            right: -20,
            bottom: "125%",
            minWidth: 225,
            borderRadius: 3,
            color: "#ffffff",
            fontSize: 12,
            visibility: "hidden",
          },
        },
      },
    },
    "& .wallet": {
      "& .help_required": {
        marginLeft: "auto",
      },
      "&__heading": {
        borderBottom: "1px solid #dddddd",
        height: 54,
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        fontWeight: 500,

        "&--left": {
          color: "#2c2c2c",
          fontSize: 16,
          textTransform: "uppercase",
        },
        "& .connect_hashpack_button": {
          width: 230,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ffffff",
          backgroundColor: "#6DC7BE",
          border: "none",
          outline: "none",
          transition: "0.3s all",
          borderRadius: 3,
          marginLeft: "auto",
          "& img": {
            marginRight: "10px",
          },
          "&:hover": {
            backgroundColor: "#4DACA2",
          },
        },
        "&--right": {
          fontSize: 13,
          marginLeft: "15px",
          textAlign: "right",
          color: "#2c2c2c",
          "& span:first-child": {
            color: "#656565",
            display: "block",
          },
        },
      },
      "&__body": {
        padding: "15px 24px",
        paddingTop: 0,
        "&__acBalance": {
          width: 340,
          minHeight: 240,
          borderRadius: 5,
          border: "1px solid #EAEAEA",
          padding: "20px 24px",
          backgroundColor: "#ffffff",
          textAlign: "center",
          "&--label": {
            marginTop: 25,
            fontSize: 16,
            fontWeight: 600,
            color: "#656565",
            textTransform: "uppercase",
          },
          "&--balance": {
            marginTop: 30,
            color: "#2C2C2C",
            fontSize: 24,
            fontWeight: 600,
            textTransform: "uppercase",
          },
          "&--Add_fund": {
            marginTop: 50,
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#6DC7BE",
            color: "#ffffff",
            border: "none",
            outline: "none!important",
            borderRadius: 3,
          },
        },
      },
    },
  },
});
