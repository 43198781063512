import { Fade, makeStyles, Modal, TextField, useTheme, useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import { useHashConnect } from "../../Hooks";
import { StyledBackdrop } from "../../lib/validators";
import { GET_FEES_FOR_TASK, LINK_WALLET_ID, REQ_SIGN_TRANSACTION } from "../../services/api-service";
import notifier from "../notifier";
import { getErrorString } from "../validator";
import "./index.scss";

const QrCodeModal = (props) => {
  const theme = useTheme();
  const isDeviceIsSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { taskDetails } = props;
  const classes = QrcodeModalStyles();
  const [taskFees, setFeesPrice] = useState([]);
  const [amount, setAmount] = useState(0);
  const {
    connect,
    installedExtensions,
    walletData: { accountIds },
    loading,
    transactionResponse,
    acknowledge,
    resetAcknowledge,
    resetTransactionResponse,
    handleTransaction: transferHbar,
  } = useHashConnect();

  useEffect(() => {
    if (taskDetails && Object.keys(taskDetails).length > 0) {
      getSummary(taskDetails.finalBid);
    }
  }, [taskDetails]);

  useEffect(() => {
    if (acknowledge) notifier.warning("Wallet Connected", "You can pay now!", 5000);
    if (resetAcknowledge) resetAcknowledge();
  }, [acknowledge]);

  useEffect(() => {
    if (transactionResponse && transactionResponse?.success) {
      notifier.success("Transaction successful", "Hbar transferred to escrow account.", 3000);
    }
    if (transactionResponse && transactionResponse?.error) {
      notifier.error("Error while transaction", transactionResponse.error, 3000);
    }
    if (resetTransactionResponse) resetTransactionResponse();
    handleClose();
  }, [transactionResponse]);

  const handleClose = () => {
    props.onClose();
  };

  const getSummary = async (finalBid) => {
    try {
      const bidId = finalBid;
      const pay = true;
      const response = await GET_FEES_FOR_TASK(bidId, pay);
      if (response.data.status === "success") {
        const taskFees = response.data.result;
        setFeesPrice(taskFees);
      }
    } catch (error) {
      notifier.error("Error", getErrorString(error));
    }
  };

  const connectHashpack = async () => {
    try {
      if (isDeviceIsSm) {
        return alert("Please connect with HashPack extension on your desktop to make a payment");
      }
      if (installedExtensions) {
        connect();
      } else {
        await sendMarkOFwalletInstall();
        // Taskbar Alert - Hashpack browser extension not installed, please click on <Go> to visit HashPack website and install their wallet on your browser
        alert(
          "Alert - HashPack browser extension not installed, please click on <<OK>> to visit HashPack website and install their wallet on your browser.  Once installed you might need to restart your browser for Taskbar to detect wallet extension first time."
        );
        window.open("https://www.hashpack.app");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendMarkOFwalletInstall = async () => {
    try {
      const requestBody = {
        isClickedOnWalletLink: true,
      };
      await LINK_WALLET_ID(requestBody);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTransaction = async ({ walletId, memo, amount }) => {
    console.log(walletId, memo, amount);
    try {
      const reqBody = {
        walletId,
        memo,
        amount,
      };
      const response = await REQ_SIGN_TRANSACTION(reqBody);
      console.log(response.data.result.transactionSign);
      setAmount(0);
      // let transactionBytes = response.data.result.transactionSign.toBytes();
      transferHbar(response.data.result.transactionSign, accountIds[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      aria-labelledby="Add Hbars In Wallet"
      aria-describedby="hbars in Escrow Account"
      className={`${classes.modal} addFundModal`}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        {taskDetails && Object.keys(taskDetails).length > 0 ? (
          <div className={`${classes.paper} addFundModal__paper`}>
            <div className="qrcodebox">
              <div className="qrcodebox__content">
                <h5>Add Fund to Escrow Account</h5>
                <TextField label="Amount in Hbar" type={"number"} fullWidth variant="outlined" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter amount(ℏ) that you wanted to add." />
                <p className="qrcodebox__content--inst">In order to add fund in your excrow account, Please scan this QR code and add hbars (&#8463;).</p>
              </div>
              <div className="qrcodebox__code">
                <div className="qrcodebox__content__QRBox">
                  <span>{isEmpty(accountIds) ? "Pay with hashpack wallet" : `You are connected with ${accountIds[0]}`}</span>
                  {isEmpty(accountIds) ? (
                    <button onClick={connectHashpack} className="connect_hashpack_button" style={{ margin: "0 auto" }}>
                      <img src={Images.hashPackIcon} alt="Hahconnect" />
                      {loading ? "Connecting...." : "Connect & pay"}
                    </button>
                  ) : (
                    <button disabled={!amount ? true : false} className="connect_hashpack_button" onClick={() => handleTransaction({ walletId: accountIds[0], memo: taskFees.memoCode, amount: parseFloat(amount) })} style={{ margin: "0 auto" }}>
                      <img src={Images.hashPackIcon} alt="Hahconnect" />
                      Pay with hashpack
                    </button>
                  )}
                  <div className="orDevider">Or</div>
                </div>
                <h5 style={{ textAlign: "center", textTransform: "uppercase", fontSize: 17, marginBottom: 10, marginTop: 10 }}>Pay Manually</h5>
                <div className="qrcodebox__content--EscrowDetails">
                  <span>Escrow Account Number:</span>
                  <span> {taskFees.accountId}</span>
                  <span className="border"></span>
                  <span>Task Memo Code:</span>
                  <span>{taskFees.memoCode}</span>
                </div>
                <div className="hintCode">
                  <span>Enter this memo in order to let taskbar recognize the task against which it is paid.</span>
                </div>
              </div>
              <span className="closeModal" onClick={handleClose}>
                <img src={Images.IconForCloseModal} alt="" />
              </span>
            </div>
          </div>
        ) : null}
      </Fade>
    </Modal>
  );
};

const QrcodeModalStyles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    // minHeight: 500,
    width: 1000,
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 6,

    "& .qrcodebox": {
      width: "100%",
      height: "100%",
      display: "flex",
      // alignItems:'center',
      // justifyContent:'center',
      position: "relative",
      "&__content": {
        // width:284,
        width: "55%",
        borderRight: "0.5px solid #dddddd",
        paddingLeft: 45,
        paddingRight: 45,
        "&--inst": {
          fontSize: 14,
          marginTop: 130,
          width: "60%",
          color: "#2C2C2C",
          marginBottom: 30,
          // textAlign:'center'
        },
        "& h5": {
          marginTop: 45,
          fontSize: 16,
          color: "#2C2C2C",
        },
        "&__QRBox": {
          textAlign: "center",
          marginBottom: 0,
          "& canvas": {
            padding: 10,
            borderRadius: 3,
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          },
          "& span": {
            fontSize: 13,
            color: "#2c2c2c",
            fontWeight: 700,
            display: "block",
            marginBottom: 15,
          },
          "& img": {
            marginRight: "10px",
            height: "24px",
            width: "auto",
          },
          "& .orDevider": {
            position: "relative",
            margin: "15px 0 15px 0",
            "&:after": {
              content: '""',
              position: "absolute",
              width: "30%",
              height: 1,
              backgroundColor: "#656565",
              top: "calc(50% - .5px)",
              left: "calc(20% - 20px )",
            },
            "&:before": {
              content: '""',
              position: "absolute",
              width: "30%",
              height: 1,
              backgroundColor: "#656565",
              top: "calc(50% - .5px)",
              right: "calc(20% - 20px )",
            },
          },
        },
        "&--EscrowDetails": {
          width: 190,
          height: "auto",
          textAlign: "center",
          fontSize: 13,
          color: "#2C2C2C",
          // padding:'12px',
          margin: "0  auto",
          background: "#F7F7F7",
          padding: 5,
          border: "1px solid #EBEBEB",
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          "& span": {
            "&:last-child ,  &:nth-child(2)": {
              fontSize: 17,
              fontWeight: 500,
            },
            "&.border": {
              height: 2,
              background: "#2c2c2c",
            },
          },
        },
      },
      "&__code": {
        width: "45%",
        borderLeft: "0.5px solid #dddddd",
        paddingTop: 15,
        paddingBottom: 10,
      },
      "& .closeModal": {
        position: "absolute",
        width: 36,
        height: 36,
        top: 20,
        right: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 3,
        cursor: "pointer",
        "& img": {
          width: 14,
          height: 14,
        },
        "&:hover": {
          backgroundColor: "#F0F0F0",
        },
      },
    },
    "& .footer": {
      borderRadius: 6,
      display: "flex",
      // alignItems:'center',
      backgroundColor: "#F05A22",
      height: 65,
      "&__left": {
        color: "white",
        paddingTop: 5,
        paddingLeft: 45,
        "& p": {
          fontSize: 15,
          fontWeight: 600,
          marginBottom: 5,
        },
        "& span": {
          fontSize: 13,
          color: "#ffffff",
        },
      },
      "&__right": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40%",
        fontSize: 26,
        color: "#ffffff",
        letterSpacing: 11.2,
        marginLeft: "auto",
      },
    },
    "& .hintCode": { fontSize: 13, padding: "8px 20px", textAlign: "center" },
  },
});

export default QrCodeModal;
