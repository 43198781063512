import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Images} from "../../../assets/common";
import Hide from "../../../components/Hide";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import {BreakPoints} from "../../../lib/validators";
import {GET_LEADERBOARD} from "../../../services/api-service";
import "./index.scss";

const Leaderboard = () => {
  const [userPosition, setUserPosition] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [leaderBoardList, setLeaderBoardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handlePageChange = (pageNo) => {
    loadLeaderboardListData(pageNo);
  };

  useEffect(() => {
    loadLeaderboardListData();
  }, []);

  const loadLeaderboardListData = async (pageNo) => {
    //Seting Loading Open
    setIsLoading(true);

    //Req Body oBject
    const requestBody = {
      pageNo: pageNo ? pageNo : 1,
      userId: localStorage.getItem("currentUser"),
    };

    //Sending Api request
    const response = await GET_LEADERBOARD(requestBody);
    const leaderBoardData = response.data.result;
    //console.log('Leaderboard data', leaderBoardData);
    //Leader board List Data
    setLeaderBoardList(leaderBoardData.leaderboard);

    //Seting Current user position in leaderboard
    leaderBoardData.leaderboard.map((user) => {
      if (user._id === localStorage.getItem("currentUser"))
        setUserPosition(user.ranking);
      return user;
    });
    setTotalRecord(leaderBoardData.totalUsers);
    //Update Current Page State
    setCurrentPage(pageNo ? pageNo : 1);

    //Close Loading
    setIsLoading(false);
  };

  return (
    <div className="leaderBoard">
      <div className="leaderBoard__heading">
        <h2>Taskers Ranking</h2>
        {!userPosition ? null : (
          <div className="leaderBoard__yourPosition">
            <img src={Images.yourPositionIcon} alt="" /> Your Position :{" "}
            <span>{userPosition}</span>
          </div>
        )}
        <div className="how">
          <Tooltip
            arrow
            title="Your position is determined by the amount of Taskbar points you have accumulated. Help the community to grow Taskbar and earn Taskbar points in the way! To learn how to earn Taskbar points please read website terms and conditions."
            placement="right">
            <span>How does it work ?</span>
          </Tooltip>
        </div>
      </div>
      <Hide type="down" size={BreakPoints.sm}>
        <TableContainer className="leaderBoard__table">
          {!isLoading && setLeaderBoardList.length > 0 ? (
            <Table stickyHeader size="small" aria-label="leaderboard Table">
              <colgroup>
                <col width="100px" />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Position</StyledTableCell>
                  <StyledTableCell>Taskers</StyledTableCell>
                  <StyledTableCell align="center">
                    Points Earned
                  </StyledTableCell>
                  
                  <StyledTableCell>Badges Earned</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaderBoardList.map((user, index) => {
                  return (
                    <StyledTableRow
                      key={`leaderBoardItem${index}`}
                      className={
                        user._id === localStorage.getItem("currentUser")
                          ? "highlight"
                          : ""
                      }>
                      <StyledTableCell>
                        {user.ranking < 10 ? `0${user.ranking}` : user.ranking}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="profile">
                          <Link to={`/${user.nickName}`}>
                            <span
                              className="profileIconIncell"
                              style={{
                                backgroundImage: `url('${user.profileImgUrl}')`,
                              }}>
                              {user.availability ? (
                                <span className="status green"></span>
                              ) : (
                                <span className="status red"></span>
                              )}
                            </span>
                          </Link>
                          <span>
                            <Link to={`/${user.nickName}`}>
                              @{user.nickName}
                            </Link>
                            <br />
                            {user.fnameVisibilty
                              ? `${
                                  user.firstName ? user.firstName : "--NA--"
                                } ${user.lastName ? user.lastName : "--NA--"}`
                              : ""}
                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {user.pointsEarned} Points
                      </StyledTableCell>
                     
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Loader />
          )}
        </TableContainer>
      </Hide>
      <Hide type="up" size={BreakPoints.sm}>
        <div className="leaderboardMobile">
          {leaderBoardList.map((user, index) => {
            return (
              <div
                key={`leaderBoardItem${index}`}
                className={
                  user._id === localStorage.getItem("currentUser")
                    ? "leaderboardMobile__item highlight"
                    : "leaderboardMobile__item"
                }>
                <p>
                  <span className="left">Position</span>
                  <span className="right">
                    {user.ranking < 10 ? `0${user.ranking}` : user.ranking}
                  </span>
                </p>
                <p>
                  <span className="left">Taskers</span>
                  <span className="right">
                    <span className="profile">
                      <span
                        className="profileIconIncell"
                        style={{
                          backgroundImage: `url('${user.profileImgUrl}')`,
                        }}>
                        {user.availability ? (
                          <span className="status green"></span>
                        ) : (
                          <span className="status red"></span>
                        )}
                      </span>
                      <span>
                      <Link to={`/${user.nickName}`}>@{user.nickName}</Link>
                        <br />
                        {user.fnameVisibilty
                          ? `${user.firstName ? user.firstName : "--NA--"} ${
                              user.lastName ? user.lastName : "--NA--"
                            }`
                          : ""}
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span className="left">Points Earned</span>
                  <span className="right">{user.pointsEarned} Points</span>
                </p>
                
                <p>
                  <span className="left">Badges Earned</span>
                  <span className="right"></span>
                </p>
              </div>
            );
          })}
        </div>
      </Hide>
      <div className="leaderBoard__pagination">
        <Hide type="down" size={BreakPoints.sm}>
          <Pagination
            neighbours={3}
            totalRecord={totalRecord}
            pageNumber={currentPage}
            sendCurrentPage={handlePageChange}
            showControls={true}
            showJump={true}
          />
        </Hide>
        <Hide type="up" size={BreakPoints.sm}>
          <Pagination
            neighbours={3}
            totalRecord={totalRecord}
            pageNumber={currentPage}
            sendCurrentPage={handlePageChange}
            showControls={true}
          />
        </Hide>
      </div>
    </div>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "none",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
    "&:hover": {
      backgroundColor: "#FfFFFf",
    },
    "&.highlight": {
      backgroundColor: "#FFF6DD",
      "& td": {
        borderStyle: "dashed",
        borderColor: "#FEC21C",
        "&:not(:first-child)": {
          borderLeft: "none!important",
        },
        "&:not(:last-child)": {
          borderRight: "none!important",
        },
      },
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    border: "none",
    "&:nth-child(1),&:nth-child(2)": {
      textAlign: "center",
    },
  },
  head: {
    paddingTop: 12,
    paddingBottom: 12,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff",
    border: "none",
    textTransform: "uppercase",
  },
  body: {
    "&:nth-child(2)": {
      textAlign: "left",
    },
  },
  stickyHeader: {
    zIndex: "auto",
  },
}))(TableCell);

export default Leaderboard;
