import React, { Component } from 'react';
import { Switch } from "react-router-dom";
import PrivateRoute from '../../../components/privateRoute';
import About from './../about-info';
import NewSuperSkill from './../add-superskill';
import EditSuperSkill from './../edit-superskill';
import MysuperSkills from './../my-superskills';




class RouterOutlet extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <PrivateRoute path={`${match.url}/create-superskill`} component={NewSuperSkill} />
        <PrivateRoute path={`${match.url}/edit-superskill`} component={EditSuperSkill} />
        <PrivateRoute path={`${match.url}/about`} component={About} />
        <PrivateRoute exact path={`${match.url}/`} component={MysuperSkills} />
        {/* Comment it out if Auto redirecttion is not required  */}
        {/* <Redirect from='*' to={`${match.url}/dashboard`} /> */}

      </Switch>
    );
  }
}

export default RouterOutlet;
