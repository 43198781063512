import { Fade, IconButton, InputAdornment, makeStyles, Modal, TextField, Tooltip, withStyles, Popover, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Images } from "../../assets/common";
import notifier from "../../components/notifier";
import { getErrorString } from "../validator";
import { StyledBackdrop } from "../../lib/validators";
import { GET_NEGOTIATION_HISTORY, SEND_NEGOTIATION_HISTORY } from "../../services/api-service";
import { isEmpty } from "lodash";

import "./index.scss";
import { useAuth } from "../../Hooks/useAuth";

const NegotiationModal = (props) => {
  const classes = negotiationModalStyles();
  const { currentUser } = useAuth();
  const [negotiationData, setNegotiationData] = useState([]);
  const { sender, openSummaryPopup, bidDetails, getNegotiationHistory, hireNow } = props;
  const [rate, setRate] = useState("");
  const [weekLimit, setWeekLimit] = useState("");
  const [description, setDescription] = useState("");
  const [bidId, setBidId] = useState("");
  // const [negotiation, setNegotiationData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentType, setPaymentType] = useState("hourly");
  const [oppositeProfile, setOppositeProfile] = useState(null);

  useEffect(() => {
    if (negotiationData.length > 0) {
      const lastData = negotiationData.slice(-1)[0];
      setWeekLimit(lastData.weeklyLimit);
      setRate(lastData.amount);
      setBidId(lastData.bid);
      setNegotiationData(negotiationData);
    }
  }, [negotiationData]);

  const setRateTime = async () => {
    await getNegotiationData(bidDetails._id);
    setOppositeProfile(currentUser._id === bidDetails.bidder._id ? bidDetails.task.author : bidDetails.bidder);
    setPaymentType(bidDetails.task.paymentTerms.paymentType);
  };
  //Close Model
  const handleClose = () => {
    props.onClose();
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    switch (id) {
      case "rate":
        setRate(value);
        break;
      case "weekLimit":
        setWeekLimit(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const sendNegotiation = async () => {
    const ld = negotiationData[negotiationData.length - 1];
    try {
      const requestBody = {
        bidId: bidDetails._id, // ID of the bid on which negotiation is happening.
        neederId: bidDetails.task.author._id, // ID of the Task Author
        taskerId: bidDetails.bidder._id, // Tasker ID
        taskId: bidDetails.task._id, // ID of Task.
        amount: !parseFloat(rate) ? ld.amount : rate,
        weeklyLimit: !parseInt(weekLimit) ? ld.weeklyLimit : weekLimit,
        proposalDescription: isEmpty(description) ? "New Price" : description,
        sender: sender, // `needer` or `tasker`
      };
      const response = await SEND_NEGOTIATION_HISTORY(requestBody);
      if (response.data.status === "success") {
        getNegotiationData();
        setRate("");
        setWeekLimit("");
        setDescription("");
        // getNegotiationData();
      }
    } catch (e) {
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const getNegotiationData = async (id) => {
    try {
      const reqBody = {
        bidId: id ?? bidId,
      };
      const response = await GET_NEGOTIATION_HISTORY(reqBody);
      let data = response.data.result;
      setNegotiationData(response.data.result);
      setDescription("");
      setWeekLimit(data[data.length - 1].weeklyLimit);
      setRate(data[data.length - 1].amount);
    } catch (e) {
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const openPriceField = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closePriceField = (e) => {
    setAnchorEl(null);
  };
  //PopoverValues
  const priceFieldOpen = Boolean(anchorEl);
  const priceFieldId = priceFieldOpen ? "simple-popover" : undefined;

  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={`${classes.modal} negotiationModal`}
      open={props.open}
      onClose={handleClose}
      onRendered={setRateTime}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {/* {loading ? <Loader /> : */}
      <Fade in={props.open}>
        <div className={`${classes.paper} negotiationModal__paper`}>
          <div className={`${classes.negotiation} negotiationModal__box`}>
            <div className="heading">
              <h4 className="heading__label">
                <img src={Images.negotiationIcon} alt="" /> Negotiation History
              </h4>
              <span className="closeBtn" onClick={handleClose}>
                <img src={Images.IconForCloseModal} alt="" />
              </span>
            </div>
            {oppositeProfile && (
              <div className="profileDes">
                <div className="profileIcon">
                  <img src={oppositeProfile.profileImgUrl} alt="" />
                  <span className="box active "></span>
                </div>
                <span className="name">{`${oppositeProfile.firstName} ${oppositeProfile.lastName}`}</span>
                <div className="plus" style={{ marginLeft: "auto" }}>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    variant="contained"
                    disableElevation
                    onClick={openPriceField}
                    aria-describedby={priceFieldId}
                    style={{
                      backgroundColor: "rgba(70, 185, 173,0.16)",
                      color: "#46b9ad",
                    }}
                  >
                    {rate} {paymentType === "fixed" ? "ℏ(s)" : "ℏ(s)/hr"}
                  </Button>
                  <Popover
                    Popover
                    id={priceFieldId}
                    open={priceFieldOpen}
                    anchorEl={anchorEl}
                    onClose={closePriceField}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      elevation: 2,
                    }}
                  >
                    <div className="pricePopup">
                      <h4>Enter new offer</h4>
                      <div className="priceField">
                        <div className="input_control">
                          <TextField
                            type="number"
                            label=""
                            className="input"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">&#8463;</InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                            name={"rate"}
                            value={rate}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="input_control">
                          <TextField
                            type="number"
                            label=""
                            className="input"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{paymentType === "hourly" ? "hrs" : "Days"}</InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                            name={"weekLimit"}
                            value={weekLimit}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="send">
                        <Button
                          color="primary"
                          size="small"
                          endIcon={<SendIcon />}
                          onClick={sendNegotiation}
                          style={{
                            backgroundColor: "#46b9ad",
                            color: "#fff",
                          }}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            )}
            <ChatBox negotiationData={negotiationData} paymentType={paymentType} sender={sender} openSummaryPopup={openSummaryPopup} hireNow={hireNow} loading={props.loading} />
            <div className="chatReply__new">
              <div className="textArea">
                <textarea rows="2" name={"description"} value={description} onChange={handleInputChange}></textarea>
              </div>
              <div className="send">
                <Button color="primary" endIcon={<SendIcon />} size="large" onClick={sendNegotiation}>
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const ChatBox = (props) => {
  const chatBox = useRef();
  const { sender, paymentType, negotiationData, hireNow, loading } = props;

  //const [ , setTaskersOffers] = useState([]);

  //set Scroll to down
  useEffect(() => {
    if (chatBox) {
      chatBox.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
    chatBox.current.scroll({
      top: chatBox.current.scrollHeight,
    });
  }, []);

  return (
    <div className="chatBox" ref={chatBox}>
      <div className="chatBox__conversation">
        {negotiationData.map((data, index) => {
          return (
            <div className={sender === data.sender ? `conversation__item conversation__item--right` : `conversation__item conversation__item--left`} key={index}>
              <div>
                <div className="conversation__item__content">
                  <div>
                    <p>{data.proposalDescription}</p>
                    {sender === "needer" && data.sender === "tasker" && index + 1 === negotiationData.length ? (
                      <Tooltip title="Hire With This Offer" placement="top" arrow>
                        <div className="hireTick tooltipBox">
                          {loading ? (
                            <div className="circle-loader">
                              <img alt="" src={Images.ellipsis_loader} />
                            </div>
                          ) : (
                            <img src={Images.redTick} onClick={() => hireNow(data)} alt="Hire now" />
                          )}
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="meta">
                    <span>
                      <b>{data.amount}</b> {paymentType === "hourly" ? "ℏ(s)/hrs" : "ℏ(s)"}
                    </span>
                    <span>
                      <b>{data.weeklyLimit}</b>
                      {paymentType === "hourly" ? "(hrs/week)" : "Days"}
                    </span>
                  </div>
                </div>
                <div className="conversation__item__date">
                  <span>
                    <img src={Images.calenderIcon} alt="" />
                    <Moment format="DD-MM-YYYY">{data.createdAt}</Moment>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const negotiationModalStyles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  paper: {
    height: "calc(100vh - 75px - 30px)",
    width: 952,
    backgroundColor: "#ffffff",
    marginTop: 75,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
  },
  negotiation: {
    height: "100%",
    "& .heading": {
      padding: "16px 20px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&__label": {
        fontSize: 16,
        color: "rgba(44, 44, 44, 1)",
        textTransform: "uppercase",
        fontWeight: 600,
        "& img": {
          width: 26,
          marginRight: 12,
        },
      },
      "& .closeBtn": {
        marginLeft: "auto",
        cursor: "pointer",
        "& img": {
          width: 14,
        },
      },
    },
    "& .profileDes": {
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      borderBottom: "1px solid #dddddd",
      boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.08)",
      backgroundColor: "white",
      marginBottom: "10px",
      "& .profileIcon": {
        width: 40,
        marginRight: 18,
        borderRadius: 3,
        position: "relative",
        "& img": {
          width: 40,
          height: 40,
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: 3,
        },
        "& .box": {
          position: "absolute",
          bottom: 0,
          right: 0,
          width: 10,
          height: 10,
          borderRadius: 3,
          "&.active": {
            backgroundColor: "#1bc11b",
          },
          "&.inactive": {
            backgroundColor: "#ED0B0B",
          },
        },
      },
      "& .name": {
        fontSize: 14,
        color: "rgba(101, 101, 101, 1)",
        fontWeight: 500,
      },
    },
    "& .chatReply": {
      display: "flex",
      alignItems: "center",
      padding: "19px 20px 10px 20px",
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: "0 0 5px 5px",
      "& .input_control": {
        width: 120,
        marginRight: 10,
        "& label, & input , & span , & p": {
          fontSize: 13,
          lineHeight: "17px",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
          paddingTop: 13.5,
          paddingBottom: 13.5,
        },
        "& .Mui-focused": {
          backgroundColor: "#FFFFFF",
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          " -webkit-appearance": "none",
        },
        "& input[type=number]": {
          " -moz-appearance": "textfield",
        },
        "&:nth-child(3)": {
          width: "auto",
          maxWidth: "100%",
          flexBasis: 0,
          flexGrow: 1,
        },
      },
      "& .send_btn": {
        width: 105,
        "& button": {
          width: "100%",
          height: 40,
          backgroundColor: "#6DC7BE",
          borderRadius: 3,
          color: "#ffffff",
          border: "none!important",
          outline: "none!important",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    "& .chatBox": {
      height: "calc(100% - 197px)",
      overflowY: "auto",
      padding: "0 20px 60px 20px",
      position: "relative",
      "-webkit-user-select": "none",
      " -moz-user-select": "none",
      " -ms-user-select": "none",
      "&::-webkit-scrollbar": {
        width: 5,
        "&-track": {
          background: "#DDDDDD",
        },
        "&-thumb": {
          backgroundColor: "#6DC7BE",
          borderRadius: 10,
        },
      },
      "&__conversation": {
        minHeight: "100%",
        display: "flex",
        flexFlow: "column",
        "& .conversation__item": {
          display: "flex",
          marginTop: 5,
          marginBottom: 5,
          "&--right": {
            justifyContent: "flex-end",
            "& .conversation__item__content": {
              "&>div": {
                flexDirection: "row-reverse",
                "& p": {
                  paddingLeft: 10,
                },
              },
              "& .meta": {
                justifyContent: "flex-satrt",
                "& span": {
                  marginRight: 10,
                },
              },
            },
            "& .conversation__item__date": {
              justifyContent: "flex-start",
            },
          },
          "&--left": {
            justifyContent: "flex-start",
            "& .conversation__item__content .meta": {
              justifyContent: "flex-start",
              "& span": {
                marginRight: 10,
              },
            },
            "& .conversation__item__date": {
              justifyContent: "flex-end",
            },
          },
          "&__content": {
            width: 350,
            padding: 10,
            backgroundColor: "#FAFAFA",
            borderRadius: 3,
            "&>div": {
              display: "flex",
              "& .hireTick": {
                width: 25,
                position: "relative",
                cursor: "pointer",
                "& .circle-loader": {
                  // position:'relative',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "25px",
                  height: "25px",
                  background: "#e8592a",
                  borderRadius: "50%",
                  "& img": {
                    width: "20px",
                  },
                },
                "& img": {
                  width: 25,
                  height: "auto",
                },
                "& span": {
                  position: "absolute",
                  display: "inline-block",
                  width: "max-content",
                  fontSize: 13,
                  color: "#ffffff",
                  backgroundColor: "#2c2c2c",
                  borderRadius: 3,
                  top: -30,
                  padding: "3px 10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  visibility: "hidden",
                },
                "&:hover span": {
                  visibility: "visible",
                },
              },
            },
            "& p": {
              color: "#656565",
              fontSize: 13,
              fontWeight: 400,
              flexGrow: 1,
              flexBasis: 0,
            },
            "& .meta": {
              display: "flex",
              "& span": {
                border: "1px solid #DDDDDD",
                borderRadius: 20,
                display: "inline-block",
                backgroundColor: "#ffffff",
                color: "#2c2c2c",
                fontSize: 13,
                padding: "3px 15px",
              },
            },
          },
          "&__date": {
            display: "flex",
            padding: " 0 5px",
            "& span": {
              display: "inline-block",
              fontSize: 12,
              color: "#656565",
            },
          },
        },
      },
    },
  },
});

export default NegotiationModal;
