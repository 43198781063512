import {
  Fade,
  makeStyles,
  Modal,
  TextField
} from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { Images } from '../../assets/common';
import { MESSAGE } from '../../constants/messages';
import { StyledBackdrop } from "../../lib/validators";
import { CREATE_REVIEW } from "../../services/api-service";
import notifier from '../notifier';
import {
  getErrorString
} from "../validator";

const CreateReviewPopupModal = (props) => {
  const classes = Styles();
  const [loading, setLoader] = useState(false);
  const [ratingVal, setRatingVal] = useState(3);
  const [comment, setComment] = useState("");

  useEffect(() => {

  }, [])

  const handleClose = () => {
    props.onClose()
  }

  const handleCommentChange = (event) => {
    const { value } = event.target;
    setComment(value);
  }

  const handleStarClick = (starVal, event) => {
    setRatingVal(starVal);
  }

  const createReview = async () => {
    let a = {
      "paymentTerms": {
        "fixed": {
          "rate": 50,
          "taskDeadline": "2020-12-24T00:00:00.000Z",
          "applyBefore": "1-3m"
        },
        "biddingEnabled": true,
        "paymentType": "fixed",
        "bidExpiryDate": "2021-12-23T18:30:00.000Z"
      },
      "amountFixed": {
        "taskPrice": 200,
        "taskbarFees": 20,
        "taskbarFeesType": "normal",
        "totalAmount": 220
      },
      "approvals": {
        "approveTaskComplete": {
          "needer": false,
          "tasker": false
        },
        "endContract": {
          "needer": true,
          "tasker": false
        }
      },
      "skills": [
        {
          "_id": "5ffea025344b452f38d3693d",
          "name": "Web Design"
        },
        {
          "_id": "5ffea025344b452f38d3693e",
          "name": "Logo Design"
        },
        {
          "_id": "5ffea025344b452f38d3693f",
          "name": "Graphic Design"
        }
      ],
      "bidders": [
        "5ffc39216f084e278c2f1a6b"
      ],
      "amountPaidToTasker": 0,
      "taskStatus": "Active",
      "currentStatus": "Running",
      "taskCompleted": false,
      "pinned": false,
      "taskEscrowAmount": 520,
      "enabled": true,
      "isDelete": false,
      "_id": "5ffea12df7b55f22c8cec516",
      "heading": "Requirement for logo design",
      "description": "Requirement for logo design for a brand company",
      "rate": 50,
      "author": {
        "address": {
          "country": "USA",
          "city": "Chicago",
          "nationality": "Indian"
        },
        "profileStats": {
          "brand": false,
          "avgRating": 0,
          "favouriteTasks": [
            "5ffea12df7b55f22c8cec516"
          ],
          "favouriteTaskers": [
            "60014f4709a7f41478c561e8"
          ]
        },
        "_id": "5ffc39216f084e278c2f1a6b",
        "firstName": "Digant",
        "lastName": "Garude",
        "nickName": "dg"
      },
      "numericId": 2,
      "posted_date": "2021-01-13T07:28:45.352Z",
      "createdAt": "2021-01-13T07:28:45.359Z",
      "updatedAt": "2021-01-30T07:41:10.792Z",
      "__v": 0,
      "finalBid": "6001253339a78b288c630f4c",
      "finalBidder": {
        "address": {
          "country": "USA",
          "city": "Chicago",
          "nationality": "Indian"
        },
        "profileStats": {
          "brand": false,
          "avgRating": 5,
          "favouriteTasks": [],
          "favouriteTaskers": []
        },
        "_id": "5ffeb359e673162164ef2010",
        "firstName": "Digant",
        "lastName": "Garude",
        "nickName": "dag"
      },
      "contractId": "0.0.257846",
      "finalBidderSuperskillId": "60014f4709a7f41478c561e8"
    }
    const taskDetails = props.taskDetails;
    try {
      setLoader(true)
      const requestBody = {
        "toId": taskDetails.finalBidder._id,
        "reviewString": comment,
        "rating": ratingVal,
        "taskId": taskDetails._id,
        "superskillId": taskDetails.finalBidderSuperskillId
      }
      const response = await CREATE_REVIEW(requestBody);
      setLoader(false)
      notifier.success(MESSAGE.SUCCESS.REVIEW_CREATE_SUCESSFULLY)
      props.onClose();
      props.getTasks();
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error('Error', errorString);
    }
  }

  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.reviewForm}>
            <div className="heading">
              <p>How was your overall experience?</p>
            </div>
            <div className="rating">
              <input type="hidden" name="ratingVal" value={ratingVal} />
              <div className="ratingStars">
                {[...Array(5)].map((item, i) => {
                  return (
                    <span key={`rating${i}`}
                      className={i + 1 <= ratingVal ? `star fill` : `star`}
                      onClick={handleStarClick.bind(this, i + 1)}></span>
                  )
                })}
              </div>
            </div>
            {/* <div className="selectSupperSkills">
              <h5>Please Specify the user SuperSkil</h5>
              <div className="superSkillsList">
                {[...Array(10)].map((item, i) => {
                  return (
                    <StyledRadioButton
                      key={`superSkills${i}`}
                      name="superSkills"
                      label="Radio Button Label"
                      id={`radio${i}`}
                      value={`val${i}`}
                    />
                  )
                })}
              </div>
            </div> */}
            <div className="commentBox">
              <TextField
                value={comment}
                placeholder="Write Comment"
                variant="outlined"
                rows={3}
                onChange={handleCommentChange}
                multiline
                fullWidth
              />
            </div>
            <div className="btns">
              <button className="cancel" onClick={handleClose}>Cancel</button>
              <button className="submit" onClick={() => createReview()}>
                {loading ? <img src={Images.ellipsis_loader} alt='' /> : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}


const Styles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },
  paper: {
    height: 350,
    width: 515,
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
  },
  reviewForm: {
    padding: '30px',
    '& .heading': {
      textAlign: 'center',
      marginTop: 7,
      '& p': {
        fontSize: 14,
        color: '#2c2c2c',
        fontWeight: 500
      }
    },
    '& .rating': {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 24,
      borderBottom: '1px solid #dddddd',
      paddingLeft: -30,
      paddingRight: -30,
      '& .ratingStars': {
        '& span': {
          display: 'inline-block',
          width: 45,
          height: 45,
          backgroundImage: `url('${Images.Rating}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer',
          backgroundSize: '35px',
          '&.fill': {
            backgroundImage: `url('${Images.GreenRating}')`,
          }
        }
      }
    },
    '& .commentBox': {
      marginBottom: 30
    },
    '& .btns': {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginLeft: 7.5,
        marginRight: 7.5,
        width: 150,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none',
        borderRadius: 3,
        backgroundColor: 'transparent',
        '&.cancel': {
          border: '1px solid #ED0B0B',
          color: '#ED0B0B',
          fontSize: 14,
          fontWeight: 600
        },
        '&.submit': {
          background: '#6DC7BE',
          color: '#ffffff',
          fontSize: 14,
          fontWeight: 500
        }
      }
    },
    '& .selectSupperSkills': {
      paddingTop: 24,
      paddingBottom: 15,
      '& h5': {
        color: '#2c2c2c',
        fontSize: 14,
      },
      '& .superSkillsList': {
        height: 200,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: 0,
        },
      }
    }
  }
});



export default CreateReviewPopupModal
