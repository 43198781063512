import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/common";
import GoBack from "../../../components/back-btn/GoBack";
import Hide from "../../../components/Hide";
import Loader from "../../../components/Loader";
import PromoteTaskModal from "../../../components/promoteTaskModal";
import Moment from "react-moment";
import { rateConversionUSDTasker } from "../../../components/validator";
import { BreakPoints, checkWalletAvailablity } from "../../../lib/validators";
import { GET_USER_ALL_TIME_DETAILS, REQ_USER_DETAILS } from "../../../services/api-service";
import "./index.scss";

function MyProfile(props) {
  // const [currentUserId, setUserId] = useState("");
  const [loading, setLoader] = useState(false);
  const [userDetails, setUserrDetails] = useState([]);
  const [superSkills, setSuperskills] = useState([]);
  const [uniqSkill, setUniqSkills] = useState([]);
  const [promoteTaskerModal, setPromoteTaskerModal] = useState(false);
  const [userAlltimeDetails, setUserAllTimeDetails] = useState({});

  useEffect(() => {
    // Component Will mount code will go here
    var userId = localStorage.getItem("currentUser");
    // setUserId(userId);
    getUserDetails(userId);
    return () => {
      // Component Will unmount will go here
    };
  }, []);

  const getUserDetails = async (id) => {
    try {
      setLoader(true);
      let currentUser = localStorage.getItem("currentUser");

      const requestBody = {
        userId: currentUser,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      const altResponse = await GET_USER_ALL_TIME_DETAILS(requestBody);
      const details = response.data.result;
      setLoader(false);
      setUserrDetails(details);
      setUserAllTimeDetails({ ...altResponse.data.result });
      const Superskills = response.data.result.profileStats.superskills;
      setSuperskills(rateConversionUSDTasker(Superskills));
      getUniqSkills(Superskills);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const getUniqSkills = (Superskills) => {
    const uniqeSkills = new Set();
    Superskills.map((Superskill) => {
      return Superskill.skills.map(
        (skill) => uniqeSkills.add(skill.name)
        // //console.log(skill)
      );
    });
    let myArr = Array.from(uniqeSkills);
    setUniqSkills(myArr);
  };

  const addSuperSkill = () => {
    props.history.push("edit-profile/create-superskill");
  };

  const editProfile = () => {
    props.history.push("edit-profile");
  };

  //Modal Oprations
  const closePromoteModal = () => {
    setPromoteTaskerModal(false);
  };
  //Open Modal
  const promoteTaskerModalOpen = () => {
    let isWalletLinked = checkWalletAvailablity();
    if (!isWalletLinked) {
      props.history.push("/home/wallet?walletLinked=false");
    }
    setPromoteTaskerModal(true);
  };

  const renderLeftSide = () => {
    // //console.log(userDetails);
    return (
      <div className="profile-description">
        <div className="profile">
          <div className="profile-top">
            <div className="profile-image">
              <img src={userDetails.profileImgUrl} alt="" />
              <div className={`box ${userDetails.availability ? "active" : ""}`}></div>
            </div>
            <div className="profile-content">
              <div className="info">
                <div className="info-name"> {userDetails.nickName}</div>
                <div className="name">
                  {userDetails.firstName} {userDetails.lastName}
                </div>
                {/* <hr /> */}
                {userDetails.address && (
                  <div className="tasker-location">
                    <img src={Images.location} alt={""} />
                    <p>
                      {userDetails.address.city} {userDetails.address.country}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Reputations */}
          <div className="reputation">
            <h3>Reputations</h3>
            <div className="badges">
              <div className="badges-name">Overall Rating</div>
              <div className="badges-icon">
                {userDetails.profileStats && (
                  <>
                    {userDetails?.profileStats?.avgRating.toFixed(2)}
                    <img src={Images.GreenRating} alt="" />
                  </>
                )}
              </div>
            </div>
            <div className="badges">
              <div className="badges-name">Badges Earned</div>
            </div>
          </div>

          {/* All time Details */}
          <div className="reputation">
            <h3>All time Details</h3>
            <div className="badges">
              <div className="badges-name">Total Earnings</div>
              <div className="badges-icon">{userDetails.amount ? userDetails.amount.totalEarned.toFixed(4) : ""} hbar</div>
            </div>
            <div className="badges">
              <div className="badges-name">Total Tasks</div>
              <div className="badges-icon">{userAlltimeDetails.totalTasks}</div>
            </div>
            <div className="badges">
              <div className="badges-name">Total Hours</div>
              <div className="badges-icon">{userAlltimeDetails.totalHours ? userAlltimeDetails.totalHours.toFixed(4) : ""}</div>
            </div>

            <div className="badges">
              <div className="badges-name">Member Since</div>
              <div className="badges-icon">
                <Moment format="MMMM YYYY">{userDetails.createdAt}</Moment>
              </div>
            </div>
            <div className="permoteTasker">
              <button onClick={promoteTaskerModalOpen}>Promote yourself</button>
            </div>
          </div>
        </div>

        {/* Skills */}
        <div className="profile-skill">
          <h3>Subskills</h3>
          {uniqSkill ? (
            <div className="skills-details">
              {uniqSkill.map((uniq, i) => (
                <div className="top-skill" key={i}>
                  {uniq}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSuperSkills = () => {
    return (
      <div className="profile-description-content-body">
        {superSkills &&
          superSkills.length &&
          superSkills.map((superskills, i) => (
            <div className="task-wrapper" key={`superSkill${i}`}>
              <div className="profile-content">
                <div className="task-name">{superskills.title}</div>
                <div className="task-pay">
                  <p>Hourly Rate &nbsp;</p>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <div className="hbars" title={superskills.fullHbars}>
                      {superskills.ratePerHour}
                    </div>
                    &nbsp;{"ℏ"}/hr
                    <span>{`(${superskills.usdRate} USD)`}</span>
                  </div>
                </div>
              </div>
              <div className="task-info">
                <p>{superskills.description}</p>
                <div className="skills">Subskills Required</div>
                <div className="skills-details">
                  {superskills.skills.map((skill, i) => (
                    <div className="top-skill" key={i}>
                      {skill.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="Profile-Description-page">
      {loading ? (
        <div style={{ height: "70vh" }}>
          <Loader />
        </div>
      ) : (
        <div className="Profile-Description-page-content">
          <Hide type="up" size={BreakPoints.sm}>
            <div className="mobile-top-nav">
              <GoBack />
              <button className="edit-btn" onClick={editProfile}>
                Edit Profile
              </button>
            </div>
          </Hide>
          {renderLeftSide()}

          <div className="profile-description-content">
            <div className="profile-description-content-header">
              <div className="left">
                <div className="profile-description-content-headerSlider">Profile Summary</div>
                <Tooltip title="Coming Soon" placement="top-end" arrow>
                  <div className="profile-description-content-headerSlider">
                    portfolio&nbsp;
                    <img src={Images.informationIcon} alt="i" />
                  </div>
                </Tooltip>
              </div>
              <Hide type="down" size={BreakPoints.sm}>
                <button className="edit-btn" onClick={editProfile}>
                  Edit Profile
                </button>
              </Hide>
            </div>

            {renderSuperSkills()}

            <div className="add-button-container">
              <button onClick={addSuperSkill}> Add Superskill</button>
            </div>
          </div>
        </div>
      )}
      <PromoteTaskModal open={promoteTaskerModal} onClose={closePromoteModal} modelFor={"tasker"} />
    </div>
  );
}
export default MyProfile;
