import { Backdrop, Fade, IconButton, InputAdornment, Modal, TextField, Tooltip, withStyles } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Images } from "../../../assets/common";
import GoBack from "../../../components/back-btn/GoBack";
import Hide from "../../../components/Hide";
import notifier from "../../../components/notifier";
import { getErrorString, getFavriteTask, rateConversionUSDTask } from "../../../components/validator";
import ViewBidOnTask from "../../../components/ViewBidOnTask";
import { MESSAGE } from "../../../constants/messages";
import { TaskbarUserContext } from "../../../context/TaskbarUserContext";
import { BreakPoints, getParamsByName, StyledBackdrop } from "../../../lib/validators";
import { CREATE_BID, GET_TASKS, RECOMAND_TASK_TO_TASKER, REQ_FAVOURITE_TASK, REQ_SUPERSKILLSDETAILS, REQ_USER_DETAILS, REQ_BID_DETAILS } from "../../../services/api-service";
import "./index.scss";

const BidNow = (props) => {
  const [loading, setLoader] = useState(false);
  const [favStatus, setFavStatus] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [rate, setRate] = useState("");
  const [weekLimit, setWeekLimit] = useState("");
  const [description, setDescription] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [action, setAction] = useState("");
  const [task, setTask] = useState({});
  const [favTasks, setFavTasks] = useState([]);
  const [superSkills, setSuperSkills] = useState([]);
  const [superSkillID, setSuperSkillID] = useState([]);
  const [isAuthor, setIsAuthor] = useState(false);
  const [rctModalOpen, setRctModalOpen] = useState(false);
  const [rcTasker, setRcTasker] = useState("");
  const [sendingRC, setSendingRC] = useState(false);
  const [mobileApply, setMobileApply] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [biddingEnabledOnTask, setBiddingEnabledOnTask] = useState(false);

  //context
  const { isUserLogIn } = useContext(TaskbarUserContext);

  useEffect(() => {
    getUserDetails();
    getURLValues();
    getSuperskills();
  }, []);

  const handleFavClick = () => {
    setFavStatus(() => !favStatus);
  };

  const getURLValues = () => {
    let action = getParamsByName("action");
    let taskId = getParamsByName("taskId");
    setAction(action ? action : "view");
    setTaskId(taskId ? taskId : props.match.params.taskId);
    getTask(taskId ? taskId : props.match.params.taskId);
  };

  const getUserDetails = async (id) => {
    const userId = localStorage.getItem("currentUser");
    try {
      const requestBody = {
        userId: id ? id : userId,
      };
      const response = await REQ_USER_DETAILS(requestBody);
      let userDetails = response.data.result;
      if (id) {
        setUserDetails(userDetails);
      } else {
        const favTasks = getFavriteTask(userDetails).map((d) => d._id);
        setFavTasks(favTasks);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTask = async (taskId) => {
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await GET_TASKS(requestBody);
      let Task = response.data.result;
      Task = rateConversionUSDTask(Task);
      const userId = localStorage.getItem("currentUser");
      const isAuthor = userId === Task[0].author._id;
      setTask(Task[0]);
      setIsAuthor(isAuthor);
      getUserDetails(Task[0].author._id);
      setPaymentType(Task[0].paymentTerms.paymentType);
      setBiddingEnabledOnTask(Task[0].paymentTerms.biddingEnabled);
    } catch (e) {
      console.log("rahul", e);
      console.log("ERROR in handleLogin:::");
    }
  };

  const FavouriteOpration = async (superSkillID, op) => {
    try {
      const requestBody = {
        favouriteTaskId: superSkillID,
        operation: op,
      };
      const response = await REQ_FAVOURITE_TASK(requestBody);
      const fav = response.data.result.profileStats.favouriteTasks;
      setFavTasks(fav);
    } catch (e) {
      console.log(e);
      console.log("ERROR in Favourite:::");
    }
  };

  const getSuperskills = async () => {
    let currentUser = localStorage.getItem("currentUser");
    try {
      const requestBody = {
        userId: currentUser,
      };
      const response = await REQ_SUPERSKILLSDETAILS(requestBody);
      const details = response.data.result;
      setSuperSkills(details);
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    switch (id) {
      case "rate":
        setRate(value);
        break;
      case "weekLimit":
        setWeekLimit(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "superSkill":
        setSuperSkillID(value);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const createBid = async () => {
    try {
      setLoader(true);
      const requestBody = {
        taskerSuperskillId: superSkillID,
        taskId: taskId,
        amount: rate,
        weeklyLimit: weekLimit,
        proposalDescription: description ? description : "_",
      };
      const response = await CREATE_BID(requestBody);
      setLoader(false);
      if (response.data.status === "success") {
        if (description) {
          notifier.success(MESSAGE.SUCCESS.APPLIED_SUCESSFULLY);
        } else {
          notifier.success(MESSAGE.SUCCESS.BID_CREATED_SUCESSFULLY);
        }
        setTimeout(() => {
          props.history.push(`/Task/list-task`);
        }, 1000);
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const handleRecomandTask = () => {
    setRctModalOpen(true);
  };

  const hadnleRctModalClose = () => {
    setRctModalOpen(false);
  };

  const sendRecomandation = async () => {
    setSendingRC(true);
    const reqBody = {
      nickName: rcTasker,
      taskId: taskId,
    };

    try {
      const response = await RECOMAND_TASK_TO_TASKER(reqBody);
      if (response.data.status === "success") {
        notifier.success("Recomndation Email Send Successfully");
        setSendingRC(false);
        setRcTasker("");
        setRctModalOpen(false);
      }
    } catch (e) {
      setSendingRC(false);
      notifier.error("Error", getErrorString(e));
    }
  };

  const handleShareButtonClick = async (numericId) => {
    const taskShortUrl = `https://tsk.bar/t/${numericId}`;
    const textContent = `I completed my #Hedera22 Hackathon quest. See my results on @mytaskbar. \n 👇🏼 \n ${taskShortUrl} \n #H22Quest`;
    navigator.clipboard.writeText(textContent);
    if (navigator.share) {
      navigator.share({
        title: "Taskbar task",
        text: textContent,
        url: taskShortUrl,
      });
    }
    notifier.success("Task url is copied to clipboard", `https://tsk.bar/t/${task.numericId}`);
  };

  return (
    <div className="Profile-Description-page">
      <div className="page-header">
        <GoBack />
        <Hide type="up" size={BreakPoints.sm}>
          <Link to={`${Object.keys(task).length ? task.author.nickName : ""}`}>View Profile</Link>
        </Hide>
      </div>
      <div className="Profile-Description-page-content">
        {mobileApply ? <div className="backdrop"></div> : null}
        <div className={mobileApply ? "profile-description bidPage open" : "profile-description bidPage"}>
          <Hide type="up" size={BreakPoints.sm}>
            <span onClick={() => setMobileApply(false)} className="closeMobileIcon">
              <img src={Images.IconForCloseModal} alt="" />
            </span>
          </Hide>
          <div className="profile">
            <Hide type="down" size={BreakPoints.sm}>
              {userDetails ? (
                <div className="profile-top">
                  <div className="profile-image">
                    {/* <img src={profPic} /> */}
                    <img src={userDetails.profileImgUrl} alt={""} />
                    <div className={`box ${userDetails.availability ? "active" : ""}`}></div>
                  </div>
                  <div className="profile-content">
                    <div className="info">
                      <div className="info-name">{userDetails.nickName} </div>
                      {userDetails.fnameVisibilty ? <div className="name">{`${userDetails.firstName} ${userDetails.lastName}`}</div> : null}
                      {/* <hr /> */}
                      {userDetails.address ? (
                        <div className="tasker-location">
                          <img src={Images.location} alt={""} />
                          {userDetails.address.city}
                          {userDetails.address.country}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <br /> */}
              {userDetails ? (
                <div className="reputation">
                  <h3>Reputations</h3>
                  <div className="badges">
                    <div className="badges-name">Overall Rating</div>
                    {/* <div className="badges-icon">{userDetails.profileStats ? <>{userDetails.profileStats.avgRating} {"  "}<img src={Images.GreenRating} /></> : null}</div> */}
                    <div className="badges-icon">
                      {userDetails.profileStats ? (
                        <>
                          {userDetails.profileStats.avgRating.toFixed(2)} {"  "}
                          <img src={Images.GreenRating} alt="" />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="badges">
                    <div className="badges-name">Badges Earned</div>
                    <div className="badges-icon">
                      <img src={Images.Rating} alt="" />
                      <img src={Images.Rating} alt="" />
                      <img src={Images.Rating} alt="" />
                    </div>
                  </div>
                  <div className="viewProfile">
                    <Link to={`/${task.author.nickName}`}>View Profile</Link>
                  </div>
                </div>
              ) : null}
            </Hide>
            {/* <br/> */}
            {Object.keys(task).length > 0 && !isAuthor && isUserLogIn && (action === "bid" || action === "apply") ? (
              <div className="reputation biddingForm">
                <div className="biddingForm__heading">
                  <h3 className="biddingForm__heading--text">{action === "bid" ? "Bid On This Task" : "Apply For This Task "}</h3>
                  <div className="biddingForm__heading__closingDetails">
                    <span className="biddingForm__heading__closingDetails--label">{action === "bid" ? <>Bid Close On</> : <>Apply Before</>}</span>
                    <span className="biddingForm__heading__closingDetails--value">
                      <Moment format="DD-MM-YYYY">{task.paymentTerms.bidExpiryDate}</Moment>
                    </span>
                  </div>
                </div>
                <div className="biddingForm__body">
                  {action === "bid" ? (
                    <BidForm rate={rate} weekLimit={weekLimit} handleInputChange={handleInputChange} createBid={createBid} loading={loading} superSkills={superSkills} paymentType={paymentType} biddingEnabled={biddingEnabledOnTask} />
                  ) : null}
                  {action === "apply" ? (
                    <ApplyForm
                      rate={rate}
                      weekLimit={weekLimit}
                      description={description}
                      handleInputChange={handleInputChange}
                      createBid={createBid}
                      loading={loading}
                      superSkills={superSkills}
                      paymentType={paymentType}
                      biddingEnabled={biddingEnabledOnTask}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/*Right Side Content*/}
        <div className="profile-description-content">
          <div className="profile-description-content-header">
            <div className="left">
              <div className="profile-description-content-headerSlider">Task Details</div>
            </div>
            <div className="shareButton" style={{ marginLeft: "auto" }}>
              {/* <IconButton size="small" onClick={() => handleShareButtonClick(task.numericId)}>
                <ShareIcon />
              </IconButton> */}
            </div>
          </div>

          {/* Profile Description Body */}
          {Object.keys(task).length > 0 ? (
            <div className="profile-description-content-body">
              <div className="back-btn">
                {/* <GoBack /> */}
                <div className="profile-content">
                  <div className="task-name heading-bottom-lining">
                    {/* {superskills.title} */}
                    {task.heading}
                  </div>
                  {action !== "view" ? (
                    <div className="task-pay">
                      <StyledTooltip title={favTasks.includes(task._id) ? " Remove Form Favorite" : "Add To Favorite"} placement="top" arrow>
                        <div className="fav-button" onClick={handleFavClick}>
                          {favTasks.includes(task._id) ? (
                            <span className="filled-fav">
                              <FavoriteIcon style={{ color: "#6DC7BE" }} className="favicon" onClick={() => FavouriteOpration(task._id, "unfavourite")} />
                            </span>
                          ) : (
                            <img src={Images.favIcon} alt={""} className="favicon" onClick={() => FavouriteOpration(task._id, "favourite")} />
                          )}
                        </div>
                      </StyledTooltip>
                      <StyledTooltip title="Recommand Task" placement="top" arrow>
                        <div className="share-btn" onClick={handleRecomandTask}>
                          <img src={Images.RecommendTaskBtn} alt="" />
                        </div>
                      </StyledTooltip>
                      <div className="referToSomeOne"></div>
                    </div>
                  ) : null}
                </div>
                {/*Tasks Related Meta data box*/}
                {task.paymentTerms.hourly ? (
                  <div className="taskMeta-data">
                    {!task.paymentTerms.biddingEnabled ? (
                      <div className="taskMeta-data__item">
                        <span className="taskMeta-data__item--label">Hours Needed</span>
                        <span className="taskMeta-data__item--value">
                          {task.paymentTerms.hourly.hoursPerWeek} {"hrs/week"}
                        </span>
                      </div>
                    ) : null}

                    <div className="taskMeta-data__item">
                      <span className="taskMeta-data__item--label">Duration</span>
                      <span className="taskMeta-data__item--value">{task.paymentTerms.hourly.taskDuration < 4 ? `${task.paymentTerms.hourly.taskDuration} week/s` : `${task.paymentTerms.hourly.taskDuration / 4} Month/s`}</span>
                    </div>

                    <div className="taskMeta-data__item">
                      <span className="taskMeta-data__item--label">{!task.paymentTerms.biddingEnabled ? "Hourly Rate" : "Hourly Price task"}</span>
                      {!task.paymentTerms.biddingEnabled ? (
                        <span className="taskMeta-data__item--value">
                          {task.rate} ℏ(s)/hr
                          <span className="taskMeta-data__item--value--usd">{`(${task.usdRate} USD)`}</span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="taskMeta-data">
                    <div className="taskMeta-data__item">
                      <span className="taskMeta-data__item--label">Task Deadline</span>
                      <span className="taskMeta-data__item--value">
                        <Moment format="DD-MM-YYYY">{task?.paymentTerms?.fixed?.taskDeadline}</Moment>
                      </span>
                    </div>

                    <div className="taskMeta-data__item">
                      <span className="taskMeta-data__item--label">{!task.paymentTerms.biddingEnabled ? " Fixed Amount" : "Fixed Amount Task"}</span>
                      {!task.paymentTerms.biddingEnabled ? (
                        <span className="taskMeta-data__item--value">
                          {task.rate} hbars <span className="taskMeta-data__item--value--usd">{`(${task.usdRate} USD)`}</span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}

                {/*Tasks description*/}
                <div className="task-info">
                  <p>{task.description}</p>
                </div>
                <div className="skills heading-bottom-lining">Subskills Required</div>
                <div className="skills-details">
                  {task.skills.map((skill) => (
                    <div className="top-skill" key={skill._id}>
                      {skill.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {action === "viewBidsOnTask" ? <AllBidsOnTask task={task} taskId={taskId} isAuthor={isAuthor} /> : null}
        </div>
      </div>
      {action !== "view" && isUserLogIn ? (
        <Hide type="up" size={BreakPoints.sm}>
          <div className="button">
            <button onClick={() => setMobileApply(true)}>{action === "bid" ? "Bid Now" : "Apply Now"}</button>
          </div>
          <div className="backDrop"></div>
        </Hide>
      ) : null}
      <Modal
        aria-labelledby="Recommend-task"
        aria-describedby="Recommend this task to other user"
        className="recomandTaskModal"
        open={rctModalOpen}
        onClose={hadnleRctModalClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* {loading ? <Loader /> : */}
        <Fade in={rctModalOpen}>
          <div className="recomandTaskModal__paper">
            <p>Whom do you want to recommend this task?</p>
            <div className="from-feild">
              <TextField variant="outlined" name="tasker_nicknamr" fullWidth label="Enter Username" value={rcTasker} onChange={(e) => setRcTasker(e.target.value)} />
            </div>
            <div className="btns">
              <button className="cencel" onClick={hadnleRctModalClose}>
                Cancel
              </button>
              <button className="send" onClick={sendRecomandation}>
                {sendingRC ? <img src={Images.ellipsis_loader} alt="loader" /> : "Send"}
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const BidForm = (props) => {
  const isFixedTask = props.paymentType === "hourly" ? false : true;
  return (
    <div className=" biddingForm__body--form biddingForm__body--forBid">
      <div className="input-control">
        <label>{props.paymentType === "hourly" ? "Rate/hr" : "Fixed Amount"}</label>
        <TextField
          type="number"
          label="Enter Price"
          className="input"
          InputProps={{
            endAdornment: <InputAdornment position="end">Hbar</InputAdornment>,
          }}
          variant="outlined"
          fullWidth
          size="small"
          name={"rate"}
          value={props.rate}
          onChange={props.handleInputChange}
        />
      </div>
      <div className="input-control">
        <label>{isFixedTask ? "Estimated Days" : "Weekly Limit"}</label>
        <TextField
          type="number"
          label={isFixedTask ? "Enter no of Days" : "Enter Time in Hours"}
          className="input"
          InputProps={{
            endAdornment: <InputAdornment position="end">Hours</InputAdornment>,
          }}
          variant="outlined"
          fullWidth
          size="small"
          name={"weekLimit"}
          value={props.weekLimit}
          onChange={props.handleInputChange}
        />
      </div>
      <div className="input-control">
        <label>Super Skills</label>
        <div className="input-container">
          <select name="superSkill" onChange={props.handleInputChange}>
            <option value="">Select Super Skill</option>
            {props.superSkills.map((d) => (
              <option value={d._id} key={d._id}>
                {d.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="submit-btn">
        <button onClick={props.createBid}>{props.loading ? <img style={{ width: "20px" }} alt="" src={Images.ellipsis_loader} /> : "Place Bid"}</button>
      </div>
    </div>
  );
};

const ApplyForm = (props) => {
  const isFixedTask = props.paymentType === "hourly" ? false : true;
  return (
    <div className="biddingForm__body--form biddingForm__body--forApply">
      <div className="input-control">
        <label>{props.paymentType === "hourly" ? "Rate/hr" : "Fixed Amount"}</label>
        <TextField
          type="number"
          label="Enter Price"
          className="input"
          InputProps={{
            endAdornment: <InputAdornment position="end">Hbar</InputAdornment>,
          }}
          variant="outlined"
          fullWidth
          size="small"
          name={"rate"}
          value={props.rate}
          onChange={props.handleInputChange}
        />
      </div>
      <div className="input-control">
        <label>{isFixedTask ? "Estimated Days" : "Weekly Limit"}</label>
        <TextField
          type="number"
          label={isFixedTask ? "Enter no of Days" : "Enter Time in Hours"}
          className="input"
          InputProps={{
            endAdornment: <InputAdornment position="end">{isFixedTask ? "Days" : "Hours"}</InputAdornment>,
          }}
          variant="outlined"
          fullWidth
          size="small"
          name={"weekLimit"}
          value={props.weekLimit}
          onChange={props.handleInputChange}
        />
      </div>
      <div className="input-control">
        <label>Super Skills</label>
        <div className="input-container">
          <select name="superSkill" onChange={props.handleInputChange}>
            <option value="">Select Super Skill</option>
            {props.superSkills.map((d) => (
              <option value={d._id} key={`skill${d._id}`}>
                {d.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="input-control">
        <label>Describe Your Proposal</label>
        <TextField placeholder="What makes you best Tasker for this project" variant="outlined" rows={5} multiline fullWidth name={"description"} value={props.description} onChange={props.handleInputChange} />
      </div>
      <div className="submit-btn">
        <button onClick={props.createBid}>{props.loading ? <img style={{ width: "20px" }} alt="" src={Images.ellipsis_loader} /> : "Apply"}</button>
      </div>
    </div>
  );
};

const AllBidsOnTask = (props) => {
  const { taskId, task, isAuthor } = props;
  const [bids, setBids] = useState([]);
  const [bidType, setBidType] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isAuthor) {
      getAllBids();
    }
    setBidType(task.paymentTerms && task.paymentTerms.biddingEnabled ? "Bids" : "Proposals");
  }, [task, isAuthor]);

  const getAllBids = async () => {
    try {
      const requestBody = {
        taskId: taskId,
      };
      const response = await REQ_BID_DETAILS(requestBody);
      const _bids = response.data.result;
      setBids(_bids);
    } catch (error) {
      console.log(error);
    }
  };

  //If user is Authorized to see Bids on task

  return (
    <div className="td_v_bids">
      <div className="td_v_bids__heading  heading-bottom-lining">Bids or Proposals On Task</div>
      {!isAuthor ? (
        <div>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Your are Not Authorized to View -- <strong>Only Task Author can See.</strong>
          </Alert>
        </div>
      ) : (
        <div className="bidsList">
          {loading ? "Loading..." : null}
          {bids.map((bid, index) => {
            return <ViewBidOnTask setLoadingTrue={() => setLoading(true)} setLoadingFalse={() => setLoading(false)} bidDetails={bid} bidType={bidType} key={index} isHired={task.finalBidder ? true : false} {...props} />;
          })}
        </div>
      )}
    </div>
  );
};

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2c2c2c",
    color: "#ffffff",
    fontSize: 12,
  },
}))(Tooltip);

export default BidNow;
