import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import cookieStorage from "./services/cookie-storage";
import notifier from "./components/notifier";
import {getErrorString} from "./components/validator";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyDn9_1znx1lHUbiOHCXcsKjqQWp9gXbsIk",
//     authDomain: "taskbar-c4855.firebaseapp.com",
//     projectId: "taskbar-c4855",
//     storageBucket: "taskbar-c4855.appspot.com",
//     messagingSenderId: "613294472518",
//     appId: "1:613294472518:web:f5c1fd67529c93c864b947",
//     measurementId: "G-NWNN7SXSG9"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyAfhiYDjRmw4xBm1oIGO9WUmCkxis_wpjQ",
  authDomain: "taskbarmvp.firebaseapp.com",
  projectId: "taskbarmvp",
  storageBucket: "taskbarmvp.appspot.com",
  messagingSenderId: "749922939114",
  appId: "1:749922939114:web:0a90d713036025db6e6793",
  measurementId: "G-FNF6CH99B9",
};

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth;
// firebase.initializeApp(firebaseConfig);

export const getToken = (setTokenFound) => {
  console.log('FCM Supported',firebase.messaging.isSupported())
  try {
    const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
    return firebase.messaging.isSupported() ? messaging
      .getToken({
        vapidKey:
          "BIz55JYpEAuF1yIv8_bYKCs1l8PRL_qU8z9b86RACwhjay3lVtA_nXNfMs1DqIvGB0V1GNJf0ItkMDWqRO_o3a0",
      })
      .then((currentToken) => {
        if (currentToken) {
          cookieStorage.createCookie("fcmToken", currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      }) : null
  } catch (e) {
    console.log(e);
    notifier.warning("Firebase Warning", getErrorString(e));
  }
};

export const onMessageListener = () => {
  try {
    const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
    return firebase.messaging.isSupported() ? new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }) : null
  } catch (e) {
    console.log(e);
    notifier.warning("Firebase Warning", getErrorString(e));
  }
};
