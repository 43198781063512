import React, { useState } from 'react';
import {AntTab , AntTabs , TabPanel} from '../../../components/componentList';
import UserList from './userList';
import AddNewUser from './addnewuser';


import './index.scss';


const AdminUser = (props) => {

    const [value, setValue] = useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yPropsUser(index) {
        return {
            id: `adminUser-tab-${index}`,
            'aria-controls': `adminUser-tabpanel-${index}`,
        };
    }


    return (<>
        <div className="admin__body__continer__content__heading">
            <AntTabs value={value} onChange={handleChange} aria-label="Material Customized tabs">
                <AntTab label="Users List"  {...a11yPropsUser(0)} />
                <AntTab label="Add New User"  {...a11yPropsUser(1)} />
            </AntTabs>
        </div>
        <div className="adminUser">
            <TabPanel value={value} index={0} boxPadding={1}>
                <UserList  {...props} />
            </TabPanel>
            <TabPanel value={value} index={1} boxPadding={3}>
                <AddNewUser />
            </TabPanel>
        </div>
    </>
    )
}

export default AdminUser;