import {
  Fade,
  InputAdornment,
  makeStyles,
  Modal,
  withStyles
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Images } from "../../../assets/common/index";
import Hide from "../../../components/Hide";
import history from "../../../components/history";
import notifier from "../../../components/notifier";
import { validateSuperSkills } from "../../../components/validator";
import { BreakPoints, getParamsByName, StyledBackdrop } from "../../../lib/validators";
import { REQ_DELETE_SUPERSKILL, REQ_SKILLS, REQ_SUPERSKILLSDETAILS, REQ_UPDATE_SUPERSKILL } from "../../../services/api-service";
import "./index.scss";


function EditSuperSkill(props) {
  const {state} = useLocation()
  const conversionRate = parseFloat(localStorage.getItem("conversionRate"));
  const [id, setID] = useState("");
  const [errors, updateErrors] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState("hbar");
  const [rate, setRate] = useState("");
  const [portfolio, setportfolio] = useState([
    { portfolioName: "", portfolioLink: "" },
  ]);
  const [loading, setLoader] = useState(false);
  const [skills, setSkills] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [checkSkillField, setCheckSkillField] = useState({
    error: false,
    helperText: "",
  });

  // let data = props.data;

  useEffect(() => {
    if(state?.superSkill) updateData(state.superSkill._id);
  }, []);

  const updateData = async (superSkillID) => {
    const requestBody = {
      superskillId: superSkillID,
    };
    const response = await REQ_SUPERSKILLSDETAILS(requestBody);
    let data = response.data.result[0];
    //console.log("hy", response, data)
    setID(data._id);
    setTitle(data.title);
    setDescription(data.description);
    setRate(data.ratePerHour);
    setSkills(data.skills);
    setportfolio(data.portfolio);
    setPaymentType(data.currency);
  };

  const handlePaymentTypeHourly = (e) => {
    e.stopPropagation();
    setRate((parseFloat(rate) / conversionRate).toFixed(4));
    setPaymentType("hbar");
  };

  const handlePaymentTypeFixed = (e) => {
    e.stopPropagation();
    setRate((parseFloat(rate) * conversionRate).toFixed(4));
    setPaymentType("usd");
  };

  const UpdateSuperSkill = async (event) => {
    //console.log("event", event.target.name)
    if (event.target.name === "draft") {
      const requestBody = {
        superskillId: id,
        title: title,
        description: description,
        skills: skills,
        currency: paymentType,
        ratePerHour: rate,
        portfolio: portfolio,
        draft: true,
      };
      await REQ_UPDATE_SUPERSKILL(requestBody);
    } else {
      const data = { title, description, skills, paymentType, rate, portfolio };
      const errors = validateSuperSkills(data);
      if (!errors) {
        // Good to go!
        const requestBody = {
          superskillId: id,
          title: title,
          description: description,
          skills: skills,
          currency: paymentType,
          ratePerHour: rate,
          portfolio: portfolio,
          draft: false,
        };

        const response = await REQ_UPDATE_SUPERSKILL(requestBody);
        if (response.data.status === "success") {
          notifier.success("Great", "Superskill is added");
          props.history.push(`/home/edit-profile`);
        }
      } else {
        updateErrors(errors);
        if (errors.skills) {
          setCheckSkillField({
            error: true,
            helperText: "Skills Felid should not be empty.",
          })
        }
        notifier.warning("Warning", "Something is not right");
        setLoader(false);
      }
    }
  };

  const handlePortfolioChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...portfolio];
    list[index][name] = value;
    setportfolio(list);
  };

  const handleInputChange = (event, newValue) => {
    const id = event.target.name;
    const value = event.target.value;
    // const checked = event.target.checked;
    switch (id) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "rate":
        setRate(value);
        break;
      case "skills":
        // setSkills(value);
        getAllSkills(value);
        //console.log("hyskill", skills, newValue)
        break;
      case "skillValue":
        //console.log("hyskills", newValue)
        break;

      case "paymentType":
        setPaymentType(value);
        break;

      default:
        console.warn("Something is not right !");
    }
  };

  const getAllSkills = async (skill) => {
    try {
      let allSkills;
      if (skill.length > 2) {
        const param = {
          searchPhrase: skill,
        };
        const response = await REQ_SKILLS(param);
        allSkills = response.data.result;
        setAllSkills(allSkills);
      }
      // else{
      //     await timeout(2000);
      //     const param = {
      //         "searchPhrase": skill
      //     }
      //     const response = await REQ_SKILLS(param);
      //      allSkills=response.data.result
      //     //console.log("skill",allSkills)
      // }
    } catch (e) {
      setLoader(false);
      console.log(e);
      console.log("ERROR in handleLogin:::");
    }
  };



  const addMore = () => {
    setportfolio([...portfolio, { portfolioName: "", portfolioLink: "" }]);
  };

  const onchangeAddSkill = (event, newValue) => {
    let newArray = newValue.map((d, i) => {
      if (typeof d === "string") {
        return { name: d };
      } else {
        return d;
      }
    });
    if (newArray.length <= 10) {
      setSkills(newArray);
    } else {
      notifier.warning("Warning", "Max No of subskills allowed is 10");
      //console.log('yash', newArray)
    }
    //console.log('yash', newArray)
  };

  const deleteSuperSkill = async (skill) => {
    try {
      setLoader(true);
      const requestBody = {
        superskillId: id,
      };
      const response = await REQ_DELETE_SUPERSKILL(requestBody);
      if (response.data.status === "success") {
        props.history.push(`/home/edit-profile`);
      }
      // setAllSkills(allSkills);
    } catch (e) {
      setLoader(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkSkillsOnBlur = (e) => {
    if (e.target.value.trim() !== "") {
      notifier.warning(
        "Warning",
        "Please Press Enter to add New subskill, Which is not in the List.",
        6000
      );
      setCheckSkillField({
        error: true,
        helperText:
          "Please Press Enter to add New subskill, Which is not in the List.",
      });
    } else {
      setCheckSkillField({
        error: false,
        helperText: "",
      });
    }
  };

  //! Redirect to my profile if state doesn't contains valid superskills id.
  if(!state?.superSkill){
    return <Redirect to={'/home/profile'} />
  }

  return (
    <>
      <div className="add-superskill-container">
        <div className="add-superskill-title">Edit superskill</div>
        <div className="add-superskill-content">
          <h3>
            Edit your subskills that you would like to monetize. This will help
            to receive Task matches and be found by other uses who seek your
            skill.
          </h3>
          <div className="add-superskill-form">
            <Hide type="down" size={BreakPoints.sm}>
              <div className="delete">
                <img src={Images.delete} alt="" />
                <h3 style={{ textAlign: "center", padding: "0px" }}>
                  Wish to delete this superskill
                </h3>
                <button onClick={() => handleClickOpen()}>Delete</button>
              </div>
            </Hide>
            <div className={"field-container"}>
              <div className="fieldName">Title</div>
              <TextField
                error={errors.title}
                helperText={errors.title}
                variant="outlined"
                type="text"
                placeholder={"Provide a title for your Superskill"}
                value={title}
                name="title"
                onChange={handleInputChange}
              />
            </div>
            <div className={"field-container"}>
              <div className="fieldName">Description</div>
              <TextField
                error={errors.description}
                helperText={errors.description}
                multiline={true}
                rows={2}
                variant="outlined"
                type="text"
                name="description"
                onChange={handleInputChange}
                value={description}
                placeholder={"Add a detail information of your service"}
              />
            </div>
            <div className={"field-container"}>
              <div className="fieldName">Subskills</div>
              <StyledAutocomplete
                multiple
                id="tags-filled"
                options={AllSkills}
                freeSolo
                value={skills}
                onChange={(event, newValue) =>
                  onchangeAddSkill(event, newValue)
                }
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      {typeof option === "string" ? (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                          deleteIcon={<img src={Images.chipCloseIcon} alt="" />}
                        />
                      ) : (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                          key={index}
                          deleteIcon={<img src={Images.chipCloseIcon} alt="" />}
                        />
                      )}
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    error={errors.skills}
                    helperText={errors.skills}
                    {...params}
                    variant="outlined"
                    placeholder="What subskills and expertise are you looking for?"
                    name="skills"
                    onChange={handleInputChange}
                    error={checkSkillField.error}
                    helperText={checkSkillField.helperText}
                    onBlur={checkSkillsOnBlur}
                  />
                )}
              />
              {skills.length ? (
                <span className="skillsCounter">{skills.length}/10</span>
              ) : null}
            </div>
            <div className={"field-container"}>
              <div className="fieldName">Rate</div>
              <div className="rate-type">
                <div className="currency-select">
                  Currency
                  <div className="btn-container">
                    <div className="type-change-btn">
                      <div
                        className={` ${paymentType === "hbar"
                            ? "active-type"
                            : "disable-type"
                          } `}
                        onClick={handlePaymentTypeHourly}></div>
                      <div
                        className={` ${paymentType === "hbar"
                            ? "active-type-label"
                            : "disable-type-label"
                          } `}>
                        Hbar
                      </div>
                    </div>
                    <div className="type-change-btn">
                      <div
                        className={` ${paymentType === "usd" ? "active-type" : "disable-type"
                          } `}
                        onClick={handlePaymentTypeFixed}></div>
                      <div
                        className={` ${paymentType === "usd"
                            ? "active-type-label"
                            : "disable-type-label"
                          } `}>
                        US dollar
                      </div>
                    </div>
                  </div>
                </div>
                <TextField
                  error={errors.rate}
                  helperText={errors.rate}
                  variant="outlined"
                  type="number"
                  value={rate}
                  placeholder={"400"}
                  name="rate"
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <StyledInputAdornment position="end">
                        {paymentType === "hbar" ? "hbars" : "US dollar"}
                      </StyledInputAdornment>
                    ),
                  }}
                />
                {/* <span className="currencyType"></span> */}
              </div>
            </div>
            <div className={"field-container"}>
              <div className="fieldName">Portfolio</div>
              <div className="portfolio">
                {portfolio.map((x, i) => {
                  return (
                    <>
                      <div className="box">
                        <TextField
                          variant="outlined"
                          type="text"
                          placeholder={"Portfolio Name"}
                          value={x.portfolioName}
                          name="portfolioName"
                          onChange={(e) => handlePortfolioChange(e, i)}
                        />
                        <TextField
                          variant="outlined"
                          type="text"
                          placeholder={"Portfolio link"}
                          value={x.portfolioLink}
                          name="portfolioLink"
                          onChange={(e) => handlePortfolioChange(e, i)}
                        />
                      </div>
                      <div className="btn-box">
                        {portfolio.length - 1 === i && (
                          <div onClick={addMore}>Add</div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="add-superskill-content-footer">
          <div className="btn-container">
            <button className="cancel" onClick={() => history.goBack()}>
              Cancel
            </button>
            <button
              className="publish"
              name="addSuperSkill"
              onClick={UpdateSuperSkill}>
              {loading ? <img src={Images.ellipsis_loader} alt="" /> : 'Update'}
            </button>
            {/* <button className="draft" name="draft" onClick={UpdateSuperSkill}>Save as draft</button> */}
          </div>
          {/* <div className="right">
                        <div className="cancel" onClick={() => history.goBack()}>Cancel</div>
                    </div> */}
        </div>

        <div>
          <DeletePopupAlert
            open={open}
            onClose={handleClose}
            delAction={deleteSuperSkill}
          />
        </div>
      </div>
      <Hide type="up" size={BreakPoints.sm}>
        <div className="delete">
          <img src={Images.delete} alt="" />
          <h3 style={{ textAlign: "center", padding: "0px" }}>
            Wish to delete this superskill
          </h3>
          <button onClick={() => handleClickOpen()}>Delete</button>
        </div>
      </Hide>
    </>
  );
}

const DeletePopupAlert = (props) => {
  const classes = DeletePopupStyles();

  const handleClose = () => {
    props.onClose();
  };

  const deleteSuperSkill = () => {
    props.delAction();
  };

  return (
    <Modal
      aria-labelledby="Delete-alert"
      aria-describedby="Alert Mesage After Requesting Delte SuperSkills"
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className="content">
            <h5>Are you sure? you want to delete this?</h5>
            <p>
              Please note that you will loss all rating and reviews for this
              superskill and this cannot be undone.
            </p>
          </div>
          <div className="buttons">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="del" onClick={deleteSuperSkill}>
              Yes Delete
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const DeletePopupStyles = makeStyles({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    minHeight: 225,
    width: 505,
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 10,
    padding: 24,
    "& .content": {
      textAlign: "center",
      marginTop: 16,
      "& h5, & p": {
        color: "#2c2c2c",
        fontSize: 14,
        marginBottom: 0,
      },
      "& h5": {
        marginBottom: 25,
      },
    },
    "& .buttons": {
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
      "& button": {
        margin: "0 7.5px",
        width: 148,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        outline: "none!important",
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 3,
        "&.cancel": {
          color: "#ED0B0B",
          border: "1px solid #ED0B0B",
        },
        "&.del": {
          backgroundColor: "#6DC7BE",
          color: "white",
          border: "none",
          "&:hover": {
            backgroundColor: "#4DACA2",
          },
        },
      },
    },
  },
});



const StyledInputAdornment = withStyles((theme) => ({
  root: {
    color: "#2c2c2c",
    "& p": {
      color: "#2c2c2c!important",
    },
  },
}))(InputAdornment);

const StyledAutocomplete = withStyles((theme) => ({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
}))(Autocomplete);

export default EditSuperSkill;
