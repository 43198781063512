import { Fade, InputAdornment, makeStyles, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Images } from "../../assets/common";
import StyledRadioButton from "../../components/StyledRadioButton";
import { MESSAGE } from "../../constants/messages";
import { BreakPoints, StyledBackdrop } from "../../lib/validators";
import { INVITE_TASKER } from "../../services/api-service";
import Hide from "../Hide";
import notifier from "../notifier";
import { getErrorString } from "../validator";
import "./index.scss";

const InviteTaskerModal = (props) => {
  const [loading, setLoader] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [rate, setRate] = useState("");
  const [weekLimit, setWeekLimit] = useState("");
  const [description, setDescription] = useState("");
  const [focus, setFocus] = useState("");
  const [taskPaymentType, setTaskPaymentType] = useState("hourly");

  const classes = createStyles();

  const onModalRender = () => {
    setRate(props.tasker ? props.tasker.fullHbars : "");
    setTaskId(props.taskId ? props.taskId : "");
    getInitialFocus();
  };

  const handleClose = () => {
    props.onClose();
  };

  const getInitialFocus = () => {
    let focus = "";
    props.createdTasks.filter((d, i) => {
      if (d._id === props.taskId) {
        focus = i;
      }
      return false;
    });
    setFocus(focus);
  };

  const sendInvitation = async () => {
    try {
      setLoader(true);
      const requestBody = {
        taskerSuperskillId: props.tasker._id,
        taskId: taskId,
        taskerId: props.tasker.userId._id,
        amount: rate,
        weeklyLimit: weekLimit,
        proposalDescription: description,
      };
      const response = await INVITE_TASKER(requestBody);
      setLoader(false);
      if (response.data.status === "success") {
        notifier.success("Success", MESSAGE.SUCCESS.INVITATION_SEND);
        props.onClose();
        if (props.getTaskers) props.getTaskers();
      }
    } catch (e) {
      setLoader(false);
      const errorString = getErrorString(e);
      notifier.error("Error", errorString);
    }
  };

  const handleInputChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    switch (id) {
      case "rate":
        setRate(value);
        break;
      case "weekLimit":
        setWeekLimit(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        console.warn("Something is not right !");
    }
  };

  const handleTask = (task, i) => {
    setTaskId(task._id);
    setFocus(i);
    setTaskPaymentType(task.paymentTerms.paymentType);
  };

  return (
    <Modal
      aria-labelledby="Perposal-Negotioan"
      aria-describedby="Chatbox For Negotiation for Perposal"
      className={`${classes.modal} inviteTaskerModal`}
      open={props.open}
      onClose={handleClose}
      onRendered={onModalRender}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={`${classes.hireNow} hireNow`}>
            <div className="heading">
              <h4 className="heading__label">INVITE TASKER</h4>
              <span className="closeBtn" onClick={handleClose}>
                <img src={Images.IconForCloseModal} alt="" />
              </span>
            </div>
            <div className="hireNowModelBody">
              <h3>Select the tasks for which you want to hire</h3>
              <Hide type="down" size={BreakPoints.sm}>
                <TableContainer className="table-cotainer">
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          <span>Select</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>Task Title</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>Contract Type</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>Budget</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>Status</span>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.createdTasks.map((task, i) => {
                        return (
                          <TableRow key={task._id}>
                            <StyledTableCell>
                              <StyledRadioButton checked={task._id === taskId} id={`tasksList${i}`} name="tasksList" value={i} onChange={() => handleTask(task, i)} />
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: focus === i ? "500" : "normal",
                              }}
                            >
                              {task.heading}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: focus === i ? "500" : "normal",
                              }}
                            >
                              {task.paymentTerms ? (task.paymentTerms.paymentType ? task.paymentTerms.paymentType : "") : ""}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: focus === i ? "500" : "normal",
                              }}
                            >
                              {task.rate} {task.paymentTerms.paymentType === "hourly" ? "ℏ/hr" : "ℏ"}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: focus === i ? "500" : "normal",
                              }}
                            >
                              {task.currentStatus}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Hide>
              <Hide type="up" size={BreakPoints.sm}>
                <div className="taskList">
                  {props.createdTasks.map((task, i) => (
                    <div className="taskList__item" key={task._id}>
                      <div className="taskList__item--radio">
                        <StyledRadioButton checked={task._id === taskId} id={`tasksList${i}`} name="tasksList" value={i} onChange={() => handleTask(task, i)} />
                      </div>
                      <div className="taskList__item__content">
                        <p className="taskList__item__content__item">
                          <span className="taskList__item__content--left">Task Title</span>
                          <span className="taskList__item__content--right">{task.heading}</span>
                        </p>
                        <p className="taskList__item__content__item">
                          <span className="taskList__item__content--left">Contract Type</span>
                          <span className="taskList__item__content--right">{task.paymentTerms ? (task.paymentTerms.paymentType ? task.paymentTerms.paymentType : "") : ""}</span>
                        </p>
                        <p className="taskList__item__content__item">
                          <span className="taskList__item__content--left">Budget</span>
                          <span className="taskList__item__content--right">
                            {task.rate} {task.paymentTerms.paymentType === "hourly" ? "ℏ/hr" : "ℏs"}
                          </span>
                        </p>
                        <p className="taskList__item__content__item">
                          <span className="taskList__item__content--left">Status</span>
                          <span className="taskList__item__content--right"> {task.currentStatus}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Hide>
              <div className="rate-container">
                <div className="input-control">
                  <label>{taskPaymentType === "hourly" ? "ℏ/Hr" : "Amount in ℏs"}</label>
                  <TextField
                    type="number"
                    label="Enter Price"
                    className="input"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">&#8463;</InputAdornment>,
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    name={"rate"}
                    value={rate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-control">
                  <label>{taskPaymentType === "hourly" ? "Weekly Limit in hours" : "Estimated Days"}</label>
                  <TextField
                    type="number"
                    label="Enter Time in Hours"
                    className="input"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{taskPaymentType === "hourly" ? "hrs" : "Days"}</InputAdornment>,
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    name={"weekLimit"}
                    //style={{width: "230px"}}
                    value={weekLimit}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="description-container">
                <label>Describe why you want to hire the tasker</label>
                <div className="input-control">
                  <TextField placeholder="Write Here..." multiline rows={3} fullWidth name="description" variant="outlined" value={description} onChange={handleInputChange} />
                </div>
                <div className="submitBtn">
                  <button onClick={() => sendInvitation()}>{loading ? <img alt="" src={Images.ellipsis_loader} /> : "Send"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const createStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    minHeight: "calc(100vh - 100px)",
    width: 1000,
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 25px )",
      height: "calc(100vh - 30px)",
    },
  },
  hireNow: {
    height: "100%",
    "& .heading": {
      padding: "15px 24px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&__label": {
        fontSize: 16,
        color: "rgba(44, 44, 44, 1)",
        textTransform: "uppercase",
        fontWeight: 600,
        marginBottom: 0,
        "& img": {
          width: 26,
          marginRight: 12,
        },
      },
      "& .closeBtn": {
        marginLeft: "auto",
        cursor: "pointer",
        "& img": {
          width: 14,
        },
      },
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    "&:not(:nth-child(2))": {
      textAlign: "center",
    },
  },
  head: {
    background: "#F8F8F8",
    textTransform: "uppercase",
    padding: "8px 15px",
    border: "none",
    color: "#2C2C2C",
  },
  body: {
    border: "none",
    padding: "11px 15px",
  },
}))(TableCell);

export default InviteTaskerModal;
