import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import NoDataToShow from "../../../components/NoDataToShow";
import ViewBidOnTask from "../../../components/ViewBidOnTask";
import { useAppSelector, useAppDispatch } from "../../../redux/app/hook";
import {
  getTasksAsync,
  getBidDetailsAsync,
  updateTaskUIView,
} from "../../../redux/features/my-tasks/myTasksSlice";

type ViewBidsOrProposalsProps = {
  viewType: "Bids" | "Proposals";
};

const ViewBidsOrProposals = ({ viewType }: ViewBidsOrProposalsProps) => {
  const {
    tasks: { singleTask },
    taskUIView: { section, taskStatus },
    bidDetails,
  } = useAppSelector((state) => state.myTask);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { taskId } = useParams<{ taskId: string }>();

  useEffect(() => {
    batch(() => {
      dispatch(getBidDetailsAsync(taskId));
      dispatch(getTasksAsync({ taskId }));
      appDispatch(updateTaskUIView({ isTaskDetailsPage: true }));
    });
  }, []);

  return (
    <div className="viewBids">
      <h4>{viewType} For task</h4>
      {!isEmpty(bidDetails) ? (
        bidDetails?.map((bid:any, index) => (
          <ViewBidOnTask key={index} bidDetails={bid} bidType={viewType} />
        ))
      ) : (
        <NoDataToShow />
      )}
    </div>
  );
};

export default ViewBidsOrProposals;
