import { Button, Card, CardContent, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, TextField, Typography } from "@material-ui/core";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Images } from "../../assets/common";
import notifier from "../../components/notifier";
import { getErrorString } from "../../components/validator";
import { API } from "../../constants/api";
import { MESSAGE } from "../../constants/messages";
import { isStringAlphaNumericOnly } from "../../lib/validators";
import { CHECK_USER_NAME_AVAIL, UPDATE_BASIC_INFOFILL } from "../../services/api-service";
import "./index.scss";

function BasicInfo(props) {
  const [state, setState] = useState({
    fName: "",
    lName: "",
    userName: "",
    country: "",
    city: "",
    nationality: "",
    checked: false,
    loading: false,
  });

  const [allCountries, setAllCountries] = useState([]);

  const getCountriesList = async () => {
    try {
      const response = await axios.get(API.REST_COUNTRIES_API);
      const countries = response.data.map((d) => d.name.common).sort();
      setAllCountries(countries);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  const [helperText, setHelperText] = useState({
    error: false,
    text: "",
  });
  const [isUserAvail, setIsUserAvail] = useState(true);

  const handleInputChange = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    setState({
      ...state,
      [event.target.name]: event.target.type === "checkbox" ? checked : value,
    });
  };

  const handleUserNameChange = (e) => {
    const { value } = e.target;
    let isMatched = isStringAlphaNumericOnly(value);

    if (!isMatched) {
      setHelperText({
        error: true,
        text: "Try Alphanumeric username without spaces",
      });
    } else {
      setHelperText({
        error: false,
        text: "",
      });
    }
    setState({
      ...state,
      userName: value,
    });
  };

  const updateInfo = async () => {
    const { fName, lName, userName, country, city, nationality, checked } = state;
    if (!fName) {
      notifier.warning(MESSAGE.ERROR.FIRST_NAME_EMPTY);
    } else if (!lName) {
      notifier.warning(MESSAGE.ERROR.LAST_NAME_EMPTY);
    } else if (!userName) {
      notifier.warning(MESSAGE.ERROR.USER_NAME_EMPTY);
    } else if (!isStringAlphaNumericOnly(userName)) {
      notifier.warning(MESSAGE.ERROR.USER_NAME_NOT_VALID);
    } else if (!isUserAvail) {
      notifier.warning(MESSAGE.ERROR.USER_NAME_EXIST);
    } else if (!country) {
      notifier.warning(MESSAGE.ERROR.COUNTRY_EMPTY);
    } else if (!city) {
      notifier.warning(MESSAGE.ERROR.CITY_EMPTY);
    } else if (city.length >= 50) {
      notifier.warning(MESSAGE.ERROR.CITY_INVALID);
    } else
      try {
        setState({
          ...state,
          loading: true,
        });
        const requestBody = {
          firstName: fName,
          lastName: lName,
          fnameVisibilty: checked,
          nickName: userName,
          address: {
            country: country,
            city: city,
            nationality: nationality,
          },
        };
        // //console.log('rahul', requestBody)
        // return;
        await UPDATE_BASIC_INFOFILL(requestBody);
        setState();
        if (window.dataLayer) {
          window.dataLayer.push({ event: "completed_signup" });
        }
        handlePageRedirection();
      } catch (e) {
        setState({
          ...state,
          loading: false,
        });
        const errorString = getErrorString(e);
        notifier.error("Error", errorString);
      }
  };

  const handlePageRedirection = (response) => {
    try {
      let redirectPath;
      redirectPath = "/home/dashboard";
      props.history.push(redirectPath);
    } catch (e) {
      console.log("ERROR in path Re-direction:::", e);
    }
  };

  const getUserNameAvailability = async (event) => {
    let userName = event.target.value;
    if (userName.length > 2) {
      try {
        const requestBody = {
          nickName: userName,
        };
        const response = await CHECK_USER_NAME_AVAIL(requestBody);
        if (!response.data.result) {
          setHelperText({
            error: true,
            text: "Username is already taken.Try another",
          });
        } else {
          setHelperText({
            error: false,
            text: "",
          });
        }
      } catch (e) {
        setHelperText({
          error: false,
          text: "",
        });
      }
    }
  };

  return (
    <div className="basicInfo-page">
      <div className="basicInfo-logo">
        <img src={Images.Logo} alt={""} />
      </div>
      <Grid container justify="center" className="pt-5 basicInfo-layout">
        <Grid>
          <Card className="basicInfo-content-wrapper">
            <CardContent>
              <Typography variant="h3" align="center">
                Require some basic information
              </Typography>
              <Typography className="my-1">This helps us to create your basic portfolio</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="First Name" variant="outlined" name="fName" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Last Name" variant="outlined" name="lName" onChange={handleInputChange} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel control={<Checkbox name="checked" onChange={handleInputChange} />} label="Keep my full name publically visible" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Enter Username"
                    variant="outlined"
                    name="userName"
                    onChange={handleUserNameChange}
                    error={helperText.error}
                    helperText={helperText.text}
                    onBlur={getUserNameAvailability}
                    onFocus={() => setIsUserAvail(true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon style={{ color: "#ccc" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Country"
                    name="country"
                    fullWidth
                    // helperText="Please select your country"
                    variant="outlined"
                    onChange={handleInputChange}
                  >
                    {allCountries.length > 0 &&
                      allCountries.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField fullWidth label="City" variant="outlined" name="city" onChange={handleInputChange} />
                </Grid>
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12} sm={3}>
                  <Button variant="contained" color="primary" onClick={() => updateInfo()} fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default BasicInfo;
